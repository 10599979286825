<template>
  <div class="products-table">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="products-table-title">
      <h1>Price Planning - {{ country.name }}</h1>
      <br />
      <span class="color-aaa">
        Overview of brands and product data change requests
      </span>
    </div>

    <div class="d-flex justify-content-left mt-30 mb-30 ms-30">
      <button
        class="buildup-card-right-panel-button btn btn-outline-dark vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Countries list
        </span>
      </button>
    </div>

    <div class="step-container" style="position: sticky; top: 0; mb-30;">
      <ul class="step-progressbar">
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'Brand manager: submit brand requests.'">
          <span class="progress-bar-text">Brand manager</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'Marketing Analyst/Distribution Manager: approve brand requests.'" v-show="country?.user_roles?.includes('marketing_analyst') || country?.user_roles?.includes('distribution_manager')">
          <span class="progress-bar-text">MA/DM: approve</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'Head of marketing: approve brand request.'">
          <span class="progress-bar-text">Head of marketing: approve</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'Head of marketing: submit country request.'">
          <span class="progress-bar-text">Head of marketing: submit</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'Country head: approve country request.'">
          <span class="progress-bar-text">Country head: approve</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'GPO: approve country request.'">
          <span class="progress-bar-text">GPO: approve</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'MOB: approve country request.'">
          <span class="progress-bar-text">MOB: finish #1</span>
        </li>
        <li class="popup-trigger" style="width: 12%;" v-floating-tooltip="'MOB: finish country request.'">
          <span class="progress-bar-text">MOB: finish</span>
        </li>
      </ul>
      <div id="popup" class="hidden"></div>
    </div>

    <div class="mt-30">
      <Header
        v-if="!$helpers.isEmptyObject(country)"
        :item="country"
        title="Country Data Change Request Status"
        :show-create="showCreateCountryRequestButton"
        :show-submit="showSubmitBrandRequestButton"
        :user-info="userInfo"
        :commentable-type="'CountryDataChangeRequest'"
        @create-parent-request="createParentRequest"
        @submit-parent-request="submitParentRequest"
        @open-discard-modal="openDiscardModal"
        @open-attachments-modal="openAttachmentsModal"
      />

      <BrandRequestsList
        :key="'BrandRequestsList'"
        :brands="itemsList"
        :user-info="userInfo"
        :country="country"
        @open-approve-modal="openApproveModal"
        @open-reject-modal="openRejectModal"
        style="width:1140px;"
      />
    </div>
    <PopupModal
      v-if="popupModalVisible"
      v-model:changeRequest="changeRequest"
      :top="150"
      :left="650"
      :object-name="modalObjectName"
      :user-info="userInfo"
      :modal-action="modalAction"
      :reject-type="rejectType"
      @close="popupModalVisible = false"
      @approve-changes="approveChanges"
      @reject-brand-changes="rejectBrandChanges"
      @reject-product-changes="rejectProductChanges"
      @discard-changes="discardChanges"
    />
    <AttachmentsModal
      v-if="attachmentsModalVisible"
      v-model:attachmentObject="attachmentObject"
      :key="componentKey"
      :top="150"
      :left="650"
      :type="'Country'"
      :user-info="userInfo"
      @close="attachmentsModalVisible = false"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import BrandRequestsList from '@/components/PricePlanning/BrandRequestsList.vue';
import Header from '@/components/PricePlanning/Header.vue';
import AttachmentsModal from '@/components/PricePlanning/Modals/AttachmentsModal.vue';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import { stepCompletedList, clearStepCompletedList } from '@/helpers/stepProgressBar'

export default {
  name: 'BrandPricePlanning',
  components: { Header, BrandRequestsList, AttachmentsModal, PopupModal, FadeLoader },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      itemsList: {},
      priceChangeRequests: {},
      loading: false,
      showCreateCountryRequestButton: false,
      showSubmitBrandRequestButton: false,
      popupModalVisible: false,
      country: {},
      modalAction: String,
      modalObjectName: String,
      rejectType: '',
      componentKey: 0,
      changeRequest: {},
      attachmentObject: {},
      attachmentsModalVisible: false,
    };
  },
  watch: {
    itemsList() {
      if (!this.$helpers.isEmptyArray(this.itemsList)) {
        let statuses = this.itemsList.map((i) => i.status);
        let unique = [...new Set(statuses.filter((e) => e != null && e != ''))];
        this.showCreateCountryRequestButton =
          unique.length == 1 &&
          unique[0] == 'approved' &&
          this.$helpers.isEmptyObject(this.country.data_change_request);
        this.showSubmitBrandRequestButton =
          unique.length == 1 && unique[0] == 'approved';
      } else {
        this.showCreateCountryRequestButton = false;
        this.showSubmitBrandRequestButton = false;
      }
    },
  },
  async beforeMount() {
    await this.loadDataChangeRequests();
  },
  methods: {
    returnToList() {
      this.$router.push(
        `/price_plannings/${this.$route.params.id}/country_data_change_requests`
      );
    },
    async loadDataChangeRequests() {
      this.loading = true;

      const url = `${this.$route.path}/get_planning_data?country_id=${this.$route.query.country_id}`;
      let { data: response } = await api.get(url);
      this.itemsList = response.items_list;
      this.country = response.country;
      this.priceChangeRequests = response.price_change_requests;

      if (this.country?.data_change_request != null) {
        await stepCompletedList(this.country?.data_change_request_status_index, this.country.data_change_request?.status == 'finished');
      } else {
        await clearStepCompletedList();
      }

      this.loading = false;
    },
    openApproveModal(item) {
      this.componentKey = Math.random();
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'approve';
      this.modalObjectName = item.name;
    },
    async approveChanges(item) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/brand_data_change_requests/${item.data_change_request.id}/approve`
        )
        .then(() => {
          this.$helpers.notifySuccess('Brand Data Change Request Approved');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      this.loadDataChangeRequests();
    },
    openRejectModal(changeRequest, rejectType) {
      this.rejectType = rejectType;
      this.changeRequest = changeRequest;
      this.modalAction = 'reject';
      this.modalObjectName = changeRequest.object_name;
      this.popupModalVisible = true;
    },
    async rejectBrandChanges(item, comment) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/brand_data_change_requests/${item.id}/reject`,
          {
            comment,
          }
        )
        .then(() => {
          this.$helpers.notifyWarning('Brand Data Change Request Rejected');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async rejectProductChanges(item, comment) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/${item.id}/reject`,
          {
            comment,
          }
        )
        .then(() => {
          this.$helpers.notifyWarning('Product Data Change Request Rejected');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async createParentRequest() {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
        )
        .then(() => {
          this.$helpers.notifySuccess('Country Price Change Request initialized');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async submitParentRequest(item) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests/${item.id}/submit`
        )
        .then(() => {
          this.$helpers.notifySuccess('Country Data Change Request submitted');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openDiscardModal(item) {
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'discard';
      this.modalObjectName = item.name;
    },
    openAttachmentsModal(countryChangeRequest) {
      this.attachmentObject = countryChangeRequest;
      this.attachmentsModalVisible = true;
    },
    async discardChanges(item) {
      this.componentKey = Math.random();
      let pathObject = item.type.replace(/[A-Z]/g, (letter, index) => { return index == 0 ? letter.toLowerCase() : '_'+ letter.toLowerCase();});
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/${pathObject}s/${item.id}/discard`
        )
        .then(() => {
          this.$helpers.notifyWarning('Country Data Change Request removed');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
  },
};
</script>
