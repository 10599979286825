<template>
  <div class="container">
    <div
      class="hierarchy-item-title fixed"
      :class="{ scrolled: currentScrollPos < 40 }"
    >
      <h1>
        Pricing Principles{{ countryName ? ': ' + countryName : '' }}:
        {{ pageSecondTitle }}
      </h1>
    </div>

    <div class="d-flex justify-content-left row col-lg-12" style="width: 100%">
      <div class="products-table-title" style="margin-left: 10px">
        <template v-if="isBottomAndTop">
          <h5 class="size-one-rem">70% target of selling price ex BNO</h5>
          <h5 class="size-one-rem">60% target of selling price ex partner</h5>
          <div class="row mb-30 infoHeader">
            <h5 class="infoTitle size-one-rem color-aaa">
              *target approved only for Partner Business yet
            </h5>
          </div>
        </template>
        <template v-if="isStandartization">
          <h5 class="size-one-rem">Price for bigger package minus 10%</h5>
        </template>
        <template v-if="isCorridorWholesaler">
          <h5 class="size-one-rem display-inline">Customs unions: +/-10%</h5>
          <br />
          <h5 class="size-one-rem display-inline">Worldwide +/-15%</h5>
          <br />
          <i class="icon principles-ok-icon display-inline" />
          <h5 class="size-one-rem display-inline">
            WS net price is within valid corridor
          </h5>
          <br />
          <i class="icon principles-wrong-icon display-inline" />
          <h5 class="size-one-rem display-inline">
            WS net price is not within valid corridor
          </h5>
        </template>
      </div>
    </div>

    <div class="d-flex justify-content-left mb-30 ms-30">
      <button
        class="buildup-card-right-panel-button btn-outline-dark vertical-middle"
        @click="returnBack"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back
        </span>
      </button>
    </div>

    <StandartizationPrincipleTable
      v-if="
        objectType && isStandartization && objectType == 'profit_center_variant'
      "
      :pricing-principles="pricingPrinciples"
      :object-type="objectType"
      :loading="loading"
    />
    <BottomAndTopPrincipleTable
      v-if="
        (objectType && isBottomAndTop) ||
        (isStandartization && objectType == 'country')
      "
      :pricing-principles="pricingPrinciples"
      :object-type="objectType"
      :loading="loading"
    />
    <CorridorWholesalerTable
      v-if="isCorridorWholesaler"
      :pricing-principles="pricingPrinciples"
      :object-type="objectType"
      :loading="loading"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import BottomAndTopPrincipleTable from '@/components/PricingPrinciples/BottomAndTopPrinciple/BottomAndTopPrincipleTable.vue';
import StandartizationPrincipleTable from '@/components/PricingPrinciples/StandartizationPricing/StandartizationPrincipleTable.vue';
import CorridorWholesalerTable from '@/components/PricingPrinciples/CorridorWholesaler/CorridorWholesalerTable.vue';

export default {
  name: 'PricingPrinciplesDetails',
  components: {
    BottomAndTopPrincipleTable,
    StandartizationPrincipleTable,
    CorridorWholesalerTable,
  },
  props: {
    items: Array,
    type: String,
    currentScrollPos: Number,
  },
  data() {
    return {
      loading: true,
      pricingPrinciples: [],
    };
  },
  computed: {
    isBottomAndTop() {
      return this.$route.path.includes('bottom_and_top_down');
    },
    isStandartization() {
      return this.$route.path.includes('standartization_of_pricing');
    },
    isCorridorWholesaler() {
      return this.$route.path.includes('corridor_of_net_wholesaler_purchases');
    },
    pageSecondTitle() {
      if (this.isBottomAndTop) {
        return 'Bottom up and Top down approach';
      } else if (this.isStandartization) {
        return 'Standardization of pricing within one brand';
      } else if (this.isCorridorWholesaler) {
        return 'Corridor of net wholesaler purchase prices';
      }
      return 'Extraordinary';
    },
    objectType() {
      if (this.$route.query.name) {
        return 'profit_center_variant';
      } else {
        return 'country';
      }
    },
    countryName() {
      return this.$route.query.name;
    },
  },
  async beforeMount() {
    this.loading = true;
    let url;
    if (this.objectType == 'country') {
      url = `${this.$route.path}/get_country_level_data`;
    } else if (this.objectType == 'profit_center_variant') {
      url = `/pricing_principles/${
        this.$route.path.split('/')[2]
      }/get_pcv_level_data?name=${this.$route.query.name}`;
    }

    let { data: response } = await api.get(url);

    this.pricingPrinciples = response.pricing_principles;
    this.loading = false;
  },

  methods: {
    returnBack() {
      let link = this.$route.path.split('/');
      link.pop();
      this.$router.push(link.join('/'));
    },
  },
};
</script>
