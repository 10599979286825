<template>
  <div class="plan-prices">
    <div :class="styleHeadOfTable()" style="z-index: 10;">
      <span>{{ titleOfTable() }}</span>
      <div class="plan-prices__controls">
        <tooltip v-if="checkCountryPage()" style="margin-left: 45px;" :distance="5" :skidding="-390">
          <div class="icon question-mark-icon"></div>
          <template #popper>
            <div>The table below shows the average plan price of the year.</div>
            <div>If you click on the magnifier you will find all plan prices which lead to the average one.</div>
            <div>If you hover over the percentage change you will see the price and exchange rate effect of</div>
            <div>this average price over the year.</div>
          </template>
        </tooltip>
        <ExcelXlsxButton
          class="toggle-button"
          :data="xlsxData"
          :columns="xlsxColumns"
          :file-name="xlsxFileName"
          sheet-name="Default"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22px"
            height="22px"
            viewBox="0 0 26 26"
            :stroke="'#3b7d15'"
          >
            <path
              style="fill: #030104"
              d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
            />
            <path
              style="fill: #ffffff"
              d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
            />
          </svg>
        </ExcelXlsxButton>
        <ToggleSwitch
          v-model="currentMode"
          :show-switch="true"
          :switch-options="modes"
        />
      </div>
    </div>
    <div style="overflow-y: auto;" :style="`${setPlanPriceTableHeight}`" class="scrollbar">
      <table class="plan-prices__table table table-striped table-hover table-sm">
        <thead style="z-index: 5;">
          <tr>
            <th class="frozen-element-top" style="background: #f2efaa; top: 0; !important;">GPMS name</th>
            <th v-for="yearTitle in yearTitles" :key="yearTitle" class="frozen-element-top">
              {{ yearTitle }}
            </th>
          </tr>
        </thead>
        <tbody>
          <b-spinner v-if="!loaded" type="border" small class="tab-spinner" />
          <tr v-for="pcv in pcvs" :key="pcv.id">
            <td @click="onClick(pcv.id)">
              {{ pcv.gpms_name }}
            </td>
            <td
              v-for="yearData in pcv.years_data"
              :key="`${pcv.id}_${yearData.year}`"
            >
              <PlanPricesDiff
                v-if="showDiffForYear(yearData)"
                :pcv="pcv"
                :date-key="yearData.year"
                :eur-diff="modeValue(yearData, 'diff_eur')"
                :local-diff="modeValue(yearData, 'diff_local')"
                :exchange-rate-effect="modeValue(yearData, 'exchange_rate_diff')"
              />
              <div class="plan-prices__price">
                <div class="plan-prices__eur-mean">
                  {{ formatPrice(eurMean(yearData).value) }}
                  {{ $helpers.getCurrencySymbol('EUR') }}
                  <i
                    class="icon search-loope-icon display-inline"
                    @click="showPriceModal($event, pcv, yearData)"
                  />
                </div>
                <div
                  v-if="
                    localMean(yearData).value === 0.0 || localMean(yearData).value
                  "
                  class="plan-prices__local-mean"
                >
                  {{ formatPrice(localMean(yearData).value) }}
                  {{ $helpers.getCurrencySymbol(localMean(yearData).currency) }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CommentsModal
      v-if="showPricesModal"
      :top="priceModalTop"
      :left="priceModalLeft"
      :container-width="325"
      parent-class="plan-prices__modal"
      @close="closePricesModal"
    >
      <h1>{{ currentPriceModalPcv.gpms_name }}</h1>
      <div>
        <span>Yearly price changes:</span>
        <div class="plan-prices__monthly-changes-container">
          <p
            v-for="priceChange in currentPriceModalPriceChanges"
            :key="priceChange.date"
            class="plan-prices__monthly-change"
          >
            {{ priceChange.date }} ->
            <span>
              {{ priceChange.priceEur.value }}
              {{ $helpers.getCurrencySymbol(priceChange.priceEur.currency) }}
            </span>
            <span v-if="priceChange.priceLocal && priceChange.priceLocal.value">
              (
              {{ priceChange.priceLocal.value }}
              {{ $helpers.getCurrencySymbol(priceChange.priceLocal.currency) }}
              )
            </span>
            <PlanPricesDiff
              v-if="
                priceChange.eur_diff ||
                priceChange.local_diff ||
                priceChange.exchange_rate_effect
              "
              :pcv="currentPriceModalPcv"
              :date-key="priceChange.date"
              :eur-diff="priceChange.eur_diff"
              :local-diff="priceChange.local_diff"
              :exchange-rate-effect="priceChange.exchange_rate_effect"
            />
          </p>
        </div>
      </div>
    </CommentsModal>
  </div>
</template>

<script>
import api from '@/helpers/api';
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import PlanPricesDiff from '@/components/PlanPrices/PlanPricesDiff.vue';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';

function generateYearArray(numOfYears = 6) {
  const year = new Date().getFullYear() - 1;
  const yearArray = [];

  for (let i = 0; i <= numOfYears; i++) {
    yearArray.push(`${year + i}`);
  }

  return yearArray;
}

export default {
  name: 'PlanPricesTable',
  components: { CommentsModal, ToggleSwitch, PlanPricesDiff, ExcelXlsxButton },
  props: {
    countryId: String,
    profitCenterId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      yearTitles: generateYearArray(),
      pcvs: [],
      showPricesModal: false,
      priceModalTop: 0,
      priceModalLeft: 0,
      currentPriceModalPcv: {},
      currentPriceModalPriceChanges: {},
      loaded: false,
      modes: ['from lp2', 'from plan price'],
      currentMode: 'from lp2',
      xlsxColumns: [
        { label: 'Country', field: 'isoCode' },
        { label: 'Sap ID', field: 'sapId' },
        { label: 'Product Name', field: 'name' },
        { label: 'SAP Name', field: 'sapName' },
        { label: 'SAP status', field: 'status' },
        { label: 'Valid from', field: 'startDate' },
        { label: 'Price EUR', field: 'priceEur' },
        { label: 'Diff EUR in %', field: 'diffEur' },
        { label: 'Price local', field: 'priceLocal' },
        { label: 'Diff local in %', field: 'diffLocal' },
      ]
    };
  },
  computed: {
    isReal() {
      return this.currentMode === 'from plan price';
    },
    priceKey() {
      return this.isReal ? 'real_plan_price' : 'plan_price';
    },
    xlsxData() {
      const pricesList = this.combinedPricesArray();
      return pricesList.map((priceEntry) => {
        const eurPrice = this.eurPrice(priceEntry.monthPrice.prices);
        const localPrice = this.nonEurPrice(priceEntry.monthPrice.prices) || {};

        return {
          isoCode: priceEntry.pcv.iso_code,
          sapId: priceEntry.pcv.sap_id,
          name: priceEntry.pcv.gpms_name,
          sapName: priceEntry.pcv.sap_name,
          status: priceEntry.pcv.status,
          startDate: priceEntry.monthPrice.date,
          priceEur: eurPrice.value,
          diffEur: priceEntry.monthPrice.eur_diff
            ? priceEntry.monthPrice.eur_diff * 100.0
            : null,
          priceLocal: localPrice.value,
          diffLocal: priceEntry.monthPrice.local_diff
            ? priceEntry.monthPrice.local_diff * 100.0
            : null,
        };
      });
    },
    xlsxFileName() {
      const isoCode = this.pcvs.length > 0 ? this.pcvs[0].iso_code : '';
      return `Plan prices ${this.currentMode} ${isoCode}`;
    },
    setPlanPriceTableHeight() {
      const pcvLength = this.pcvs.length

      if (pcvLength > 6) {
        return 'height: 420px;'
      } else {
        return 'height: 100%;'
      }
    }
  },
  mounted() {
    this.getPlanPrices();
  },
  methods: {
    async getPlanPrices() {
      if (!this.countryId) {
        this.loaded = true;
        return false;
      }
      let from = new Date(new Date().getFullYear() - 1, 0, 1);
      let to = new Date(new Date().getFullYear() + 5, 11, 31);
      const response = await api.get(
        `/countries/${this.countryId}/plan_prices?from=${from}&to=${to}&pc_id=${this.profitCenterId}`
      );
      this.pcvs = response.data.sort((a, b) =>
        a.gpms_name.localeCompare(b.gpms_name)
      );
      this.loaded = true;
    },
    showPriceModal(e, pcv, yearData) {
      this.currentPriceModalPcv = pcv;
      this.currentPriceModalPriceChanges = this.priceChanges(yearData);
      this.priceModalTop = e.target.getBoundingClientRect().top;
      this.priceModalLeft = e.target.getBoundingClientRect().left;
      this.showPricesModal = true;
    },
    closePricesModal() {
      this.showPricesModal = false;
    },
    priceChanges(yearData) {
      return yearData[this.priceKey].map((monthPrice) => {
        return Object.assign({}, monthPrice, {
          priceEur: this.eurPrice(monthPrice.prices),
          priceLocal: this.nonEurPrice(monthPrice.prices),
        });
      });
    },
    eurMean(yearData) {
      return this.eurPrice(yearData.mean_prices[this.priceKey]);
    },
    localMean(yearData) {
      return this.nonEurPrice(yearData.mean_prices[this.priceKey]) || {};
    },
    eurPrice(pricesArr) {
      return pricesArr.find((v) => v.currency === 'EUR');
    },
    nonEurPrice(pricesArr) {
      return pricesArr.find((v) => v.currency !== 'EUR');
    },
    formatPrice(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        useGrouping: false,
      });
    },
    onClick(pcvId) {
      window.location.href = `/profit_center_variants/${pcvId}`;
    },
    changeMode(mode) {
      this.currentMode = mode;
    },
    modeValue(yearData, rawKey) {
      const key = this.isReal ? `real_${rawKey}` : rawKey;
      return yearData[key];
    },
    showDiffForYear(yearData) {
      const diffEur = this.modeValue(yearData, 'diff_eur');
      return diffEur || diffEur === 0.0;
    },
    combinedPricesArray() {
      let result = [];
      this.pcvs.forEach((pcv) => {
        const pcvResult = [];
        pcv.years_data.forEach((yearData) => {
          yearData[this.priceKey].forEach((monthPrice) => {
            const alreadyInArray = monthPrice.prices.every((price) => {
              return (
                pcvResult.length > 0 &&
                pcvResult[pcvResult.length - 1].monthPrice.prices.some((p) =>
                  this.pricesEql(price, p)
                )
              );
            });
            if (!alreadyInArray) {
              pcvResult.push({ pcv: pcv, monthPrice: monthPrice });
            }
          });
        });
        result = [...result, ...pcvResult];
      });
      return result;
    },
    styleHeadOfTable() {
      return this.checkCountryPage() ? 'country-plan-prices__title' : 'plan-prices__title'
    },
    titleOfTable() {
      return this.checkCountryPage() ? '' : 'Plan Prices:'
    },
    checkCountryPage() {
      return this.$route.path.split('/')[1] === 'countries' ? true : false
    },
    pricesEql(price1, price2) {
      return (
        price1.value === price2.value && price1.currency === price2.currency
      );
    },
  },
};
</script>
