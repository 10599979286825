<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          :class="[
            'comments-modal-container change-request-modal',
            {
              'max-content': data?.maxContent,
              [parentClass]: parentClass,
            },
          ]"
          :style="styleObject"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <slot>
              <div class="comments-container" style="width: 800px !important">
                <h3>Change Requests for {{ object }} {{ objectName }}</h3>

                <template v-if="currentValue">
                  <span class="comment-number">Current value</span>
                  <div class="little-info-card-main-data">
                    <span class="little-info-card-value">
                      {{ $helpers.numberWithCommasAndZeros(currentValue) }}
                      {{ currencySign }}
                    </span>
                  </div>
                </template>
                <br />

                <h3>Create new request</h3>
                <div v-for="f in fields" :key="f" class="form-check m-3">
                  <input
                    :id="f"
                    v-model="field"
                    :value="f"
                    class="form-check-input"
                    type="radio"
                    :name="f"
                  />
                  <label class="form-check-label" :for="f">
                    {{ $helpers.fieldToSpecificName(f) }}
                  </label>
                  <h4 class="description-quote">
                    <template v-if="f == 'exch_bu'">
                      BU exchange rate for next year can be adjusted till end of
                      November
                    </template>
                    <template v-else>
                      LE exchange rate can be adjusted at any time this year and
                      starting December for the next year
                    </template>
                  </h4>
                </div>
                <div class="opened-details">
                  <RequestForm
                    v-model:change-request="newChangeRequest"
                    :field="field"
                    @submit-changes="submitChanges"
                  >
                  </RequestForm>
                </div>

                <template v-if="!$helpers.isEmptyArray(changeRequests)">
                  <div class="messageBox m-25">
                    <h4>Change Requests List</h4>
                  </div>
                  <ol class="list-group list-group-numbered">
                    <li
                      v-for="(changeRequest, rowIndex) in changeRequests"
                      :key="changeRequest.created_at"
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div
                        class="ms-2 me-auto link-with-hover"
                        @click="openDetails(rowIndex, changeRequest)"
                      >
                        <span class="info-quote-clickable display-inline">
                          {{
                            $helpers.dateToString(
                              new Date(changeRequest.created_at)
                            )
                          }}
                        </span>
                        <div class="fw-bold">Request valid for:</div>
                        <span style="font-weight: normal">
                          {{
                            $helpers.dateToString(
                              new Date(changeRequest.valid_from)
                            )
                          }}
                          -
                          {{
                            $helpers.dateToString(
                              new Date(changeRequest.valid_until)
                            )
                          }}
                        </span>
                      </div>
                      <span
                        class="badge bg-success rounded-pill"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Tooltip on bottom"
                      >
                        {{ changeRequest.corrections.length }}
                      </span>

                      <div
                        v-show="openedDetails.row == rowIndex"
                        class="opened-details messageBox"
                        style="width: 60%"
                      >
                        <RequestDetails
                          v-if="
                            changeRequest && !$helpers.isEmptyArray(userInfo)
                          "
                          :key="rowIndex + 100"
                          :change-request="changeRequest"
                          :field="changeRequest.field"
                          :row-index="rowIndex"
                          :user-info="userInfo"
                          :change-request-with-cfo="changeRequestWithCfo"
                          :currency-sign="currencySign"
                          :request-in-progress="requestInProgress"
                          @approve-changes="approveChanges"
                          @submit-changes="submitChanges"
                          @reject-changes="rejectChanges"
                          @open-finish-modal="openFinishModal"
                          @discard-request="discardRequest"
                        >
                        </RequestDetails>
                      </div>
                    </li>
                  </ol>
                  <div ref="lastInvisibleRow" />
                </template>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <FinishPopupModal
        v-if="finishPopupVisible"
        :changeRequest="changeRequest"
        :user-info="userInfo"
        :modal-action="modalAction"
        :top="250"
        :left="650"
        @finish-request="finishRequest"
        @close="closeModal"/>
    </div>
  </transition>
</template>

<script>
import api from '@/helpers/api';
import RequestDetails from '@/components/CurrencyDataChangeRequest/RequestDetails.vue';
import RequestForm from '@/components/CurrencyDataChangeRequest/RequestForm.vue';
import Modal from '@/components/Modals/index.vue';
import FinishPopupModal from '@/components/CurrencyDataChangeRequest/FinishPopupModal.vue';

export default {
  name: 'CurrencyDataChangeRequest',
  components: { RequestForm, RequestDetails, Modal, FinishPopupModal },
  props: {
    data: Object,
    object: String,
    objectId: Number,
    objectName: String,
    top: Number,
    left: Number,
    containerWidth: Number,
    parentClass: String,
    userInfo: Object,
    fields: {
      type: Array,
      default() {
        return ['exch_fixed', 'exch_bu'];
      },
    },
    currentValue: Number,
    changeRequestWithCfo: {
      type: Boolean,
      default: false,
    },
    currencySign: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],

  data() {
    return {
      field: this.fields[0],
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      newChangeRequest: {
        amount: 0,
        comment: '',
        range: this.range,
      },
      changeRequests: [],
      openedDetails: {
        row: -1,
        id: null,
        historyRow: -1,
      },
      requestInProgress: false,
      changeRequest: {},
      modalAction: '',
      finishPopupVisible: false,
    };
  },
  computed: {
    styleObject: function () {
      const result = {};

      result.left = this.getLeft() + 'px';

      if (this.top > window.innerHeight * 0.7) {
        result.bottom = window.innerHeight - this.top + 20 + 'px';
      } else {
        result.top = this.top + 50 + 'px';
      }

      return result;
    },
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    openDetails(index, changeRequest) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.openedDetails.id = changeRequest.id;
        this.openedDetails.row = index;
      }
    },
    async getData() {
      this.requestInProgress = true;
      const result = await api.get(
        `/currency_data_change_requests/object_requests?object=currency&object_id=${this.objectId}`
      );

      this.changeRequests = result.data.map((chr) => {
        chr.range = [new Date(chr.valid_from), new Date(chr.valid_until)];
        return chr;
      });
      this.requestInProgress = false;
    },
    async submitChanges(changeRequest) {
      this.requestInProgress = true;
      const result = await api
        .post(
          `/currency_data_change_requests/`,
          JSON.stringify({
            object: this.object,
            object_id: this.objectId,
            field: this.field,
            comment: changeRequest.comment,
            valid_from: this.$helpers.dateToString(changeRequest.range[0]),
            valid_until: this.$helpers.dateToString(changeRequest.range[1]),
            values: {
              [this.field]: changeRequest.amount,
            },
          })
        )
        .then(() => {
          this.getData();
          this.scrollModalDown();
          this.requestInProgress = false;
        })
        .catch((err) => {
          this.showErrors(err.response.data);
        });
    },

    async rejectChanges(changeRequest) {
      this.requestInProgress = true;
      const result = await api
        .post(
          `/data_change_requests/${changeRequest.id}/reject`,
          JSON.stringify({
            comment: changeRequest.comment,
            valid_from: this.$helpers.dateToString(changeRequest.range[0]),
            valid_until: this.$helpers.dateToString(changeRequest.range[1]),
            values: {
              [this.field]: changeRequest.amount,
            },
          })
        )
        .catch((err) => {
          this.showErrors(err.response.data);
        });

      await this.getData();
      this.requestInProgress = false;
    },

    async approveChanges(changeRequest) {
      this.requestInProgress = true;
      const result = await api.post(
        `/data_change_requests/${changeRequest.id}/approve`,
        JSON.stringify({
          comment: changeRequest.comment,
        })
      );

      await this.getData();
      this.requestInProgress = false;
    },
    openFinishModal(item) {
      this.componentKey = Math.random();
      this.changeRequest = item;
      this.finishPopupVisible = true;
      this.modalAction = 'finish';
    },
    async finishRequest(changeRequest) {
      this.finishPopupVisible = false;
      this.requestInProgress = true;
      const result = await api
        .post(
          `/data_change_requests/${changeRequest.id}/finish`,
          JSON.stringify({
            comment: changeRequest.comment,
            valid_from: this.$helpers.dateToString(changeRequest.range[0]),
            valid_until: this.$helpers.dateToString(changeRequest.range[1]),
          })
        )
        .then(() => {
          this.$router.push(`/data_change_requests/${changeRequest.id}`);
        });

      await this.getData();
      this.requestInProgress = false;
    },

    async discardRequest(changeRequest) {
      this.requestInProgress = true;
      const result = await api.post(
        `/data_change_requests/${changeRequest.id}/discard`
      );

      await this.getData();
      this.requestInProgress = false;
    },

    showErrors(errors) {
      Object.values(errors).forEach(v => { this.$helpers.notifyError(v[0]) })
      this.requestInProgress = false;
    },

    clickOutsideFilters() {
      this.$emit('close');
    },
    getLeft() {
      let result;
      const maxLeft = window.innerWidth - this.containerWidth;
      if (this.left > maxLeft) {
        result = maxLeft - 300;
      } else {
        result = this.left - 300;
      }

      return result;
    },
    async scrollModalDown() {
      this.openedDetails.row = this.changeRequests.length;

      await this.$helpers.sleep(500);

      const el = this.$refs.lastInvisibleRow;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    closeModal() {
      this.finishPopupVisible = false;
      this.loading = false;
    },
  },
};
</script>
