<template>
  <div class="products-table">
    <div class="products-table-title">
      <h1>Pricing Planning</h1>
      <br />
      <span class="color-aaa">
        {{ pageTitle() }}
      </span>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import BrandRequestsList from '@/components/PricePlanning/BrandRequestsList.vue';

export default {
  name: 'PrincingPlanning',
  components: { BrandRequestsList },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      itemsList: {},
      priceChangeRequests: {},
      loading: false,
    };
  },
  async beforeMount() {
    this.loading = true;

    const url = `${this.$route.path}/get_planning_data`;

    let { data: response } = await api.get(url);

    this.itemsList = response.items_list;
    this.priceChangeRequests = response.price_change_requests;

    this.loading = false;
  },
  methods: {
    pageTitle() {
      return 'Global level with the list of planning scopes.';
    },
  },
};
</script>
