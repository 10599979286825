<template>
  <div class="container">
    <div class="row mb-30">
      <h1>Global Sales Development</h1>
    </div>
    <div class="mb-30" :class="{ loading }">
      <div v-if="!loading" class="table-header-left selectors">
        <div class="countries-multiselect">
          <Multiselect
            v-model="selectedValues"
            :options="chartSelectOptions"
            select-label
            placeholder="Please select a country"
            :show-labels="false"
            class="display-inline"
            style="max-width: 600px; min-width: 600px"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
          >
            <template #selection="{ values, isOpen }">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} options selected
              </span>
            </template>
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
        </div>
        <div class="switch-radio-buttons">
          <ToggleSwitch
            v-model="selectedType"
            :show-switch="true"
            :switch-options="typeOptions"
            @change:model-value="() => sortCountries(countries)"
          />
        </div>
      </div>
      <div v-if="!loading">
        <div class="statistic-bar-chart-euro" :style="isVisibleChart('euro')">
          <StatisticBarChart
            :countries="selectedCountries"
            :params="params"
            :selected-type="'euro'"
          />
        </div>
        <div class="statistic-bar-chart-units" :style="isVisibleChart('units')">
          <StatisticBarChart
            :countries="selectedCountries"
            :params="params"
            :selected-type="'units'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticBarChart from '@/pages/Countries/StatisticBarChart.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import api from '@/helpers/api';

export default {
  name: 'Countries',
  components: {
    StatisticBarChart,
    ToggleSwitch,
  },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      loading: false,
      typeOptions: ['euro', 'units'],
      selectedType: 'euro',
      countries: [],
      params: {},
      selectedValues: [],
    };
  },
  computed: {
    chartSelectOptions() {
      let countriesNames = this.countries
        ? this.countries.map((c) => c.name)
        : [];
      return [...new Set(countriesNames)];
    },
    selectedCountries() {
      return this.countries.filter((c) => this.selectedValues.includes(c.name));
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const url = `${this.$route.path}/countries_with_year_sales`;
      let { data: response } = await api.get(url);
      this.params = response.params;
      await this.sortCountries(response.countries);
      this.selectedValues = this.countries
        ? this.countries.slice(0, 10).map((c) => c.name)
        : [];
      this.loading = false;
    },
    async sortCountries(countries) {
      let sortLabel =
        this.selectedType === 'euro'
          ? `${this.params.current}`
          : `${this.params.current}_units`;
      this.countries = countries.sort((a, b) => b[sortLabel] - a[sortLabel]);
    },
    isVisibleChart(type) {
      return this.selectedType === type ? '' : 'display: none;';
    },
  },
};
</script>
