import {
    type RouteRecordRaw,
    createRouter,
    createWebHistory,
} from 'vue-router'

// Pages
import DetailsPage from '@/pages/HierarchyItemList/HierarchyItemDetails.vue'
import BrandDetailsPage from '@/pages/HierarchyItemList/HierarchyItemBrandDetails.vue'
import CountryDetailsPage from '@/pages/HierarchyItemList/HierarchyItemCountryDetails.vue'
import ProfitCenterDetailsPage from '@/pages/HierarchyItemList/HierarchyItemProfitCenterDetails.vue'
import CurrencyDetailsPage from '@/pages/HierarchyItemList/HierarchyItemCurrencyDetails.vue'
import CustomerDetailsPage from '@/pages/HierarchyItemList/HierarchyItemCustomerDetails.vue'
import ProductVariantDetailsPage from '@/pages/HierarchyItemList/HierarchyItemProductVariantDetails.vue'
import ProfitCenterVariantDetailsPage from '@/pages/HierarchyItemList/HierarchyItemProfitCenterVariantDetails.vue'
import DashboardPage from '@/pages/Dashboard/index.vue'
import CurrencyTablePage from '@/pages/HierarchyItemList/HierarchyItemListCurrency.vue'
import CountryTablePage from '@/pages/HierarchyItemList/HierarchyItemListCountry.vue'
import BrandTablePage from '@/pages/HierarchyItemList/HierarchyItemListBrand.vue'
import TablePage from '@/pages/HierarchyItemList/index.vue'
import PriceBuildupDetail from '@/pages/PriceBuildupList/PriceBuildupDetails.vue'
import PriceBuildupList from '@/pages/PriceBuildupList/index.vue'
import DiscountsAndDeductions from '@/pages/DiscountsAndDeductions/index.vue'
import PriceChangeRequest from '@/pages/PriceChangeRequests/PriceChangeRequest.vue'
import PricingPrinciples from '@/pages/PricingPrinciples/index.vue'
import PricingPrinciplesDetails from '@/pages/PricingPrinciples/PricingPrinciplesDetails.vue'
import PriceReferencing from '@/pages/PriceReferencing/index.vue'
import PricePlanning from '@/pages/PricePlanning/index.vue'
import CountryPricePlanning from '@/pages/PricePlanning/CountryPricePlanning.vue'
import BrandPricePlanning from '@/pages/PricePlanning/BrandPricePlanning.vue'
import ProductPricePlanning from '@/pages/PricePlanning/ProductPricePlanning.vue'
import ProductPricePlanningPriceBuildup from '@/pages/PricePlanning/ProductPricePlanningPriceBuildup.vue'
import PlanPricePlanning from '@/pages/PricePlanning/PlanPricePlanning.vue'
import DataChangeRequests from '@/pages/DataChangeRequests/index.vue'
import DataChangeRequestDetailsPage from '@/pages/DataChangeRequests/DataChangeRequestDetails.vue'
import ProcessorSequences from '@/pages/ProcessorSequences/index.vue'

// Components
import EditUser from '@/components/Users/EditUser.vue'
import ManageUsers from '@/components/Users/ManageUsers.vue'
import Search from '@/components/Search/index.vue';

const routes: RouteRecordRaw[] = [


    { path: '/', component: Search },

    { path: '/profit_centers/:id', component: ProfitCenterDetailsPage },

    { path: '/brands', component: BrandTablePage },
    { path: '/brands/:id', component: BrandDetailsPage },

    { path: '/products', component: TablePage },
    { path: '/products/:id', component: DetailsPage },

    { path: '/product_variants', component: TablePage },
    { path: '/product_variants/:id', component: ProductVariantDetailsPage },

    { path: '/countries', component: CountryTablePage },
    { path: '/countries/:id', component: CountryDetailsPage },

    { path: '/currencies', component: CurrencyTablePage },
    { path: '/currencies/:id', component: CurrencyDetailsPage },

    { path: '/customers', component: TablePage },
    { path: '/customers/:id', component: CustomerDetailsPage },

    { path: '/profit_center_variants', component: TablePage },
    { path: '/profit_center_variants/:id', component: ProfitCenterVariantDetailsPage },
    { path: '/profit_center_variants/:id/price_buildups', component: PriceBuildupList },
    { path: '/profit_center_variants/:id/price_buildups/:pb_id', component: PriceBuildupDetail },
    { path: '/profit_center_variants/:id/price_change_requests/:pcr_id', component: PriceChangeRequest },

    { path: '/discounts_and_deductions', component: DiscountsAndDeductions },

    { path: '/dashboard', component: DashboardPage },
    { path: '/edit_user', component: EditUser },
    { path: '/manage_users', component: ManageUsers },

    { path: '/pricing_principles', component: PricingPrinciples },
    { path: '/pricing_principles/premium_pricing', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/bottom_and_top_down', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/bottom_and_top_down/details', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/corridor_of_net_wholesaler_purchases', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/corridor_of_net_wholesaler_purchases/details', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/standartization_of_pricing', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/standartization_of_pricing/details', component: PricingPrinciplesDetails },
    { path: '/pricing_principles/management_of_prices', component: PricingPrinciplesDetails },

    { path: '/price_referencing', component: PriceReferencing },

    { path: '/data_change_requests', component: DataChangeRequests },
    { path: '/data_change_requests/:id', component: DataChangeRequestDetailsPage },

    { path: '/price_plannings', component: PricePlanning },
    { path: '/price_plannings/:id/country_data_change_requests', component: CountryPricePlanning },
    { path: '/price_plannings/:id/brand_data_change_requests', component: BrandPricePlanning },
    { path: '/price_plannings/:id/product_data_change_requests', component: ProductPricePlanning },
    { path: '/price_plannings/:id/product_data_change_requests/:request_id', component: ProductPricePlanningPriceBuildup },
    { path: '/price_plannings/:price_planning_id/plan_price_planning/:pcv_id', component: PlanPricePlanning },
    { path: '/processor_sequences', component: ProcessorSequences },
]

const router = createRouter({
    routes,
    history: createWebHistory(),
})

export default router
