<template>
  <div class="card info-card p-0 m-0">
    <span class="object-information capitalize">Portfolio share</span>
    <div class="chart-content" :style="showTable && 'display: none;'">
      <div class="chart-container" :class="{ 'd-none': showTable }">
        <div class="pie-chart" :style="loading && 'display: none;'">
          <canvas ref="chart" width="500" height="320" />
        </div>
        <div
          v-if="!loading && data && data.sum_of_sales && data.sum_of_units"
          class="chart-summary"
        >
          <span>Summary</span>
          <h3>€{{ $helpers.numberWithCommas(summary.sales.toFixed(2)) }}</h3>
          <span class="chart-summary-units">
            {{ $helpers.numberWithCommas(summary.items) }} Units
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { brandColors } from '@/helpers/constants';
import { numberWithCommas } from '@/helpers';

export default {
  name: 'PieChart',
  plugins: [ChartDataLabels],
  props: {
    object: String,
    items: Array,
    name: String,
  },
  data() {
    return {
      datacollection: null,
      data: null,
      type: 'doughnut',
      myChart: null,
      loading: false,
      showTable: false,
      datasets: [],
      labels: [],
      summary: {
        items: 0,
        sales: 0,
      },
      colors: [
        '#333FFF',
        '#58CFFF',
        '#B8FFF2',
        '#7A79FF',
        '#FFB0E3',
        '#23B899',
      ],
      //'#4C89FE', '#FFB571', '#58CCFF', '#FFE560', '#8D9FFF', '#38D4B4', '#94E563', '#FAD7A0', '#ABEBC6', '#D2B4DE'
    };
  },

  async mounted() {
    await this.getDataAndDraw();
  },
  beforeUnmount(){
    if (this.myChart) {
        for (const chart of Object.values(window.Chart.instances)) {
          window.Chart.animationService.cancelAnimation(chart);
        }
        this.myChart.destroy();
      }
  },
  methods: {
    fillData() {
      let set = {
        data: [],
        backgroundColor: [],
      };

      this.items
        .filter(
          (item) =>
            item.month_eur + item.ytd_eur > 0 && item.name != 'Undefined'
        )
        .slice(0, 5)
        .forEach((item, i) => {
          this.labels.push(item.name);
          set['data'].push(item.ytd_eur);
          let color =
            brandColors[
              item.name
                .split(' ')
                .sort((a, b) => b.length - a.length)[0]
                .toLowerCase()
                .replace('s-', '')
            ] || this.colors[i];
          set['backgroundColor'].push(color);
        });

      if (this.items.length > 5) {
        this.labels.push('Others');
        let sum = 0;
        this.items.slice(5, this.items.length).forEach((item, i) => {
          sum += item.ytd_eur;
        });
        set['data'].push(sum);
        set['backgroundColor'].push('#aaaaaa');
      }

      this.datasets.push(set);

      const self = this;
      this.datacollection = {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          layout: {
            padding: {
              left: 10,
              bottom: 10,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right',
            align: 'left',
            labels: {
              fontSize: 14,
            },
          },
          title: {
            text: 'YTD sales in euro',
            display: true,
            position: 'right',
            fontSize: '15',
            textDirection: 'ltr',
          },
          tooltips: {
            enabled: true,
            position: 'average',
            caretSize: 15,
            caretPadding: 2,
            mode: 'point',
            intersect: false,
            titleFontSize: 8,
            titleFontColor: '#003B6A',
            backgroundColor: '#a5b4c6',
            callbacks: {
              label: function (item, ctx) {
                let sum = 0;
                let dataArr = ctx.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let value = ctx.datasets[0].data[item.index];
                let label = ctx.labels[item.index];
                let percentage = (value * 100) / sum;

                return (
                  label +
                  ': ' +
                  self.$helpers.numberWithCommas(value.toFixed(2)) +
                  '(' +
                  self.$helpers.numberWithCommas(percentage.toFixed(2)) +
                  '%)'
                );
              },
            },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = (value * 100) / sum;

                if (percentage > 5) {
                  return percentage.toFixed(2) + '%';
                } else if (percentage > 1) {
                  return '<5%';
                } else {
                  return '';
                }
              },
              color: '#ffffff',
              anchor: 'center',
              offset: 1,
              opacity: 1,
              textShadowBlur: 1,
              textShadowColor: '#fafafa',
              font: {
                size: 11,
                style: 'bold',
              },
            },
          },
        },
      };
    },
    createChart(chartData) {
      const ctx = this.$refs.chart?.getContext('2d');

      if(!ctx) return;

      if (this.myChart) {
        if (this.myChart) {
          for (const chart of Object.values(window.Chart.instances)) {
            window.Chart.animationService.cancelAnimation(chart);
          }
          this.myChart.destroy();
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },
    async getDataAndDraw() {
      this.fillData();
      this.createChart(this.datacollection);
    },
  },
};
</script>
