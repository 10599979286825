<template>
  <div class="products-table">
    <div class="products-table-title" :class="{ loading: loading.selectors }">
      <h1 v-if="!loading.selectors">
        Global Currency overview
      </h1>
    </div>

    <!--  Filters, sorting and ordering options  -->
    <HierarchyItemListHeader
      :loading="loading.selectors"
      :dropdowns-widths="dropdownsWidths"
      :model-keys-array="modelKeysArray"
      :model-values="modelValues"
      :model-options="modelOptions"
      :order-value="orderValue"
      :order-options="orderOptions"
      :items-per-page-value="itemsPerPageValue"
      :items-per-page-options="itemsPerPageOptions"
      :user-info="userInfo"
      @order-changed="orderChanged"
      @open-create-new-modal="openCreateNewModal"
      @clear-filters="clearFilters"
    />

    <div>
      <b-card no-body>
        <b-tabs
          card
          content-class="mt-3"
          fill
          active-nav-item-class="font-weight-bold text-uppercase"
        >
          <b-tab no-body>
            <template #title>
              <b-spinner
                v-if="loading.currencies"
                type="border"
                small
                class="tab-spinner"
              />
              <template v-else>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                    :fill="'#839AB5'"
                  />
                </svg>
              </template>
              <div class="tab-title">exchange rate deviations</div>
            </template>
            <div
              v-if="exchangeRateDeviations"
              class="row small-table-row"
            >
              <div class="col-md-12">
                <HierarchyItemTab
                  v-if="!$helpers.isEmptyArray(exchangeRateDeviations)"
                  :items="exchangeRateDeviations"
                  :table-keys="[
                    'name',
                    'current_rate',
                    'last_month_rate',
                    'deviation_percent',
                    'current_fixed_rate',
                    'deviation_fixed_percent',
                    'type',
                  ]"
                  :name="'currencies'"
                  table-sortable="type"
                  :use-stacked-chart="false"
                  :show-total="false"
                  :pre-selected-filter="preSelectedFilter"
                  :user-info="userInfo"
                />
              </div>
            </div>
          </b-tab>

          <b-tab no-body>
            <template #title>
              <b-spinner
                v-if="loading.currencies"
                type="border"
                small
                class="tab-spinner"
              />
              <template v-else>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                    :fill="'#839AB5'"
                  />
                </svg>
              </template>
              <div class="tab-title">sales exchange rate effects</div>
            </template>
            <div
              v-if="nonEuroCountriesInfo" class="row small-table-row">
              <div class="col-md-12">
                <HierarchyItemTab
                  v-if="!$helpers.isEmptyArray(nonEuroCountriesInfo)"
                  :items="nonEuroCountriesInfo"
                  :table-keys="[
                    'country',
                    'sales_effect_month',
                    'sales_effect_ytg',
                  ]"
                  :show-tab-filter="false"
                  :name="'sales_exchange_rate_effects'"
                  :use-stacked-chart="true"
                />
                <div v-else>
                  <div
                    class="table-card little-table-container"
                    style="border-radius: none !importnant"
                  >
                    <div class="table-responsive m-0">
                      <div class="dashboard-table-header">
                        <h4>No results available</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab no-body>
            <template #title>
              <b-spinner
                v-if="loading.currencies"
                type="border"
                small
                class="tab-spinner"
              />
              <template v-else>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                    :fill="'#839AB5'"
                  />
                </svg>
              </template>
              <div class="tab-title">daily exchange rate effects</div>
            </template>
            <div class="row small-table-row">
              <div v-if="exchangeRateDeviations.length > 0" class="col-md-12">
                <ExchangeRateEffects :items="exchangeRateDeviations" />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <teleport to="body">
      <Modal
        v-if="showDeleteConfirmationModal"
        :data="{ maxContent: true }"
        @close="showDeleteConfirmationModal = false"
      >
        <div class="d-flex flex-column align-items-center">
          Are you sure you want to delete {{ title }} {{ deletionId }}?
          <div class="mt-2">
            <button
              class="button-no-style modal-button me-5"
              @click="confirmDeletion(deletionId)"
            >
              Yes
            </button>
            <button
              class="button-no-style modal-button"
              @click="cancelDeletion"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </teleport>
    <teleport to="body">
      <Modal
        v-if="showEditModal || showCreateNewModal"
        class="edit-modal"
        @close="
          (e) => (showEditModal ? closeEditModal(e) : closeCreateNewModal(e))
        "
      >
        <EditNewForm
          v-model:new-item-form="newItemForm"
          :show-edit-modal="showEditModal"
          :dropdowns-options="dropdownsOptions"
          @update-item="updateItem"
          @add-item="addItem"
        />
      </Modal>
    </teleport>
  </div>
</template>

<script>
import HierarchyItemListHeader from '@/components/HierarchyItemList/HierarchyItemListHeader.vue';
import HierarchyItemTab from '@/components/HierarchyItemList/HierarchyItemTab.vue';
import Modal from '@/components/Modals/index.vue';
import EditNewForm from '@/components/Modals/EditNewForm.vue';
import { editFormFields } from '@/helpers/constants';
import api from '@/helpers/api';
import ExchangeRateEffects from '@/components/Currencies/ExchangeRateEffects.vue';

// Could be extended: response.data must include to_json property.
export default {
  name: 'HierarchyItemListCurrency',
  components: {
    HierarchyItemListHeader,
    HierarchyItemTab,
    Modal,
    EditNewForm,
    ExchangeRateEffects
  },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      title: '',
      products: [],
      modelKeysArray: [],
      modelOptions: {},
      modelValues: {},
      itemsPerPageOptions: [20, 40, 60, 80, 100],
      itemsPerPageValue: 20,
      orderOptions: [],
      orderValue: { direction: 'ASC', field_name: 'id', full_name: 'id up' },
      loading: {
        selectors: false,
        table: false,
        currencies: false,
      },
      showCreateNewModal: false,
      newItemForm: {},
      showDeleteConfirmationModal: false,
      showEditModal: false,
      deletionId: null,
      editId: null,
      dropdownsOptions: {},
      page: 1,
      dropdownsWidths: {},
      exchangeRateDeviations: [],
      nonEuroCountriesInfo: [],
      preSelectedFilter: ''
    };
  },

  async beforeMount() {
    this.loading.selectors = true;

    let { data: response } = await api.get(`${this.$route.path}/selector_data`);

    this.orderOptions = response.order_options.options.sort(
      this.$helpers.dynamicSort('full_name')
    );

    const modelOptions = response.model_options.field_data;
    modelOptions['order_by'] = response.order_options.options.map(
      (orderOption) => orderOption.full_name
    );
    if (Object.prototype.hasOwnProperty.call(modelOptions, 'is_liquid')) {
      modelOptions.is_liquid = modelOptions.is_liquid.map((v) =>
        v === false ? 'false' : v
      );
    }

    for (const option in modelOptions) {
      modelOptions[option] = modelOptions[option].filter(
        (optionItem) => optionItem !== null && optionItem !== ''
      );
  
      if (typeof modelOptions[option][0] === 'string') {
        modelOptions[option] = modelOptions[option].sort();
      } else if (typeof modelOptions[option][0] === 'number') {
        modelOptions[option] = modelOptions[option].sort((a, b) => a - b);
      }
    }

    for (const prop in modelOptions) {
      this.modelOptions[prop] = modelOptions[prop];
      const modelOptionsCopy = [...modelOptions[prop]];
      modelOptionsCopy.push(prop);
      this.dropdownsWidths[prop] =
        modelOptionsCopy
          .filter((item) => item !== null)
          .reduce(function (a, b) {
            return a.length > b.length ? a : b;
          }).length * 9.5;
    }

    response.model_options.fields.map((field) => {
      this.modelValues[field] = '';
    });

    this.modelKeysArray = [
      ...this.modelKeysArray,
      ...response.model_options.field_names,
    ];

    this.title = response.model_options.model_name;
    editFormFields[this.title].map((field) => {
      this.newItemForm[field] = '';
    });

    this.loading.selectors = false;
  },

  methods: {
    async search(set = true) {
      if (set) {
        this.loading.table = true;
        this.loading.currencies = true;
        this.page = 1;
      }

      const orderBy = {};

      if (this.orderValue.direction) {
        orderBy['order_direction'] = this.orderValue.direction;
        orderBy['order_by'] = this.orderValue.field_name;
      }

      const serializedObject = this.$helpers.serialize({
        ...this.modelValues,
        page: this.page,
        items_per_page: this.itemsPerPageValue,
        ...orderBy,
      });
      
      const deviationsResponse = await api.get(
        `/currencies/exchange_rate_deviations${
          serializedObject === '' ? '' : `?${serializedObject}`
        }`
      );

      this.exchangeRateDeviations = deviationsResponse.data;

      const countriesResponse = await api.get(
        `/currencies/sales_exchange_rate_effects${
          serializedObject === '' ? '' : `?${serializedObject}`
        }`
      );

      this.nonEuroCountriesInfo = countriesResponse.data;

      this.loading.table = false;
      this.loading.currencies = false;
    },

    clearFilters() {
      for (const value in this.modelValues) {
        if (value === 'items_per_page') {
          this.modelValues[value] = 20;
        } else {
          this.modelValues[value] = '';
        }
      }
      this.orderValue = {
        direction: 'ASC',
        field_name: 'id',
        full_name: 'id up',
      };
      this.preSelectedFilter = '';
      this.search();
    },

    orderChanged(prop, val) {
      this[prop] = val;

      if (val.name != '' || val.short_name != '') {
        this.preSelectedFilter = 'Show all';
        this.search();
      } else {
        this.preSelectedFilter = '';
        this.search();
      }
    },

    async addItem() {
      const data = {};
      for (const prop in this.newItemForm) {
        data[prop] =
          this.newItemForm[prop] && this.newItemForm[prop].id
            ? this.newItemForm[prop].id
            : this.newItemForm[prop];
      }

      try {
        await api.post(`${this.$route.path}.json`, data);

        for (const prop in this.newItemForm) {
          this.newItemForm[prop] = '';
        }

        this.search();
        this.showCreateNewModal = false;
        this.$helpers.notifySuccess('Item has been added');
      } catch (e) {
        const errorsData = e.response.data;
        for (const prop in errorsData) {
          this.$helpers.notifyError(prop + ' ' + errorsData[prop].join('; ') + '.');
        }
      }
    },
    openCreateNewModal() {
      for (const prop in this.newItemForm) {
        this.newItemForm[prop] = '';
      }
      this.showCreateNewModal = true;
    },
    closeCreateNewModal() {
      this.showCreateNewModal = false;
    },

    async deleteItem(id) {
      try {
        await api.delete(`${this.$route.path}/${id}.json`);
        await this.search();
      } catch (e) {
        console.log(e);
      }
    },
    async confirmDeletion(id) {
      await this.deleteItem(id);
      this.showDeleteConfirmationModal = false;
    },

    cancelDeletion() {
      this.deletionId = null;
      this.showDeleteConfirmationModal = false;
    },

    async updateItem() {
      const data = {};
      for (const prop in this.newItemForm) {
        data[prop] =
          this.newItemForm[prop] && this.newItemForm[prop].id
            ? this.newItemForm[prop].id
            : this.newItemForm[prop];
      }

      try {
        await api.put(`${this.$route.path}/${this.editId}.json`, data);
        this.showEditModal = false;
        this.search();
        this.$helpers.notifySuccess('Item has been updated');
      } catch (e) {
        const errorsData = e.response.data;
        for (const prop in errorsData) {
          this.$helpers.notifyError(prop + ' ' + errorsData[prop].join('; ') + '.');
        }
      }
    },
    closeEditModal() {
      this.showEditModal = false;
    }
  }
};
</script>
