<template>
  <div class="products-table container">
    <div class="card table-card users-table">
      <div class="table-responsive">
        <table
          class="table table-striped borderless table-card-table analysis-details-table"
        >
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Roles</th>
              <th scope="col">
                <IconText
                  label-for="currency"
                  title="Currency"
                  icon="currency-icon"
                />
              </th>
              <th scope="col">
                <IconText
                  label-for="country"
                  title="Country"
                  icon="country-icon"
                />
              </th>
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>
                <span @click="openSwitchUserModal(user)" class="link" v-floating-tooltip="`Become ${user.email}`">{{ user.email }}</span>
              </td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>
                <template v-if="user.id !== userInfo.id">
                  <div class="form-group search-filters-country-irp">
                    <div class="search-filters-country-irp-country-wrapper">
                      <div
                        class="chips"
                        :class="!user.roles.length ? 'm-0' : ''"
                      >
                        <span
                          v-for="chip in user.roles"
                          :key="`${chip.value}${chip.title}`"
                          class="multiselect__tag"
                        >
                          <IconText :title="chip.title" />
                          <i
                            v-if="user.roles.length > 1"
                            aria-hidden="false"
                            tabindex="1"
                            class="icon close-tag-icon close-tag-button"
                            @click="removeRole(user, chip)"
                          />
                        </span>
                      </div>
                      <Multiselect
                        id="role"
                        v-model="user.roles"
                        :options="rolesArray"
                        :multiple="true"
                        placeholder="All"
                        select-label
                        :show-labels="true"
                        :show-no-results="false"
                        :close-on-select="false"
                        label="title"
                        track-by="title"
                        show-selected
                      >
                        <template #selection="{ values, isOpen }">
                          <span
                            v-if="values.length && !isOpen"
                            class="multiselect__single"
                            >{{ values.length }} options selected</span
                          >
                        </template>
                        <template #caret="{ toggle }">
                          <div
                            class="multiselect__select"
                            @mousedown.prevent.stop="toggle"
                          >
                            <i class="icon icon-down-arrow" />
                          </div>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                </template>
                <template v-else>
                  {{ user.account_types[0] }}
                </template>
              </td>
              <td>
                <div class="form-group search-filters-country-irp">
                  <div class="search-filters-country-irp-country-wrapper">
                    <div
                      class="chips"
                      :class="!user.currencies.length ? 'm-0' : ''"
                    >
                      <span
                        v-for="chip in user.currencies"
                        :key="chip"
                        class="multiselect__tag"
                      >
                        <IconText :title="chip" />
                        <i
                          aria-hidden="true"
                          tabindex="1"
                          class="icon close-tag-icon close-tag-button"
                          @click="
                            user.currencies = user.currencies.filter(
                              (c) => c !== chip
                            )
                          "
                        />
                      </span>
                    </div>
                    <Multiselect
                      id="currency"
                      v-model="user.currencies"
                      :options="currenciesArray"
                      :multiple="true"
                      placeholder="All"
                      select-label
                      :show-labels="true"
                      :show-no-results="false"
                      :close-on-select="false"
                      show-selected
                    >
                      <template #selection="{ values, isOpen }">
                        <span
                          v-if="values.length && !isOpen"
                          class="multiselect__single"
                        >
                          {{ values.length }} options selected
                        </span>
                      </template>
                      <template #caret="{ toggle }">
                        <div
                          class="multiselect__select"
                          @mousedown.prevent.stop="toggle"
                        >
                          <i class="icon icon-down-arrow" />
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </td>
              <td>
                <div class="form-group search-filters-country-irp">
                  <div class="search-filters-country-irp-country-wrapper">
                    <div
                      class="chips"
                      :class="!user.countries.length ? 'm-0' : ''"
                    >
                      <span
                        v-for="chip in user.countries"
                        :key="chip.value"
                        class="multiselect__tag"
                      >
                        <IconText :title="chip.value" />
                        <i
                          aria-hidden="true"
                          tabindex="1"
                          class="icon close-tag-icon close-tag-button"
                          @click="removeCountry(user, chip)"
                        />
                      </span>
                    </div>
                    <Multiselect
                      id="country"
                      v-model="user.countries"
                      :options="countriesArray"
                      :multiple="true"
                      placeholder="All"
                      select-label
                      :show-labels="true"
                      :show-no-results="false"
                      :close-on-select="false"
                      label="title"
                      track-by="title"
                      show-selected
                    >
                      <template #selection="{ values, isOpen }">
                        <span
                          v-if="values.length && !isOpen"
                          class="multiselect__single"
                          >{{ values.length }} options selected</span
                        >
                      </template>
                      <template #caret="{ toggle }">
                        <div
                          class="multiselect__select"
                          @mousedown.prevent.stop="toggle"
                        >
                          <i class="icon icon-down-arrow" />
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="submit"
                  class="btn btn-submit"
                  value="Save"
                  @click="updateUserFromTable(user)"
                />
              </td>
              <td>
                <input
                  type="submit"
                  class="btn btn-submit"
                  value="Details"
                  @click="editUser(user)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <PopupModal
      v-if="popupModalVisible"
      :switchUser="switchUser"
      :top="150"
      :left="650"
      :user-info="userInfo"
      @close="popupModalVisible = false"
      @become-user="becomeUser"
    />

    <teleport to="body">
      <Modal
        v-if="showEditModal"
        class="edit-modal"
        @close="showEditModal = false"
      >
        <EditUser
          :user-info="currentEditUser"
          admin-interface
          @update-user="updateUserInApi"
        >
        </EditUser>
      </Modal>
    </teleport>
  </div>
</template>

<script>
import api from '@/helpers/api';
import Modal from '@/components/Modals/index.vue';
import EditUser from '@/components/Users/EditUser.vue';
import IconText from '@/components/IconText.vue';
import { processUserUpdateError } from '@/helpers/usersHelper';
import PopupModal from '@/components/Users/Modals/PopupModal.vue';

export default {
  name: 'ManageUsers',
  components: {
    Modal,
    EditUser,
    IconText,
    PopupModal,
  },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      users: [],
      showEditModal: false,
      currentEditUser: {},
      currencies: [],
      roles: [],
      countries: [],
      modalObjectName: String,
      switchUser: {},
      popupModalVisible: false,
    };
  },
  computed: {
    currenciesArray() {
      if (this.currencies.length > 0) {
        return this.currencies.map((c) => c.short_name);
      } else {
        return this.currencies;
      }
    },
    rolesArray() {
      if (this.roles.length > 0) {
        return this.roles.map((c) => ({
          title: c.full_account_type,
          value: c.account_type,
        }));
      } else {
        return [{ title: '', value: '' }];
      }
    },
    countriesArray() {
      if (this.countries.length > 0) {
        return this.countries.map((c) => ({
          title: c.name,
          value: c.iso_code,
        }));
      } else {
        return [{ title: '', value: '' }];
      }
    },
  },
  async mounted() {
    await this.getData();
  },
  async created() {
    const { data: users } = await api.get('/users');

    this.users = users.map((p) => p.to_json);
  },
  methods: {
    async editUser(user) {
      this.currentEditUser = user;
      this.showEditModal = !this.showEditModal;
    },
    async updateUserFromTable(user) {
      let currencyIds = [...new Set(user.currencies)].map((c) =>
        this.currencyIdByName(c)
      );
      let roleIds = [...new Set(user.roles)].map((c) => this.roleIdByName(c));
      let countryIds = [...new Set(user.countries)].map((c) =>
        this.countryIdByName(c)
      );
      let userParams = {
        currency_ids: currencyIds,
        role_ids: roleIds,
        country_ids: countryIds,
      };

      await this.updateUserInApi(user.id, userParams);
    },
    async updateUserInApi(userId, userParams) {
      try {
        await api.put(`/users/${userId}/admin_update`, {
          user: userParams,
        });
        this.$helpers.notifySuccess('Your account data was succesfully updated!');
      } catch (e) {
        processUserUpdateError(e);
      }

      const { data: users } = await api.get('/users');
      this.users = users.map((p) => p.to_json);
    },
    async getData() {
      this.loading = true;
      let result;
      result = await api.get(`/currencies/visible_currencies`);
      this.currencies = result.data;
      result = await api.get(`/countries/visible_countries`);
      this.countries = result.data;
      result = await api.get(`/roles`);
      this.roles = result.data;
      this.loading = false;
    },
    currencyIdByName(name) {
      return this.currencies.filter((c) => c.short_name === name)[0].id;
    },
    roleIdByName(role) {
      return this.roles.filter((c) => c.account_type === role.value)[0].id;
    },
    countryIdByName(country) {
      return this.countries.filter((c) => c.iso_code === country.value)[0].id;
    },
    sortUsers(users) {
      const myUserIndex = users.findIndex(
        (user) => user.id === this.userInfo.id
      );

      let myUser;
      if (myUserIndex > -1) {
        myUser = users[myUserIndex];
        users.splice(myUserIndex, 1);
      }
      return [myUser, ...users.sort(this.$helpers.dynamicSort('email'))];
    },
    removeRole(user, role) {
      user.roles = user.roles.filter((c) => c.value !== role.value);
    },
    removeCountry(user, country) {
      user.countries = user.countries.filter((c) => c.value !== country.value);
    },
    openSwitchUserModal(user) {
      this.switchUser = user;
      this.popupModalVisible = true;
    },
    async becomeUser(user) {
      api.get(`/switch_user?scope_identifier=user_${user.id}`).then(() => {
        window.location.href = '/dashboard';
        // this.$router.go();
        this.$helpers.notifySuccess('User succesfully switched');
      })
    }
  },
};
</script>
