<template>
  <div class="converter">
    <div
      :class="['converterContainer', { active: formVisible }]"
      @click="toggleFormVisiblity()"
    >
      <div class="feedbackInfo">
        {{ getFeedbackButtonInfo() }}
      </div>
      <h3 class="feedbackTitle">
        <IconText icon="currency-icon" title="" />
      </h3>
    </div>
    <div v-if="formVisible" class="formContentWrapper">
      <h3>Currency converter</h3>
      <div class="messageBox">
        <IconText
          label-for="convetable"
          title="Enter amount"
          icon="status-icon"
        />
        <input v-model="amount" class="form-control text-input" />
      </div>

      <div class="messageBox">
        <IconText
          label-for="convetable"
          title="Select currency to convert"
          icon="status-icon"
        />
        <span v-if="!loading" class="title" :class="{ longTitle: false }">
          <Multiselect
            id="convetable"
            v-model="fromCurrency"
            :options="currencyOptions"
            :placeholder="'currency'"
            select-label
            :show-labels="false"
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
        </span>

        <span style="width: 100%">
          <i
            class="icon icon-down-arrow-repo"
            style="margin: 10px 0 10px 50%"
          />
        </span>

        <IconText
          label-for="result"
          title="Select base currency"
          icon="status-icon"
        />
        <span v-if="!loading" class="title" :class="{ longTitle: false }">
          <Multiselect
            id="result"
            v-model="toCurrency"
            :options="currencyOptions"
            :placeholder="'currency'"
            select-label
            :show-labels="false"
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
        </span>
      </div>

      <div class="messageBox">
        <IconText
          label-for="result"
          title="Choose exchange rate date"
          icon="status-icon"
        />

        <DatePicker
          v-model:value="exchangeRateDate"
          :clearable="false"
          type="date"
          :disabled-date="disabledEndDate"
          placeholder="Date Select"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>
      </div>

      <template v-if="outputValue['rate']">
        <tr>
          <td>Exchange rate:</td>
          <td class="output-value">
            {{ outputValue['rate'] }}
          </td>
        </tr>
        <tr>
          <td>Conversion amount:</td>
          <td>
            <span class="output-value">
              {{ $helpers.numberWithCommas(outputValue['amount']) }}
            </span>
          </td>
        </tr>
      </template>

      <button class="btn btn-green normal-button" @click="convertCurrency">
        Convert
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import IconText from '@/components/IconText.vue';

export default {
  name: 'CurrencyConverter',
  components: {
    IconText,
  },
  emits: ['closeModal', 'changeCurrentSwitchValue'],
  data() {
    return {
      fromCurrency: '',
      toCurrency: '',
      amount: 0,
      outputValue: {},
      outputCurrenct: 0,
      currencies: [],
      currencyOptions: [],
      loading: false,
      formVisible: false,
      feedbackTypeSelect: 'feedback',
      time0: null,
      exchangeRateDate: new Date(),
      disabledBefore: new Date(2012, 0, 1),
      disabledAfter: new Date(),
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()]
    };
  },
  watch: {
    internalCurrentValue: function (val) {
      this.$emit('changeCurrentSwitchValue', val);
    },
    currentValue: function () {
      this.internalCurrentValue = this.currentValue;
    }
  },
  async mounted() {
    this.loading = true;

    const response = await api.get(`/currencies?items_per_page=200`);

    let cur = [];
    response.data.forEach((a) => cur.push([a.short_name, a.exch_rate]));
    this.currencies = cur;

    let options = [];
    response.data.forEach((a) => options.push(a.short_name));
    this.currencyOptions = options;

    this.loading = false;
  },

  methods: {
    async convertCurrency() {
      try {
        const response = await api.get(
          `/currencies/convert_currency?from_currency=${this.fromCurrency}&amount=${this.amount}&to_currency=${this.toCurrency}&date=${this.exchangeRateDate}`
        );

        this.outputValue = response.data;
      } catch (e) {
        this.$helpers.notifyError(e.response.data['error']);
      }
    },
    toggleFormVisiblity() {
      this.formVisible = !this.formVisible;
    },
    getFeedbackTitle() {
      return this.feedbackTypeSelect == 'feedback'
        ? 'Leave Feedback'
        : 'Report a bug';
    },
    getFeedbackButtonTitle() {
      return this.formVisible ? 'Close x' : 'Currencies';
    },
    getFeedbackButtonInfo() {
      return this.formVisible
        ? 'Click to close currencies converter'
        : 'Click to open currencies converter';
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    }
  },
};
</script>
