<template>
  <div class="small-progress-bar link" style="margin: 0 !important;" v-floating-tooltip="notice">
    <div :style="fillerStyles">
    </div>
  </div>
  <span class="small-progress-bar-label link" v-floating-tooltip="notice">{{ `${completed}%` }}</span>
</template>


<script>
import { computed } from "vue";
export default {
  name: "ProgressBar",
  props: {
    bgcolor: String,
    completed: Number,
    notice: String,
  },
  data() {
    return {
      // percentToNotice: {
      //   15: "Brand manager: approve product requests.",
      //   30: "Brand manager: submit brand request.",
      //   45: "Head of marketing(Marketing analyst/Distribution manager): approve brand request.",
      //   60: "Head of marketing: submit country request.",
      //   75: "Country head: approve country request.",
      //   90: "GPO: approve country request.",
      //   95: "MOB #1: finish country request.",
      //   99: "MOB #2: finish country request.",
      //   100: "Proceed to the next step.",
      // }
    }
  },
  setup(props) {
    const fillerStyles = computed(() => {
      return {
        height: "100%",
        width: `${props.completed}%`,
        backgroundColor: props.bgcolor,
        transition: "width 1s ease-in-out",
        borderRadius: "inherit",
        textAlign: "right",
      };
    });

    return { fillerStyles };
  },
};
</script>
