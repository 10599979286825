<template>
  <div
    v-if="items.length"
    class="table-card little-table-container"
    style="border-radius: none !importnant"
  >
    <div class="table-responsive m-0">
      <table class="table table-striped borderless table-card-table">
        <thead>
          <tr>
            <th
              v-for="(key, i) in tableKeys"
              :key="`${key}${$route.path}`"
              scope="col"
              :style="{
                textAlign: getTextAlign(i, tableKeys.length),
              }"
              class="clickable link"
              @click="sortByRow(key)"
            >
              <span class="display-inline" style="font-size: 15px !important">
                {{
                  $helpers
                    .capitalWithoutDashes(key)
                    .replace('percent', '%')
                    .replace('eur', 'EUR')
                    .replace('ytd', 'YTD')
                    .replace('ytg', 'YTG')
                    .replace('Ytd', 'YTD')
                }}
              </span>
              <span
                class="display-inline"
                style="vertical-align: middle; margin-left: 10px"
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-down-arrow open-details-cell-icon clickable link"
                  :class="{
                    'rotate-tab-arrow': sortDir === 'desc' && sortKey === key,
                  }"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </span>
            </th>
            <th />
          </tr>
        </thead>

        <tbody id="tab-container" class="scrollbar" style="height: 600px">
          <template
            v-for="(row, rowIndex) in filteredResults"
            :key="`${rowIndex}${$route.path}`"
          >
            <tr :style="rowStyle(row, rowIndex)">
              <td
                v-for="(key, index) in tableKeys"
                :key="rowIndex + index + $route.path"
                :class="
                  index === 0 && row[key] !== 'Total' ? 'link-with-hover' : ''
                "
                :style="{
                  textAlign: getTextAlign(index, tableKeys.length),
                }"
                @click="
                  index === 0 && row[key] !== 'Total'
                    ? $router.push(getItemUrl(row))
                    : $emit('close-filters')
                "
              >
                <span class="display-inline">
                  {{ row[key] }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'ListPage',
  props: {
    items: Array,
    name: String,
    tableKeys: Array,
    userInfo: Object,
  },
  data() {
    return {
      range: [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31),
      ],
      sortKey: 'last_modified',
      sortDir: 'desc',
    };
  },
  computed: {
    filteredResults() {
      let result = [];
      result = this.items;
      result = result.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      result = result.sort((a, b) => {
        return b.visible - a.visible;
      });

      return result;
    },
  },

  methods: {
    sortByRow(key) {
      this.$emit('close-filters');
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },

    getItemUrl(row) {
      let outputUrl = undefined;
      outputUrl = `/${this.name}/${row.id}`;

      return outputUrl;
    },

    rowStyle(row, row_index) {
      if (row.visible == false) {
        return 'background: #EEEEEE';
      } else {
        return '';
      }
    },

    getTextAlign(index, length) {
      if (index + 1 == length) {
        return 'center';
      } else if (index > 0) {
        return 'right';
      } else {
        return 'left';
      }
    },
  },
};
</script>
