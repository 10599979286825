<template>
  <div ref="analysis" class="analysis scrollbar" style="overflow: hidden;">
    <button
      class="button-no-style link d-flex align-items-center card analysis-back-button"
      @click="$emit('close')"
    >
      <span class="text-uppercase me-2">Close</span>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{ width: '10px', height: '20px' }"
      >
        <path
          d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
          fill="#9DAFC2"
        />
      </svg>
    </button>

    <div class="analysis-content">
      <div class="analysis-row">
        <h1>
          Plan prices {{ country.name }} {{ $helpers.dateToString(new Date()) }}
        </h1>
      </div>
    </div>
    <div class="card plan-prices-table">
      <PlanPricesTable :country="country" />
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import PlanPricesTable from '@/components/PricePlanning/Modals/PricePlanningPlanPricesTable.vue';

export default {
  name: 'PricePlanningModal',
  components: { PlanPricesTable },
  props: {
    country: Object,
  },
  emits: ['close'],
  data() {
    return {
      buildupData: {},
      loading: false,
      currentDetailsItems: [],
      graphData: [],
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    };
  },
};
</script>
