<template>
  <div
    v-if="items.length"
    class="table-card little-table-container"
    style="border-radius: none !importnant"
  >
    <div class="table-responsive m-0">
      <div v-if="activeRow.title" class="dashboard-table-header">
        <h2 class="capitalize">
          <span v-if="showTitle">{{
            $helpers.capitalWithoutDashes(name)
          }}</span>
          <span>{{ activeRow.title || '' }}</span>
        </h2>
      </div>

      <table
        class="table table-striped-small borderless little-table-brand-cake"
      >
        <thead>
          <tr>
            <th
              v-for="(key, i) in tableKeys"
              :key="`${key}${$route.path}`"
              scope="col"
              class="clickable link"
              @click="sortByRow(key)"
            >
              <span class="display-inline" style="font-size: 15px !important">
                {{
                  $helpers
                    .capitalWithoutDashes(key)
                    .replace('percent', '%')
                    .replace('eur', 'EUR')
                    .replace('ytd', 'YTD')
                    .replace('ytg', 'YTG')
                    .replace('Ytd', 'YTD')
                }}
              </span>
              <span
                class="display-inline"
                style="vertical-align: middle; margin-left: 10px"
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-down-arrow open-details-cell-icon clickable link"
                  :class="{
                    'rotate-tab-arrow': sortDir === 'desc' && sortKey === key,
                  }"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </span>
            </th>
            <th />
          </tr>
        </thead>

        <tbody id="tab-container" class="scrollbar">
          <template
            v-for="(row, rowIndex) in filteredResults"
            :key="`${rowIndex}${$route.path}`"
          >
            <tr :style="rowStyle(row, rowIndex)">
              <td
                v-for="(key, index) in tableKeys"
                :key="rowIndex + index + $route.path"
              >
                <span class="display-inline">
                  {{ $helpers.numberWithCommasAndZeros(row[key]) }}
                  {{ showPercent(index) }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import Chart from '@/components/Chart/index.vue';
import StackedChart from '@/components/StackedChart.vue';
import CurrencyDataChangeRequest from '@/components/CurrencyDataChangeRequest/index.vue';

export default {
  name: 'HierarchyItemTab',
  components: {
    Chart,
    StackedChart,
    CurrencyDataChangeRequest,
    AnalysisModal: defineAsyncComponent(() =>
      import('@/components/Modals/SlideModal/AnalysisModal.vue')
    ),
    SalesDetailsModal: defineAsyncComponent(() =>
      import('@/components/DiscountsAndDeductions/SalesDetailsModal.vue')
    ),
  },
  props: {
    items: Array,
    name: String,
    tableKeys: Array,
    showTitle: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    userInfo: Object,
  },
  data() {
    return {
      activeRow: {},
      filterOptions: [],
      sortDir: 'desc',
      sortKey: 'ytd_eur'
    };
  },
  computed: {
    filteredResults() {
      let result = this.items;

      result = result.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      result = result.sort((a, b) => {
        return b.visible - a.visible;
      });

      if (this.showTotal) {
        result = result.filter((row) => row.name != 'Total');
        let summary = this.addSummary(result);
        result.unshift(summary);
      }

      return result;
    }
  },

  methods: {
    sortByRow(key) {
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },
    showPercent(index) {
      if (
        (index == 3 || index == 5) &&
        ['country_discounts_and_deductions'].includes(this.name)
      ) {
        return '%';
      }
    },
    rowStyle(row, row_index) {
      if (this.showTotal && row_index == 0) {
        return 'background: #f2efaa';
      } else if (this.activeRow.index == row_index) {
        return 'background: #f2efaa';
      } else if (row.visible == false) {
        return 'background: #EEEEEE';
      } else {
        return 'background: #F4FCEE;';
      }
    },
    getTextAlign(index, length) {
      if (index + 1 == length) {
        return 'center';
      } else if (index > 0) {
        return 'right';
      } else {
        return 'left';
      }
    },
    addSummary(items) {
      let totals = {};

      if (items.length == 0) return totals;

      let keys = Object.keys(items[0]);
      keys.forEach((x) => {
        if (typeof items[0][x] == 'number') {
          totals[x] = 0;
        } else {
          totals[x] = '';
        }
      });

      totals.name = 'Total';
      totals.country = 'Total';

      items.forEach((h) => {
        totals.month_eur += h.month_eur;
        totals.month_units += h.month_units;
        totals.ytd_eur += h.ytd_eur;
        totals['ytd_eur_%'] += h['ytd_eur_%'];
        totals.ytd_units += h.ytd_units;
        totals['ytd_units_%'] += h['ytd_units_%'];
        totals.sales_effect_month += h.sales_effect_month;
        totals.sales_effect_ytg += h.sales_effect_ytg;
      });

      return totals;
    }
  }
};
</script>
