<template>
  <b-skeleton v-if="loading" width="90px" height="35px" />
  <div v-else class="chart-toggle-buttons report-toggle-area">
    <button class="toggle-button" @click="exportToPdf">
      <b-spinner v-if="loadingButton" type="border" small class="tab-spinner" />
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="22px"
        height="22px"
        viewBox="0 0 550.801 550.801"
        :stroke="'#f34423'"
      >
        <path
          d="M160.381,282.225c0-14.832-10.299-23.684-28.474-23.684c-7.414,0-12.437,0.715-15.071,1.432V307.6   c3.114,0.707,6.942,0.949,12.192,0.949C148.419,308.549,160.381,298.74,160.381,282.225z"
        />
        <path
          d="M272.875,259.019c-8.145,0-13.397,0.717-16.519,1.435v105.523c3.116,0.729,8.142,0.729,12.69,0.729   c33.017,0.231,54.554-17.946,54.554-56.474C323.842,276.719,304.215,259.019,272.875,259.019z"
        />
        <path
          d="M488.426,197.019H475.2v-63.816c0-0.398-0.063-0.799-0.116-1.202c-0.021-2.534-0.827-5.023-2.562-6.995L366.325,3.694   c-0.032-0.031-0.063-0.042-0.085-0.076c-0.633-0.707-1.371-1.295-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.419   c-0.676-0.369-1.393-0.675-2.131-0.896c-0.2-0.056-0.38-0.138-0.58-0.19C359.87,0.119,359.037,0,358.193,0H97.2   c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.873v160.545   c0,17.043,13.824,30.87,30.873,30.87h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601   V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87v-160.54C519.297,210.838,505.47,197.019,488.426,197.019z M97.2,21.605   h250.193v110.513c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.605z M362.359,309.023c0,30.876-11.243,52.165-26.82,65.333   c-16.971,14.117-42.82,20.814-74.396,20.814c-18.9,0-32.297-1.197-41.401-2.389V234.365c13.399-2.149,30.878-3.346,49.304-3.346   c30.612,0,50.478,5.508,66.039,17.226C351.828,260.69,362.359,280.547,362.359,309.023z M80.7,393.499V234.365   c11.241-1.904,27.042-3.346,49.296-3.346c22.491,0,38.527,4.308,49.291,12.928c10.292,8.131,17.215,21.534,17.215,37.328   c0,15.799-5.25,29.198-14.829,38.285c-12.442,11.728-30.865,16.996-52.407,16.996c-4.778,0-9.1-0.243-12.435-0.723v57.67H80.7   V393.499z M453.601,523.353H97.2V419.302h356.4V523.353z M484.898,262.127h-61.989v36.851h57.913v29.674h-57.913v64.848h-36.593   V232.216h98.582V262.127z"
        />
      </svg>
    </button>
    <ExcelXlsxButton
      class="toggle-button"
      :data="dataset"
      :columns="columns"
      :file-name="fileName"
      :sheet-name="sheetName"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22px"
        height="22px"
        viewBox="0 0 26 26"
        :stroke="'#3b7d15'"
      >
        <path
          style="fill: #030104"
          d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
        />
        <path
          style="fill: #ffffff"
          d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
        />
      </svg>
    </ExcelXlsxButton>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';

export default {
  components: { ExcelXlsxButton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rootElementRef: HTMLDivElement,
    collection: Object,
    graphName: {
      type: String,
      default: 'graph',
    },
    pageName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loadingButton: false,
      columns: [],
      pdfColumns: [],
      dataset: [],
      pdfDataset: [],
      sheetName: 'Sheet1',
    };
  },
  computed: {
    fileName() {
      if (this.pageName && this.pageName.length > 0) {
        return `${this.pageName} - ${this.graphName}`;
      }
      return this.graphName;
    },
  },
  watch: {
    collection: {
      handler(obj) {
        this.updateCollection(obj);
      },
    },
  },
  mounted() {
    this.updateCollection(this.collection);
  },
  methods: {
    async exportToPdf() {
      this.loadingButton = true;

      let pdf = this.preparePdf();
      let x = 25;
      let y = 40;

      pdf.text(this.graphName, x, y);

      y = y + 20;
      y = await this.writeElement(pdf, 'chart', x, y);
      y = y + 10;
      y = await this.writeElement(pdf, 'sub-chart', x, y);

      await this.addElementLikeTable(pdf, y);
      pdf.save(`${this.fileName}.pdf`);

      this.loadingButton = false;
    },

    async writeElement(pdf, elementName, x, y) {
      const elem = this.rootElementRef.querySelectorAll(
        `[data-pdf="${elementName}"]`
      );

      if (!elem || elem.length === 0) return y;
      if (elementName === 'chart') {
        return y + (await this.addElementLikeChart(pdf, elem[0], x, y));
      } else if (elementName === 'sub-chart') {
        pdf.setTextColor('#a5b4c6');
        y = y + (await this.addElementLikeText(pdf, elem[0], x, y, 10, 750));
        pdf.setTextColor('#000000');
        return y;
      }
    },

    async addElementLikeChart(pdf, element, x, y) {
      let isSummary = false;
      let chartSummaryChildren = [];
      let canvasElement = element.getElementsByTagName('canvas')[0];
      let chartSummaryElements =
        element.getElementsByClassName('chart-summary');
      if (chartSummaryElements && chartSummaryElements.length !== 0) {
        chartSummaryChildren = chartSummaryElements[0].children;
        isSummary =
          chartSummaryChildren &&
          chartSummaryChildren.length !== 0 &&
          chartSummaryChildren[0].offsetHeight !== 0;
      }
      let sizes = await this.addElementLikeImage(
        pdf,
        canvasElement,
        x,
        y,
        isSummary
      );
      if (!isSummary) return sizes.y;
      await this.addElementLikeSummary(
        pdf,
        chartSummaryChildren,
        x + sizes.x,
        y,
        190
      );
      return sizes.y;
    },

    async addElementLikeSummary(pdf, element, x, y, maxWidth) {
      let textX = x + 30;
      let textY = y + 10;
      pdf.setTextColor('#003B6A');
      for (const tag of element) {
        textY = tag.className === 'chart-summary__title' ? textY + 10 : textY;
        if (
          tag.tagName === 'TABLE' ||
          tag.getElementsByTagName('table').length > 0
        ) {
          let tableTag = tag.getElementsByTagName('tbody')[0];
          textY =
            textY +
            (await this.addElementLikeTableText(
              pdf,
              tableTag,
              textX,
              textY,
              8,
              maxWidth
            )) +
            10;
        } else {
          textY =
            textY +
            (await this.addElementLikeText(
              pdf,
              tag,
              textX,
              textY,
              10,
              maxWidth
            )) +
            10;
        }
      }
      pdf.setTextColor('#000000');
    },

    async addElementLikeImage(pdf, element, x, y, isSummary) {
      let width = element.offsetWidth;
      let height = element.offsetHeight;
      if (width === 0 || height === 0) return { x: 0, y: 0 };
      let sizes = this.resizeImage(width, height, isSummary);
      await html2canvas(element).then((canvas) => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', x, y, sizes.x, sizes.y);
      });
      return sizes;
    },

    async addElementLikeTableText(pdf, bodyTag, x, y, fontSize, maxWidth) {
      const columnWidth = 140;
      const space = 5;
      let textY = y;
      if (!bodyTag || bodyTag.children.length === 0) return 0;
      for (const tag of bodyTag.children) {
        let leftValue = tag.getElementsByClassName('chart-summary__left')[0];
        let rightValue = tag.getElementsByClassName('chart-summary__right')[0];
        await this.addElementLikeText(
          pdf,
          rightValue,
          x + columnWidth + space,
          textY,
          fontSize,
          maxWidth - columnWidth - space,
          true
        );
        textY =
          textY +
          (await this.addElementLikeText(
            pdf,
            leftValue,
            x,
            textY,
            fontSize,
            columnWidth,
            true
          )) +
          10;
      }
      return textY;
    },

    async addElementLikeText(
      pdf,
      element,
      x,
      y,
      fontSize,
      maxWidth,
      calculate = false
    ) {
      if (element.getElementsByClassName('chart-summary--bold').length > 0)
        pdf.setFont(undefined, 'bold');
      let text = element.textContent.trim();
      let height = element.offsetHeight;
      pdf.setFontSize(fontSize);
      pdf.text(text, x, y, { maxWidth: maxWidth });
      pdf.setFont(undefined, 'normal');
      return fontSize * (calculate ? this.calculatePossibleStrings(height) : 1);
    },

    async addElementLikeTable(pdf, y) {
      let newColumns = this.pdfColumns.map((item) => {
        return { header: item.label, dataKey: item.field };
      });
      if (this.pdfDataset.length) {
        pdf.autoTable({
          margin: { top: 40 },
          startY: y + 20,
          body: this.pdfDataset,
          columns: newColumns,
        });
      }
    },

    calculatePossibleStrings(height) {
      const oneLineHeight = 21;
      if (height <= oneLineHeight) return 1;
      return Math.floor(height / oneLineHeight);
    },

    preparePdf() {
      return new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16,
        hotfixes: ['px_scaling'],
      });
    },

    resizeImage(width, height, isSummary) {
      let maxWidth = isSummary ? 530 : 750;
      if (width > maxWidth)
        return { x: maxWidth, y: (height * maxWidth) / width };
      else return { x: width, y: height };
    },

    calculateStandardChartData(obj) {
      this.columns = this.prepareColumns(obj);
      this.pdfColumns = this.columns.filter(item => item.label !== 'Product name');

      this.pdfDataset = this.calculateDataset(obj, false);
      this.dataset = this.calculateDataset(obj, true);
    },

    calculateDataset(obj, isExcel) {
      let normalizedData = [];
      const objLabels = obj.labels;
      const objData = obj.filteredResults;

      objData.forEach((element) => {
        let result = objLabels.reduce((acc, curr, index) => {
          let value = this.$helpers.numberWithCommas(element[1][index], obj.currency) || '';
          acc[curr] = isExcel ? parseFloat(value.replaceAll(',', '')) : value;
          return acc;
        }, {});

        result.name = element[0].replaceAll('_', ' ');
        result.country = this.collection.countries[element[0]];
        result.sap_id = this.collection.sap_ids[element[0]];

        normalizedData.push(result);
      });

      return normalizedData;
    },

    calculateAnalysisDetailsData(obj) {
      this.columns = [
        { label: '#ID', field: 'id' },
        { label: 'Product Name', field: 'name' },
        { label: 'Country', field: 'country' },
        { label: obj.header, field: 'value' },
      ];
      this.dataset = obj.filteredResults.map((item, index) => {
        const name = obj.labels[index]

        return {
          name: name.replaceAll('_', ' '),
          country: this.collection.countries[name],
          value: item.value ? item.value : 0,
          id: item.sap_id ? item.sap_id : 0,
        };
      });
      this.pdfDataset = this.dataset;
    },

    updateCollection(collection) {
      if (!collection) return;
      if (collection.type === 'currencyChart') {
        this.calculateStandardChartData(collection);
      } else {
        this.calculateAnalysisDetailsData(collection);
      }
    },
    prepareColumns(obj) {
      let col = this.collection.object === 'sales' ? 
                        [
                          { label: 'Name', field: 'name' }
                        ] 
                                        : 
                        [
                          { label: '#ID', field: 'sap_id' },
                          { label: 'Product name', field: 'name' },
                          { label: 'Country', field: 'country' }
                        ]
      
      return col.concat(
        obj.labels.map((item) => {
          return { label: item, field: item };
        })
      );
    }
  }
};
</script>
