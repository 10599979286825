<template>
  <tr
    class="price-level"
    :class="{ deflash: deflash, flash: flash, 'error-field': isError }"
    @animationend="deflash = false"
  >
    <td>{{ data.full_name }}</td>

    <td>
      <span v-if="data && !editingField" ref="popover-target">{{
        changeRequestValue
      }}</span>
      <div v-if="editingField" class="price-level-input">
        <input
          v-model="changedValue"
          class="price-level-input__value"
          size="7"
          @input="onValueChange"
        />
        <span
          class="price-level-input__plus-sign"
          :class="{ 'price-level-input__active': plusMode }"
          @click="onChangeSign"
        >
          +
        </span>
        <span>/</span>
        <span
          class="price-level-input__minus-sign"
          :class="{ 'price-level-input__active': !plusMode }"
          @click="onChangeSign"
        >
          -
        </span>
        <input v-model="percentValue" size="4" @input="onPercentChange" />
        <span class="price-level-input__percent">%</span>
      </div>
      <b-popover
        v-model:show="isErrorProp"
        :target="() => $refs['popover-target']"
        title="Error Updating Field"
        custom-class="popover-error"
        triggers=""
      >
        {{ errorMessage }}
      </b-popover>
    </td>

    <td>
      <span v-if="data">
        {{ currentBuildupValue }}
      </span>
    </td>

    <td class="col-save-btn">
      <div class="price-level__flex">
        <button v-if="editingField" class="btn btn-green" @click="saveField">
          Save
        </button>
      </div>
    </td>
    <td>
      <div class="price-level__flex">
        <button
          v-if="canEdit"
          class="buildup-card-right-panel-button button-no-style"
          @click="editField"
        >
          <i class="icon status-icon" />
        </button>
        <button
          v-if="editingField"
          class="buildup-card-right-panel-button button-no-style"
          @click="cancelEdit"
        >
          <i class="icon close-tag-icon-big" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ProductLevelCard',
  props: {
    data: Object,
    currency: String,
    editingField: {
      type: Boolean,
      default: false,
    },
    currentPriceBuildup: Array,
    editedField: String,
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
  },
  emits: ['editField', 'cancelEdit', 'save'],
  data() {
    return {
      changedValue: this.data.value,
      percentValue: 0,
      deflash: false,
      flash: false,
      plusMode: true,
    };
  },
  computed: {
    isErrorProp() {
      return this.isError;
    },
    currentBuildupValue() {
      if (!this.currentPriceBuildup) {
        return null;
      }

      let currentLevel = this.currentPriceBuildup.find(
        (level) => level.short_name == this.data.key
      );
      if (!currentLevel || !currentLevel.value) {
        return null;
      }

      return this.formattedValue(currentLevel.value, this.data.type, 1);
    },
    changeRequestValue() {
      return this.formattedValue(this.data.value, this.data.type, 100);
    },
    canEdit() {
      return !this.editingField && !this.data.uneditable;
    },
  },
  watch: {
    editingField() {
      this.changedValue = this.data.value;
      this.plusMode = true;
      this.percentValue = 0;
    },
    'data.value': function () {
      this.flash = true;
    },
    editedField() {
      if (this.flash) {
        this.flash = false;
        this.deflash = true;
      }
    },
  },
  methods: {
    editField() {
      this.plusMode = true;
      this.$emit('editField', this.data.key);
    },
    cancelEdit() {
      this.$emit('cancelEdit');
    },
    saveField() {
      this.$emit('save', this.data.key, this.changedValue);
    },
    formattedValue(value, type, percentMultiplier) {
      if (type == 'currency') {
        return `${value} ${this.currency}`;
      }
      return value ? (value * percentMultiplier).toFixed(2) + '%' : '-';
    },
    onValueChange() {
      const currentValue = parseFloat(this.changedValue);
      if (isNaN(currentValue)) return;

      const signedPercent = (
        (currentValue / this.data.value - 1) *
        100.0
      ).toFixed(2);
      this.plusMode = signedPercent >= 0;
      this.percentValue = Math.abs(signedPercent);
    },
    onPercentChange() {
      let currentPercent = parseFloat(this.percentValue);
      if (isNaN(currentPercent)) return;

      currentPercent = this.plusMode ? currentPercent : -currentPercent;
      const digits = this.data.type === 'percent' ? 4 : 2;
      this.changedValue = (
        this.data.value *
        (currentPercent / 100.0 + 1)
      ).toFixed(digits);
    },
    onChangeSign() {
      this.plusMode = !this.plusMode;
      this.onPercentChange();
    },
  },
};
</script>
