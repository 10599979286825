<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          class="comments-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '40%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div
              v-if="
                changeRequest &&
                !$helpers.isEmptyArray(changeRequest.corrections)
              "
              class="form-group row ms-0"
            >
              <h3 class="display-inline">Correction history</h3>
              <div class="col-lg-10">
                <div
                  v-for="correction in changeRequest.corrections"
                  :key="correction.created_at"
                >
                  <Correction
                    :correction="correction"
                    :currency-sign="currencySign"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Correction from '@/components/CurrencyDataChangeRequest/Correction.vue';

export default {
  name: 'CorrectionsModal',
  components: { Correction },
  props: {
    changeRequest: Object,
    currencySign: String,
    userInfo: Object,
  },
  emits: ['close'],
};
</script>
