<template>
  <div class="table-card little-table-container">
    <div class="chart-block m-0 pt-0 px-0">
      <div class="chart-header">
        <h1 class="m-0">{{ $helpers.firstLetterCapital('Overview') }}</h1>
      </div>

      <table class="table borderless little-table-prices">
        <thead>
          <th scope="col">2 years ago</th>
          <th scope="col">previous year</th>
          <th scope="col">YTD</th>
          <th scope="col">previous month</th>
          <th v-if="objectType !== 'customers'" scope="col">Budget YTD</th>
          <th v-if="objectType !== 'customers'" scope="col">Forecast YTD</th>
        </thead>
        <tbody v-if="data">
          <tr>
            <td class="column_23">Gross Sales I</td>
            <td style='position: relative;' v-for="(value, i) in data['gross_sales_1']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="column_23">
              Discount in invoice
              <pre>Share of Gross I</pre>
            </td>
            <td style='position: relative;' v-for="(value, i) in data['discounts']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
              <br />
              <span>
                <div class='little-table-values'>
                  {{
                    $helpers.numberWithCommasFloat(((value / data['gross_sales_1'][i]) * 100).toFixed(2))
                  }}%
                </div>
              </span>
            </td>
          </tr>
          <tr>
            <td class="column_23">Gross Sales II</td>
            <td style='position: relative;' v-for="(value, i) in data['gross_sales_2']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="column_23">
              Sales deductions
              <pre>Share of Gross II</pre>
            </td>
            <td style='position: relative;' v-for="(value, i) in data['deductions']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
              <br />
              <span>
                <div class='little-table-values'>
                  {{ $helpers.numberWithCommasFloat(((value / data['gross_sales_2'][i]) * 100).toFixed(2)) }}%
                </div>
              </span>
            </td>
          </tr>
          <tr>
            <td class="column_23">Net Sales</td>
            <td style='position: relative;' v-for="(value, i) in data['net_sales']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="column_23">
              Total deductions
              <pre>Share of Gross I</pre>
            </td>
            <td style='position: relative;' v-for="(value, i) in data['total_sales']" :key="i">
              <div class='little-table-values'>
                {{ $helpers.numberWithCommasFloat(value) }}
              </div>
              <br />
              <span>
                <div class='little-table-values'>
                  {{ $helpers.numberWithCommasFloat(((value / data['gross_sales_1'][i]) * 100).toFixed(2)) }}%
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesPricingSummaryTable',
  props: {
    data: Object,
    objectType: {
      type: String,
      default: 'countries',
    },
  }
};
</script>
