<template>
  <transition name="modal">
    <div>
      <div @click.self="$emit('close')">
        <div
          :class="{
            'max-content': data?.maxContent,
            [parentClass]: parentClass,
          }"
        >
          <div
            class="slide-modal-body"
            style="box-shadow: none !important"
            :class="{ open }"
            :style="{ marginLeft }"
          >
            <slot> default body </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const extraMarginPixels = 620;

export default {
  name: 'SlideModal',
  props: {
    data: Object,
    open: Boolean,
    parentClass: String
  },
  emits: ['close'],
  data: function () {
    return {
      marginLeft: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.onResize);

    this.onResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.marginLeft = window.innerWidth - extraMarginPixels + 'px';
    },
  },
};
</script>
