export const accountTypesMap = {
  'Country Manager': 'country_manager',
  'Global Manager': 'global_manager',
  'Admin': 'admin',
  'Chief Financial Officer': 'cfo',
  'Member of the Board-FI/CO/IT': 'mob',
  'Country Head': 'country_head',
  'Global Pricing Officer': 'gpo',
  'Controlling Manager': 'controlling_manager',
  'Global Forecast Manager': 'global_forecast_manager',
  'Country Support Manager': 'country_support_manager',
  'Head of Marketing': 'head_of_marketing',
  'Global Marketing Manager': 'global_marketing_manager',
  'Distribution Manager': 'distribution_manager',
  'Member of the Board-GB': 'mob_gb',
  'MOB-FI-Deputy': 'mob_dep',
  'Brand manager': 'brand_manager',
  'CEO': 'ceo',
  'Head of Global Marketing': 'head_of_global_marketing',
  'Marketing Analyst': 'marketing_analyst',
  'Area Sales Manager': 'area_sales_manager',
  'Deputy Country Head': 'deputy_country_head',
  'MOB-GB-Deputy': 'mob_gb_dep',
  'Global Brand Manager': 'global_brand_manager',
}
