<template>
  <div class="products-table">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />

    <div class="products-table-title">
      <h1>Processor Sequences</h1>
      <br />
      <span class="color-aaa">
        Manage data processing by creating processor sequences
      </span>
    </div>

    <div class="plan-price-planning__buttons mt-30 mb-30" style="justify-content: flex-start !important;">
      <button class="btn btn-success" @click="openPopup" v-floating-tooltip="'Create new sequence'">New sequence</button>
    </div>

    <ProcessorSequencesTable
      :items="items"
      :name="'Sequences'"
      :table-keys="['name','id','state','created_at', 'updated_at', 'duration','created_by']"
      :user-info="userInfo"
      @execute-processor-chain="executeProcessorChain"
      @remove-processor-chain="removeProcessorChain"
      @load-more="loadMore"
    />

  </div>
  <NewSequencePopup
    v-if="popupModalVisible"
    :processor-options="processorOptions"
    :user-info="userInfo"
    @saved="handleSaveComplete"
    @close="popupModalVisible = false"
  />
</template>

<script>
import api from "@/helpers/api";
import NewSequencePopup from "@/components/ProcessorSequences/NewSequencePopup.vue";
import ProcessorSequencesTable from "@/components/ProcessorSequences/ListPage.vue"
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';

export default {
  name: 'ProcessorSequences',
  components: { NewSequencePopup, ProcessorSequencesTable, FadeLoader },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      popupModalVisible: false,
      items: [],
      processorOptions: [],
      page: 1,
      itemsPerPageValue: 20,
      modelValues: {},
      loading: false,
      listLoading: false,
    };
  },
  async beforeMount() {
    this.getProcessorSequences();
    this.getListOfProcessors();
  },
  methods: {
    openPopup() {
      this.popupModalVisible = true;
    },
    handleSaveComplete() {
      this.getProcessorSequences();
      this.getListOfProcessors();
    },
    convertArray(array) {
      const newArray = array.map((item) => {
        item.map((processor) => {
          if (processor.options.length === 0) {
            return processor.processor;
          } else {
            return item;
          }
        })
      });

      return newArray;
    },
    async getProcessorSequences() {
      this.loading = true;

      const serializedObject = this.$helpers.serialize({
        page: this.page,
        items_per_page: this.itemsPerPageValue,
      });

      const url = `/processor_sequences.json${
        serializedObject === '' ? '' : `?${serializedObject}`
      }`;

      await api
        .get(url)
        .then(response => {
          this.items = response.data;
        })
        .catch(_err => {
          this.$helpers.notifyError('Cannot get processor sequences');
        });

      this.loading = false;
    },
    async executeProcessorChain(id) {
      this.loading = true;

      const item = this.items[id];
      await api
        .post(`/processor_sequences/${item.id}/start`)
        .then(_response => {
          this.$helpers.notifySuccess('Processors sequence successfully started');
          this.getProcessorSequences();
        })
        .catch(_err => {
          this.$helpers.notifyError(`Cannot run processor sequence with folowing options: ${processors}`);
        });
      // await api
      //   .post('api/processor_triggers/custom',
      //     JSON.stringify({
      //         processors: processors,
      //         name: item.name,
      //         created_by: this.userInfo.email
      //     })
      //   )
      //   .then(_response => {
      //     this.$helpers.notifySuccess('Processors sequence successfully executing');
      //     this.getProcessorSequences();
      //   })
      //   .catch(_err => {
      //     this.$helpers.notifyError(`Cannot run processor sequence with folowing options: ${processors}`);
      //   });

      this.loading = false;
    },
    async removeProcessorChain(id) {
      await api
        .delete(`/processor_sequences/${id}.json`)
        .then(_response => {
          this.$helpers.notifySuccess('Processor sequence successfully deleted');
        })
        .catch(_err => {
          this.$helpers.notifyError('Cannot delete processor sequence');
        });
      this.getProcessorSequences();
    },
    async getListOfProcessors(){
      await api
        .get('/processor_sequences/list_processors.json')
        .then(response => {
          this.processorOptions = response.data
        })
    },
    async loadMore() {
      this.listLoading = true;

      this.page += 1;

      const oldItems = this.items;
      await this.getProcessorSequences();

      if (!this.$helpers.isEmptyArray(this.items)) {
        this.items = [...oldItems, ...this.items];
      } else {
        this.page -= 1;
      }

      this.listLoading = false;
    },
  }
};
</script>

<style>
.item-block {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 10px
}
</style>
