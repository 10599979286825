<template>
  <div class="chart-block card w-100 m-0">
    <div class="chart-header">
      <span>
        <h1 class="m-0">{{ currentTitle }}</h1>
      </span>

      <ReportsArea
        :graph-name="currentTitle"
        :root-element-ref="pdfRootRef"
        :collection="{
          type: 'analysisDetailsChart',
          header: currentTitle,
          countries: countries,
          labels: filteredData.map((item) => item.gpms_name),
          filteredResults: filteredData,
        }"
      />
      <ToggleSwitch
        v-model="buildupDataType"
        :show-switch="true"
        :switch-options="buildupDataOptions"
        @change:model-value="reloadGraph"
      />
    </div>
    <div data-pdf="chart" class="chart-content">
      <div class="w-100">
        <canvas ref="chart" />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import ReportsArea from '@/components/Report/ReportsArea.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'AnalysisDetailsChart',
  components: { ReportsArea, ToggleSwitch },
  props: {
    items: {},
    title: String,
    dataType: String,
    pdfRootRef: HTMLDivElement,
    currency: {
      type: String,
      default: 'EUR',
    },
    countries: {},
  },
  data() {
    return {
      buildupDataOptions: ['real', 'planned'],
      buildupDataType: 'planned',
    };
  },
  computed: {
    currentTitle() {
      return `Current valid values graph in ${this.sign}`;
    },
    filteredData() {
      return this.items[this.buildupDataType];
    },
    sign() {
      return this.dataType == 'percent'
        ? ' %'
        : ` ${this.$helpers.getCurrencySymbol(this.currency)}`;
    },
  },
  mounted() {
    this.reloadGraph();
  },
  methods: {
    reloadGraph() {
      const dataCollection = this.fillData();
      this.createChart('chartId1', dataCollection);
    },
    createChart(chartId, chartData) {
      const ctx = this.$refs.chart.getContext('2d');

      if (this.myChart) {
        if (this.myChart) {
          for (const chart of Object.values(window.Chart.instances)) {
            window.Chart.animationService.cancelAnimation(chart);
          }
          this.myChart.destroy();
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },
    fillData() {
      const self = this;
      const labels = this.filteredData.map((item) => item.gpms_name);
      const data = this.filteredData.map((item) => item.value);
      const max = Math.max(...data);

      return {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: this.title,
              data,
              backgroundColor: '#4C89FE',
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: false,
            text: 'details',
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, chart) {
                var datasetLabel =
                  chart.datasets[tooltipItem.datasetIndex].label || '';
                return (
                  datasetLabel +
                  ': ' +
                  self.$helpers.numberWithCommas(tooltipItem.yLabel) +
                  self.sign
                );
              },
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Product name',
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Value',
              },
            },
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: max * 1.1,
                },
                stacked: false,
              },
            ],
          },
          legend: {
            onClick: function (e, legendItem) {
              if (self.labelClicks.includes(legendItem.datasetIndex)) {
                self.labelClicks = self.labelClicks.filter(
                  (ind) => ind !== legendItem.datasetIndex
                );
              } else {
                self.labelClicks.push(legendItem.datasetIndex);
              }
              Chart.defaults.global.legend.onClick.call(this, e, legendItem);
            },
          },
          plugins: {
            datalabels: {
              color: '#ffffff',
              backgroundColor: '#4479e0',
              borderColor: '#3965bb',
              borderRadius: 15,
              borderWidth: 2,
              anchor: 'end',
              offset: -1,
              opacity: 2,
              padding: {
                bottom: 5,
              },
              clamp: true,
              textShadowBlur: 1,
              textShadowColor: '#666666',
              font: {
                size: 11,
                style: 'bold',
              },
            },
          },
        },
      };
    },
  },
};
</script>
