// Gets needed lists for Add/Edit modal selectors
import api from './api'

export const loadDropdowns = async (newItemForm) => {
  const dropdownsOptions = {}
  for (const prop in newItemForm) {
    if (prop.split('_').pop() === 'id' && prop !== 'id' && prop !== 'sap_id') {
      const page = prop.slice(0, -3) + 's.json'
      const {data: response} = await api.get(page)

      if (response) {
        dropdownsOptions[prop] = response.map((p) => {
          if (p.to_json) {
            return ({
              name: p.to_json.full_name || p.to_json.name,
              id: p.to_json.id,
            })
          } else {
            return ({
              name: p.full_name || p.name,
              id: p.id,
            })
          }

        })
      }
    }
  }
  return dropdownsOptions
}
