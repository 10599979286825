<template>
  <div class="world-map" :class="loading ? 'loading' : 'ready'">
    <div id="country-map" />
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import { countryCapitalCoordinates } from '@/helpers/map';

export default {
  name: 'WorldMap',
  components: {},
  props: {
    countries: Array,
    loading: Boolean,
  },
  emits: ['setCountry'],
  data() {
    return {
      selectedIso: null,
    };
  },

  watch: {
    selectedIso() {
      this.drawCountryInfo();
    },
    countries: {
      async handler() {
        this.drawColors.countriesIso = this.countries.map(
          (country) => country.iso_code
        );
        await this.setMap();
        await this.setDefaultCountry();
      },
      deep: true,
    },
  },
  created() {
    this.selectedCountryMap = null;
    this.selectedCountry = null;
    this.drawColors = {
      countriesIso: [],
      notActiveReferences: [],
      activeReferences: [],
    };
    this.homePoint = {
      longitude: 10,
      latitude: 52,
    };
    this.root = null;
    this.chart = null;
    this.series = {
      polygon: null,
      background: null,
      line: null,
      arrow: null,
      bullets: null,
    };
  },
  methods: {
    drawPolygonSeries() {
      this.series.polygon = this.chart.series.push(
        am5map.MapPolygonSeries.new(this.root, {
          geoJSON: am5geodata_worldLow,
        })
      );
      this.series.polygon.mapPolygons.template.setAll({
        tooltipText: '{name}',
        toggleKey: 'active',
        interactive: true,
      });
      this.series.polygon.mapPolygons.template.states.create('hover', {
        fill: this.root.interfaceColors.get('primaryButtonHover'),
      });
      this.series.background = this.chart.series.push(
        am5map.MapPolygonSeries.new(this.root, {})
      );
      this.series.background.mapPolygons.template.setAll({
        fill: am5.color(0x000000),
        fillOpacity: 0.1,
        strokeOpacity: 0,
      });
      this.series.background.data.push({
        geometry: am5map.getGeoRectangle(90, 180, -90, -180),
      });
      this.drawPolygonSeriesColors();
      this.setPolygonActions();
    },
    setPolygonActions() {
      this.chart.set('zoomControl', am5map.ZoomControl.new(this.root, {}));
      this.chart.zoomToGeoPoint(
        {
          longitude: this.homePoint.longitude,
          latitude: this.homePoint.latitude,
        },
        5
      );
      this.series.polygon.events.on('datavalidated', () => {
        this.chart.animate({
          key: 'rotationX',
          to: -this.homePoint.longitude,
          duration: 1500,
          easing: am5.ease.inOut(am5.ease.cubic),
        });
        this.chart.animate({
          key: 'rotationY',
          to: -this.homePoint.latitude,
          duration: 1500,
          easing: am5.ease.inOut(am5.ease.cubic),
        });
      });
      let previousPolygon;
      this.series.polygon.mapPolygons.template.on(
        'active',
        (active, target) => {
          if (previousPolygon && previousPolygon !== target) {
            previousPolygon.set('active', false);
          }
          if (target.get('active')) {
            let centroid = target.geoCentroid();
            if (centroid) {
              this.chart.animate({
                key: 'rotationX',
                to: -centroid.longitude,
                duration: 1500,
                easing: am5.ease.inOut(am5.ease.cubic),
              });
              this.chart.animate({
                key: 'rotationY',
                to: -centroid.latitude,
                duration: 1500,
                easing: am5.ease.inOut(am5.ease.cubic),
              });
            }
            this.selectedIso = target.dataItem.dataContext.id;
          }
          previousPolygon = target;
        }
      );
    },
    drawPolygonSeriesColors() {
      this.series.polygon.mapPolygons.template.adapters.add(
        'fill',
        (fill, target) => {
          if (
            this.drawColors.countriesIso.includes(
              target.dataItem.dataContext.id
            )
          ) {
            let color = this.root.interfaceColors.get('positive');
            target.setRaw('fill', color);
            return color;
          }
          if (
            this.drawColors.activeReferences.includes(
              target.dataItem.dataContext.id
            )
          ) {
            let color = am5.color(0xae81ff);
            target.setRaw('fill', color);
            return color;
          }
          if (
            this.drawColors.notActiveReferences.includes(
              target.dataItem.dataContext.id
            )
          ) {
            let color = this.root.interfaceColors.get('secondaryButtonHover');
            target.setRaw('fill', color);
            return color;
          }
          return this.root.interfaceColors.get('primaryButton');
        }
      );
    },
    drawLinesSeries() {
      this.series.line = this.chart.series.push(
        am5map.MapLineSeries.new(this.root, {})
      );
      this.series.line.mapLines.template.setAll({
        stroke: am5.color(0x000000),
        strokeOpacity: 0.6,
      });
    },
    drawArrowSeries() {
      this.series.arrow = this.chart.series.push(
        am5map.MapPointSeries.new(this.root, {})
      );
      this.series.arrow.bullets.push(() => {
        let arrow = am5.Graphics.new(this.root, {
          fill: am5.color(0x000000),
          stroke: am5.color(0x000000),
          draw: (display) => {
            display.moveTo(0, -3);
            display.lineTo(8, 0);
            display.lineTo(0, 3);
            display.lineTo(0, -3);
          },
        });
        return am5.Bullet.new(this.root, {
          sprite: arrow,
        });
      });
    },
    drawCountryBulletsSeries() {
      let currentUrl = window.location.protocol + '//' + window.location.host;
      this.series.bullets = this.chart.series.push(
        am5map.MapPointSeries.new(this.root, {})
      );
      this.series.bullets.bullets.push((root, axis, dataItem) => {
        let currentElement = `${currentUrl}${dataItem.dataContext.image_url}`;
        let graphics = am5.Picture.new(root, {
          width: 20,
          height: 20,
          x: am5.percent(30),
          y: am5.percent(50),
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          src: currentElement,
        });
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
    },
    drawCountryFlag(longitude, latitude, name, iso_code3, image_url) {
      this.series.bullets.data.push({
        geometry: { type: 'Point', coordinates: [longitude, latitude] },
        title: name,
        id: iso_code3,
        image_url: image_url,
      });
    },
    drawFlagBullets() {
      this.drawCountryFlag(
        this.selectedCountryMap.longitude,
        this.selectedCountryMap.latitude,
        this.selectedCountryMap.capital,
        this.selectedCountryMap.iso_code3,
        this.selectedCountry.flag_url
      );
      let references = this.selectedCountry.reference_countries.references;
      for (let i = 0; i < references.length; i++) {
        let mapObj = countryCapitalCoordinates.find(
          (result) => result.iso_code3 === references[i].iso_code3
        );
        this.drawCountryFlag(
          mapObj.longitude,
          mapObj.latitude,
          mapObj.capital,
          mapObj.iso_code3,
          references[i].flag_url
        );
      }
    },
    drawArrowLinesFromBullets() {
      am5.array.each(
        this.selectedCountry.reference_countries.references,
        (did) => {
          let destinationDataItem = this.series.bullets.getDataItemById(
            did.iso_code3
          );
          let lineDataItem = this.series.line.pushDataItem({
            geometry: {
              type: 'LineString',
              coordinates: [
                [
                  this.selectedCountryMap.longitude,
                  this.selectedCountryMap.latitude,
                ],
                [
                  destinationDataItem.get('longitude'),
                  destinationDataItem.get('latitude'),
                ],
              ],
            },
          });
          this.series.arrow.pushDataItem({
            lineDataItem: lineDataItem,
            positionOnLine: 0.5,
            autoRotate: true,
          });
        }
      );
    },
    setMap() {
      this.root = am5.Root.new('country-map');
      this.chart = this.root.container.children.push(
        am5map.MapChart.new(this.root, {
          panX: 'rotateX',
          panY: 'rotateY',
          paddingBottom: 20,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          maxZoomLevel: 25,
          minZoomLevel: 4,
          homeZoomLevel: 5,
          homeGeoPoint: this.homePoint,
          projection: am5map.geoOrthographic(),
          maxPanOut: 0.1,
          pinchZoom: false,
        })
      );
      this.drawPolygonSeries();
      this.chart.appear(1000, 100);
    },
    drawCountryInfo() {
      if (this.selectedCountry) this.clearPreviousData();
      this.$emit('setCountry', this.selectedIso);
      this.selectedCountry = this.countries.find(
        (country) => country.iso_code === this.selectedIso
      );
      this.selectedCountryMap = countryCapitalCoordinates.find(
        (result) => result.iso_code === this.selectedIso
      );
      let notActiveReferences = [];
      let activeReferences = [];
      if (this.selectedCountry && this.selectedCountry.reference_countries) {
        this.drawReferencesSeries(notActiveReferences, activeReferences);
      } else {
        this.drawReferencedSeries();
      }
      this.drawColors.activeReferences = activeReferences;
      this.drawColors.notActiveReferences = notActiveReferences;
    },
    drawReferencesSeries(notActiveReferences, activeReferences) {
      this.selectedCountry.reference_countries.references
        .filter((c) => !c.is_active)
        .map((c) => notActiveReferences.push(c.iso_code));
      this.selectedCountry.reference_countries.references
        .filter((c) => c.is_active)
        .map((c) => activeReferences.push(c.iso_code));
      this.drawLinesSeries();
      this.drawArrowSeries();
      this.drawCountryBulletsSeries();
      this.drawFlagBullets();
      this.drawArrowLinesFromBullets();
    },
    drawReferencedSeries() {},
    clearPreviousData() {
      let lineIndex = this.chart.series.indexOf(this.series.line);
      let arrowIndex = this.chart.series.indexOf(this.series.arrow);
      let bulletsIndex = this.chart.series.indexOf(this.series.bullets);
      if (bulletsIndex !== -1)
        this.chart.series.removeIndex(bulletsIndex).dispose();
      if (arrowIndex !== -1)
        this.chart.series.removeIndex(arrowIndex).dispose();
      if (lineIndex !== -1) this.chart.series.removeIndex(lineIndex).dispose();
    },
    async setDefaultCountry() {
      this.selectedIso = 'IL';
      let mapObj = countryCapitalCoordinates.find(
        (result) => result.iso_code === this.selectedIso
      );
      this.homePoint.longitude = mapObj.longitude;
      this.homePoint.latitude = mapObj.latitude;
    },
  },
};
</script>

<style scoped>
#country-map,
.world-map {
  width: 100%;
  height: calc(85vh - 250px);
}
.world-map.loading {
  cursor: wait;
}
.world-map.ready {
  cursor: move;
}
</style>
