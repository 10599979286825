<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="processorSequenceModalContainer"
          class="sequence-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '55%',
            alignSelf: 'center',
            marginLeft: '15%',
            marginTop: '-15%',
            height: '90vh'
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <h3>Processor Sequence: {{ item.name }}</h3>
            <div class="form-group row ms-0 mt-30">
              <div class="col-lg-12">
                <div v-for="(hubProcess, hubProcessIndex) in item.hub_process_queue" :key="`${hubProcessIndex}_blocks`" class="item-block-chain mb-30">
                  <div class="block-number">#{{ hubProcessIndex + 1 }}</div>
                  <div v-for="(process, processIndex) in hubProcess.filter(hp => hp)" :key="`${processIndex}_lines`" class="processor-square">
                    <h5>{{ convertFullName(process.processor) }}</h5>

                    <div class="d-flex align-items-left justify-content-left processor-options" v-if="process.options" v-for="(k,v) in process.options">
                      <span class="property-title-label m-0">{{ v }}:</span>
                      <span class="pricing-info-card-value ms-3">{{ k }}</span>
                    </div>
                    <div v-if="processIndex != (hubProcess.filter(hp => hp).length - 1)" class="arrow-container"><div class="arrow"></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DetailsPopup',
  props: {
    item: Object,
    top: Number,
    left: Number,
  },
  emits: [
    'close',
  ],
  data() {
    return {
    };
  },
  methods: {
    convertFullName(full_name){
      return full_name.split('::').slice(-1)[0];
    },
  },
};
</script>
