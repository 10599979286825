<template>
  <b-skeleton
    v-show="$helpers.isEmptyArray(saleDeviation)"
    :style="{ height: '600px' }"
  />
  <div v-show="!$helpers.isEmptyArray(saleDeviation)" class="map_chart">
    <div class="sale-deviation--selector">
      <div class="map_chart-header">
        <ToggleSwitch style="position: absolute; left: 0;"
          v-model="mapTypeOption"
          :show-switch="true"
          :switch-options="mapTypeOptions"
          @change:model-value="switchMapTypeData"
        />

        <DatePicker style="padding-right: 2%;"
          v-model:value="range"
          type="date"
          :clearable="false"
          range
          placeholder="Data Select"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>

        <ToggleSwitch
          v-model="selectedOption"
          :show-switch="true"
          :switch-options="switchOptions"
        />
      </div>
    </div>

    <table style="position: absolute; z-index: 9; margin: 20px 0 0 5px">
      <tr>
        <td :style="`background: #DADADA; width:10px; height:10px`" />
        <td style="display: inline-block">
          <span class="little-info-card-footer-text">No sales for period</span>
        </td>
      </tr>
      <tr>
        <td :style="`background: #F5F5F5; width:10px; height:10px`" />
        <td style="display: inline-block">
          <span class="little-info-card-footer-text">Inactive country</span>
        </td>
      </tr>
      <template v-for="(color, i) in chromaScale" :key="color">
        <tr v-if="i % 2 != 0 || i == 0 || i == 40">
          <td :style="`background: ${color}; width:10px; height:10px`"></td>
          <td v-if="i === 0" style="display: inline-block">
            <span class="little-info-card-footer-text">-100%</span>
          </td>
          <td v-if="i === 9" style="display: inline-block">
            <span class="little-info-card-footer-text">-75%</span>
          </td>
          <td v-if="i === 19" style="display: inline-block">
            <span class="little-info-card-footer-text">-50%</span>
          </td>
          <td v-if="i === 29" style="display: inline-block">
            <span class="little-info-card-footer-text">-25%</span>
          </td>
          <td v-if="i === 39" style="display: inline-block">
            <span class="little-info-card-footer-text">- 1%</span>
          </td>
          <td v-if="i === 40" style="display: inline-block">
            <span class="little-info-card-footer-text"> {{ '>= 0%' }}</span>
          </td>
        </tr>
      </template>
    </table>

    <WorldMapVue
      :class="[
        $helpers.isEmptyArray(saleDeviation) && 'none-events',
        { grayscale: loading },
      ]"
      :country-data="selectCountryData(saleDeviation)"
      country-stroke-color="#909090"
      default-country-fill-color="#dadada"
      :show-overlay="showMapOverlay"
      @click="onClickMapCountry"
      @mouseenter="onMouseEnterMapCountry"
      @mouseleave="onMouseLeaveMapCountry"
    >
      <template #overlay>
        <h4>
          <b>{{ overlayData.country_name }} ({{ overlayData.country_code }})</b>
        </h4>
        <h5>
          Deviation(FC):
          {{ $helpers.numberWithCommas(overlayData.percent_deviation) }}%
        </h5>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                <span>Type</span>
              </th>
              <th scope="col">
                <span>Sales</span>
              </th>
              <th scope="col">
                <span>Forecast</span>
              </th>
              <th scope="col">
                <span>Budget</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>€</td>
              <td>
                {{
                  $helpers.numberWithCommas(overlayData.amounts.sales_amount)
                }}
              </td>
              <td>
                {{
                  $helpers.numberWithCommas(overlayData.amounts.forecast_amount)
                }}
              </td>
              <td>
                {{
                  $helpers.numberWithCommas(overlayData.amounts.budget_amount)
                }}
              </td>
            </tr>
            <tr>
              <td>Units</td>
              <td>
                {{ $helpers.numberWithCommas(overlayData.units.sales_units) }}
              </td>
              <td>
                {{
                  $helpers.numberWithCommas(overlayData.units.forecast_units)
                }}
              </td>
              <td>
                {{ $helpers.numberWithCommas(overlayData.units.budget_units) }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </WorldMapVue>
  </div>
</template>

<script>
import WorldMapVue from '@/components/WorldMap/index.vue';
import chroma from 'chroma-js';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import { countries } from 'country-data';
import { uniqArray } from '@/helpers';

const americanCountry = [
  'CA',
  'GL',
  'US',
  'MX',
  'VE',
  'BS',
  'BR',
  'CO',
  'PE',
  'BO',
  'AR',
  'CL',
  'FK',
  'PY',
  'UY',
  'NI',
  'EC',
  'GY',
  'SR',
  'GF',
  'TT',
  'PA',
  'CR',
  'GT',
  'HN',
  'BZ',
  'SV',
  'CU',
  'DO',
  'HT',
  'JM',
  'PR'
];

export default {
  name: 'MapGraph',
  components: {
    WorldMapVue,
    ToggleSwitch,
  },
  props: {
    saleDeviation: Array,
    options: Array,
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['getSaleDeviation'],
  data() {
    return {
      selectedOption: 'Month',
      showMapOverlay: false,
      wasSelectOption: false,
      mapTypeOption: 'The rest of the world',
      range: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(),
      ],
      unusableCountry: americanCountry,
      switchOptions: ['Month', 'YTD'],
      mapTypeOptions: ['The rest of the world', 'America'],
      overlayData: {
        amounts: {
          sales_amount: 0,
          forecast_amount: 0,
          budget_amount: 0,
        },
        units: {
          sales_units: 0,
          forecast_units: 0,
          budget_units: 0,
        },
        percent_deviation: 0,
        sales_parts_deviation: 0,
        country_url: '',
        country_code: '',
        country_name: '',
      },
      chromaScale: uniqArray(
        chroma
          .scale(['#f61818', '#ff7c00'])
          .colors(2)
          .concat(chroma.scale(['#ff7c00', '#ff9a48']).colors(10))
          .concat(chroma.scale(['#ff9a48', '#fff300']).colors(11))
          .concat(chroma.scale(['#fff300', '#beee3c']).colors(11))
          .concat(chroma.scale(['#beee3c', '#a2d420']).colors(10))
          .concat(chroma.scale(['#a2d420', '#36e67f']).colors(2))
      )
    };
  },
  watch: {
    range(val) {
      this.$emit('getSaleDeviation', val);

      if (!this.wasSelectOption) {
        this.selectedOption = '';
      }
      this.wasSelectOption = false;
    },
    selectedOption(val) {
      this.wasSelectOption = true;
      if (val)
        this.range = [
          new Date(
            new Date().getFullYear(),
            val == 'YTD' ? 0 : new Date().getMonth(),
            1
          ),
          new Date(),
        ];
    },
  },
  mounted() {
    americanCountry.forEach((code) =>
      document.getElementById(`${code}`).style.display = "none"
    );
    document
      .getElementById('map-svg')
      .setAttribute('viewBox', '200 0 1008 650');
  },
  computed: {
    nonAmericanCountry() {
      let filteredCountries = this.saleDeviation.filter(item => !americanCountry
                               .includes(item.country_code))
                               .map(item => item.country_code)
      filteredCountries.push('XK', 'TW')

      return filteredCountries
    }
  },
  methods: {
    switchMapTypeData() {
      if (this.mapTypeOption === 'The rest of the world') {
        this.unusableCountry = americanCountry
        this.showCountries(this.nonAmericanCountry)
        document.getElementById('map-svg').setAttribute('viewBox', '200 0 1008 650');
      } else {
        this.unusableCountry = this.nonAmericanCountry
        this.showCountries(americanCountry)
        document.getElementById('map-svg').setAttribute('viewBox', '-250 -50 1030 720');
      }

      this.unusableCountry.forEach(function(code) {
        let element = document.getElementById(`${code}`)
        if (element != null) {
          element.style.display = 'none';
        }
      });
    },
    showCountries(countryList) {
      countryList.forEach(function(code) {
        let element = document.getElementById(`${code}`)
        if (element != null) {
          element.style.display = '';
        }
      });
    },
    changeCurrentSwitchValue(val) {
      this.selectedOption = val;
    },
    findCountryInfo(countryCode) {
      return this.saleDeviation.find(
        (info) => info.country_code == countryCode
      );
    },
    onMouseEnterMapCountry(countryCode) {
      let countryInformation = this.findCountryInfo(countryCode);
      if (countryInformation) {
        this.overlayData = countryInformation;
      } else {
        this.overlayData.country_name = countries[countryCode].name;
        this.overlayData.country_code = countryCode;
      }
      this.showMapOverlay = true;
    },
    onMouseLeaveMapCountry() {
      this.overlayData = this.clearOverlayData();
      this.showMapOverlay = false;
    },
    onClickMapCountry(countryCode) {
      let countryInfo = this.findCountryInfo(countryCode);

      if (countryInfo) return (window.location.href = countryInfo.country_url);
    },
    selectCountryData(saleInformationArray) {
      if (!saleInformationArray.length) return {};
      let CountryData = {};
      let copySaleInformationArray = [...saleInformationArray];

      copySaleInformationArray.forEach(
        (saleInfo) =>
          (CountryData[`${saleInfo.country_code}`] = this.chooseColor(
            saleInfo.sales_parts_deviation
          ))
      );

      return CountryData;
    },
    chooseColor(part) {
      if (part === '') {
        return '#F5F5F5';
      } else if (Math.sign(part) >= 0) {
        return this.chromaScale.slice(20, 41)[(20).toString()];
      } else if (Math.sign(part) == -1) {
        return this.chromaScale.slice(0, 40).reverse()[
          (-(part + 1)).toString()
        ];
      }
    },
    clearOverlayData() {
      var hash = {
        amounts: {
          sales_amount: 0,
          forecast_amount: 0,
          budget_amount: 0,
        },
        units: {
          sales_units: 0,
          forecast_units: 0,
          budget_units: 0,
        },
        percent_deviation: 0,
        sales_parts_deviation: 0,
        country_url: '',
        country_code: '',
        country_name: '',
      };
      return hash;
    },
  },
};
</script>
