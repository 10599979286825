<template>
  <div class="card table-card little-table-container">
    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span>
        <PulseLoader :loading="loading" />
      </span>
    </div>
    <b-card no-body>
      <b-tabs
        card
        content-class="mt-3"
        fill
        active-nav-item-class="font-weight-bold text-uppercase"
      >
        <b-tab
          v-for="principles in pricingPrinciples"
          :key="principles.name"
          no-body
        >
          <template #title>
            <b-spinner v-if="loading" type="border" small class="tab-spinner" />

              <svg
              v-else 
              style="margin-right: 10px"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                  :fill="'#839AB5'"
                />
              </svg>
            <div class="tab-title">Price difference</div>
          </template>
          <div class="row small-table-row">
            <div class="col-md-12">
              <template v-for="(values, brand) in principles.data" :key="brand">
                <div :style="`background: ${brandColors[brand]}`">
                  <h4 class="ms-30">{{ brand }}</h4>
                </div>
                <table class="table borderless w-100">
                  <tbody class="scrollbar">
                    <tr
                      v-for="(differences, productType) in values"
                      :key="`${productType}${brand}`"
                    >
                      <td style="width: 50%">{{ productType }}</td>
                      <td style="width: 50%">
                        <table>
                          <tr>
                            <td
                              v-for="difference in differences"
                              :key="$helpers.toString(difference)"
                            >
                              <LineComponent
                                :data="difference"
                                :status-value="
                                  difference.difference.valid_limit
                                "
                                @open-principles-modal="openPrinciplesModal"
                              />
                            </td>
                          </tr>
                        </table>
                        <CommentsModal
                          v-if="showPrinciplesModal"
                          :top="principlesButtonTop"
                          :left="principlesButtonLeft"
                          :container-width="325"
                          parent-class="principles-modal"
                          @close="closePrinciplesModal"
                        >
                          <h1>Reference products</h1>
                          <div
                            v-for="(product, index) in pricingPrincipleInfo"
                            :key="product.id"
                          >
                            <span
                              class="d-flex align-items-center justify-content-between link principles-modal__principle_text"
                            >
                              <span
                                @click="
                                  $router.push(
                                    '/profit_center_variants/' + product.id
                                  )
                                "
                              >
                                {{ product.name }} - {{ product.price }}
                                {{
                                  $helpers.getCurrencySymbol(product.currency)
                                }}
                              </span>
                              <span
                                class="button-no-style clear-button clickable display-inline"
                                @click="showPrinciplesMessages(index)"
                              >
                                more
                                <i
                                  class="icon right-arrow display-inline"
                                  @click="showPrinciplesMessages(index)"
                                />
                              </span>
                            </span>
                            <template
                              v-if="displayPrinciplesMessages.includes(index)"
                            >
                              Last price changes:
                              <p
                                v-for="priceChange in product.prices"
                                :key="$helpers.toString(priceChange)"
                                class="principle-message"
                              >
                                {{ priceChange.date }} ->
                                {{ priceChange.price }}
                                {{
                                  $helpers.getCurrencySymbol(product.currency)
                                }}
                              </p>
                            </template>
                          </div>
                        </CommentsModal>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <div class="row small-table-row">
      <div class="col-md-12" />
    </div>

    <div class="row small-table-row">
      <div class="col-md-12" />
    </div>
  </div>
</template>

<script>
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import LineComponent from '@/components/PricingPrinciples/LineComponent.vue';

export default {
  name: 'StandartizationPrincipleTable',
  components: { CommentsModal, LineComponent },
  props: {
    objectType: String,
    pricingPrinciples: Array,
    loading: Boolean,
  },
  data() {
    return {
      pricing_principles: {},
      product_price_difference: {},
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
        id: null,
      },
      range: [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31),
      ],
      objectId: null,
      brandColors: {
        Sinupret: '#97BF0D',
        Bronchipret: '#4B6006',
        Tonsipret: '#CFF352',
        Imupret: '#598215',
        Rinupret: '#CDEE95',
        Canephron: '#FFD300',
        Agnucaston: '#A05599',
        Mastodynon: '#774072',
        Klimadynon: '#DABAD7',
        Allunapret: '#00457D',
        Assalix: '#E85412',
        Silimarit: '#F39910',
      },
      showPrinciplesModal: false,
      displayPrinciplesMessages: [],
    };
  },
  computed: {
    path() {
      return `/pricing_principles/${this.$route.path.split('/')[2]}/`;
    },
    countryName() {
      if (this.$route.query.name) {
        return this.$route.query.name;
      } else {
        return '';
      }
    },
  },
  methods: {
    getUrl(country) {
      return `/pricing_principles/${
        this.$route.path.split('/')[2]
      }/details?name=${country}`;
    },
    openPrinciplesModal(pricingPrincipleInfo, top, left) {
      this.principlesButtonTop = top;
      this.principlesButtonLeft = left;
      this.pricingPrincipleInfo = pricingPrincipleInfo;
      this.showPrinciplesModal = true;
    },
    showPrinciplesMessages(index) {
      if (this.displayPrinciplesMessages.includes(index)) {
        this.displayPrinciplesMessages = this.displayPrinciplesMessages.filter(
          (p) => p !== index
        );
      } else {
        this.displayPrinciplesMessages.push(index);
      }
    },
    closePrinciplesModal() {
      this.displayPrinciplesMessages.length = 0;
      this.showPrinciplesModal = false;
    },
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
  },
};
</script>
