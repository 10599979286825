<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <fade-loader :loading="loading" :color="'#fff'" />
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          class="comments-modal-container change-request-modal"
          :style="{
            width: '45%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
            height: '400px',
          }"
        >
          <div class="modal-body" style="min-height:200px;">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <div>
              <h3>{{ attachmentObject.changeable_type }} {{ attachmentObject.name }}</h3>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10 mb-30">
                  <input style="max-width: 400px;" ref="fileupload" type="file" multiple @change="handleUploadFile($event)" :key="componentKey"/>
                  <button class="btn btn-success ms-3" @click="submitFile()" v-floating-tooltip="'Upload a file'">Upload</button>
                  <button class="btn btn-warning ms-3" @click="cleanSelected()" v-floating-tooltip="'Clear selection'">Clear</button>
                </div>
                <div class="attachments-modal">
                  <label for="commentInput"
                    >Attached files:</label
                  ><br />
                  <div class="d-flex align-items-start">
                    <div class="card">
                      <div class="table-responsive overflow-x-clip">
                        <table class="table table-striped-pricing">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(option, idx) in attachments"
                              :key="`${option.name}_${option.id}_${idx}`"
                            >
                              <tr>
                                <td style="width: 70%;">{{ option.file_name }}</td>
                                <td>
                                  <button
                                    v-floating-tooltip="'Remove file'"
                                    class="btn btn-danger"
                                    @click="openRemoveAttachment(option)"
                                  >
                                    <i class="icon trash-icon" />
                                  </button>
                                  <button
                                    v-floating-tooltip="'Download file'"
                                    class="btn btn-info ms-3"
                                    @click="downloadAttachment(option)"
                                  >
                                    <i class="icon download-icon" />
                                  </button>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PopupModal
              v-if="popupModalVisible"
              v-model:changeRequest="deleteModalItem"
              :key="componentKey"
              :top="250"
              :left="650"
              :object-name="modalObjectName"
              :user-info="userInfo"
              :modal-action="modalAction"
              @close="closeModal()"
              @remove-attachment="removeAttachment"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/helpers/api';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';

export default {
  name: 'AttachmentsModal',
  components: {
    PopupModal,
    FadeLoader,
  },
  props: {
    attachmentObject: Object,
    type: String,
    top: Number,
    left: Number,
    userInfo: Object,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      range: [new Date(), new Date(new Date().getFullYear(), 12, 31)],
      attachments: [],
      loading: false,
      files: [],
      componentKey: 0,
      popupModalVisible: false,
      deleteModalItem: {},
    };
  },
  async mounted() {
    await this.getAttachments();
  },
  computed: {
    changeRequestProp: {
      get() {
        return this.changeRequest;
      },
      set(value) {
        this.$emit('update:changeRequest', value);
      },
    },
  },
  methods: {
    async getAttachments() {
      this.loading = true;
      const start_at = this.$helpers.dateToString(this.range[0]);
      const end_at = this.$helpers.dateToString(this.range[1]);
      const { data: attachments } = await api.get(
        `/attachments?attachable_id=${this.attachmentObject.id}&attachable_type=${this.attachmentObject.type}&from_date=${start_at}&to_date=${end_at}`
      )
      console.log(attachments);
      this.attachments = attachments?.map((p) => p.to_json);
      this.loading = false;
    },
    async downloadAttachment(item) {
      this.loading = true;
      const start_at = this.$helpers.dateToString(this.range[0]);
      const end_at = this.$helpers.dateToString(this.range[1]);
      const response = await api.get(
        `/attachments/${item.id}?attachable_id=${this.attachmentObject.id}&attachable_type=${this.attachmentObject.type}&from_date=${start_at}&to_date=${end_at}`)
      const contentType = this.$helpers.fileContentType[response.data.file_name.split('.')[1]]
      const linkSource = `data:${contentType};base64,${response.data.file}`;
      const downloadLink = document.createElement("a");
      const fileName = response.data.file_name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.loading = false;
    },
    openRemoveAttachment(item) {
      this.loading = true;
      this.deleteModalItem = item;
      this.modalAction = 'removeAttachment';
      this.modalObjectName = item.file_name;
      this.popupModalVisible = true;
    },
    async removeAttachment(item) {
      this.loading = true;
      const start_at = this.$helpers.dateToString(this.range[0]);
      const end_at = this.$helpers.dateToString(this.range[1]);
      await api
        .delete(`/attachments/${item.id}?attachable_id=${this.attachmentObject.id}&attachable_type=${this.attachmentObject.type}&from_date=${start_at}&to_date=${end_at}`)
        .then(_response => {
          this.attachments = this.attachments.filter(file => file.id !== item.id);
          this.$helpers.notifySuccess('File successfully deleted!');
        })
        .catch(err => {
            console.log(err)
            const errorField = Object.keys(err.response.data.errors)[0];
            let message = err.response.data.errors[errorField];
            this.errorMessage = message;
            this.$helpers.notifyError(message);
          });

      this.popupModalVisible = false;
      this.loading = false;
    },
    async cleanSelected() {
      this.$refs.fileupload.value = null;
    },
    handleUploadFile(event) {
      this.files = event.target.files;
    },
    async submitFile() {
      const selectedFiles = this.files
      if (selectedFiles.length > 0) {
        this.loading = true;

        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append('files[]', file);
        });
        await api
          .post(`/attachments?attachable_id=${this.attachmentObject.id}&attachable_type=${this.attachmentObject.type}`,
                formData)
          .then(_response => {
            this.$helpers.notifySuccess('File successfully uploaded!');
            this.getAttachments();
            this.files = [];
            this.componentKey = Math.random();
          })
          .catch(err => {
            const errorField = Object.keys(err.response.data.errors)[0];
            let message = err.response.data.errors[errorField];
            this.errorMessage = message;
            this.$helpers.notifyError(message);
          });

        this.loading = false;
      }
    },
    closeModal() {
      this.popupModalVisible = false;
      this.loading = false;
    },
  },
};
</script>
