<template>
  <div>
    <div class="form-group row ms-0">
      <div class="col-lg-4">
        <label for="amountInput">New value:</label>
        <input
          id="amountInput"
          v-model="changeRequestModel.amount"
          type="number"
          class="form-control text-input"
        />
      </div>
    </div>

    <div class="form-group row ms-0">
      <div class="col-lg-5">
        <label>Valid date period:</label>
        <DatePicker
          ref="datepicker"
          v-model:value="changeRequestModel.range"
          :clearable="false"
          type="range"
          range
          :disabled-date="disabledDates"
          placeholder="Date Select"
          :open="isVisibleDatePicker"
          :close="!isVisibleDatePicker"
          @close="isVisibleDatePicker = false"
          @open="isVisibleDatePicker = true"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>
      </div>
    </div>

    <div class="form-group row ms-0">
      <div class="col-lg-10">
        <label for="commentInput">Comment for correction:</label>
        <textarea
          id="commentInput"
          v-model="changeRequestModel.comment"
          class="form-control text-input"
          rows="2"
        />
      </div>
    </div>

    <div class="form-group row ms-0">
      <div class="col-xs-2">
        <b-spinner
          v-if="requestInProgress"
          type="border"
          small
          class="tab-spinner"
        />
        <button
          v-if="!requestInProgress && changeRequest.corrections"
          class="btn btn-warning normal-button"
          @click="rejectChanges()"
        >
          Reject
        </button>
        <button
          v-if="!requestInProgress && !changeRequest.corrections"
          class="btn btn-primary normal-button"
          @click="submitChanges()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestForm',
  props: {
    changeRequest: Object,
    field: String,
    requestInProgress: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['submitChanges', 'rejectChanges', 'update:changeRequest'],
  data() {
    return {
      isVisibleDatePicker: false,
      disabledBefore: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ),
      disabledAfter: new Date(new Date().getFullYear() + 5, 12, 31),
      range: [new Date(), new Date(new Date().getFullYear() + 5, 12, 31)],
    };
  },
  computed: {
    changeRequestModel: {
      get() {
        return this.changeRequest;
      },
      set(value) {
        this.$emit('update:changeRequest', value);
      },
    },
  },
  watch: {
    'changeRequest.range'() {
      this.isVisibleDatePicker = false;
    },
  },
  methods: {
    disabledDates(date) {
      if (this.field == 'exch_bu') {
        return this.disabledBuDates(date) || date > this.disabledAfter;
      } else {
        return this.disabledLeRates(date) || date > this.disabledAfter;
      }
    },
    disabledLeRates(date) {
      let month = new Date().getMonth() + 1;

      if (month < 12) {
        return (
          date > new Date(new Date().getFullYear() + 5, 11, 31) || date < new Date()
        );
      } else {
        return date < new Date();
      }
    },
    disabledBuDates(date) {
      let month = new Date().getMonth() + 1;

      if (month == 12) {
        return date <= new Date(new Date().getFullYear() + 1, 11, 31);
      } else {
        return date <= new Date(new Date().getFullYear() - 1, 11, 31);
      }
    },
    submitChanges() {
      this.$emit('submitChanges', this.changeRequest);
    },
    rejectChanges() {
      this.$emit('rejectChanges', this.changeRequest);
    },
  },
};
</script>
