<template>
  <div class="comments-box">
    <div v-if="isEditable" class="comment-edit">
      <textarea
        v-model="newRuleMessage"
        class="comment-input"
        placeholder="Add a comment..."
      />
      <div class="actions-area">
        <span class="save-comment-icon" @click="onSave">
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 24 24">
              <path
                fill="#96DE5C"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
              />
            </svg>
          </i>
        </span>
        <span class="close-comment-icon" @click="onClose">
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </i>
        </span>
      </div>
    </div>

    <div v-else class="comment-show">
      <p v-floating-tooltip="commentUserInfo" class="comment-text mb-0">
        {{ lastComment.text }}
      </p>
      <div class="actions-area">
        <span class="edit-comment-icon" @click="onEdit">
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 24 24">
              <path
                :fill="'#96DE5C'"
                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
              />
            </svg>
          </i>
        </span>
        <span
          ref="commentsIcon"
          class="comments-list"
          @click="showCommentsModal"
        >
          <i class="icon link comment-icon" />
        </span>
      </div>
    </div>

    <CommentsModal
      v-if="commentsModalVisible"
      :top="commentsIconTop"
      :left="commentsIconLeft - 300"
      :container-width="600"
      @close="commentsModalVisible = false"
    >
      <div class="comments-container">
        <span class="comment-number">History ({{ comments.length }})</span>
        <div v-for="comment in comments" :key="comment.id">
          <Comment
            :user-info="userInfo"
            :comment-data="comment"
            :with-image="false"
            :with-remove="true"
            @on-delete="onDelete"
          />
        </div>
      </div>
    </CommentsModal>
  </div>
</template>

<script>
import api from '@/helpers/api';
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import Comment from '@/components/Modals/CommentsModal/Comment.vue';

export default {
  name: 'CommentsBox',
  components: { CommentsModal, Comment },
  props: {
    userInfo: Object,
    commentableInfo: Object,
  },
  data() {
    return {
      newRuleMessage: '',
      comments: [],
      isEditable: false,
      commentsModalVisible: false,
      commentsIconTop: 0,
      commentsIconLeft: 0,
    };
  },
  computed: {
    lastComment() {
      if (this.comments.length > 0) return this.comments[0];
      else return {};
    },
    commentUserInfo() {
      if (this.lastComment.author)
        return `${this.lastComment.author}, ${this.lastComment.date}`;
      else return '';
    },
  },
  watch: {
    async commentableInfo() {
      await this.getComments();
    },
  },
  async mounted() {
    await this.getComments();
  },

  methods: {
    showCommentsModal() {
      this.commentsIconTop =
        this.$refs.commentsIcon.getBoundingClientRect().top;
      this.commentsIconLeft =
        this.$refs.commentsIcon.getBoundingClientRect().left;
      this.commentsModalVisible = true;
    },
    onEdit() {
      this.newRuleMessage = this.lastComment.text || '';
      this.isEditable = true;
    },
    onClose() {
      this.isEditable = false;
    },
    async getComments() {
      let url = `/comments?commentable_type=${this.commentableInfo.type}&commentable_id=${this.commentableInfo.id}&commentable_key=${this.commentableInfo.key}`;
      const response = await api.get(url);
      this.comments = response.data;
    },
    async onSave() {
      if (this.lastComment.text !== this.newRuleMessage) {
        const result = await api.post(
          `/comments/`,
          JSON.stringify({
            comment: {
              message: this.newRuleMessage,
              commentable_type: this.commentableInfo.type,
              commentable_id: this.commentableInfo.id,
              commentable_key: this.commentableInfo.key,
            },
          })
        );
        this.comments.unshift(result.data);
      }
      this.onClose();
    },
    async onDelete(id) {
      await api.delete(`/comments/${id}.json`);
      this.comments = this.comments.filter((e) => e.id !== id);
    },
  },
};
</script>
