<template>
  <b-skeleton v-if="loading" width="100px" height="35px" />
  <div
    v-else-if="displayingCheck"
    class="d-flex align-items-center justify-content-between"
  >
    <div
      class="hierarchy-item-currency-selector hierarchy-item-exchange-rate-type-selector"
    >
      <Multiselect
        v-model="selectType.value"
        :options="selectType.options"
        :placeholder="selectType.placeholder"
        select-label
        :show-labels="false"
      >
        <template #caret="{ toggle }">
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <i class="icon icon-down-arrow" />
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartMultiselect',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectBy: String,
  },
  emits: ['changeCurrencyChartData'],
  data() {
    return {
      selectType: {
        value: '',
        options: [],
        placeholder: '',
      },
    };
  },
  computed: {
    displayingCheck() {
      return ['showCurrencyToggler', 'showCurrencyType'].includes(
        this.selectBy
      );
    },
  },
  watch: {
    'selectType.value': function (val) {
      if (this.selectBy === 'showCurrencyType') {
        let currencyType = val == 'Price currency' ? 'price' : 'local';
        this.$emit('changeCurrencyChartData', currencyType);
      }
    },
  },
  mounted() {
    switch (this.selectBy) {
      case 'showCurrencyType':
        this.selectType.options = ['Local Currency', 'Price currency'];
        this.selectType.placeholder = 'Currency Type';
        break;
    }
  },
};
</script>
