<template>
  <div
    v-if="priceInfo.length && !isCompetitor"
    class="card price-build-table"
    :style="!country && 'width: 100%; margin: 0 0 30px 0;'"
  >
    <div v-if="country" class="price-build-header">
      <h2 class="display-block">
        Price Build up {{ country }}
      </h2>
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="card-item little-info-card-footer-small"
          v-floating-tooltip="'Currency deviation'"
        >
          <div style="display: flex !important">
            <i v-if="currencyDeviation >= 0" class="icon up-icon" />
            <i v-else class="icon down-icon" />
            <span
              class="little-info-card-percentage"
              :class="
                currencyDeviation >= 0
                  ? 'little-info-card-percentage-up'
                  : 'little-info-card-percentage-down'
              "
              style="margin-top: 10px; justify-self: center"
            >
              {{ $helpers.numberWithCommas(currencyDeviation) }}%
            </span>
          </div>
        </div>

        <div
          class="hierarchy-item-currency-selector hierarchy-item-exchange-rate-type-selector"
        >
          <div class="switch-radio-buttons">
            <ToggleSwitch
              v-model="currencyShort"
              :show-switch="true"
              :switch-options="localExchangeRateTypeOptions"
              @change:model-value="changeCurrentSwitchValue"
            />
          </div>
        </div>

        <div class="chart-calendar-switcher-buttons">
          <DatePicker
            v-model:value="range"
            :clearable="false"
            type="date"
            range
            placeholder="Data Select"
            :disabled-date="disabledEndDate"
            @change="getPriceBuildupDetailsAndDraw"
          >
            <template #icon-calendar>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                  :fill="
                    range[0] !== null && range[1] !== null
                      ? '#003B6A'
                      : '#839AB5'
                  "
                />
              </svg>
            </template>
            <template #icon-clear>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                  fill="#003B6A"
                />
              </svg>
            </template>
          </DatePicker>
          <div class="chart-toggle-range-buttons">
            <button class="toggle-button" @click="switchDatePickerType('YTD')">
              YTD
            </button>
            <button class="toggle-button" @click="switchDatePickerType('MAT')">
              MAT
            </button>
          </div>
        </div>
      </div>

      <button
        v-if="showTable"
        class="expand-narrow-down-button button-no-style clickable"
        @click="showTable = false"
      >
        <svg
          width="18"
          height="2"
          viewBox="0 0 18 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3333 0.083374H9.91667H1.66667C1.16041 0.083374 0.75 0.493779 0.75 1.00004C0.75 1.5063 1.16041 1.91671 1.66667 1.91671H9.91667H16.3333C16.8396 1.91671 17.25 1.5063 17.25 1.00004C17.25 0.493779 16.8396 0.083374 16.3333 0.083374Z"
            fill="#003B6A"
          />
        </svg>
      </button>
      <button
        v-else
        class="expand-narrow-down-button button-no-style clickable"
        @click="showTable = true"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.83333 7.16667H14.6667C15.1269 7.16667 15.5 7.53976 15.5 8C15.5 8.46024 15.1269 8.83333 14.6667 8.83333H8.83333V14.6667C8.83333 15.1269 8.46024 15.5 8 15.5C7.53976 15.5 7.16667 15.1269 7.16667 14.6667V8.83333H1.33333C0.873096 8.83333 0.5 8.46024 0.5 8C0.5 7.53976 0.873096 7.16667 1.33333 7.16667H7.16667V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V7.16667Z"
            fill="#31394D"
          />
        </svg>
      </button>
      <button
        class="buildup-card-right-panel-button button-no-style vertical-middle"
        @click="redirectToPriceBuildups"
      >
        <i class="icon chart-bar display-inline me-30 vertical-middle" />
      </button>
    </div>

    <div class="table-responsive">
      <table class="table table-striped-details borderless">
        <thead>
          <tr>
            <th scope="col" class="price-build-table-name-row">Name</th>
            <template v-if="!hidePlannedData">
              <th
                v-for="item in ['Planned Data', 'Real Data', 'Deviation', 'Formula']"
                :key="item + $route.path"
                scope="col"
              >
                {{ item }}
              </th>
            </template>

            <th scope="col" />
            <th scope="col" />
            <th scope="col" />
          </tr>
        </thead>

        <tbody>
          <template
            v-for="(item, index) in filteredStatPriceInfo"
            :key="item.full_name + item.short_name + $route.path"
          >
            <tr>
              <td>{{ item.full_name }}</td>
              <template v-if="!hidePlannedData">
                <td
                  v-for="(value, idx) in [
                    $helpers.showFieldValue(item, currencyShort),
                    $helpers.showFieldValue(
                      statPrices[item.short_name],
                      currencyShort
                    ),
                    $helpers.getAvailableDeviation(
                      item,
                      statPrices[item.short_name]
                    ),
                  ]"
                  :key="value + idx + $route.path"
                >
                  {{ value }}
                </td>
                <td>
                  <VDropdown :distance="6">
                    <button class="btn btn-formula btn-sm ms-10">
                      <i class="icon formula-icon" />
                    </button>

                    <template #popper>
                      <div style='padding: 15px;'>
                        {{ item.formula || 'not formula' }}
                      </div>
                    </template>
                  </VDropdown>
                </td>
              </template>

              <td class="open-details-cell">
                <svg
                  v-floating-tooltip="
                    `Show ${
                      openedDetails.row === index ? 'less' : 'more'
                    } information`
                  "
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-down-arrow open-details-cell-icon clickable link"
                  :class="{ 'rotate-arrow': openedDetails.row === index }"
                  @click="openDetailsRow(index, item.short_name)"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </td>
            </tr>
            <tr class="no-hover">
              <td
                colspan="7"
                :class="[
                  'opened-details',
                  {
                    'is-closed': openedDetails.row !== index,
                  },
                ]"
                :style="
                  openedDetails.row !== index
                    ? 'padding: 0 !important'
                    : undefined
                "
              >
                <div
                  v-if="openedDetails.row === index && !openDetailsLoading"
                  class="opened-details-container"
                >
                  <div>
                    <Chart
                      object="details"
                      :table-mode="false"
                      :quote="defineQuote()"
                      :show-calendar="false"
                      :page-name="item.full_name"
                      :data-type="openedDetails.type"
                      :style-object="{
                        boxShadow: 'none',
                        border: 'none',
                      }"
                      :start-end-date="range"
                      :override-currency="selectedCurrency"
                      :selected-detail="openedDetails.shortName"
                    />

                    <div
                      v-if="
                        selectedCurrency != priceBuildupCurrency &&
                        openedDetails.type === 'currency'
                      "
                      class="quote-line-grayed"
                    >
                      Planned data uses {{ plannedDataRate }} exchange rate,
                      Real data uses {{ realDataRate }} exchange rate
                    </div>
                  </div>

                  <h3 class="table-header">Details Table</h3>

                  <div
                    class="mb-30 d-flex align-items-center justify-content-center"
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Month</th>
                          <th
                            v-for="result in openedDetails.results"
                            :key="result[0] + $route.path"
                            scope="col"
                          >
                            {{ result[0] }}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(_, idx) in openedDetails.labels"
                          :key="openedDetails.labels[idx]"
                        >
                          <td>{{ openedDetails.labels[idx] }}</td>
                          <td
                            v-for="(array, i) in openedDetails.results"
                            :key="array[i][idx] + $route.path"
                          >
                            {{
                              $helpers.restructureAndShowFieldValue(
                                array[1][idx],
                                openedDetails.type,
                                currencyShort
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="close-details">
                    <svg
                      v-floating-tooltip="
                        `Show ${
                          openedDetails.row === index ? 'less' : 'more'
                        } information`
                      "
                      class="icon icon-up-arrow open-details-cell-icon link clickable"
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="openDetailsRow(index, item.short_name)"
                    >
                      <path
                        d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                        fill="#9CB2CB"
                      />
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import Chart from '@/components/Chart/index.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'HierarchyItemDetailsPriceBuildupTable',
  components: { Chart, ToggleSwitch },
  props: {
    pcvName: String,
    priceInfo: Array,
    country: {
      type: String,
      default: null,
    },
    exchangeRateTypeOptions: Array,
    id: Number,
    priceBuildupCurrency: {
      type: String,
      default: null,
    },
    isCompetitor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectExchangeRateType'],
  data() {
    return {
      currencyShort: this.priceInfo.filter(
        (e) => e.full_name === 'Currency Short'
      )[0].value,
      showTable: true,
      localExchangeRateTypeOptions: this.exchangeRateTypeOptions,
      selectedCurrency: this.exchangeRateTypeOptions[0],
      statPrices: {},
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
      },
      openDetailsLoading: false,
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 11, 31),
      range: [
        this.startDate
          ? this.startDate
          : new Date(new Date().getFullYear(), 0, 1),
        this.endDate
          ? this.endDate
          : new Date(new Date().getFullYear(), 11, 31),
      ],
      buildupExceptions: [
        'Currency Deviation',
        'Gap in EUR (Curr dev)',
        'Gap in EUR (Real dev)',
        'Gap in Local Curr (Curr dev)',
        'Gap in Local Curr (Real dev)',
      ],
      quottedFields: [
        'ph_pprice_gross',
        'ph_pprice_net',
        'ret_price_net_wo_vat',
        'ret_price_gross_wo_vat',
        'ret_price_incl_vat',
      ],
      disabledBefore: new Date(2012, 1, 1),
      disabledAfter: new Date(2026, 1, 1),
    };
  },
  computed: {
    filteredStatPriceInfo() {
      const filteredByStat = this.priceInfo.filter((price) => {
        if (price.type === 'text') {
          return false;
        }
        if (this.buildupExceptions.includes(price.full_name)) {
          return false;
        }
        const shortName = price.short_name;
        if (shortName.includes('stat')) {
          const remove_after = shortName.lastIndexOf('_');
          const shortNameNoStat = shortName.substring(0, remove_after);
          this.statPrices[shortNameNoStat] = price;
          return false;
        } else {
          return true;
        }
      });

      if (this.showTable) {
        return filteredByStat;
      } else {
        return filteredByStat.slice(0, 3);
      }
    },

    currencyDeviation() {
      let curDev = null;
      try {
        curDev = this.priceInfo.filter(
          (e) => e['full_name'] === 'Currency Deviation'
        )[0].value;
      } catch {
        curDev = '-';
      }
      return curDev;
    },

    plannedDataRate() {
      if (
        this.selectedCurrency === 'PLN' &&
        this.openedDetails &&
        this.keyIndex(this.openedDetails.shortName) >=
          this.keyIndex('ex_partn_price_gross')
      ) {
        return 'Customer fixed';
      }
      return 'LE';
    },

    realDataRate() {
      if (['MDL', 'UZS'].includes(this.selectedCurrency)) {
        return 'API';
      }
      return 'Actual';
    },

    hidePlannedData() {
      return this.isCompetitor && this.country !== 'Germany';
    },
  },
  methods: {
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    async getPriceBuildupDetailsAndDraw() {
    },
    async getDetailsTableData() {
      if (this.openedDetails.shortName) {
        this.openDetailsLoading = true;
        const response = await api.get(
          `/profit_center_variants/${this.id}/graph_data?object=details` +
            `&from_date=${this.$helpers.dateToString(this.range[0])}` +
            `&to_date=${this.$helpers.dateToString(
              this.range[1]
            )}&details_key=${this.openedDetails.shortName}` +
            `&currency_short_name=${this.selectedCurrency}`
        );

        let responseData = response.data.results
        const labels = Object.keys(responseData);
        const results = this.preparePriceBuildupData(responseData);


        this.openedDetails = {
          ...this.openedDetails,
          data: response.data,
          labels,
          results,
        };
        this.openedDetails['type'] = this.filteredStatPriceInfo.find(
          (a) => a.short_name === this.openedDetails.data.details_key
        ).type;

        this.openDetailsLoading = false;
      }
    },

    preparePriceBuildupData(response) {
      let results = Object.values(response);

      const obj = {};
      for (const res in results[0]) {
        obj[res] = [];
      }

      for (const result in results) {
        for (const objProp in results[result]) {
          obj[objProp].push(results[result][objProp]);
        }
      }

      return Object.entries(obj);
    },

    async openDetailsRow(index, shortName) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.openedDetails.shortName = shortName;
        this.openedDetails.row = index;
        await this.getDetailsTableData();
      }
    },

    async changeCurrentSwitchValue(selectedCurrency) {
      this.selectedCurrency = selectedCurrency;
      this.$emit('selectCurrency', this.selectedCurrency);
      await this.getDetailsTableData();
    },

    async switchDatePickerType(dataType) {
      let year = new Date().getFullYear();

      if (dataType === 'YTD') {
        this.range = [new Date(year, 0, 1), new Date()];
      } else {
        this.range = [new Date(new Date().setFullYear(year - 1)), new Date()];
      }
    },

    redirectToPriceBuildups() {
      this.$router.push(
        `/profit_center_variants/${this.$route.params.id}/price_buildups`
      );
    },

    defineQuote() {
      let quote = '';
      if (
        this.quottedFields.includes(this.openedDetails.shortName) &&
        this.country === 'Russia'
      ) {
        quote = 'Source: DSM';
      }
      return quote;
    },

    keyIndex(key) {
      return this.priceInfo.findIndex((h) => h['short_name'] === key);
    },
  },
};
</script>
