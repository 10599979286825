<template>
  <div :id="`diff_${pcv.id}_${dateKey}`" class="plan-prices__diff">
    <span :class="diffClass()">
      {{ toSignedPercent(eurDiff) }}
    </span>
    <b-popover
      v-if="localDiff || exchangeRateEffect"
      :target="`diff_${pcv.id}_${dateKey}`"
      triggers="hover focus"
    >
      <div v-if="localDiff || localDiff === 0.0">
        <span> Price effect: {{ toSignedPercent(localDiff) }} </span>
      </div>
      <div v-if="exchangeRateEffect || exchangeRateEffect === 0.0">
        <span>
          Exchange rate effect: {{ toSignedPercent(exchangeRateEffect) }}
        </span>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'PlanPricesDiff',
  props: {
    pcv: Object,
    dateKey: [String, Number],
    eurDiff: Number,
    localDiff: Number,
    exchangeRateEffect: Number,
  },
  methods: {
    diffClass() {
      return {
        'plan-prices__diff-positive': this.eurDiff > 0,
        'plan-prices__diff-negative': this.eurDiff < 0,
      };
    },
    toSignedPercent(value) {
      return `${value > 0 ? '+' : ''}${this.formatPrice(
        (value * 100.0).toFixed(2)
      )}%`;
    },
    formatPrice(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        useGrouping: false,
      });
    },
  },
};
</script>
