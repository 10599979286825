<template>
  <div :class="['predictionWrapper', { active: formVisible }]">
    <div :class="['predictionContainer', { hasLoader: isLoading }]">
      <div class="predictionHeader">
        <h1>Data Prediction</h1>
        <button class="closeButton" @click="toggleForm(false)">
          <svg
            id="closeButtonPred"
            width="21"
            height="21"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.64844 1L12.0007 11.3523"
              stroke="#DBE1E9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.3523 1L1 11.3523"
              stroke="#DBE1E9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="predictionContent">
        <div class="msgContainer">
          <div class="msg">
            {{ currentMessage }}
          </div>
        </div>

        <div v-show="inputFormVisible" class="predictionForm">
          <select v-model="curSelOption" class="itemSelect">
            <option value="" disabled selected>
              Please select what you want to predict
            </option>
            <option
              v-for="option in availableItems"
              :key="option"
              :value="{ option }"
            >
              {{ option }}
            </option>
          </select>

          <button class="btn btn-predictionSubmit" @click="submitData()">
            Predict
          </button>
        </div>
      </div>

      <div v-if="hasPredictionData()">
        <div v-show="hasPredictionData" class="resultContainer">
          <h2>Your results</h2>
          <a :href="csvUrl" class="btn btnCSVDownload" target="_blank">
            Download Results (.csv)
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                fill="#000000"
              />
            </svg>
          </a>
          <br />
          <h5>Selected Option: {{ curSelOption.option }}</h5>
          <h5>Prediction ID: {{ recievedData.prediction_id }}</h5>

          <div v-for="(val, key) in graphUrls" :key="key">
            <h3 class="graphHeader">
              {{ key }}
              <a :href="val" class="btn btnImageDownload" target="_blank">
                Download Graph
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#000000"
                  />
                </svg>
              </a>
            </h3>
            <div
              v-if="graphObjectsLoaded"
              :width="currentWidth + 'px'"
              :height="currentWidth * 0.53333 + 'px'"
              class="graphWrapper"
              v-html="graphObject[key]"
            />
          </div>
        </div>
      </div>

      <div class="debugInfo">
        <h6>debug output</h6>
        <h6>RAW unfiltered output data</h6>
        {{ recievedData }}
        <h6>Rest</h6>
        {{ curSelOption.option }}
        <br />
        {{ formVisible }}
        <br />
        {{ availableItems }}
        <br />
        {{ rawData }}
      </div>

      <div :class="['predictionLoader', { isActive: isLoading }]">
        <div class="msgContainer">
          <div class="msg">
            {{ currentMessage }}
          </div>
        </div>

        <div class="lds-container">
          <div class="lds-grid">
            <div v-for="i in 9" :key="i" class="lds-item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';

export default {
  name: 'Prediction',
  props: {
    rawData: Object,
    dateRange: {
      type: Array,
      default() {
        return [];
      },
    },
    valueRange: {
      type: Array,
      default() {
        return [];
      },
    },
    availableItems: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedItem: String,
    predictionDataLoaded: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  emits: ['elementDisabled'],
  data() {
    return {
      data: {},
      formVisible: true,
      curSelOption: {
        option: JSON.parse(JSON.stringify(this.availableItems[0])),
      },
      loadedAllData: false,
      sendPredRequest: false,
      recievedPrediction: false,
      recievedData: {},
      isLoading: true,
      dataObject: {
        dates: [],
        values: [],
      },
      currentMessage: '',
      inputFormVisible: false,
      csvUrl: '',
      graphUrls: {},
      graphObject: {},
      graphObjectsLoaded: false,
      currentWidth: 0,
    };
  },
  watch: {
    predictionDataLoaded(newVal, oldVal) {
      if (newVal && !oldVal) {
        console.log('predictionDataLoaded loaded');
        this.loadedAllData = true;
        this.toggleLoader(false);
        this.toggleInputForm(true);
        this.setMsg(
          'Done loading additional Data. Select what you want to predict!'
        );
      } else {
        this.setMsg('Loading additional Data... Hang on!');
      }
    },
    formVisible(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.toggleLoader(true);
      }
    },
  },
  mounted() {
    this.curSelOption = this.availableItems[0];
    this.toggleLoader(true);
    this.toggleInputForm(false);
    this.setMsg('Loading additional Data... Hang on!');
  },

  methods: {
    async sendPredictionRequest() {
      this.toggleInputForm(false);
      this.toggleLoader(true);

      this.setMsg('Sending prediction request.');

      let result = await api.post(
        `/predictions`,
        JSON.stringify(this.dataObject)
      );

      this.setMsg(
        'Your request is processing. This will take a while... hang on!'
      );

      const parsedResult = await result.data;

      if (result.status == 200) {
        console.log('positive result');
        this.setMsg('Your results are ready. Here you go!');

        this.recievedData = parsedResult;
        await this.parseURLs();
      } else {
        this.setMsg(
          'Unknown error when trying to get your results - please try again later!'
        );
        console.log('negative result');
      }

      this.toggleLoader(false);
    },
    toggleInputForm(option = false) {
      this.inputFormVisible = option;
    },
    toggleForm(option = true) {
      this.formVisible = option;

      if (!option) {
        this.$emit('elementDisabled', true);
      }
    },
    toggleLoader(option = false) {
      this.isLoading = option;
    },
    async submitData() {
      this.setMsg('Submiting data to prediction service... Hang on!');

      this.toggleInputForm(false);

      console.log('preparing your data...');
      this.prepareData();

      this.setMsg('');

      await this.sendPredictionRequest();
    },
    prepareData() {
      const selOption = this.curSelOption.option;

      this.setMsg('Preparing your data!');

      for (const [key, value] of Object.entries(this.rawData.results)) {
        const curVal = value[selOption];

        this.dataObject.dates.push(key);
        this.dataObject.values.push(curVal);
      }

      return this.dataObject;
    },
    setMsg(content = 'Hi') {
      this.currentMessage = content;
    },
    hasPredictionData() {
      if (Object.keys(this.recievedData).length > 0) {
        return true;
      }

      return false;
    },
    getDimensions() {
      if (this.loadedAllData) {
        const element = document.getElementsByClassName('predictionHeader');
        let dimensions = {};

        if (element) {
          dimensions = element[0].getBoundingClientRect();
          this.currentWidth = dimensions.width;
        }

        return dimensions;
      }
    },
    async parseURLs() {
      console.log('Preparing urls');

      for (const [key, value] of Object.entries(
        this.recievedData.prediction_urls
      )) {
        if (key == 'csv_path') {
          this.csvUrl = value;
        } else {
          this.graphUrls[key] = value;
          this.graphObjectsLoaded = false;
          this.graphObject[key] = await fetch(value, {
            method: 'get',
            'Content-Type': 'application/text',
          })
            .then((response) => {
              return response.text();
            })
            .then((t) => {
              this.graphObjectsLoaded = true;
              return t;
            });
        }
      }
    },
  },
};
</script>
