<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="commentsModalContainer"
          class="comments-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '45%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="$emit('close')"
                class="svg-close-button"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CommentsModal',
  props: {
    data: Object,
    top: Number,
    left: Number,
    containerWidth: Number,
    parentClass: String,
  },
  emits: ['close'],
  computed: {
    styleObject: function () {
      const result = {};

      const maxLeft = window.innerWidth - this.containerWidth;
      if (this.left > maxLeft) {
        result.left = maxLeft - 300 + 'px';
      } else {
        result.left = this.left - 300 + 'px';
      }

      if (this.top > window.innerHeight * 0.7) {
        result.bottom = window.innerHeight - this.top + 20 + 'px';
      } else {
        result.top = this.top + 40 + 'px';
      }

      return result;
    },
  },
};
</script>
