<template>
  <div class="little-info-card">
    <div class="little-info-card-main">
      <div class="little-info-card-main-data">
        <span class="little-info-card-title">{{ titleFirst }}:</span>
        <span class="little-info-card-value"
          >{{ $helpers.numberWithoutCommas(salesDiscounts) }} €</span
        >
        <span class="little-info-card-title">{{ titleSecond }}:</span>
        <span class="little-info-card-value"
          >{{ $helpers.numberWithoutCommas(salesDeductions) }} €</span
        >
      </div>
      <div class="little-info-card-main-pic">
        <!-- <i :class="`icon ${img}`"/>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesInfoCard',
  props: {
    titleFirst: String,
    titleSecond: String,
    salesDiscounts: Number,
    salesDeductions: Number,
  },
};
</script>
