<template>
  <div class="price-buildup-list">
    <div class="d-flex justify-content-left mt-30 mb-30">
      <button
        class="buildup-card-right-panel-button button-no-style vertical-middle"
        @click="returnToPcv"
      >
        <i
          class="icon left-arrow-toggler display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Product
        </span>
      </button>
    </div>

    <h2 class="mb-30">Price Buildup List</h2>

    <div v-if="!loading" class="card small-table-card">
      <div class="table-responsive">
        <table class="table table-striped borderless">
          <thead>
            <tr v-if="priceBuildups.length > 0">
              <th
                v-for="key in Object.keys(priceBuildups[0])"
                :key="key"
                scope="col"
              >
                {{ key }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in priceBuildups"
              :key="item.id"
              class="link"
              @click="$router.push(`${$route.path}/${item.id}`)"
            >
              <td v-for="value in Object.values(item)" :key="value">
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import { filterAllowedKeys } from '@/helpers';

export default {
  name: 'PriceBuildupListPage',
  data() {
    return {
      loading: false,
      priceBuildups: [],
      pcvId: this.$route.params.id,
    };
  },
  async beforeMount() {
    this.loading = true;

    this.priceBuildups = await this.loadPriceBuildups();

    this.loading = false;
  },
  methods: {
    returnToPcv() {
      this.$router.push(`/profit_center_variants/${this.pcvId}`);
    },
    async loadPriceBuildups() {
      let { data: response } = await api.get(
        `/profit_center_variants/${this.pcvId}/price_buildups`
      );
      let result = response.map((p) => p.to_json);

      const allowed = [
        'id',
        'profit_center_variant_name',
        'status',
        'valid_from',
        'valid_until',
      ];

      return filterAllowedKeys(result, allowed);
    },
  },
};
</script>
