<template>
  <div
    class="search-result-card"
    :style="getBrandColor()"
    :class="[activeHover ? 'activeHover' : '', activeOpen ? 'activeOpen' : '']"
    @mouseover="activeHoverNotActiveOpen()"
    @mouseleave="activeHoverNotActiveOpen(false)"
  >
    <div v-if="!loading" class="form-icon" @click="toggleDetailsVisibility">
      <i
        v-if="!activeHover && !activeOpen"
        class="icon"
        :class="searchResult.is_competitor ? 'pill' : 'bionorica-icon'"
      />
      <i v-else class="icon right-arrow" />

      <div v-if="activeOpen" class="detailsContainer">
        <ul class="detailLinks">
          <li class="infoItem">Competitor: {{ searchResult.is_competitor }}</li>
          <li class="infoItem">Status: {{ searchResult.status }}</li>
          <li
            v-if="searchResult.manufacturer && searchResult.manufacturer.name"
            class="infoItem"
          >
            Manufacturer: {{ searchResult.manufacturer.name }}
          </li>
          <li class="clickableItem" @click="onCardClick">
            GPMS Name: {{ searchResult.gpms_name }}
          </li>
          <li
            v-if="searchResult.brand && searchResult.brand.name"
            class="clickableItem"
            @click="$router.push(`/brands/${searchResult.brand.id}`)"
          >
            Brand: {{ searchResult.brand['name'] }}
          </li>
          <li
            v-if="searchResult.product && searchResult.product.full_name"
            class="clickableItem"
            @click="$router.push(`/products/${searchResult.product.id}`)"
          >
            Product: {{ searchResult.product['full_name'] }}
          </li>
          <li
            v-if="
              searchResult.product_variant &&
              searchResult.product_variant.full_name
            "
            class="clickableItem"
            @click="
              $router.push(
                `/product_variants/${searchResult.product_variant.id}`
              )
            "
          >
            ProductVariant: {{ searchResult.product_variant['full_name'] }}
          </li>
          <li
            v-if="searchResult.country && searchResult.country.name"
            class="clickableItem"
            @click="$router.push(`/countries/${searchResult.country.id}`)"
          >
            Country: {{ searchResult.country['name'] }}
          </li>
        </ul>
        <div class="chart-container">
          <Chart
            v-if="!searchResult.is_competitor"
            :show-switch="false"
            :show-calendar="false"
            data-type="sales"
            object="sales"
            :ids="[searchResult.id]"
            :start-date="getTimeFrameForGraphs()[0]"
            :end-date="getTimeFrameForGraphs()[1]"
            graph-type="bar"
            :show-header="false"
            :show-summary="false"
            :show-stacked="false"
            :show-average-details="false"
          />
        </div>
        <div class="showDetails clickableItem" @click="onCardClick">
          Details
        </div>
      </div>

      <i class="icon under-pill-triangle" />
    </div>
    <div class="card search-result-card" :class="{ loading }">
      <div v-if="!loading" class="shadow-card">
        <div class="card-glas-header">
          <div class="card-title-left">
            <button class="button-no-style m-0" @click="onCardClick">
              <span :class="{ highlight: highlightProperty === 'Name' }">
                <span v-if="searchResult.is_bundle_pack">{{
                  searchResult.sap_name
                }}</span>
                <span v-else>{{ searchResult.gpms_name }}</span>
              </span>
            </button>
            <div class="card-id">
              <span :class="{ highlight: highlightProperty === 'ID' }"
                >ID {{ searchResult.sap_id }}</span
              >
              <span v-if="searchResult.is_competitor">{{
                getMatchingId(searchResult.sap_id)
              }}</span>
            </div>
          </div>
          <div class="card-title-right card-product-variants">
            <span
              v-if="searchResult.is_competitor && searchResult.is_main"
              class="card-product-variants-item mb-1"
            >
              <div
                v-if="searchResult.is_main === 'true'"
                class="d-flex align-items-center"
              >
                <i class="me-2 property-icon is-main-competitors-icon" />
                <span class="card-product-variants-item">
                  Main Competitor
                </span>
              </div>
              <div v-else>
                <div class="search-filters-with-checkbox-checkbox-wrapper">
                  <label class="checkbox">
                    <div class="checkbox__input me-2">
                      <input type="checkbox" class="checkboxInput" disabled />
                      <div class="checkbox__control">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-width="3"
                            d="M1.73 12.91l6.37 6.37L22.79 4.59"
                          />
                        </svg>
                      </div>
                    </div>
                    <span class="card-product-variants-item">
                      Main Competitor
                    </span>
                  </label>
                </div>
              </div>
            </span>

            <div v-if="searchResult.status">
              <IconText
                :title="$helpers.firstLetterCapital(searchResult.status)"
                icon="status-icon"
                :text-color="highlightProperty === 'Status' ? '#67B84A' : ''"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="card-content">
        <div class="card-price-levels-table">
          <div
            class="table-responsive m-0"
            :class="searchResult.is_competitor ? 'd-flex' : ''"
          >
            <div v-if="searchResult.is_competitor" class="table-competitors">
              <table class="table table-borderless overflow-hidden">
                <tbody>
                  <tr v-if="searchResult.manufacturer">
                    <td class="item-title ps-0">
                      <IconText
                        label-for="Manufacturer"
                        title="Manufacturer"
                        icon="manufacturer-icon"
                      />
                    </td>
                    <td>
                      {{ searchResult.manufacturer.name }}
                    </td>
                  </tr>
                  <tr v-if="searchResult.original_brands">
                    <td class="item-title ps-0">
                      <IconText
                        label-for="Market"
                        title="Market"
                        icon="brand-icon"
                      />
                    </td>
                    <td>
                      <template v-if="searchResult.original_brands">
                        <span
                          v-for="brand in searchResult.original_brands"
                          :key="brand.name"
                          class="card-product-variants-item link mb-1"
                          @click="$router.push(`/brands/${brand.id}`)"
                        >
                          {{ brand.name }}
                        </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table class="table table-borderless overflow-hidden">
              <thead>
                <tr class="search-result-card-prices-header">
                  <th
                    v-if="!searchResult.is_competitor"
                    scope="col"
                    :class="{
                      highlight: highlightProperty === 'List price gross I',
                    }"
                  >
                    List price gross I
                  </th>
                  <th
                    v-if="!searchResult.is_competitor"
                    scope="col"
                    :class="{
                      highlight: highlightProperty === 'List price gross II',
                    }"
                  >
                    List price gross II
                  </th>
                  <th
                    v-if="!searchResult.is_competitor"
                    scope="col"
                    :class="{
                      highlight:
                        highlightProperty === 'List price gross II real',
                    }"
                  >
                    List price gross II real
                  </th>
                  <th
                    scope="col"
                    :class="{
                      highlight:
                        highlightProperty === 'Wholesaler purchase price net',
                    }"
                  >
                    Wholesaler purchase price net
                  </th>
                  <th
                    scope="col"
                    :class="{
                      highlight:
                        highlightProperty === 'Pharmacy purchase price net',
                    }"
                  >
                    Pharmacy purchase price net
                  </th>
                  <th scope="col">Retail price incl. VAT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="searchResult.price_info">
                  <td
                    v-for="searchResultCardField in resultPricesCardFields"
                    :key="searchResultCardField"
                  >
                    {{
                      $helpers.showFieldValue(
                        prices.find(
                          (price) => price.full_name === searchResultCardField
                        ),
                        currencyShort
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="search-result-card-footer">
          <div class="card-product-variants left">
            <span
              v-if="searchResult.country"
              class="card-product-variants-item link"
              :class="{ highlight: highlightProperty === 'Country' }"
              @click="$router.push(`/countries/${searchResult.country.id}`)"
            >
              {{ searchResult.country.name }}
            </span>
            <span
              v-if="searchResult.brand"
              class="card-product-variants-item link"
              :class="{ highlight: highlightProperty === 'Brand' }"
              @click="$router.push(`/brands/${searchResult.brand.id}`)"
            >
              {{ searchResult.brand.name }}
            </span>
            <span
              v-if="searchResult.product"
              class="card-product-variants-item link"
              :class="{ highlight: highlightProperty === 'Product' }"
              @click="$router.push(`/products/${searchResult.product.id}`)"
            >
              {{ searchResult.product.full_name }}
            </span>
            <span
              v-if="searchResult.product_variant"
              class="card-product-variants-item link"
              :class="{ highlight: highlightProperty === 'Product variant' }"
              @click="
                $router.push(
                  `/product_variants/${searchResult.product_variant.id}`
                )
              "
            >
              {{ searchResult.product_variant.full_name }}
            </span>
          </div>
          <div
            v-if="!searchResult.is_competitor"
            class="card-product-variants right"
          >
            <span
              v-if="
                searchResult.country &&
                searchResult.country.reference_countries &&
                searchResult.country.reference_countries.references &&
                searchResult.country.reference_countries.references.length
              "
              ref="IRPButton"
              class="card-product-variants-item link card-product-variants-item__irp"
              :class="{ highlight: highlightProperty === 'IRP' }"
              @click="
                onIRPClick(searchResult.country.reference_countries.references)
              "
            >
              IRP
            </span>

            <span
              v-if="
                searchResult.pricing_principle_info &&
                !searchResult.is_competitor
              "
              ref="principlesIcon"
              class="card-product-variants-item card-product-variants-item__principles"
              @click="onPrinciplesClick(searchResult.pricing_principle_info)"
            >
              <i :class="`icon link ${principlesIcon}`" />
            </span>

            <span
              v-if="searchResult.sap_id"
              ref="commentsIcon"
              class="card-product-variants-item card-product-variants-item__comments"
              @click="onCommentsClick(searchResult.sap_id)"
            >
              <i class="icon link comment-icon" />
              <span class="comment-icon-count">
                {{ commentsCount() }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <CommentsModal
      v-if="commentsModalVisible"
      :top="commentsIconTop"
      :left="commentsIconLeft - 300"
      :container-width="780"
      @close="commentsModalVisible = false"
    >
      <div class="comments-container">
        <span class="comment-number">Comments ({{ commentsData.length }})</span>
        <div v-for="comment in commentsData" :key="comment.id">
          <Comment
            :user-info="userInfo"
            :comment-data="comment"
            :with-image="true"
            :editable="true"
            :with-remove="true"
            @on-delete="deleteComment"
            @update-comment="updateComment"
          />
        </div>

        <div class="comment-input-wrapper">
          <input
            ref="addComment"
            v-model="commentText"
            class="comment-input"
            placeholder="Add a comment..."
            @focus="inputHasFocus = true"
          />
          <button class="button-no-style">
            <i
              v-if="inputHasFocus"
              class="icon send-text-icon"
              @click="createComment()"
            />
            <i v-else class="icon send-text-inactive-icon" />
          </button>
        </div>
      </div>
    </CommentsModal>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import Comment from '@/components/Modals/CommentsModal/Comment.vue';
import {
  searchResultsCardFields,
  searchResultsCompetitorsCardFields,
  brandColors,
} from '@/helpers/constants';
import api from '@/helpers/api';
import Chart from '@/components/Chart/index.vue';

export default {
  name: 'SearchResultsCard',
  components: { IconText, CommentsModal, Comment, Chart },
  props: {
    userInfo: Object,
    comments: Array,
    searchResult: Object,
    highlightProperty: String,
    loading: Boolean,
  },
  emits: ['showIRPModal', 'openPrinciplesModal'],
  data() {
    return {
      commentsModalVisible: false,
      commentsIconTop: 0,
      commentsIconLeft: 0,
      commentsList: [],
      commentText: '',
      inputHasFocus: false,
      commentsData: [],
      currencyShort: '',
      prices: [],
      incorrectData: false,
      searchResultsCardFields,
      activeHover: false,
      activeOpen: false,
    };
  },
  computed: {
    principlesIcon() {
      return this.searchResult.pricing_principle_info.every(
        (principle) => principle.status === true
      )
        ? 'principles-ok-icon'
        : 'principles-wrong-icon';
    },
    resultPricesCardFields() {
      return this.searchResult.is_competitor
        ? searchResultsCompetitorsCardFields
        : searchResultsCardFields;
    },
  },
  watch: {
    comments: {
      deep: true,
      async handler() {
        this.commentsData = this.comments;
      },
    },
  },
  mounted() {
    if (!this.searchResult.price_info) {
      this.incorrectData = true;
    } else {
      this.currencyShort = this.searchResult.price_info.find(
        (h) => h['full_name'] == 'Currency Short'
      ).value;
      if (!this.currencyShort) {
        this.incorrectData = true;
      }

      this.prices = this.searchResult.price_info;
    }
  },
  methods: {
    getBrandColor() {
      if (this.loading) return '';
      let brandName;
      if (this.searchResult.is_competitor) {
        brandName = this.searchResult.original_brands
          ? this.searchResult.original_brands[0].name
          : '';
      } else {
        brandName = this.searchResult.brand ? this.searchResult.brand.name : '';
      }
      let color = brandColors[brandName.toLowerCase()];
      return `border-top: 5px solid ${color};`;
    },
    onCardClick() {
      this.$router.push(`profit_center_variants/${this.searchResult.id}`);
    },
    onCommentsClick() {
      this.commentsIconTop =
        this.$refs.commentsIcon.getBoundingClientRect().top;
      this.commentsIconLeft =
        this.$refs.commentsIcon.getBoundingClientRect().left;
      this.commentsModalVisible = true;
    },
    async updateComment(id, comment) {
      if (this.commentText.text !== '') {
        const result = await api.put(
          `/comments/${id}`,
          JSON.stringify({
            comment: { message: comment },
          })
        );
        if (result.data) {
          this.commentsData = this.comments.map((e) => {
            if (e.id === id) {
              e.text = comment;
            }
            return e;
          });
        }
      }
    },
    async createComment() {
      if (this.commentText !== '') {
        const result = await api.post(
          `/comments/`,
          JSON.stringify({
            comment: {
              message: this.commentText,
              commentable_type: 'ProfitCenterVariant',
              commentable_id: this.searchResult.id,
              commentable_key: 'search',
            },
          })
        );
        this.commentsData.unshift(result.data);
        this.commentText = '';
      }
    },
    commentsCount() {
      if (this.commentsData?.length && this.commentsData?.length > 99) {
        return '99+';
      }
      return this.commentsData?.length || '';
    },
    async deleteComment(id) {
      await api.delete(`/comments/${id}.json`);
      this.commentsData = this.commentsData.filter((e) => e.id !== id);
    },

    onIRPClick(countries) {
      let countryNames = countries.map((c) => c.name);
      this.$emit(
        'showIRPModal',
        countryNames,
        this.$refs.IRPButton.getBoundingClientRect().top,
        this.$refs.IRPButton.getBoundingClientRect().left
      );
    },
    onPrinciplesClick(pricingPrincipleInfo) {
      this.$emit(
        'openPrinciplesModal',
        pricingPrincipleInfo,
        this.$refs.principlesIcon.getBoundingClientRect().top,
        this.$refs.principlesIcon.getBoundingClientRect().left
      );
    },
    getTimeFrameForGraphs() {
      const today = new Date();
      const beginningOfYear = new Date(today.getFullYear(), 0, 1);

      return [beginningOfYear, today];
    },
    getMatchingId(sapId) {
      if (this.searchResult.country?.name === 'Poland')
        return ' / PI ' + sapId.match(/(\d.*)/)[0];
      return '';
    },
    toggleDetailsVisibility() {
      if (this.activeHover) {
        this.activeOpen = !this.activeOpen;
        this.activeHover = false;
      } else {
        this.activeOpen = false;
        this.activeHover = false;
      }
    },
    activeHoverNotActiveOpen(mode = true) {
      if (!this.activeOpen && mode === true) {
        this.activeHover = true;
        return true;
      }

      this.activeHover = false;
      return false;
    },
  },
};
</script>
