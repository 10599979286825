<template>
  <div
    v-if="priceInfoArray.length"
    class="card price-build-table m-0"
    :style="!country && 'width: 100%;'"
  >
    <div class="price-build-header">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div
          class="hierarchy-item-currency-selector hierarchy-item-exchange-rate-type-selector"
        >
          <div class="switch-radio-buttons">
            <ToggleSwitch
              v-model="buildupDataType"
              :show-switch="true"
              :switch-options="priceInfoTypeOptions"
              @change:model-value="changeCurrentSwitchValue"
            />
          </div>
        </div>

        <div
          v-if="!!selectedExchangeRateType"
          class="hierarchy-item-currency-selector hierarchy-item-exchange-rate-type-selector"
        >
          <div class="switch-radio-buttons">
            <ToggleSwitch
              v-model="selectedExchangeRateType"
              :show-switch="true"
              :loading="loadingPriceBuildupCurrency"
              :switch-options="localExchangeRateTypeOptions"
              @change:model-value="
                () =>
                  $emit('selectPriceBuildupCurrency', selectedExchangeRateType)
              "
            />
          </div>
        </div>
      </div>
      <button
        v-if="showTable"
        class="expand-narrow-down-button button-no-style clickable"
        @click="showTable = false"
      >
        <svg
          width="18"
          height="2"
          viewBox="0 0 18 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3333 0.083374H9.91667H1.66667C1.16041 0.083374 0.75 0.493779 0.75 1.00004C0.75 1.5063 1.16041 1.91671 1.66667 1.91671H9.91667H16.3333C16.8396 1.91671 17.25 1.5063 17.25 1.00004C17.25 0.493779 16.8396 0.083374 16.3333 0.083374Z"
            fill="#003B6A"
          />
        </svg>
      </button>
      <button
        v-else
        class="expand-narrow-down-button button-no-style clickable"
        @click="showTable = true"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.83333 7.16667H14.6667C15.1269 7.16667 15.5 7.53976 15.5 8C15.5 8.46024 15.1269 8.83333 14.6667 8.83333H8.83333V14.6667C8.83333 15.1269 8.46024 15.5 8 15.5C7.53976 15.5 7.16667 15.1269 7.16667 14.6667V8.83333H1.33333C0.873096 8.83333 0.5 8.46024 0.5 8C0.5 7.53976 0.873096 7.16667 1.33333 7.16667H7.16667V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V7.16667Z"
            fill="#31394D"
          />
        </svg>
      </button>
    </div>
    <div v-if="showTable" class="table-responsive">
      <table class="table table-striped borderless">
        <thead>
          <tr>
            <th scope="col" class="price-build-table-name-row">Name</th>
            <th scope="col">Average</th>
            <th scope="col">Max</th>
            <th scope="col">Min</th>
            <th scope="col" />
          </tr>
        </thead>

        <span v-if="loadingPriceBuildupCurrency">
          <PulseLoader :loading="loadingPriceBuildupCurrency" />
        </span>
        <tbody :class="{ 'opacity-20': loadingPriceBuildupCurrency }">
          <tr v-for="item in priceInfoArray" :key="item[0]">
            <td>{{ item[0] }}</td>
            <template v-if="item[1].type === 'percent'">
              <td>
                {{
                  $helpers.forceZeroes($helpers.numberWithCommas(item[1].avg))
                }}%
              </td>
              <td>
                {{
                  $helpers.forceZeroes($helpers.numberWithCommas(item[1].max))
                }}%
              </td>
              <td>
                {{
                  $helpers.forceZeroes($helpers.numberWithCommas(item[1].min))
                }}%
              </td>
            </template>
            <template v-else>
              <td>
                {{ $helpers.forceZeroes($helpers.numberWithCommas(item[1].avg))
                }}{{ $helpers.getCurrencySymbol(selectedExchangeRateType) }}
              </td>
              <td>
                {{ $helpers.forceZeroes($helpers.numberWithCommas(item[1].max))
                }}{{ $helpers.getCurrencySymbol(selectedExchangeRateType) }}
              </td>
              <td>
                {{ $helpers.forceZeroes($helpers.numberWithCommas(item[1].min))
                }}{{ $helpers.getCurrencySymbol(selectedExchangeRateType) }}
              </td>
            </template>
            <td>
              <span
                class="link d-flex"
                @click="$emit('stack-page', [item[0], item[1]['short_name']])"
              >
                <i class="icon add-icon me-1" /> (details)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'AnalysisPriceBuildupTable',
  components: { ToggleSwitch },
  props: {
    priceInfo: Object,
    country: String,
    exchangeRateTypeOptions: Array,
    loadingPriceBuildupCurrency: {
      type: Boolean,
      default: false,
    },
    currency: String,
  },
  emits: ['selectPriceBuildupCurrency', 'stack-page', 'getPriceBuildupDetails'],
  data() {
    return {
      showTable: true,
      range: [
        this.startDate ? this.startDate : new Date(2019, 12, 31),
        new Date(),
      ],
      priceInfoTypeOptions: ['real', 'planned'],
      buildupDataType: 'planned',
      priceInfoKeys: [],
      localExchangeRateTypeOptions: this.exchangeRateTypeOptions || [],
      selectedExchangeRateType:
        this.currency || (this.exchangeRateTypeOptions || [])[0],
      plannedKeys: [
        'Cost of Goods Sold',
        'Margin Manufacturer',
        'List price gross I',
        'Discount in invoice',
        'Margin Manufacturer II',
        'List price gross II',
        'Plan price',
        'sales deductions',
        'Net price',
        'Margin Partner',
        'Ex partner price gross',
        'Discount Partner',
        'Wholesaler purchase price gross',
        'Discount to Wholesaler',
        'Wholesaler purchase price net',
        'Mark-up Wholesaler',
        'Pharmacy purchase price gross',
        'Discount to pharmacy',
        'Pharmacy purchase price net',
        'Mark-up Pharmacy',
        'Retail price gross w/o VAT',
        'Discount to consumer',
        'Retail price net w/o VAT',
        'VAT',
        'Retail price incl. VAT',
        'Real list price gross II',
        'Customs Tax / Fees',
      ],
      realKeys: [
        'Cost of Goods Sold real',
        'Margin Manufacturer real',
        'List price gross I real',
        'Discount in invoice real',
        'Margin Manufacturer II real',
        'List price gross II real',
        'Plan price real',
        'sales deductions real',
        'Net price real',
        'Margin Partner real',
        'Ex partner price gross real',
        'Discount Partner real',
        'Wholesaler purchase price gross real',
        'Discount to Wholesaler real',
        'Wholesaler purchase price net real',
        'Mark-up Wholesaler real',
        'Pharmacy purchase price gross real',
        'Discount to pharmacy real',
        'Pharmacy purchase price net real',
        'Mark-up Pharmacy real',
        'Retail price gross w/o VAT real',
        'Discount to consumer real',
        'Retail price net w/o VAT real',
        'VAT real',
        'Retail price incl. VAT real',
        'Real list price gross II real',
        'Customs Tax / Fees real',
      ],
    };
  },
  computed: {
    priceInfoArray() {
      return Object.entries(this.priceInfo)
        .filter((e) => this.priceInfoKeys.includes(e[0]));
    },
  },
  beforeMount() {
    this.priceInfoKeys = this.plannedKeys;
  },
  methods: {
    getPriceBuildupDetails() {
      this.$emit('getPriceBuildupDetails', this.range[1]);
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    changeCurrentSwitchValue(option) {
      if (option === 'real') {
        this.priceInfoKeys = this.realKeys;
      } else {
        this.priceInfoKeys = this.plannedKeys;
      }
    },
  },
};
</script>
