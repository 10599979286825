<template>
  <div class="container">
    <div class="row mb-30">
      <h1>International Price Referencing (IRP)</h1>
    </div>
    <div class="row" :class="{ mapLoading }">
      <WorldMap
        :countries="countries"
        :loading="mapLoading"
        @set-country="setCountry"
      />
    </div>
    <div
      v-if="country"
      ref="countryInfo"
      class="container"
      :class="{ mapLoading }"
    >
      <div
        class="hierarchy-item-title fixed"
        :class="{ scrolled: currentScrollPos < -660 }"
      >
        <h2>
          {{ country.name }}
          <i :class="`flag-${country.image_url}`" />
        </h2>
      </div>
      <div
        v-if="referencedCountries.length > 0 && !loading"
        class="mb-30 referenced-countries-box"
      >
        <span> is reference country for: </span>
        <div
          v-for="item in referencedCountries"
          :key="$helpers.toString(item)"
          class="referenced-country"
        >
          <i :class="`flag flag-${item.image_url}`" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div
        v-if="refCountry && !loading"
        class="grid-container-price-referencing mb-30"
      >
        <div class="grid-info-table">
          <InfoTable :user-info="userInfo" :country="refCountry" />
        </div>
        <div class="grid-chart-map">
          <PriceTable :country="refCountry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import WorldMap from '@/pages/PriceReferencing/WorldMap.vue';
import PriceTable from '@/pages/PriceReferencing/PriceTable.vue';
import InfoTable from '@/pages/PriceReferencing/InfoTable.vue';

export default {
  name: 'PriceReferencing',
  components: { WorldMap, InfoTable, PriceTable },
  props: {
    userInfo: Object,
    currentScrollPos: Number,
  },
  data() {
    return {
      loading: false,
      mapLoading: false,
      country: null,
      refCountry: null,
      countries: [],
      referencedCountries: [],
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async setCountry(country_iso) {
      this.refCountry = this.countries.filter(
        (c) => c.iso_code === country_iso
      )[0];
      await this.getReferencedCountries(country_iso);
    },
    async getData() {
      this.mapLoading = true;
      const response = await api.post(
        `/price_referencing/countries_info_for_references`
      );
      this.countries = response.data;
      this.mapLoading = false;
    },
    async getReferencedCountries(country_iso) {
      this.loading = true;
      let response = await api.post(
        `price_referencing/referenced_countries`,
        JSON.stringify({
          country_iso: country_iso,
        })
      );
      this.country = response.data.country;
      this.referencedCountries = response.data.refs;
      this.loading = false;
    },
  },
};
</script>
