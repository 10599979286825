<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          class="comments-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '45%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <template v-if="modalAction == 'finish'">
              <h3 class="mb-30">Finish Currency Change Request</h3>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to finish request?</label
                  >
                </div>
              </div>
              <div class="col-lg-10">
                <p class="text-danger">This will discard all related price change requests and create new ones for selected period.</p>
              </div>

              <div class="align-right w100">
                <button
                  class="btn btn-success normal-button"
                  :disabled="isFinishButtonDisabled"
                  @click="finishRequest"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FinishPopupModal',
  props: {
    changeRequest: Object,
    top: Number,
    left: Number,
    userInfo: Object,
    modalAction: String,
    rejectType: String,
  },
  emits: [
    'close',
    'finishRequest',
  ],
  data() {
    return {
      isFinishButtonDisabled: false,
    };
  },
  computed: {
    changeRequestProp: {
      get() {
        return this.changeRequest;
      },
      set(value) {
        this.$emit('update:changeRequest', value);
      },
    },
  },
  watch: {
  },
  methods: {
    finishRequest() {
      this.$emit('finishRequest', this.changeRequestProp);
      this.isFinishButtonDisabled = true;
    }
  },
};
</script>
