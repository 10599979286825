<template>
  <div
    class="table-card chart-block card-card m-0 pt-0 px-0"
    :class="{ loading }"
    :style="styleObject"
  >
    <div class="chart-header">
      <h1 v-if="!loading" class="m-0">
        {{ $helpers.firstLetterCapital(object) }} Graph
      </h1>

      <ChartMultiselect :select-by="selectBy" :loading="loading" />

      <div v-if="!loading" class="chart-settings">
        <DatePicker
          v-if="showCalendar"
          v-model:value="range"
          :clearable="false"
          type="date"
          range
          :disabled-date="disabledEndDate"
          placeholder="Data Select"
          @change="getDataAndDraw"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>
      </div>
    </div>

    <div class="chart-content">
      <div class="chart-container" :class="{ 'd-none': showTable }">
        <div class="chart" :style="loading && 'display: none;'">
          <canvas ref="chart" />
        </div>
        <div v-if="!loading && summary" class="chart-summary">
          <span v-if="summary.forecast_discounts" class="chart-summary__title"
            >Forecast discount</span
          >
          <span
            v-if="summary.forecast_discounts"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{
              $helpers.numberWithCommas(summary.forecast_discounts.toFixed(2))
            }}
            €
          </span>
          <span v-if="summary.forecast_deductions" class="chart-summary__title"
            >Forecast deductions</span
          >
          <span
            v-if="summary.forecast_deductions"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{
              $helpers.numberWithCommas(summary.forecast_deductions.toFixed(2))
            }}
            €
          </span>

          <span v-if="summary.discounts" class="chart-summary__title"
            >Discounts</span
          >
          <span
            v-if="summary.discounts"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.discounts.toFixed(2)) }} €
          </span>
          <span v-if="summary.deductions" class="chart-summary__title"
            >Deductions</span
          >
          <span
            v-if="summary.deductions"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.deductions.toFixed(2)) }} €
          </span>
          <span v-if="summary.share" class="chart-summary__title"
            >Share % of Gross Sales I</span
          >
          <span
            v-if="summary.share"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.share.toFixed(2)) }} %
          </span>

          <span v-if="summary.exchange_effects" class="chart-summary__title"
            >Total Sales Exchange Rate Effect</span
          >
          <span
            v-if="summary.exchange_effects"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.exchange_effects.toFixed(2)) }}
            €
          </span>

          <span v-if="summary.net_sales_total" class="chart-summary__title"
            >Total Net Sales</span
          >
          <span
            v-if="summary.net_sales_total"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.net_sales_total.toFixed(2)) }}
            €
          </span>

          <span v-if="summary.forecast_sales" class="chart-summary__title"
            >Total Forecast Sales</span
          >
          <span
            v-if="summary.forecast_sales"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.forecast_sales.toFixed(2)) }} €
          </span>

          <span v-if="summary.le_effect" class="chart-summary__title"
            >Total LE exchange rate effect</span
          >
          <span
            v-if="summary.le_effect"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.le_effect.toFixed(2)) }} €
          </span>

          <span v-if="summary.bu_effect" class="chart-summary__title"
            >Total BU exchange rate effect</span
          >
          <span
            v-if="summary.bu_effect"
            class="chart-summary-toggler chart-summary-toggler-sales"
            :class="{ 'is-active': true }"
          >
            {{ $helpers.numberWithCommas(summary.bu_effect.toFixed(2)) }} €
          </span>
        </div>
      </div>
    </div>
    <h4 class="info-quote">{{ quote }}</h4>
    <h4
      v-if="dataType == 'exchangeEffects'"
      data-pdf="sub-chart"
      class="info-quote"
    >
      Сurrency actual exchange rate was updated on {{ currencyUpdatedAt }}
    </h4>
  </div>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartMultiselect from '@/components/Chart/ChartMultiselect.vue';
import api from '@/helpers/api';

export default {
  name: 'StackedChart',
  plugins: [ChartDataLabels],
  components: { ChartMultiselect },
  props: {
    object: String,
    objectType: String,
    currencyId: Number,
    objectId: {
      type: Number,
      default: 0,
    },
    startDate: Date,
    styleObject: Object,
    selectBy: String,
    showCalendar: {
      type: Boolean,
      default: true,
    },
    tableMode: {
      type: Boolean,
      default: true,
    },
    twoYAxesMode: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      default: 'sales',
    },
    quote: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      datacollection: null,
      type: 'bar',
      myChart: null,
      range: [
        this.startDate ? this.startDate : new Date(2019, 12, 31),
        new Date(),
      ],
      loading: false,
      showTable: false,
      results: {},
      labels: [],
      labelClicks: [],
      summary: {
        forecast_discounts: 0,
        forecast_deductions: 0,
        discounts: 0,
        deductions: 0,
        share: 0,
        exchange_effects: 0,
        net_sales_total: 0,
        forecast_sales: 0,
        le_sales: 0,
        bu_sales: 0,
      },
      stacked: true,
      currencyToggler: {
        currentCurrency: 'EUR',
        facturaCurrency: 'EUR',
        countryCurrency: 'RUB',
      },
      isItemsActive: false,
      currencyType: '',
      disabledBefore: new Date(2012, 1, 1),
      disabledAfter: new Date(2026, 1, 1),
      graphData: {},
      colors: [
        '#C39BD3',
        '#5499C7',
        '#48C9B0',
        '#52BE80',
        '#F4D03F',
        '#EB984E',
        '#A569BD',
        '#5DADE2',
        '#45B39D',
        '#58D68D',
        '#F5B041',
        '#DC7633',
      ],
    };
  },
  computed: {
    currencyUpdatedAt() {
      if (this.graphData) {
        return this.$helpers.dateToLocaleDate(
          this.graphData.actual_exchange_rate_updated_at
        );
      } else {
        return null;
      }
    },
  },
  async mounted() {
    await this.getDataAndDraw();
  },

  methods: {
    async getDataAndDraw() {
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1])
      );
      this.fillData();
      this.createChart(this.datacollection);
    },
    async getData(fromDate, toDate) {
      this.loading = true;

      let id;

      if (this.$route.path == `/currencies/${this.$route.params.id}`) {
        id = '';
      } else if (this.objectId > 0) {
        id = this.objectId;
      } else {
        id = '';
      }

      let response;
      if (this.dataType === 'exchangeEffects') {
        response = await api.get(
          `/currencies/${this.currencyId}/exchange_rate_graph_data?object_type=${this.objectType}&id=${id}&from_date=${fromDate}&to_date=${toDate}`
        );
      } else {
        response = await api.get(
          `${this.$route.path}/graph_data?object_type=${this.objectType}&id=${id}&from_date=${fromDate}&to_date=${toDate}`
        );
      }
      this.graphData = response.data;
      this.loading = false;
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    fillData() {
      const resultsObject = this.graphData.results;

      const results = Object.values(resultsObject);
      const obj = {};
      for (const res in results[0]) {
        obj[res] = [];
      }
      for (const result in results) {
        for (const objProp in results[result]) {
          if (obj[objProp]) {
            obj[objProp].push(results[result][objProp]);
          } else {
            obj[objProp] = [];
          }
        }
      }
      const labels = Object.keys(resultsObject);
      this.labels = labels;

      const arr = Object.entries(obj);
      this.results = arr;

      const avg = (arr) =>
        arr.reduce(function (p, c, i) {
          return p + (c - p) / (i + 1);
        }, 0);

      this.summary.forecast_discounts = 0;
      this.summary.forecast_deductions = 0;
      this.summary.discounts = 0;
      this.summary.deductions = 0;
      this.summary.exchange_effects = 0;
      this.summary.net_sales_total = 0;
      this.summary.forecast_sales = 0;
      this.summary.le_effect = 0;
      this.summary.bu_effect = 0;

      if (this.object === 'sales') {
        for (const item of this.results) {
          if (item[0].includes('forecast_discounts')) {
            this.summary.forecast_discounts += item[1].reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('forecast_deductions')) {
            this.summary.forecast_deductions += item[1].reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('discounts')) {
            this.summary.discounts += item[1].reduce((acc, curr) => acc + curr);
          } else if (item[0].includes('deductions')) {
            this.summary.deductions += item[1].reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('sales_exchange_rate_effect')) {
            let forecasts = this.results.filter(
              (a) => a[0] == 'LE_exchange_rate_effect'
            )[0][1];

            item[1].forEach((el, index) => {
              if (el != 0) forecasts[index] = el;
            });

            this.summary.exchange_effects += forecasts.reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('forecast_sales')) {
            this.summary.forecast_sales += item[1].reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('net_sales')) {
            this.summary.net_sales_total += item[1].reduce(
              (acc, curr) => acc + curr
            );
          } else if (item[0].includes('LE_exchange_rate_effect')) {
            this.summary.le_effect += item[1].reduce((acc, curr) => acc + curr);
          } else if (item[0].includes('BU_exchange_rate_effect')) {
            this.summary.bu_effect += item[1].reduce((acc, curr) => acc + curr);
          }
        }
      }

      let net_sales = 0;
      let deductions = 0;
      let discounts = 0;
      this.summary.share = 0;

      if (this.object === 'sales') {
        for (const item of this.results) {
          if (item[0].includes('net_sales')) {
            net_sales += item[1].reduce((acc, curr) => acc + curr);
          } else if (item[0] == 'deductions') {
            deductions += item[1].reduce((acc, curr) => acc + curr);
          } else if (item[0] == 'discounts') {
            discounts += item[1].reduce((acc, curr) => acc + curr);
          }
        }
      }

      this.summary.share =
        ((deductions + discounts) / (net_sales - deductions - discounts)) * 100;

      let datasets;
      datasets = this.assembleDatasets(arr);

      const yAxes =
        this.twoYAxesMode && this.object === 'sales'
          ? [
              {
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: this.currentMax('items'),
                },
                stacked: this.stacked,
              },
              {
                display: true,
                position: 'right',
                id: 'y-axis-2',
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: this.currentMax('sales'),
                },
                stacked: this.stacked,
              },
            ]
          : [
              {
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: this.currentMax(),
                },
                stacked: this.stacked,
              },
            ];
      const self = this;
      this.datacollection = {
        type: this.type,
        data: {
          labels,
          datasets,
        },
        options: {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, chart) {
                var datasetLabel =
                  chart.datasets[tooltipItem.datasetIndex].label || '';
                let value = tooltipItem.yLabel;

                // Convertion designed to make effect visible on graph since it's ratio to net sales might be to small
                if (
                  self.dataType === 'exchangeEffects' &&
                  datasetLabel.includes('exchange rate effect') &&
                  value !== 0
                ) {
                  let array = chart.datasets.filter(
                    (a) => a.label === 'net sales'
                  )[0].data;
                  let average = array.reduce((a, b) => a + b) / array.length;

                  value = tooltipItem.yLabel - average * 0.1;
                }
                return (
                  datasetLabel + ': ' + self.$helpers.numberWithCommas(value)
                );
              },
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Month',
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Value',
              },
            },
            xAxes: [
              {
                stacked: this.stacked,
              },
            ],
            yAxes,
          },
          legend: {
            onClick: function (e, legendItem) {
              if (self.labelClicks.includes(legendItem.datasetIndex)) {
                self.labelClicks = self.labelClicks.filter(
                  (ind) => ind !== legendItem.datasetIndex
                );
              } else {
                self.labelClicks.push(legendItem.datasetIndex);
              }

              if (this.twoYAxesMode && self.object === 'sales') {
                if (self.labelClicks.length) {
                  const [[label]] = self.results.filter(
                    (_, index) => index !== self.labelClicks[0]
                  );
                  this.chart.options.scales.yAxes[0].ticks.suggestedMax =
                    self.currentMax(label);
                  this.chart.options.scales.yAxes[1].ticks.suggestedMax =
                    self.currentMax(label);
                } else {
                  this.chart.options.scales.yAxes[0].ticks.suggestedMax =
                    self.currentMax('items');
                  this.chart.options.scales.yAxes[1].ticks.suggestedMax =
                    self.currentMax('sales');
                }
              } else {
                this.chart.options.scales.yAxes[0].ticks.suggestedMax =
                  self.currentMax();
              }

              Chart.defaults.global.legend.onClick.call(this, e, legendItem);
            },
          },
        },
      };
    },
    createChart(chartData) {
      const ctx = this.$refs.chart.getContext('2d');

      if (this.myChart) {
        if (this.myChart) {
          for (const chart of Object.values(window.Chart.instances)) {
            window.Chart.animationService.cancelAnimation(chart);
          }
          this.myChart.destroy();
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },
    currentMax(label) {
      const factor = 1.1;

      let max = 0;
      if (label) {
        const result = this.results.find((result) => result[0] === label);
        const data = result[1];
        const localMax = Math.max(...data);
        max = localMax > max ? localMax : max;
      } else {
        this.results.forEach((label, labelIndex) => {
          if (!this.labelClicks.includes(labelIndex)) {
            const data = label[1];
            const localMax = Math.max(...data);
            max = localMax > max ? localMax : max;
          }
        });
      }
      return max * factor;
    },
    assembleDatasets(filteredData) {
      return filteredData.map((line, index) => {
        let stackIndex = [
          'LE_exchange_rate_effect',
          'BU_exchange_rate_effect',
          'forecast_sales',
          'net_sales',
          'forecast_discounts',
          'forecast_deductions',
          'budget_discounts',
          'budget_deductions',
        ].includes(line[0])
          ? 1
          : 0;

        const label = line[0].replace(/_/g, ' ');
        let data = line[1];

        // Convertion designed to make effect visible on graph since it's ratio to net sales might be to small
        if (
          this.dataType === 'exchangeEffects' &&
          label.includes('exchange rate effect')
        ) {
          let array = filteredData.filter((a) => a[0] == 'net_sales')[0][1];
          let average = array.reduce((a, b) => a + b) / array.length;

          data = line[1].map((a, i) => {
            if (a == 0) return 0;
            return a + average * 0.1;
          });
        } else {
          data = line[1];
        }

        let color = this.colors[index];

        if (label === 'discounts') {
          color = '#FFE560';
        } else if (label === 'net sales') {
          color = '#4C89FE';
        } else if (label === 'deductions') {
          color = '#38D4B4';
        } else if (label === 'sales exchange rate effect') {
          color = '#38D4B4';
        } else if (label === 'LE exchange rate effect') {
          color = '#FFB571';
        } else if (label === 'forecast sales' || label === 'LE sales') {
          color = '#8D9FFF';
        } else if (label === 'budget discounts') {
          color = '#58CCFF';
        }

        let yAxisID = 'y-axis-1';
        if (this.twoYAxesMode && this.object === 'sales' && index === 1) {
          yAxisID = 'y-axis-2';
        }

        return {
          label,
          backgroundColor: color,
          borderColor: color,
          data,
          stack: stackIndex,
          order: -(index + 1),
          fill: this.object !== 'sales' && this.object !== 'currencies',
          hidden: this.labelClicks.includes(index),
          yAxisID,
        };
      });
    },
  },
};
</script>
