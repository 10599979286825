<template>
  <div class="card info-table p-0 m-0">
    <div
      class="info"
      :style="{
        gridTemplateRows: `repeat(4, auto)`,
        gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
      }"
    >
      <div class="card-item">
        <span class="title text-rotate">
          <IconText title="referencing rule" />
        </span>
        <div class="card-body">
          <CommentsBox
            :user-info="userInfo"
            :commentable-info="{
              type: 'Country',
              key: 'referencing_rule',
              id: country.id,
            }"
          />
          <CountryIconsBox
            v-if="isReferenceInfo('references')"
            :items="country.reference_countries.references"
            :item-key="'referencing_rule'"
          />
        </div>
      </div>
      <div class="card-item">
        <span class="title text-rotate">
          <IconText title="referencing price level" />
        </span>
        <div class="card-body">
          <CommentsBox
            :user-info="userInfo"
            :commentable-info="{
              type: 'Country',
              key: 'referencing_price_level',
              id: country.id,
            }"
          />
          <CountryIconsBox
            v-if="isReferenceInfo('references')"
            :items="country.reference_countries.references"
            :item-key="'referencing_price_level'"
          />
        </div>
      </div>
      <div class="card-item">
        <span class="title text-rotate">
          <IconText title="regulated price level" />
        </span>
        <div class="card-body">
          <CommentsBox
            :user-info="userInfo"
            :commentable-info="{
              type: 'Country',
              key: 'regulated_price_level',
              id: country.id,
            }"
          />
          <span
            v-if="isReferenceInfo('regulated_price_level')"
            class="info-country__text"
          >
            {{ countryInfo(country.reference_countries.regulated_price_level) }}
          </span>
        </div>
      </div>
      <div class="card-item">
        <span class="title text-rotate">
          <IconText title="schedule of referencing" />
        </span>
        <div class="card-body">
          <CommentsBox
            :user-info="userInfo"
            :commentable-info="{
              type: 'Country',
              key: 'schedule_of_referencing',
              id: country.id,
            }"
          />
          <span
            v-if="isReferenceInfo('schedule_of_referencing')"
            class="info-country__text"
          >
            {{
              countryInfo(country.reference_countries.schedule_of_referencing)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import CommentsBox from '@/components/CommentsBox.vue';
import CountryIconsBox from '@/pages/PriceReferencing/CountryIconsBox.vue';
import { searchResultsCardProps as props } from '@/helpers/propsConstants';

export default {
  name: 'InfoTable',
  components: { IconText, CommentsBox, CountryIconsBox },
  props: {
    country: Object,
    userInfo: Object,
  },
  methods: {
    countryInfo(name) {
      const values = name.split(';');
      return values
        .map((value) => {
          let priceInfo = props.searchResult['price_info'].find(
            (d) => d.short_name === value.trim()
          );
          return priceInfo ? priceInfo.full_name : value;
        })
        .join(', ');
    },
    isReferenceInfo(key) {
      return (
        this.country &&
        this.country.reference_countries &&
        this.country.reference_countries[key]
      );
    },
  },
};
</script>
