<template>
  <div class="table-header">
    <div v-if="!loading" class="table-header-left selectors display-inline">
      <OptionsSelect
        :loading="loading"
        :dropdowns-widths="dropdownsWidths"
        :model-keys-array="modelKeysArray"
        :model-values="modelValues"
        :model-options="modelOptions"
        @clear-filters="clearFilters"
      />
    </div>

    <div v-if="!loading" class="display-inline">
      <template v-if="!$route.path.includes('currencies') && !$route.path.includes('data_change_requests')">
        <span>Sort by</span>
        <div
          class="hierarchy-item-currency-selector"
          :style="
            dropdownsWidths['order_by'] !== 0 &&
            `width: ${dropdownsWidths['order_by']}px;`
          "
        >
          <Multiselect
            v-model="orderInternalValue"
            :options="orderOptions"
            select-label
            placeholder
            :show-labels="false"
            track-by="full_name"
            label="full_name"
            hide-selected
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
        </div>
      </template>
    </div>
    <div v-if="!loading" class="display-inline">
      <template v-if="!$route.path.includes('currencies') && !$route.path.includes('data_change_requests')">
        <span>Show by</span>
        <div
          class="hierarchy-item-currency-selector"
          :style="
            dropdownsWidths['items_per_page'] !== 0 &&
            `width: ${dropdownsWidths['items_per_page']}px;`
          "
        >
          <Multiselect
            v-model="itemsPerPageInternalValue"
            :options="itemsPerPageOptions"
            :searchable="false"
            :show-labels="false"
            hide-selected
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import OptionsSelect from '@/components/OptionsSelect.vue';

export default {
  name: 'HierarchyItemListHeader',
  components: { OptionsSelect },
  props: {
    loading: Boolean,
    dropdownsWidths: Object,
    modelKeysArray: Array,
    modelValues: Object,
    modelOptions: Object,
    orderValue: Object,
    orderOptions: Array,
    itemsPerPageValue: Number,
    itemsPerPageOptions: Array,
    userInfo: Object,
  },
  emits: ['orderChanged', 'clearFilters'],
  data() {
    return {
      modelInternalValues: this.modelValues,
      orderInternalValue: this.orderValue,
      itemsPerPageInternalValue: this.itemsPerPageValue,
    };
  },
  // Watching multiselects to get new data when selector value changed
  watch: {
    modelInternalValues: {
      deep: true,
      handler(val) {
        this.$emit('orderChanged', 'modelValues', val);
      },
    },
    orderInternalValue(val) {
      this.$emit('orderChanged', 'orderValue', val);
    },
    itemsPerPageInternalValue(val) {
      this.$emit('orderChanged', 'itemsPerPageValue', val);
    },
  },
  methods: {
    clearFilters() {
      this.$emit('clearFilters');
    },
  },
};
</script>
