<template>
  <div class="little-info-card">
    <div class="little-info-card-main dashboard-info-card-main">
      <div class="little-info-card-main-data">
        <span class="little-info-card-title">Quantity</span>
      </div>
    </div>

    <div
      v-for="(value, name) in counterData"
      :key="`${name}${value}`"
      class="analysis-info-card-row"
    >
      <span class="analysis-info-card-title">{{ name }}</span>
      <span class="analysis-info-card-value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalysisInfoCard',
  props: {
    counterData: Object,
  },
};
</script>
