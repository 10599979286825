<template>
  <div
    ref="pdfRoot"
    class="chart-block card-card m-0 pt-0 px-0"
    :style="styleObject"
    v-if="hideChartForEur()"
  >
    <div v-if="showHeader" class="chart-header">
      <h1 class="m-0">{{ header }}</h1>

      <template v-if="!loading">
        <ChartMultiselect
          v-if="object !== 'currencies'"
          :select-by="selectBy"
          @change-currency-chart-data="changeCurrencyChartData"
        />
        <span v-else-if="object === 'currencies' && data?.currency">
          <div
            class="little-info-card-footer-text-small"
            style="display: inline-block !important"
          >
            Currency:
          </div>
          <h4 style="display: inline-block !important; padding-left: 10px">
            {{ data.currency }}
          </h4>
        </span>
      </template>

      <div class="chart-settings">
        <ToggleSwitch
          v-if="!loading"
          v-model="priceType"
          :show-switch="object === 'competitors'"
          :switch-options="switchPriceTypes"
          :options-with-label="true"
          @change:model-value="getDataAndRedraw(true)"
        />

        <ReportsArea
          :loading="loading"
          :root-element-ref="$refs.pdfRoot"
          :collection="{
            type: 'currencyChart',
            labels: labels,
            filteredResults: filteredResults,
            currency: switchCurrencyShortName,
            countries: data?.countries || [],
            sap_ids: data?.sap_ids || {},
            object: object
          }"
          :graph-name="header"
          :page-name="pageName"
        />

        <b-skeleton v-if="loading" width="420px" height="35px" />
        <div
          v-else
          :class="object === 'sales' ? 'chart-calendar-switcher-buttons' : ''"
        >
          <DatePicker
            v-if="showCalendar"
            v-model:value="range"
            type="date"
            :clearable="false"
            range
            :disabled-date="disabledEndDate"
            placeholder="Data Select"
            @change="getDataAndRedraw"
          >
            <template #icon-calendar>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                  :fill="
                    range[0] !== null && range[1] !== null
                      ? '#003B6A'
                      : '#839AB5'
                  "
                />
              </svg>
            </template>
            <template #icon-clear>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                  fill="#003B6A"
                />
              </svg>
            </template>
          </DatePicker>

          <div v-if="object === 'sales'" class="chart-toggle-range-buttons">
            <button class="toggle-button" @click="switchDatePickerType('YTD')">
              YTD
            </button>
            <button class="toggle-button" @click="switchDatePickerType('MAT')">
              MAT
            </button>
          </div>

          <div class="chart-toggle-buttons report-toggle-area" style="margin-left:10px;" v-if="!bundleSale && object == 'sales' && objectType != 'brands'">
            <button class="toggle-button" @click="redrawDailyMonthly('monthly')">
              <i class="icon link month-icon" :class="{'inactive': dailyKonditions == 'daily'}"/>
            </button>
            <button class="toggle-button" @click="redrawDailyMonthly('daily')">
              <i class="icon link day-icon" :class="{'inactive': dailyKonditions == 'monthly'}"/>
            </button>
          </div>

          <div
            v-if="
              object === 'sales' &&
              (pageName == 'Germany' ||
                $route.path.includes('profit_center_variants'))
            "
            class="chart-toggle-bundle-buttons"
          >
            <button
              v-if="($route.path.includes('profit_center_variants') || object === 'sales' && pageName == 'Germany') && dailyKonditions == 'monthly'"
              class="toggle-button"
              :class="{ 'active-button': bundleSale }"
              @click="toggleBundleSales()"
            >
              Bundle Sales
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 36 36"
              >
                <path
                  fill="currentColor"
                  d="m32.43 8.35l-13-6.21a1 1 0 0 0-.87 0l-15 7.24a1 1 0 0 0-.57.9v16.55a1 1 0 0 0 .6.92l13 6.19a1 1 0 0 0 .87 0l15-7.24a1 1 0 0 0 .57-.9V9.25a1 1 0 0 0-.6-.9ZM19 4.15l10.93 5.22l-5.05 2.44l-10.67-5.35Zm-2 11.49L6 10.41l5.9-2.85l10.7 5.35Zm1 15.8V17.36l13-6.29v14.1Z"
                  class="clr-i-solid clr-i-solid-path-1"
                />
                <path fill="none" d="M0 0h36v36H0z" />
              </svg>
            </button>
          </div>
        </div>

        <b-skeleton v-if="loading" width="130px" height="35px" />
        <div v-else class="chart-toggle-buttons">
          <button class="toggle-button" @click="changeChartType('bar')">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              :stroke="type === 'bar' ? '#003B6A' : '#DFE7EF'"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.3584 12.1001V18.7001"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 3.30005V18.7001"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6411 8.80005V18.7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <button class="toggle-button" @click="changeChartType('line')">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              :fill="type === 'line' ? '#003B6A' : '#DFE7EF'"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.84291 9.88028C3.52821 10.1496 3.49142 10.6231 3.76075 10.9378C4.03008 11.2525 4.50352 11.2892 4.81822 11.0199L3.84291 9.88028ZM6.90122 8.2501L7.4003 7.69026C7.1202 7.44056 6.69866 7.43629 6.41356 7.68028L6.90122 8.2501ZM9.986 11.0001L9.48692 11.5599C9.74058 11.7861 10.1146 11.8134 10.3984 11.6265L9.986 11.0001ZM17.0821 7.22654C17.4281 6.99878 17.5239 6.53367 17.2961 6.1877C17.0684 5.84173 16.6033 5.7459 16.2573 5.97366L17.0821 7.22654ZM4.81822 11.0199L7.38888 8.81991L6.41356 7.68028L3.84291 9.88028L4.81822 11.0199ZM6.40214 8.80994L9.48692 11.5599L10.4851 10.4403L7.4003 7.69026L6.40214 8.80994ZM10.3984 11.6265L17.0821 7.22654L16.2573 5.97366L9.5736 10.3737L10.3984 11.6265Z"
              />
              <path
                d="M3.84291 15.3803C3.52821 15.6496 3.49142 16.1231 3.76075 16.4378C4.03008 16.7525 4.50352 16.7892 4.81822 16.5199L3.84291 15.3803ZM6.90122 13.7501L7.4003 13.1903C7.1202 12.9406 6.69866 12.9363 6.41356 13.1803L6.90122 13.7501ZM9.986 16.5001L9.48692 17.0599C9.74058 17.2861 10.1146 17.3134 10.3984 17.1265L9.986 16.5001ZM17.0821 12.7265C17.4281 12.4988 17.5239 12.0337 17.2961 11.6877C17.0684 11.3417 16.6033 11.2459 16.2573 11.4737L17.0821 12.7265ZM4.81822 16.5199L7.38888 14.3199L6.41356 13.1803L3.84291 15.3803L4.81822 16.5199ZM6.40214 14.3099L9.48692 17.0599L10.4851 15.9403L7.4003 13.1903L6.40214 14.3099ZM10.3984 17.1265L17.0821 12.7265L16.2573 11.4737L9.5736 15.8737L10.3984 17.1265Z"
              />
            </svg>
          </button>

          <button
            v-if="tableMode"
            class="toggle-button"
            @click="changeChartType('table')"
          >
            <svg
              v-if="showTable"
              width="22"
              height="21"
              viewBox="0 0 22 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7173 5.28271H3.71729"
                stroke="#003B6A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.7173 10.2827L3.71729 10.2827"
                stroke="#003B6A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.7173 15.2827H3.71729"
                stroke="#003B6A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="22"
              height="21"
              viewBox="0 0 22 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7173 5.28271H3.71729"
                stroke="#DFE7EF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.7173 10.2827L3.71729 10.2827"
                stroke="#DFE7EF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.7173 15.2827H3.71729"
                stroke="#DFE7EF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <b-skeleton v-if="loading" width="35px" height="35px" />
        <div v-else class="chart-toggle-buttons prediction-wrapper">
          <button class="toggle-button graph-button" @click="runPrediction()">
            <svg
              width="21"
              height="22"
              viewBox="0 0 512 512"
              :stroke="type === 'bar' ? '#003B6A' : '#DFE7EF'"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M488,96c-13.234,0-24,10.767-24,24c0,1.003,0.069,1.99,0.189,2.961l-88.738,44.369C368.122,158.006,356.753,152,344,152
                c-22.056,0-40,17.944-40,40c0,8.762,2.839,16.869,7.636,23.466l-45.533,49.673c-0.236,0.258-0.445,0.53-0.641,0.808
                C262.557,264.696,259.359,264,256,264c-4.552,0-8.812,1.274-12.442,3.484l-52.98-43.348c0.919-2.542,1.422-5.281,1.422-8.136
                c0-13.233-10.766-24-24-24s-24,10.767-24,24c0,6.692,2.756,12.75,7.188,17.107l-36.87,79.007C113.555,312.041,112.782,312,112,312
                c-13.234,0-24,10.767-24,24c0,1.297,0.107,2.569,0.306,3.812l-48.285,26.337C35.769,362.329,30.152,360,24,360
                c-13.234,0-24,10.767-24,24s10.766,24,24,24s24-10.767,24-24c0-1.297-0.107-2.569-0.306-3.812l48.285-26.337
                c4.252,3.82,9.868,6.148,16.021,6.148c13.234,0,24-10.767,24-24c0-6.692-2.756-12.75-7.188-17.107l36.87-79.007
                c0.763,0.073,1.536,0.114,2.319,0.114c4.552,0,8.812-1.274,12.442-3.484l52.98,43.348C232.503,282.406,232,285.145,232,288
                c0,13.233,10.766,24,24,24s24-10.767,24-24c0-4.098-1.035-7.959-2.853-11.337c0.261-0.22,0.514-0.452,0.751-0.71l45.526-49.665
                C329.439,229.911,336.48,232,344,232c22.056,0,40-17.944,40-40c0-3.586-0.482-7.061-1.371-10.37l88.727-44.364
                C475.674,141.431,481.541,144,488,144c13.234,0,24-10.767,24-24S501.234,96,488,96z M24,392c-4.411,0-8-3.589-8-8s3.589-8,8-8
                s8,3.589,8,8S28.411,392,24,392z M112,344c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S116.411,344,112,344z M168,224
                c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S172.411,224,168,224z M256,296c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8
                S260.411,296,256,296z M344,216c-13.233,0-24-10.767-24-24s10.767-24,24-24s24,10.767,24,24S357.233,216,344,216z M488,128
                c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S492.411,128,488,128z"
              />
              <path
                d="M344,184c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.66
                c1.49,1.489,3.55,2.34,5.66,2.34c2.11,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66
                C348.17,184.85,346.11,184,344,184z"
              />
              <path
                d="M344,136c2.1,0,4.17-0.851,5.66-2.351c1.48-1.479,2.34-3.54,2.34-5.649c0-2.11-0.86-4.17-2.34-5.66
                c-1.49-1.49-3.56-2.34-5.66-2.34c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.1,0.85,4.17,2.34,5.649
                C339.83,135.149,341.89,136,344,136z"
              />
              <path
                d="M344,104c2.11,0,4.17-0.851,5.66-2.34C351.15,100.17,352,98.1,352,96c0-2.101-0.85-4.17-2.34-5.66
                C348.17,88.85,346.11,88,344,88c-2.11,0-4.17,0.85-5.66,2.34C336.85,91.83,336,93.89,336,96c0,2.109,0.85,4.17,2.34,5.66
                C339.83,103.149,341.89,104,344,104z"
              />
              <path
                d="M344,72c2.1,0,4.16-0.851,5.66-2.34C351.15,68.16,352,66.1,352,64c0-2.101-0.85-4.17-2.34-5.66
                C348.17,56.85,346.1,56,344,56s-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.56-2.34,5.66c0,2.1,0.85,4.17,2.34,5.66
                C339.83,71.149,341.9,72,344,72z"
              />
              <path
                d="M344,40c2.1,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.101-0.85-4.17-2.34-5.66
                C348.17,24.85,346.11,24,344,24c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.56-2.34,5.66c0,2.109,0.85,4.17,2.34,5.66
                C339.83,39.149,341.9,40,344,40z"
              />
              <path
                d="M344,344c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.66
                c1.49,1.489,3.55,2.34,5.66,2.34c2.1,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66
                C348.17,344.85,346.11,344,344,344z"
              />
              <path
                d="M344,312c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.56-2.34,5.66c0,2.1,0.85,4.17,2.34,5.66
                c1.49,1.489,3.56,2.34,5.66,2.34s4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.561,2.34-5.66c0-2.101-0.85-4.17-2.34-5.66
                C348.17,312.85,346.11,312,344,312z"
              />
              <path
                d="M344,280c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.649
                c1.49,1.5,3.55,2.351,5.66,2.351c2.11,0,4.17-0.851,5.66-2.351c1.49-1.479,2.34-3.54,2.34-5.649c0-2.11-0.85-4.17-2.34-5.66
                C348.17,280.85,346.11,280,344,280z"
              />
              <path
                d="M344,248c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.649
                c1.49,1.5,3.55,2.351,5.66,2.351c2.1,0,4.17-0.851,5.66-2.351c1.49-1.479,2.34-3.54,2.34-5.649c0-2.11-0.85-4.17-2.34-5.66
                C348.17,248.85,346.1,248,344,248z"
              />
              <path
                d="M344,472c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.66
                c1.49,1.489,3.55,2.34,5.66,2.34c2.11,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66
                C348.17,472.85,346.11,472,344,472z"
              />
              <path
                d="M344,440c-2.11,0-4.17,0.85-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.109,0.85,4.17,2.34,5.66
                c1.49,1.489,3.55,2.34,5.66,2.34c2.11,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.551,2.34-5.66c0-2.101-0.85-4.17-2.34-5.66
                C348.17,440.85,346.11,440,344,440z"
              />
              <path
                d="M344,408c-2.11,0-4.17,0.859-5.66,2.34c-1.49,1.49-2.34,3.55-2.34,5.66c0,2.1,0.85,4.17,2.34,5.66
                c1.49,1.489,3.55,2.34,5.66,2.34c2.11,0,4.17-0.851,5.66-2.34c1.49-1.49,2.34-3.561,2.34-5.66c0-2.11-0.85-4.17-2.34-5.66
                C348.17,408.859,346.11,408,344,408z"
              />
              <path
                d="M344,376c-2.11,0-4.17,0.85-5.66,2.34c-1.48,1.49-2.34,3.55-2.34,5.66c0,2.109,0.86,4.17,2.34,5.649
                c1.49,1.5,3.56,2.351,5.66,2.351s4.17-0.851,5.66-2.351c1.48-1.479,2.34-3.54,2.34-5.649c0-2.11-0.86-4.17-2.34-5.66
                C348.17,376.85,346.11,376,344,376z"
              />
            </svg>
          </button>
        </div>

        <template v-if="!loading">
          <ToggleSwitch
            v-model="priceSource"
            :show-switch="object === 'competitors'"
            :switch-options="switchPriceSource"
            :options-with-label="true"
            @change:model-value="getDataAndRedraw(true)"
          />

          <ToggleSwitch
            v-model="switchCurrencyShortName"
            :show-switch="showSwitch"
            :switch-options="switchTypes"
            @change:model-value="switchCurrencyChartData"
          />

          <ToggleSwitch
            v-model="buildupDataType"
            :show-switch="object === 'analysisLineChart' || object === 'prices'"
            :switch-options="buildupDataOptions"
            @change:model-value="getDataAndRedraw(true)"
          />
        </template>
      </div>
    </div>

    <div :class="['chart-content', { grayscale: updating }]">
      <!-- Chart: Table view -->
      <b-skeleton-table
        v-if="loading && showTable"
        :rows="5"
        :columns="5"
        :table-props="{ bordered: true, striped: true }"
      />
      <div class="table-responsive scrollbar" :class="{ 'd-none': !showTable }">
        <table class="table table-striped borderless fixed-layout">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th
                v-for="result in filteredResults"
                :key="$helpers.toString(result)"
                scope="col"
              >
                {{ $helpers.getTableName(result[0], switchCurrencyShortName) }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(result, index) in labels"
              :key="$helpers.toString(result)"
            >
              <td>{{ labels[index] }}</td>
              <td
                v-for="array in filteredResults"
                :key="$helpers.toString(array)"
              >
                {{
                  $helpers.numberWithCommas(
                    array[1][index],
                    switchCurrencyShortName
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Chart: Bar view & Lines view -->
      <div v-if="loading" class="skeleton-chart">
        <div>
          <b-skeleton height="400px" />
        </div>
        <div>
          <b-skeleton height="100%" width="100%" />
        </div>
      </div>
      <div
        v-else
        data-pdf="chart"
        :class="[
          'chart-container',
          {
            'd-none': showTable,
            'chart-container--average-collapsed':
              showAverageSection && averageValuesCollapsed,
          },
        ]"
      >
        <div
          :class="object === 'principles' ? 'principles-chart' : 'chart'"
          :style="{ width: setEmptyWidth, height: '90%' }"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-3"
            v-if="labelIds.length > 8 && !disableLabelMultiselect"
          >
            <span class="color-aaa me-4" style="font-size: 14px">
              Select which labels
              <br />
              to show on graph:
            </span>
            <div class="d-flex align-items-center">
              <Multiselect
                v-model="selectedLabelIds"
                :options="chartSelectOptions"
                track-by="name"
                label="name"
                select-label
                placeholder="All"
                :multiple="true"
                :show-labels="false"
                :close-on-select="false"
              >
                <template #selection="{ values, isOpen }">
                  <span
                    v-if="values.length && !isOpen"
                    class="multiselect__single"
                  >
                    {{ values.length }} label{{ values.length > 1 ? 's' : '' }}
                    selected
                  </span>
                </template>
                <template #caret="{ toggle }">
                  <div
                    class="multiselect__select"
                    @mousedown.prevent.stop="toggle"
                  >
                    <i class="icon icon-down-arrow" />
                  </div>
                </template>
              </Multiselect>
              <button
                class="btn btn-green display-inline ms-4"
                style="width: 175px !important; line-height: 1.2"
                @click="() => redraw()"
              >
                Update chart
              </button>
            </div>
          </div>
          <canvas ref="chart" />
        </div>

        <!-- Chart summary container -->
        <div v-if="showSummary && showSalesSummary" class="chart-summary">
          <!-- Total Sales -->
          <span
            v-if="summary.sales !== 0 || summary.units !== 0"
            class="chart-summary__title"
          >
            Total Sales
          </span>
          <span
            v-if="summary.sales !== 0"
            :class="[
              'chart-summary-toggler chart-summary-toggler-sales link clickable',
              { 'is-active': !isItemsActive },
            ]"
            @click="setIsItemsActive(false)"
          >
            {{ $helpers.numberWithCommas(summary.sales.toFixed(2)) }}
            {{ $helpers.getCurrencySymbol(switchCurrencyShortName) }}
          </span>
          <span
            v-if="summary.units !== 0"
            class="chart-summary-toggler link clickable"
            :class="{ 'is-active': isItemsActive }"
            @click="setIsItemsActive(true)"
          >
            {{ $helpers.numberWithCommas(summary.units) }} Units
          </span>

          <!-- Total Budget -->
          <span
            v-if="summary.budget != 0 || summary.budget_units !== 0"
            class="chart-summary__title"
          >
            Total Budget
          </span>
          <span
            v-if="summary.budget !== 0"
            :class="[
              'chart-summary-toggler chart-summary-toggler-sales link clickable',
              { 'is-active': !isItemsActive },
            ]"
            @click="setIsItemsActive(false)"
          >
            {{ $helpers.numberWithCommas(summary.budget.toFixed(2)) }}
            {{ $helpers.getCurrencySymbol(switchCurrencyShortName) }}
          </span>
          <span
            v-if="summary.budget_units !== 0"
            :class="[
              'chart-summary-toggler link clickable',
              { 'is-active': isItemsActive },
            ]"
            @click="setIsItemsActive(true)"
          >
            {{ $helpers.numberWithCommas(summary.budget_units) }} Units
          </span>

          <!-- Total Previous Forecast -->
          <span
            v-if="
              summary.previous_forecasts != 0 ||
              summary.previous_forecasts_units !== 0
            "
            class="chart-summary__title"
          >
            Total Previous Forecast
          </span>
          <span
            v-if="summary.previous_forecasts !== 0"
            :class="[
              'chart-summary-toggler chart-summary-toggler-sales link clickable',
              { 'is-active': !isItemsActive },
            ]"
            @click="setIsItemsActive(false)"
          >
            {{
              $helpers.numberWithCommas(summary.previous_forecasts.toFixed(2))
            }}
            {{ $helpers.getCurrencySymbol(switchCurrencyShortName) }}
          </span>
          <span
            v-if="summary.previous_forecasts_units !== 0"
            :class="[
              'chart-summary-toggler link clickable',
              { 'is-active': isItemsActive },
            ]"
            @click="setIsItemsActive(true)"
          >
            {{ $helpers.numberWithCommas(summary.previous_forecasts_units) }}
            Units
          </span>

          <!-- Total Forecast -->
          <span
            v-if="summary.forecast != 0 || summary.forecast_units !== 0"
            class="chart-summary__title"
          >
            Total Forecast
          </span>
          <span
            v-if="summary.forecast !== 0"
            :class="[
              'chart-summary-toggler chart-summary-toggler-sales link clickable',
              { 'is-active': !isItemsActive },
            ]"
            @click="setIsItemsActive(false)"
          >
            {{ $helpers.numberWithCommas(summary.forecast.toFixed(2)) }}
            {{ $helpers.getCurrencySymbol(switchCurrencyShortName) }}
          </span>
          <span
            v-if="summary.forecast_units !== 0"
            :class="[
              'chart-summary-toggler link clickable',
              { 'is-active': isItemsActive },
            ]"
            @click="setIsItemsActive(true)"
          >
            {{ $helpers.numberWithCommas(summary.forecast_units) }} Units
          </span>
        </div>

        <!-- Average Section -->
        <div
          v-if="showAverageSection && !loading"
          class="chart-average-section"
        >
          <div class="chart-average-section__header">
            <b-button
              :class="[
                'chart-average-section__btn ',
                {
                  'chart-average-section__btn--collapsed':
                    averageValuesCollapsed,
                },
              ]"
              @click="toggleAverageValuesCollapsed()"
            >
              <div class="d-flex align-items-center">
                <span> Last values </span>
                <svg
                  class="icon icon-down-arrow clickable link"
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </div>
            </b-button>
          </div>
          <div
            v-show="!averageValuesCollapsed"
            class="chart-summary chart-summary--averages"
          >
            <table
              v-if="averageValues.length"
              class="chart-summary__averages-table"
            >
              <tbody>
                <tr
                  v-for="average in averageValues"
                  :key="`${average.value} + ${average.label}`"
                >
                  <td
                    class="chart-summary__left clickable link"
                    @click="openProductPage(average)"
                  >
                    <u>{{ average.label }}</u>
                  </td>
                  <td class="chart-summary__right">
                    <span v-if="(pricesCurrency || overrideCurrency) && dataType">
                      {{
                        $helpers.restructureAndShowFieldValue(
                          average.value.toFixed(2),
                          dataType,
                          pricesCurrency || overrideCurrency,
                          true
                        )
                      }}
                    </span>
                    <span v-else>
                      &nbsp;&nbsp;
                      {{ $helpers.numberWithCommas(average.value.toFixed(2)) }}
                      {{ sign }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="d-flex justify-content-center">No Data</div>

            <span v-show="averageRedirectAlert.length > 0">
              <h4 class="info-quote red-alert m-0">
                {{ averageRedirectAlert }}
              </h4>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="chart-quote">
      <h4 v-if="object == 'currencies'" data-pdf="sub-chart" class="info-quote">
        Actual exchange rate (SAP) was updated on {{ currencyUpdatedAt }}<br />
        API-rate=Source: Fixer.io<br />
        BU-rate=planned and fixed rate for the complete year<br />
        LE-rate=last estimated planned rate<br />
      </h4>
      <h4 v-else data-pdf="sub-chart" class="info-quote">
        {{ defineLegendCompetitors }}
      </h4>
    </div>
    <Prediction
      v-if="showPredictionWrapper"
      :raw-data="data"
      :prediction-data-loaded="predictionDataLoaded"
      :available-items="getChartElements()"
      @element-disabled="hidePredictionElement()"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import Chart from 'chart.js';
import ChartMultiselect from '@/components/Chart/ChartMultiselect.vue';
import Prediction from '@/components/Prediction.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import { isEmptyObject } from '@/helpers';
import ReportsArea from '@/components/Report/ReportsArea.vue';

export default {
  components: { ReportsArea, ChartMultiselect, ToggleSwitch, Prediction },
  props: {
    object: String,
    graphType: String,
    ids: Array,
    startDate: Date,
    endDate: Date,
    styleObject: Object,
    selectBy: String,
    showSwitch: Boolean,
    title: String,
    disableLabelMultiselect: {
      type: Boolean,
      default: false,
    },
    pagesStack: {
      type: String,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: true,
    },
    showStacked: {
      type: Boolean,
      default: false,
    },
    showCalendar: {
      type: Boolean,
      default: true,
    },
    showAdditionalInfo: {
      type: Boolean,
      default: true,
    },
    showAverageDetails: {
      type: Boolean,
      default: true,
    },
    fetchedResults: Object,
    fetchedResultsType: String,
    tableMode: {
      type: Boolean,
      default: true,
    },
    twoYAxesMode: {
      type: Boolean,
      default: false,
    },
    searchPath: String,
    switchOptions: Array,
    objectType: {
      type: String,
      default: '',
    },
    objectId: {
      type: String,
      default: '',
    },
    conditionType: String,
    objectName: String,
    overrideCurrency: {
      type: String,
      default: null,
    },
    quote: {
      type: String,
      default: '',
    },
    pageName: {
      type: String,
      default: null,
    },
    dataType: {
      type: String,
      default: null,
    },
    union: {
      type: String,
      default: null,
    },
    selectedFilter: {
      type: String,
      default: null,
    },
    forcePcvGraph: {
      type: Boolean,
      default: false,
    },
    selectedDetail: {
      type: String,
      default: null,
    },
    startEndDate: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      labelIds: [],
      selectedLabelIds: [],
      datacollection: null,
      data: null,
      type: 'line',
      myChart: null,
      range: this.setRange(),
      loading: true,
      updating: false,
      showTable: false,
      results: {},
      labels: [],
      xlsxLabels: [],
      labelClicks: [],
      summary: {
        sales: 0,
        units: 0,
        budget: 0,
        budget_units: 0,
        forecast: 0,
        forecast_units: 0,
        previous_forecasts: 0,
        previous_forecasts_units: 0,
        real_average_margin: 0,
      },
      showSalesSummary: false,
      showAverageSection: false,
      averageValues: [],
      averageValuesCollapsed: false,
      stacked: false,
      currencyToggler: {
        currentCurrency: 'EUR',
        facturaCurrency: 'EUR',
        countryCurrency: 'RUB',
      },
      isItemsActive: false,
      currencyType: '',
      disabledBefore: new Date(2012, 0, 1),
      disabledAfter: new Date(new Date().getFullYear() + 6, 11, 31),
      preloadedData: this.fetchedResults,
      preloadedDataType: this.fetchedResultsType,
      switchCurrencyShortName: '',
      buildupDataOptions: ['real', 'planned'],
      buildupDataType: 'planned',
      priceType: 'ret_price_incl_vat_stat',
      priceSource: 'first_source',
      lastDataUpdate: '',
      averageRedirectAlert: [],
      useExtendedPredictionTimeFrame: true,
      predictionDataLoaded: false,
      showPredictionWrapper: false,
      bundleSale: false,
      dailyKonditions: 'monthly',
      dailyKonditionsOptions: ['monthly', 'daily'],
      dailyClicked: false,
      pricesCurrency: {
        type: String,
        default: null,
      }
    };
  },

  computed: {
    chartSelectOptions() {
      return this.labelIds.map((option) => ({
        name: option,
        id: option,
      }));
    },

    switchTypes() {
      return this.data || this.switchOptions
        ? this.switchOptions || this.data.currencies
        : [];
    },

    switchPriceTypes() {
      return this.data && this.data.price_types ? this.data.price_types : [];
    },

    switchPriceSource() {
      return this.data && this.data.price_source ? this.data.price_source : [];
    },
    filteredResults() {
      if (this.results.length) {
        if (this.object === 'sales') {
          return this.results.filter((e) =>
            this.isItemsActive
              ? e[0].includes('_in_units')
              : e[0].includes('_in_currency')
          );
        }
        if (this.object === 'analysisLineChart') {
          return this.results.map((a) => [
            a[0],
            a[1].map((b) => b[this.buildupDataType]),
          ]);
        }
        return this.results;
      } else {
        return [];
      }
    },

    header() {
      if (this.object === 'analysisLineChart') {
        return this.title + ' in' + this.sign;
      } else if (
        this.object === 'principles' &&
        this.$route.path.includes('bottom_and_top_down')
      ) {
        return 'Margin in ' + this.sign;
      } else if (this.object === 'principles') {
        return 'Deviation in ' + this.sign;
      } else {
        return this.object === 'sales'
          ? 'Net Sales'
          : `${this.$helpers.removeDashes(
              this.$helpers.firstLetterCapital(this.object)
            )} Graph`;
      }
    },

    sign() {
      let currency = this.switchCurrencyShortName;
      if (this.object === 'currencies' || this.object === 'competitors') {
        currency = this.data.currency;
      } else if (this.object === 'prices') {
        currency = this.pricesCurrency;
      } else if (this.overrideCurrency) {
        currency = this.overrideCurrency;
      }

      const currencySign = this.$helpers.getCurrencySymbol(currency) || '€';

      return this.fetchedResultsType === 'percent' ? ' %' : ' ' + currencySign;
    },

    currencyUpdatedAt() {
      if (this.data) {
        return this.$helpers.dateToLocaleDate(
          this.data.actual_exchange_rate_updated_at
        );
      } else {
        return null;
      }
    },

    defineLegendCompetitors() {
      return [
        this.quote,
        this.object === 'competitors'
          ? `Last data update: ${this.getLastDataUpdate()}`
          : '',
      ].join('\xa0'.repeat(3));
    },
  },

  watch: {
    ids: {
      handler() {
        this.$nextTick(async () => {
          await this.getDataAndDrawSetup();
        });
      },
    },
    startEndDate: {
      deep: true,
      async handler(val) {
        this.range = val;
        this.switchCurrencyShortName = this.overrideCurrency || this.switchCurrencyShortName || 'EUR';
        await this.getDataAndDrawSetup();
      },
    },
  },

  mounted() {
    if (this.object === 'sales') this.type = 'bar';
    if (this.switchCurrencyShortName == '') this.switchCurrencyShortName = this.overrideCurrency || 'EUR';
    if (this.graphType) this.changeChartType(this.graphType, false);
    if (this.showStacked) this.stacked = true;

    this.getDataAndDrawSetup();
  },

  beforeUnmount() {
    if (this.myChart) {
      for (const chart of Object.values(window.Chart.instances)) {
        window.Chart.animationService.cancelAnimation(chart);
      }
      this.myChart.destroy();
    }
  },

  methods: {
    async getDataAndDrawSetup() {
      this.loading = true;
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1])
      ).then(() => (this.loading = false));

      this.redraw(true);
    },
    async getDailyData(fromDate, toDate) {
      let result;
      this.updating = true;
      result = await api.post(
          `/profit_center_variants/graph_data`,
          JSON.stringify({
            object: this.object,
            from_date: fromDate,
            to_date: toDate,
            buildup_lvl: this.pagesStack,
            ids: this.ids,
            currency_short: this.overrideCurrency || 'EUR',
            details_key: this.selectedDetail,
            daily_konditions: this.dailyKonditions
          })
        );
    },
    async getData(fromDate, toDate) {
      let result;
      this.updating = true;

      if (this.forcePcvGraph || this.$route.path === '/') {
        result = await api.post(
          `/profit_center_variants/graph_data`,
          JSON.stringify({
            object: this.object,
            from_date: fromDate,
            to_date: toDate,
            buildup_lvl: this.pagesStack,
            ids: this.ids,
            currency_short: this.overrideCurrency || 'EUR',
            details_key: this.selectedDetail,
            daily_konditions: this.dailyKonditions,
          })
        );
      } else if (this.$route.path === '/dashboard') {
        result = await api.post(
          `countries/graph_data`,
          JSON.stringify({
            object: this.object,
            from_date: fromDate,
            to_date: toDate,
            daily_konditions: this.dailyKonditions,
          })
        );
      } else {
        const path = this.searchPath || this.$route.path;
        result = await api.get(
          `${path}/graph_data?object=${this.object}&from_date=${fromDate}&to_date=${toDate}&type=${this.currencyType}&currency_short_name=${this.switchCurrencyShortName}&object_type=${this.objectType}&id=${this.objectId}&condition_type=${this.conditionType}&object_name=${this.objectName}&buildup_data_type=${this.buildupDataType}&union=${this.union}&selected_filter=${this.selectedFilter}&details_key=${this.selectedDetail}&price_type=${this.priceType}&price_source=${this.priceSource}&is_bundle_pack=${this.bundleSale}&daily_konditions=${this.dailyKonditions}`
        );
      }

      this.data = result.data;
      this.updating = false;
    },

    async switchCurrencyChartData() {
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1]),
        this.object !== 'competitors'
      );
      this.redraw();
    },

    async redrawDailyMonthly(type) {
      this.dailyKonditions = type;
      let date = new Date();
      if (type == 'daily' && !this.dailyClicked) {
        this.range = [
          new Date(date.getFullYear(), date.getMonth() - 1, 1),
          new Date(date.getFullYear(), date.getMonth() + 1, 0)
        ];
      } else if (!this.dailyClicked) {
        this.range = [
          new Date(new Date().getFullYear(), 0, 1),
          date
        ];
      }
      this.dailyClicked = true;
      this.getDataAndRedraw(true);
    },

    async getDataAndRedraw(mounted) {
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1])
      );
      this.redraw(mounted);
    },

    async openProductPage(average) {
      try {
        let result = await api.post(
          `/profit_center_variants/get_id_by_country`,
          JSON.stringify({ label: average.label })
        );

        this.$router.push(`/profit_center_variants/${result.data.pcv_id}`);
      } catch (e) {
        this.averageRedirectAlert = 'Cannot redirect to product page';
      }
    },

    async runPrediction() {
      this.showPredictionWrapper = false; //leave this to be able to use the component again after closing
      this.predictionDataLoaded = false;

      console.log('running prediction function');

      this.showPredictionWrapper = true;

      if (this.useExtendedPredictionTimeFrame) {
        let startDate = new Date('2012-01-01T00:00:00Z')
          .toISOString()
          .split('T')[0];
        let endDate = new Date().toISOString().split('T')[0];

        await this.getData(startDate, endDate);
        this.predictionDataLoaded = true;
      }
    },

    redraw(mounted) {
      this.fillData();

      if (!this.disableLabelMultiselect) {
        if (mounted)
          this.selectedLabelIds = [
            ...this.labelIds
              .slice(0, 8)
              .map((label) => ({ name: label, id: label })),
          ];

        if (this.labelIds.length > 8) {
          this.datacollection = {
            ...this.datacollection,
            data: {
              ...this.datacollection.data,
              datasets: this.datacollection?.data?.datasets.filter((el) =>
                this.selectedLabelIds.find((label) => label.name === el.label)
              ),
            },
          };
        }
      }

      if (this.type) this.createChart(this.datacollection);
    },

    fillData() {
      const resultsObject = isEmptyObject(this.preloadedData)
        ? this.data.results
        : this.preloadedData;

      const results = Object.values(resultsObject);
      const obj = this.getCountryData(results);

      this.setXlsxLabels(resultsObject);
      const labels = Object.keys(resultsObject);
      this.labels = labels;

      const arr = Object.entries(obj);
      this.results = arr;

      this.setDefaultSummaryValues();

      if (this.object === 'sales') {
        this.calculateSummaryValues();
        this.showSalesSummary =
          this.$helpers.sumArrayValues(this.summary) !== 0;
      }

      if (this.object === 'prices') {
        this.pricesCurrency = this.data.currency;
      } else if (this.object === 'details') {
        this.pricesCurrency = this.switchCurrencyShortName;
      }

      const sortedArr = this.object === 'currencies' ? this.setCurrencyAverageValues(arr) : this.setAverageValues(arr);
      let filteredData = this.filterData(sortedArr);

      const datasets = this.setDataset(filteredData);
      const yAxes = this.yAxes();

      const self = this;
      this.labelIds = datasets.map((el) => el.label).sort();

      if (this.labelIds.find(element => element === this.objectName)) {
        this.labelIds = [this.objectName, ...[...this.labelIds].filter((el) => el !== this.objectName)];
      };

      this.datacollection = {
        type: this.type,
        data: { labels, datasets },
        options: {
          plugins: { datalabels: { display: false } },
          responsive: true,
          responsiveAnimationDuration: 500,
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem, chart) => self.getDatasetLabel(tooltipItem, chart)
            }
          },
          hover: { mode: 'nearest', intersect: true },
          scales: {
            x: {
              display: true,
              scaleLabel: { display: true, labelString: 'Month' }
            },
            y: {
              display: true,
              scaleLabel: { display: true, labelString: 'Value' }
            },
            xAxes: [{ stacked: this.stacked }],
            yAxes
          },
        }
      };
    },

    setAverageValues(arr) {
      this.showAverageSection =
        !this.showSalesSummary &&
        this.showAdditionalInfo &&
        this.showAverageDetails;

      this.averageValues = this.filteredResults
        .map((item) => ({
          label: item[0],
          value: this.getAverage(item[1]),
        }))
        .filter((el) => el.value)
        .sort(function(prev, curr){
          return curr.value - prev.value;
        });

      const avg = (arr) =>
        arr.reduce(function (p, c, i) {
          return p + (c - p) / (i + 1);
        }, 0);

      const sortedArr = arr.sort((a, b) => {
        return avg(a[1]) - avg(b[1]);
      });

      return sortedArr
    },

    setCurrencyAverageValues(arr) {
      this.showAverageSection =
        !this.showSalesSummary &&
        this.showAdditionalInfo &&
        this.showAverageDetails;

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const lastValues = {};

      arr.forEach(([label, values]) => {
        let lastValue = null;
        values.forEach((value, index) => {
          const valueDate = new Date(currentYear, index, 1);
          if (valueDate.getMonth() === currentMonth) {
            lastValue = value;
          } else if (index === values.length - 1 && lastValue === null) {
            lastValue = value;
          }
        });
        lastValues[label] = lastValue;
      });

      this.averageValues = Object.entries(lastValues)
        .sort(([, a], [, b]) => b - a)
        .map(([label, value]) => ({ label, value }));
      
      const avg = (arr) =>
        arr.reduce(function (p, c, i) {
          return p + (c - p) / (i + 1);
        }, 0);

      const sortedArr = arr.sort((a, b) => {
        return avg(a[1]) - avg(b[1]);
      });

      return sortedArr
    },

    filterData(sortedArr) {
      let filteredData = sortedArr;

      if (this.object === 'sales') {
        filteredData = sortedArr.filter((e) =>
          this.isItemsActive ? e[0].includes('_in_units') : e[0].includes('_in_currency')
        );
      }
      else if (this.object === 'details') {
        filteredData = sortedArr.sort(function (a, b) {
          return b[0].length - a[0].length;
        });
      }
      else if (this.object === 'principles') {
        filteredData = sortedArr;
      }
      else if (this.object === 'analysisLineChart') {
        filteredData = sortedArr
          .map((a) => [a[0], a[1].map((b) => b[this.buildupDataType])])
          .map(function (a) {
            let monthValues = a[1];
            while (monthValues[monthValues.length - 1] === 0) {
              monthValues.pop();
            }
            return [a[0], monthValues];
          });
      }

      if (this.type === 'line' && this.object !== 'analysisLineChart') {
        filteredData = filteredData.map(function (a) {
          let monthValues = a[1];
          while (monthValues[monthValues.length - 1] === 0) {
            monthValues.pop();
          }
          return [a[0], monthValues];
        });
      }

      return filteredData;
    },

    getCountryData(results) {
      const obj = {};

      for (const res in results[0]) {
        if (this.isCorridorPath()) {
          let countryName = this.$helpers.getCountryName(res.slice(-2)) || res.slice(-2);
          obj[countryName] = [];
        } else {
          obj[res] = [];
        }
      }

      for (const result in results) {
        for (const objProp in results[result]) {
          if (this.isCorridorPath()) {
            let nameKey = this.$helpers.getCountryName(objProp.slice(-2)) || objProp.slice(-2);
            if (obj[nameKey]) {
              obj[nameKey].push(results[result][objProp]);
            } else {
              obj[nameKey] = [];
            }
          } else {
            obj[objProp].push(results[result][objProp]);
          }
        }
      }

      return obj;
    },

    isCorridorPath() {
      return this.object === 'principles' && this.$route.path.includes('corridor_of_net_wholesaler_purchases')
    },

    getDatasetLabel(tooltipItem, chart) {
      const isDatasetLabel = chart.datasets[tooltipItem.datasetIndex].label;
      let datasetLabel = isDatasetLabel || '';
      let currency = this.sign;

      if (this.object === 'sales' && isDatasetLabel) {
        let labelArray = isDatasetLabel.split(' ');
        let type = labelArray.pop();

        if (type === 'units') {
          currency = '';
          datasetLabel = isDatasetLabel;
        } else {
          datasetLabel =
            labelArray.join(' ') + ' ' + this.switchCurrencyShortName;
        }
      }

      return (
        datasetLabel +
        ': ' +
        this.$helpers.numberWithCommas(tooltipItem.yLabel) +
        currency
      );
    },

    setDataset (filteredData) {
      const datasets = filteredData
        .sort((a, b) => b - a)
        .map((line, index) => {
          const label = this.legendChartLabel(line[0]);
          return {
            label,
            borderWidth: this.object === 'competitors' && this.objectName === label ? 5 : 3,
            backgroundColor: this.getColor(index, label),
            borderColor: this.getColor(index, label),
            data: line[1],
            fill: this.object === 'prices',
            hidden: this.labelClicks.includes(index),
            yAxisID: this.yAxisID(index),
          };
        });

      return datasets
    },

    yAxes() {
      const left = { display: true, position: 'left', id: 'y-axis-1', stacked: false }
      const right = { display: true, position: 'right', id: 'y-axis-2', stacked: false }

      const yAxes =
        this.twoYAxesMode && this.object === 'sales'
          ? [
              Object.assign(left, {
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: this.currentMax('items'),
                  suggestedMin: this.currentMin('items')
                }
              }),
              Object.assign(right, {
                ticks: {
                  callback: this.$helpers.numberWithCommas,
                  suggestedMax: this.currentMax('sales'),
                  suggestedMin: this.currentMin('items')
                }
              })
            ]
          : [
              Object.assign(left, {
                ticks: {
                  callback: (value) =>
                    this.$helpers.numberWithCommas(value) +
                    (this.preloadedDataType === 'percent' ? ' %' : ''),
                  suggestedMax: this.currentMax(),
                  suggestedMin: this.currentMin()
                }
              })
            ];

      return yAxes
    },

    yAxisID(index) {
      let yAxisID = 'y-axis-1';
      if (this.twoYAxesMode && this.object === 'sales' && index === 1) {
        yAxisID = 'y-axis-2';
      }

      return yAxisID
    },

    getColor(index, label) {
      if (this.object === 'prices') {
        return this.$helpers.setColorByIndex(index);
      } 
      if (this.object === 'sales') {
        return this.$helpers.setColorByName(label);
      } 
      if (this.object === 'competitors' && this.objectName === label ) {
        return '#e85412';
      } 
      else {
        const colorArray = this.$helpers.defaultColorPalette();
        return colorArray[index];
      }
    },

    createChart(chartData) {
      const ctx = this.$refs.chart?.getContext('2d');

      if (!ctx) return;

      if (this.myChart) {
        for (const chart of Object.values(window.Chart.instances)) {
          window.Chart.animationService.cancelAnimation(chart);
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },

    legendChartLabel(name) {
      if (name.includes('actual_forecasts'))
        return name
          .replace(/actual_forecasts/g, 'forecasts')
          .replace(/_/g, ' ');
      if (name.includes('forecasts'))
        return name
          .replace(/forecasts/g, 'previous_forecast')
          .replace(/_/g, ' ');
      return name.replace(/_/g, ' ');
    },
    
    toggleBundleSales() {
      this.bundleSale = !this.bundleSale;
      this.getDataAndRedraw();
    },

    switchDatePickerType(dataType) {
      let year = new Date().getFullYear();

      if (dataType === 'YTD') {
        this.range = [new Date(year, 0, 1), new Date()];
      } else {
        this.range = [new Date(new Date().setFullYear(year - 1)), new Date()];
      }

      this.getDataAndRedraw();
    },

    changeCurrencyChartData(currencyType) {
      this.currencyType = currencyType;
      this.getDataAndRedraw();
    },

    changeChartType(type, forceRedraw = true) {
      this.stacked = false;

      if (type === 'bar') {
        this.type = 'bar';
        this.stacked = this.object === 'prices';
        this.hideTable(forceRedraw);
      } else if (type === 'line') {
        this.type = 'line';
        this.hideTable(forceRedraw);
      } else if (type === 'table') {
        this.showTable = true;
        this.type = '';
      }
    },

    hideTable(forceRedraw) {
      this.showTable = false;
      if (forceRedraw) {
        this.redraw();
      }
    },

    currentMax(label) {
      let max = 0;

      if (label) {
        const result = this.filteredResults.find(
          (result) => result[0] === label
        );

        max = this.calculateMax(result[1], max);
      } else {
        this.filteredResults.forEach((label, labelIndex) => {
          if (!this.labelClicks.includes(labelIndex)) {
            max = this.calculateMax(label[1], max);
          }
        });
      }

      return max * 1.1;
    },

    currentMin(label) {
      const uniqueValues = [...new Set(this.filteredResults.flat(2))];
      const uniqNumbers = uniqueValues.filter(item => typeof item === "number");

      if (uniqNumbers.length === 1) {
        return 1 + (1 - this.currentMax(label));
      } else {
        return;
      }
    },

    calculateMax(data, max) {
      const localMax = Math.max(...data);
      return localMax > max ? localMax : max;
    },

    setXlsxLabels(resultsObject) {
      const key = Object.keys(resultsObject)[0];
      const labels = Object.keys(resultsObject[key]);
      this.xlsxLabels = labels;
    },

    setRange() {
      if(this.object === 'competitors') {
        const year = new Date().getFullYear();
        return [new Date(new Date().setFullYear(year - 1)), new Date()];
      } else {
        return this.startEndDate ? this.startEndDate : [
          this.startDate
            ? this.startDate
            : new Date(new Date().getFullYear(), 0, 1),
          this.endDate ? this.endDate : new Date(),
        ]
      }
    },

    toggleCurrency(e) {
      if (e.target.classList.contains('hierarchy-item-toggler-over')) {
        e.target.classList.remove('hierarchy-item-toggler-over');
        e.target.classList.toggle('hierarchy-item-toggler-out');
      } else {
        if (e.target.classList.contains('hierarchy-item-toggler-out')) {
          e.target.classList.remove('hierarchy-item-toggler-out');
        }
        process.nextTick(() => {
          e.target.classList.toggle('hierarchy-item-toggler-over');
        });
      }

      const toggler = this.currencyToggler;
      toggler.currentCurrency =
        toggler.currentCurrency === toggler.facturaCurrency
          ? toggler.countryCurrency
          : toggler.facturaCurrency;
    },

    setIsItemsActive(isActive) {
      if (this.isItemsActive !== isActive) {
        this.isItemsActive = isActive;
        this.redraw();
      }
    },

    setEmptyWidth() {
      let width = this.styleObject.width;
      if (width !== null && width === '') {
        return width;
      }

      return this.averageValuesCollapsed ? '60vw' : '50vw !important';
    },

    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },

    getLastDataUpdate() {
      return this.data && (this.data.last_data_update || '');
    },

    getAverage(arr) {
      const nonZeroArr = arr.filter((i) => i && i !== 0);
      if (this.$helpers.isEmptyArray(nonZeroArr)) return 0;

      return nonZeroArr.reduce((p, a) => p + a, 0) / nonZeroArr.length;
    },

    toggleAverageValuesCollapsed() {
      this.averageValuesCollapsed = !this.averageValuesCollapsed;
    },

    getChartElements() {
      if (isEmptyObject(this.data.results)) {
        let outputKeys = [];
        for (const key in Object.keys(this.results)) {
          outputKeys.push(this.results[key][0]);
        }
        return outputKeys;
      } else {
        let firstKey = Object.keys(this.data.results)[0];
        return Object.keys(this.data.results[firstKey]);
      }
    },

    hidePredictionElement() {
      this.showPredictionWrapper = false;
      this.predictionDataLoaded = false;
    },

    hideChartForEur() {
      if (this.object !== 'currencies') return true;
      let currency = this.data?.currency
      if (this.object === 'currencies' && currency && currency !== 'EUR') return true;
      return false;
    },

    setDefaultSummaryValues() {
      this.summary.sales = 0;
      this.summary.units = 0;
      this.summary.budget = 0;
      this.summary.budget_units = 0;
      this.summary.forecast = 0;
      this.summary.forecast_units = 0;
      this.summary.previous_forecasts = 0;
      this.summary.previous_forecasts_units = 0;
      this.summary.bno_margin = 0;
    },

    calculateSummaryValues() {
      for (const item of this.results) {
        let keys = item[0];
        let value = item[1].reduce((acc, curr) => acc + curr);

        if (keys.includes('sales_in_currency')) {
          this.summary.sales = Math.floor(value * 100) / 100;
        } else if (keys.includes('sales_in_units')) {
          this.summary.units = value;
        } else if (keys.includes('actual_forecasts_in_currency')) {
          this.summary.forecast = value;
        } else if (keys.includes('actual_forecasts_in_units')) {
          this.summary.forecast_units = value;
        } else if (keys.includes('budgets_in_units')) {
          this.summary.budget_units = value;
        } else if (keys.includes('budgets_in_currency')) {
          this.summary.budget = value;
        } else if (keys.includes('forecasts_in_units')) {
          this.summary.previous_forecasts_units = value;
        } else if (keys.includes('forecasts_in_currency')) {
          this.summary.previous_forecasts = value;
        } else if (keys.includes('real_average_margin_in_percent')) {
          this.summary.real_average_margin = value;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
.skeleton-chart {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  & > div:first-child {
    padding: 30px;
  }
  & > div:last-child {
    padding: 30px;
  }
}
</style>
