<template>
  <div>
    <div id="bubble-chart" />
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
  name: 'CountryBubbleChart',
  props: {
    country: Object,
    chartData: Array,
  },

  watch: {
    country: {
      deep: true,
      handler() {
        this.setNewData();
      },
    },
    chartData: {
      deep: true,
      handler() {
        this.setNewData();
      },
    },
  },
  created() {
    this.chartParams = {
      xAxis: null,
      yAxis: null,
      legend: null,
    };
    this.chartSeries = {};
  },
  mounted() {
    this.setChart();
    this.setNewData();
  },
  methods: {
    setChart() {
      this.root = am5.Root.new('bubble-chart');

      this.root.setThemes([am5themes_Animated.new(this.root)]);
      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          maxTooltipDistance: 0,
          layout: this.root.verticalLayout,
        })
      );
      this.chartParams.xAxis = this.chart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          baseInterval: { timeUnit: 'month', count: 1 },
          renderer: am5xy.AxisRendererX.new(this.root, {}),
        })
      );
      this.chartParams.yAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          numberFormat: `#'${this.$helpers.getCurrencySymbol()}'`,
          renderer: am5xy.AxisRendererY.new(this.root, {}),
          tooltip: am5.Tooltip.new(this.root, {}),
        })
      );
      this.chart.set(
        'cursor',
        am5xy.XYCursor.new(this.root, {
          behavior: 'none',
        })
      );
      this.chartParams.legend = this.chart.children.unshift(
        am5.Legend.new(this.root, {
          marginBottom: 10,
          marginLeft: 50,
          marginRight: 50,
          x: am5.percent(50),
          centerX: am5.percent(50),
          height: am5.percent(17),
          verticalScrollbar: am5.Scrollbar.new(this.root, {
            fill: am5.color(0x550000),
            fillOpacity: 0.1,
            orientation: 'vertical',
          }),
          layout: am5.GridLayout.new(this.root, {
            maxColumns: 4,
          }),
        })
      );
      this.chartParams.legend.itemContainers.template.states.create(
        'hover',
        {}
      );
      this.chartParams.legend.itemContainers.template.events.on(
        'pointerover',
        (e) => {
          e.target.dataItem.dataContext.hover();
        }
      );
      this.chartParams.legend.itemContainers.template.events.on(
        'pointerout',
        (e) => {
          e.target.dataItem.dataContext.unhover();
        }
      );
    },
    drawLineSeries() {
      let currentUrl = window.location.protocol + '//' + window.location.host;
      this.chartData.forEach((c) => {
        let countryData = c.data;
        let countryImageUrl = `${currentUrl}${c.flag_url}`;
        let series = this.chart.series.push(
          am5xy.SmoothedXLineSeries.new(this.root, {
            imageUrl: countryImageUrl,
            name: c.name,
            xAxis: this.chartParams.xAxis,
            yAxis: this.chartParams.yAxis,
            valueYField: 'value',
            valueXField: 'date',
          })
        );
        let tooltip = am5.Tooltip.new(this.root, {
          getFillFromSprite: false,
          fill: am5.color(0x000000),
          labelText: `[bold]{name}[/]: {valueY.formatNumber('#,###.00')}${this.$helpers.getCurrencySymbol()}`,
        });
        tooltip.get('background').setAll({
          fill: am5.color(0x5c85c6),
          fillOpacity: 1,
          stroke: am5.color(0x000000),
          strokeOpacity: 0.8,
        });
        tooltip.label.setAll({
          fill: am5.color(0x000000),
        });
        series.set('tooltip', tooltip);
        series.bullets.push(() => {
          let graphics = am5.Picture.new(this.root, {
            width: 20,
            height: 20,
            x: am5.percent(30),
            y: am5.percent(50),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            src: countryImageUrl,
          });
          return am5.Bullet.new(this.root, {
            sprite: graphics,
          });
        });
        series.data.processor = am5.DataProcessor.new(this.root, {
          dateFields: ['date'],
          dateFormat: 'yyyy-MM',
        });
        series.set('setStateOnChildren', true);
        series.states.create('hover', {});
        series.mainContainer.set('setStateOnChildren', true);
        series.mainContainer.states.create('hover', {});
        series.strokes.template.states.create('hover', {
          strokeWidth: 4,
        });
        if (countryData?.length) series.data.setAll(countryData);
        series.appear(1000);
        this.chartSeries[c.name] = series;
      });
      this.chartParams.legend.data.setAll(this.chart.series.values);
      this.chart.appear(1000, 100);
    },
    setNewData() {
      this.clearChartSeries();
      this.drawLineSeries();
    },
    clearChartSeries() {
      if (Object.keys(this.chartSeries).length > 0) {
        Object.keys(this.chartSeries).forEach((k) => {
          let sIndex = this.chart.series.indexOf(this.chartSeries[k]);
          if (sIndex !== -1) this.chart.series.removeIndex(sIndex).dispose();
        });
      }
      this.chartSeries = {};
    },
  },
};
</script>

<style scoped>
#bubble-chart {
  width: 100%;
  height: 500px;
}
</style>
