<template>
  <div class="container-fluid">
    <div class="main" style="height: 100vh">
      <TheNavbar
        :collapsed="appState.collapsed"
        :user-info="userInfo"
        :search-array="filtersForm.search.options"
        @search-tag="searchTag"
        @toggle-collapsed="appState.collapsed = !appState.collapsed"
      />

      <div
        ref="scrollableContent"
        class="content scrollbar"
        :class="{
          'collapsed-content': appState.collapsed,
        }"
        :user-info="userInfo"
      >
        <router-view v-slot="{ Component }">
          <template v-if="$route.path === '/' || $route.path.includes('?')">
            <component
              :is="Component"
              :key="$route.path"
              v-model:filters-form="filtersForm"
              :app-state="appState"
              :results="results"
              :loading="loading"
              :comments="comments"
              :user-info="userInfo"
              @load-page="loadPage"
              @delete-tag="deleteTag"
              @clear-filters="clearFilters"
              @search-tag="searchTag"
              @selected-tag="selectedTag"
              @deleted-tag="deletedTag"
              @checkbox-tag="checkboxTag"
            />
          </template>
          <template v-else>
            <component
              :is="Component"
              :key="$route.path"
              :results="results"
              :user-info="userInfo"
              :current-scroll-pos="currentScrollPos"
              @search-profit-center-variant="search"
            />
          </template>
        </router-view>

        <FeedbackForm />
        <CurrencyConverter />
        <notifications :width="300" :height="2300" />
      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar/index.vue';
import { isEmptyArray } from '@/helpers';
import {
  resultsDummy,
  filtersFormEmpty,
  singleFilterKeys,
} from '@/helpers/constants';
import api from '@/helpers/api';
import FeedbackForm from '@/components/FeedbackForm.vue';
import CurrencyConverter from '@/components/CurrencyConverter.vue';

export default {
  components: {
    TheNavbar,
    FeedbackForm,
    CurrencyConverter,
  },
  data() {
    return {
      filtersForm: filtersFormEmpty,
      appState: {
        chips: [
          {
            id: 'status',
            selectedOption: 'active',
          },
          {
            id: 'status',
            selectedOption: 'planned for launch',
          },
          {
            id: 'status',
            selectedOption: 'material in change',
          },
        ],
        pageNumber: 1,
        pageCount: 1,
        collapsed: true,
      },
      results: resultsDummy,
      isNewFilter: false,
      comments: {},
      loading: false,
      userInfo: {},
      currentScrollPos: 40,
    };
  },
  watch: {
    filtersForm: {
      handler() {
        this.searchNow();
        this.saveState();
      },
      deep: true,
    },
    appState: {
      handler() {
        this.saveState();
      },
      deep: true,
    },
  },
  async created() {
    const { data } = await api.get('/users/me');
    this.userInfo = data;
  },
  async beforeMount() {
    let filtersFormVar = JSON.parse(JSON.stringify(this.filtersForm));
    if (localStorage.getItem('appState')) {
      this.appState = JSON.parse(localStorage.getItem('appState'));
      if (isEmptyArray(this.appState.chips)) {
        this.appState.chips = [
          {
            id: 'status',
            selectedOption: 'active',
          },
          {
            id: 'status',
            selectedOption: 'planned for launch',
          },
          {
            id: 'status',
            selectedOption: 'material in change',
          },
        ];
      }
      this.appState.chips.forEach((el) => {
        if (filtersFormVar[el.id]['value']) {
          if (singleFilterKeys.includes(el.id)) {
            filtersFormVar[el.id]['value'] = el.selectedOption;
          } else {
            filtersFormVar[el.id]['value'].push(el.selectedOption);
            filtersFormVar[el.id]['value'] = [
              ...new Set(filtersFormVar[el.id]['value']),
            ];
          }
        } else {
          filtersFormVar[el.id] = el.selectedOption;
        }
      });
    }

    let result = await api.get('/profit_center_variants/autocomplete_list');
    let resultObj = result.data;
    let resultObjIds = resultObj.ids.sort((a, b) => a - b);
    filtersFormVar.search.options = resultObjIds;
    filtersFormVar.id.options = resultObjIds;

    result = await api.get('/profit_center_variants/selector_data');
    resultObj = result.data;
    filtersFormVar.country.options = resultObj.countries.name.sort();
    filtersFormVar.region.options = resultObj.country_regions.name.sort();
    filtersFormVar.tradeUnion.options = resultObj.trade_unions.name.sort();
    filtersFormVar.businessModel.options =
      resultObj.countries.business_model.sort();
    filtersFormVar.brand.options = resultObj.brands.name.sort();
    filtersFormVar.originalBrands.options =
      resultObj.original_brands.name.sort();
    filtersFormVar.product.options = resultObj.products.name.sort();
    filtersFormVar.dosageForms.options =
      resultObj.product_variants.dosage_type.sort();
    filtersFormVar.productCategory.options = resultObj.brands.categories.sort();
    filtersFormVar.isLiquid.options = resultObj.product_variants.is_liquid
      .map((v) => (v === false ? 'false' : v))
      .sort();
    filtersFormVar.packageSize.options =
      resultObj.product_variants.package_size.sort((a, b) => a - b);
    filtersFormVar.inclCompetitors.options =
      resultObj.profit_center_variants.incl_competitors.sort();
    filtersFormVar.status.options =
      resultObj.profit_center_variants.status.sort();
    filtersFormVar.currency.options = resultObj.currencies.name.sort();
    filtersFormVar.orderBy.options = resultObj.order_options.options;
    filtersFormVar.isBundlePack.options =
      resultObj.profit_center_variants.is_bundle_pack.sort();
    this.filtersForm = JSON.parse(JSON.stringify(filtersFormVar));
  },
  mounted() {
    this.$refs.scrollableContent.addEventListener('scroll', this.scrolled);
  },
  beforeUnmount() {
    this.$refs.scrollableContent.removeEventListener('scroll', this.scrolled);
  },
  methods: {
    async search() {
      if (this.isNewFilter) {
        this.isNewFilter = false;
      } else if (window.location.href.includes('?')) {
        const options = this.decodeQueryString();
        this.loading = true;

        const result = await api.post(
          '/profit_center_variants/search',
          JSON.stringify({
            profit_center_variants: {
              sap_ids: options['search'],
              status: options['status'],
              incl_competitors: options['inclCompetitors'],
              is_main_competitors: options['isMainCompetitors'],
              is_bundle_pack: this.actualizeBundlePack(options['isBundlePack']),
            },
            product_variants: {
              package_sizes: options['packageSize'],
              dosage_types: options['dosageForms'],
              is_liquid: options['isLiquid'],
            },
            products: {
              names: options['product'],
            },
            brands: {
              names: options['brand'],
              category_names: options['productCategory'],
            },
            original_brands: {
              names: options['originalBrands'],
            },
            countries: {
              names: options['country'],
              business_models: options['businessModel'],
              reference_countries: options['referenceCountries'],
            },
            country_regions: {
              names: options['region'],
            },
            trade_unions: {
              names: options['tradeUnion'],
            },
            currencies: {
              names: options['currency'],
            },
            info: true,
            items_per_page: parseInt(options['itemsPerPage']),
            page: this.appState.pageNumber,
            order_by: this.normalizeQueryStringChar(options['orderBy'])
          })
        );

        this.results = result.data;

        let resultObj = this.results.filters_scope;
        const transformedObj = this.transformOptions(options);

        let filtersFormVar = transformedObj;
        filtersFormVar.country.options = this.uniq(
          resultObj.countries.name,
          filtersFormVar.country.value
        );
        filtersFormVar.region.options = this.uniq(
          resultObj.country_regions.name,
          filtersFormVar.region.value
        );
        filtersFormVar.tradeUnion.options = this.uniq(
          resultObj.trade_unions.name,
          filtersFormVar.tradeUnion.value
        );
        filtersFormVar.businessModel.options = this.uniq(
          resultObj.countries.business_model,
          filtersFormVar.businessModel.value
        );
        filtersFormVar.brand.options = this.uniq(
          resultObj.brands.name,
          filtersFormVar.brand.value
        );
        filtersFormVar.product.options = this.uniq(
          resultObj.products.name,
          filtersFormVar.product.value
        );
        filtersFormVar.dosageForms.options = this.uniq(
          resultObj.product_variants.dosage_type,
          filtersFormVar.dosageForms.value
        );
        filtersFormVar.productCategory.options = this.uniq(
          resultObj.brands.categories,
          filtersFormVar.productCategory.value
        );
        filtersFormVar.isLiquid.options = this.uniq(
          resultObj.product_variants.is_liquid.map((v) =>
            v === false ? 'false' : v
          ),
          filtersFormVar.isLiquid.value
        );
        filtersFormVar.packageSize.options = this.uniq(
          resultObj.product_variants.package_size,
          filtersFormVar.packageSize.value
        ).sort((a, b) => a - b);
        filtersFormVar.inclCompetitors.options = this.uniq(
          resultObj.profit_center_variants.incl_competitors,
          filtersFormVar.inclCompetitors.value
        );
        filtersFormVar.status.options = this.uniq(
          resultObj.profit_center_variants.status,
          filtersFormVar.status.value
        );
        filtersFormVar.originalBrands.options = this.uniq(
          resultObj.original_brands.name,
          filtersFormVar.originalBrands.value
        );
        filtersFormVar.currency.options = this.uniq(
          resultObj.currencies.name,
          filtersFormVar.currency.value
        );
        filtersFormVar.orderBy.options = this.uniq(
          resultObj.order_options.options,
          filtersFormVar.orderBy.value
        );
        filtersFormVar.isBundlePack.options = this.uniq(
          resultObj.profit_center_variants.is_bundle_pack.map((v) =>
            v === false ? 'false' : v
          ),
          filtersFormVar.isBundlePack.value
        );
        filtersFormVar.highlightProperty.options = this.uniq(
          filtersFormVar.highlightProperty.options.filter(item => item !== filtersFormVar.highlightProperty.value),
          filtersFormVar.highlightProperty.value
        )

        this.isNewFilter = true;
        this.filtersForm = filtersFormVar;

        this.appState.pageCount = Math.ceil(
          this.results.count / this.filtersForm.itemsPerPage.value
        );

        const newChips = this.newChips(options)

        this.appState.chips = [...newChips]

        this.loading = false;

        this.$router.push('/');
      } else {
        this.loading = true;

        const result = await api.post(
          '/profit_center_variants/search',
          JSON.stringify({
            profit_center_variants: {
              sap_ids: isEmptyArray(this.filtersForm.search.value)
                ? null
                : this.filtersForm.search.value,
              status: isEmptyArray(this.filtersForm.status.value)
                ? null
                : this.filtersForm.status.value,
              incl_competitors: this.filtersForm.inclCompetitors.value,
              is_main_competitors: this.filtersForm.isMainCompetitors,
              is_bundle_pack: this.filtersForm.isBundlePack.value || [false],
            },
            product_variants: {
              package_sizes: isEmptyArray(this.filtersForm.packageSize.value)
                ? null
                : this.filtersForm.packageSize.value,
              dosage_types: isEmptyArray(this.filtersForm.dosageForms.value)
                ? null
                : this.filtersForm.dosageForms.value,
              is_liquid: isEmptyArray(this.filtersForm.isLiquid.value)
                ? null
                : this.filtersForm.isLiquid.value,
            },
            products: {
              names: isEmptyArray(this.filtersForm.product.value)
                ? null
                : this.filtersForm.product.value,
            },
            brands: {
              names: isEmptyArray(this.filtersForm.brand.value)
                ? null
                : this.filtersForm.brand.value,
              category_names: isEmptyArray(
                this.filtersForm.productCategory.value
              )
                ? null
                : this.filtersForm.productCategory.value,
            },
            original_brands: {
              names: isEmptyArray(this.filtersForm.originalBrands.value)
                ? null
                : this.filtersForm.originalBrands.value,
            },
            countries: {
              names: isEmptyArray(this.filtersForm.country.value)
                ? null
                : this.filtersForm.country.value,
              business_models: isEmptyArray(
                this.filtersForm.businessModel.value
              )
                ? null
                : this.filtersForm.businessModel.value,
              reference_countries: this.filtersForm.referenceCountries,
            },
            country_regions: {
              names: isEmptyArray(this.filtersForm.region.value)
                ? null
                : this.filtersForm.region.value,
            },
            trade_unions: {
              names: isEmptyArray(this.filtersForm.tradeUnion.value)
                ? null
                : this.filtersForm.tradeUnion.value,
            },
            currencies: {
              names: isEmptyArray(this.filtersForm.currency.value)
                ? null
                : this.filtersForm.currency.value,
            },
            info: true,
            items_per_page: isEmptyArray(this.filtersForm.itemsPerPage.value)
              ? null
              : this.filtersForm.itemsPerPage.value,
            page: this.appState.pageNumber,
            order_by: this.filtersForm.orderBy.value.internal_name,
          })
        );

        this.results = result.data;

        let resultObj = this.results.filters_scope;
        let filtersFormVar = JSON.parse(JSON.stringify(this.filtersForm));

        filtersFormVar.country.options = this.uniq(
          resultObj.countries.name,
          filtersFormVar.country.value
        );
        filtersFormVar.region.options = this.uniq(
          resultObj.country_regions.name,
          filtersFormVar.region.value
        );
        filtersFormVar.tradeUnion.options = this.uniq(
          resultObj.trade_unions.name,
          filtersFormVar.tradeUnion.value
        );
        filtersFormVar.businessModel.options = this.uniq(
          resultObj.countries.business_model,
          filtersFormVar.businessModel.value
        );
        filtersFormVar.brand.options = this.uniq(
          resultObj.brands.name,
          filtersFormVar.brand.value
        );
        filtersFormVar.product.options = this.uniq(
          resultObj.products.name,
          filtersFormVar.product.value
        );
        filtersFormVar.dosageForms.options = this.uniq(
          resultObj.product_variants.dosage_type,
          filtersFormVar.dosageForms.value
        );
        filtersFormVar.productCategory.options = this.uniq(
          resultObj.brands.categories,
          filtersFormVar.productCategory.value
        );
        filtersFormVar.isLiquid.options = this.uniq(
          resultObj.product_variants.is_liquid.map((v) =>
            v === false ? 'false' : v
          ),
          filtersFormVar.isLiquid.value
        );
        filtersFormVar.packageSize.options = this.uniq(
          resultObj.product_variants.package_size,
          filtersFormVar.packageSize.value
        ).sort((a, b) => a - b);
        filtersFormVar.inclCompetitors.options = this.uniq(
          resultObj.profit_center_variants.incl_competitors,
          filtersFormVar.inclCompetitors.value
        );
        filtersFormVar.status.options = this.uniq(
          resultObj.profit_center_variants.status,
          filtersFormVar.status.value
        );
        filtersFormVar.originalBrands.options = this.uniq(
          resultObj.original_brands.name,
          filtersFormVar.originalBrands.value
        );
        filtersFormVar.currency.options = this.uniq(
          resultObj.currencies.name,
          filtersFormVar.currency.value
        );
        filtersFormVar.orderBy.options = this.uniq(
          resultObj.order_options.options,
          filtersFormVar.orderBy.value
        );
        filtersFormVar.isBundlePack.options = this.uniq(
          resultObj.profit_center_variants.is_bundle_pack.map((v) =>
            v === false ? 'false' : v
          ),
          filtersFormVar.isBundlePack.value
        );
        this.isNewFilter = true;
        this.filtersForm = filtersFormVar;

        this.appState.pageCount = Math.ceil(
          this.results.count / this.filtersForm.itemsPerPage.value
        );

        this.loading = false;
      }

      await this.loadComments();
    },
    async loadComments() {
      let ids = this.results.items.map((obj) => {
        return obj.id;
      });
      let url = `/comments?commentable_type=ProfitCenterVariant&commentable_id=${ids}&commentable_key=search&grouped_by=commentable_id`;
      const response = await api.get(url);
      this.comments = response.data;
    },

    uniq() {
      return [
        ...new Set(Array.from(arguments).reduce((a, b) => a.concat(b))),
      ].sort();
    },
    selectedTag(selectedOption, id) {
      if (singleFilterKeys.includes(id)) {
        this.appState.chips = this.appState.chips.filter(
          (chip) => !(chip.id === id)
        );
      }
      this.appState.chips.push({ id, selectedOption });
    },
    decodeQueryString() {
      const decodedString = decodeURIComponent(window.location.href.split('/?query=')[1]);
      const pairs = decodedString.split('&')
      const hash = {}
      pairs.forEach(pair => {
        const [key, value] = pair.split("=");
        const component = decodeURIComponent(value)
        const decodedValue = this.normalizeQueryStringChar(component);
        if ((decodedValue.includes(',') || this.arrayKeys().includes(key)) && decodedValue !== 'null') {
          hash[key] = decodedValue.split(',');
        } else {
          hash[key] = decodedValue === 'null' ? null : value;
        }
      });
      return hash;
    },
    transformOptions(options) {
      const transformedObj = Object.entries(options).reduce((acc, [key, value]) => {
        if (['isMainCompetitors', 'referenceCountries'].includes(key)) {
          acc[key] = value;
        } else if (key === 'orderBy') {
          acc[key] = {
            value: this.findOrderByHashByValue(value),
            options: this.filtersForm[key].options
          };
        } else if (key === 'highlightProperty') {
          acc[key] = {
            value: value || '',
            options: this.filtersForm[key].options
          };
        } else if (key === 'isBundlePack') {
          acc[key] = {
            value: this.actualizeBundlePack(value) || [],
            options: this.filtersForm[key].options
          };
        } else if (key === 'inclCompetitors') {
          acc[key] = {
            value: this.normalizeQueryStringChar(value),
            options: this.filtersForm[key].options
          };
        } else {
          acc[key] = {
            value: value || [],
            options: this.filtersForm[key].options
          };
        }
        return acc;
      }, {});

      return transformedObj;
    },
    newChips(options) {
      const chipsHash = Object.entries(options).reduce((acc, [key, value]) => {
        if (value !== null && this.chipKeys().includes(key)) acc[key] = value;
        return acc;
      }, {});

      const newChips = Object.entries(chipsHash).flatMap(([key, values]) => {
        if (this.arrayKeys().includes(key) || key === 'isBundlePack') {
          return values.map(value => ({
            id: key,
            selectedOption: value
          }));
        } else {
          return {
            id: key,
            selectedOption: this.normalizeQueryStringChar(values)
          };
        }
      });

      return newChips;
    },
    chipKeys() {
      return ['id', 'search', 'status', 'inclCompetitors', 'referenceCountries', 'country',
              'isLiquid', 'brand', 'tradeUnion', 'product', 'dosageForms', 'businessModel',
              'packageSize', 'isBundlePack', 'productCategory', 'originalBrands', 'currency',
              'region', 'isMainCompetitors'];
    },
    arrayKeys() {
      return ['id', 'search', 'status', 'packageSize', 'dosageForms', 'isLiquid',
              'product', 'brand', 'productCategory', 'originalBrands', 'country',
              'businessModel', 'region','tradeUnion', 'currency'];
    },
    normalizeQueryStringChar(value) {
      return value.replace(/\+/g, ' ')
    },
    findOrderByHashByValue(value) {
      return JSON.parse(JSON.stringify(this.filtersForm['orderBy'].options.find(item => item.internal_name === this.normalizeQueryStringChar(value))))
              || { full_name: 'SAP id up', internal_name: 'sap_id ASC' }
    },
    actualizeBundlePack(value) {
      if (Array.isArray(value)) {
        return value.map(item => item === 'true' ? JSON.parse(item) : item);
      } else if (value === 'true') {
        return [JSON.parse(value)];
      } else {
        return value ? [value] : [];
      }
    },
    deletedTag(selectedOption, id) {
      this.appState.chips = this.appState.chips.filter(
        (chip) => !(chip.id === id && chip.selectedOption === selectedOption)
      );
    },
    deleteTag(selectedOption, id) {
      if (Object.prototype.hasOwnProperty.call(this.filtersForm[id], 'value')) {
        if (singleFilterKeys.includes(id)) {
          this.filtersForm[id].value = '';
        } else {
          this.filtersForm[id].value = this.filtersForm[id].value.filter(
            (option) => option !== selectedOption
          );
        }
      } else {
        this.filtersForm[id] = !this.filtersForm[id];
      }
      this.appState.chips = this.appState.chips.filter(
        (chip) => !(chip.selectedOption === selectedOption && chip.id === id)
      );
    },
    checkboxTag(selectedOption, id) {
      if (this.appState.chips.filter((chip) => chip.id === id).length) {
        this.appState.chips = this.appState.chips.filter(
          (chip) => chip.id !== id
        );
      } else {
        this.appState.chips.push({
          id,
          selectedOption,
        });
      }
    },
    searchTag(selectedOption, id) {
      if (this.$route.path === '/') {
        if (
          this.filtersForm[id].value.filter((name) => name === selectedOption)
            .length
        ) {
          this.filtersForm[id].value = this.filtersForm[id].value.filter(
            (name) => name !== selectedOption
          );
        } else {
          this.filtersForm[id].value.push(selectedOption);
        }
        if (
          this.appState.chips.filter(
            (chip) => chip.id === id && chip.selectedOption === selectedOption
          ).length
        ) {
          this.appState.chips = this.appState.chips.filter(
            (chip) =>
              !(chip.id === id && chip.selectedOption === selectedOption)
          );
        } else {
          this.appState.chips.push({
            id,
            selectedOption,
          });
        }
      } else {
        this.appState.chips.length = 0;
        this.appState.chips.push({
          id,
          selectedOption,
        });
        this.filtersForm.search.value = [selectedOption];
        this.filtersForm.id.value = [];
        this.filtersForm.isMainCompetitors = false;
        this.filtersForm.country.value = [];
        this.filtersForm.referenceCountries = false;
        this.filtersForm.region.value = [];
        this.filtersForm.tradeUnion.value = [];
        this.filtersForm.businessModel.value = [];
        this.filtersForm.brand.value = [];
        this.filtersForm.originalBrands.value = [];
        this.filtersForm.productCategory.value = [];
        this.filtersForm.product.value = [];
        this.filtersForm.dosageForms.value = [];
        this.filtersForm.packageSize.value = [];
        this.filtersForm.status.value = [];
        this.filtersForm.currency.value = [];
        this.filtersForm.isBundlePack.value = false;
        this.saveState();
        this.$router.push('/');
      }
    },
    loadPage(page) {
      this.appState.pageNumber = page;
      this.search();
    },
    searchNow() {
      if (!this.isNewFilter) {
        this.appState.pageNumber = 1;
      }
      this.search();
    },
    saveState() {
      localStorage.setItem('appState', JSON.stringify(this.appState));
    },
    clearFilters() {
      this.appState.chips.forEach((chip) =>
        this.deleteTag(chip.selectedOption, chip.id)
      );
    },
    scrolled() {
      this.currentScrollPos =
        this.$refs.scrollableContent.children[0].getBoundingClientRect().top;
    },
  },
};
</script>
