import axios from 'axios'

export default axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
    Accept: 'application/json' // Special Rails header
  },
})
