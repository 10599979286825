<template>
  <div v-if="itemsFiltered.length" class="card small-table-card">
    <div class="table-responsive">
      <table class="table table-striped borderless">
        <thead>
          <tr v-if="showTitle">
            <th scope="col">
              {{ $helpers.removeDashes(name) }}
              <button
                v-if="showTable"
                class="expand-narrow-down-button button-no-style clickable"
                @click="showTable = false"
              >
                <svg
                  width="18"
                  height="2"
                  viewBox="0 0 18 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3333 0.083374H9.91667H1.66667C1.16041 0.083374 0.75 0.493779 0.75 1.00004C0.75 1.5063 1.16041 1.91671 1.66667 1.91671H9.91667H16.3333C16.8396 1.91671 17.25 1.5063 17.25 1.00004C17.25 0.493779 16.8396 0.083374 16.3333 0.083374Z"
                    fill="#003B6A"
                  />
                </svg>
              </button>
              <button
                v-else
                class="expand-narrow-down-button button-no-style clickable"
                @click="showTable = true"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.83333 7.16667H14.6667C15.1269 7.16667 15.5 7.53976 15.5 8C15.5 8.46024 15.1269 8.83333 14.6667 8.83333H8.83333V14.6667C8.83333 15.1269 8.46024 15.5 8 15.5C7.53976 15.5 7.16667 15.1269 7.16667 14.6667V8.83333H1.33333C0.873096 8.83333 0.5 8.46024 0.5 8C0.5 7.53976 0.873096 7.16667 1.33333 7.16667H7.16667V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V7.16667Z"
                    fill="#31394D"
                  />
                </svg>
              </button>
            </th>
          </tr>
        </thead>
        <tbody v-if="showTable">
          <tr
            v-for="item in itemsFiltered"
            :key="`/${url ? url : name}/${item[0]}` + $route.path"
          >
            <td @click="$router.push(`/${url ? url : name}/${item[0]}`)">
              {{ item[1] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HierarchyItemDetailsTable',
  props: {
    items: Array,
    name: String,
    url: String,
    showTitle: {
      type: Boolean,
      default: true,
    },
    showTable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    itemsFiltered() {
      if (this.name == 'profit_centers') {
        return this.items.filter((item) => item[1] != 'Competitors');
      } else {
        return this.items;
      }
    },
  },
};
</script>
