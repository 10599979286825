<template>
  <div class="container">
    <div
      class="hierarchy-item-title fixed"
      :class="{ scrolled: currentScrollPos < 40 }"
    >
      <h1 :class="{ loading }">
        {{
          data.full_name
            ? data.full_name
            : data.name
            ? data.name
            : data.is_bundle_pack
            ? data.sap_name
            : data.gpms_name
        }}
      </h1>
    </div>

    <div
      class="d-flex justify-content-center mb-30 row col-lg-12"
      style="width: 100%"
    >
      <div class="hierarchy-item-image-and-card-info">
        <div :class="{ loading }">
          <InfoCard v-if="Object.keys(data).length" :data="data" />
        </div>
      </div>
    </div>
    <div v-if="!data.is_competitor" class="d-flex justify-content-center row mb-30">
      <Chart
        object="sales"
        :show-switch="true"
        :quote="defineQuoteSales()"
        :page-name="data.sap_name"
      />
    </div>
    <div class="d-flex justify-content-center row mb-30">
      <Chart
        object="prices"
        :page-name="data.sap_name"
        :override-currency="data.currency_short"
        data-type="currency"
      />
    </div>
    <div class="d-flex justify-content-center row mb-30">
      <Chart
        object="currencies"
        select-by="showCurrencyType"
        :page-name="data.sap_name"
      />
    </div>
    <div class="d-flex justify-content-center row mb-30">
      <HierarchyItemDetailsPriceBuildupTable
        v-if="!loading"
        :id="data.id"
        :price-info="data.price_info"
        :country="data.country.name"
        :exchange-rate-type-value="selectedCurrency.value"
        :exchange-rate-type-options="selectedCurrency.options"
        :price-buildup-currency="data.price_buildup_currency"
        :is-competitor="data.is_competitor"
        :pcv-name="data.gpms_name"
        @select-currency="selectCurrency"
      />
    </div>
    <div v-if="hasCompetitors()" class="d-flex justify-content-center row mb-30">
      <Chart
        object="competitors"
        :show-switch="true"
        :quote="$helpers.defineQuote(data)"
        :page-name="data.sap_name"
        :object-name="data.gpms_name"
      />
    </div>

    <div v-if="hasCompetitors()" class="d-flex justify-content-center row mb-30">
      <ChartDosage
        object="dosages"
        :show-switch="true"
        :quote="$helpers.defineQuote(data)"
        :object-name="data.gpms_name"
      />
    </div>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard.vue';
import Chart from '@/components/Chart/index.vue';
import ChartDosage from '@/components/Chart/ChartDosage.vue';
import HierarchyItemDetailsPriceBuildupTable from '@/components/HierarchyItemList/HierarchyItemDetailsPriceBuildupTable.vue';
import { hierarchy, currenciesShortISO4217 } from '@/helpers/constants';
import api from '@/helpers/api';

// Universal details page for brands, products, product variants, countries, currencies and profit center variants.
export default {
  name: 'HierarchyItemProfitCenterVariantDetails',
  components: {
    HierarchyItemDetailsPriceBuildupTable,
    InfoCard,
    Chart,
    ChartDosage
  },
  props: {
    currentScrollPos: Number,
  },
  emits: ['close'],
  data() {
    return {
      data: {},
      hierarchy,
      loading: false,
      currency: {
        value: '',
        options: currenciesShortISO4217,
      },
      selectedCurrency: {
        value: '',
        options: [],
      }
    };
  },

  async beforeMount() {
    this.loading = true;

    await this.getData();
    this.selectedCurrency.options = this.data.available_currencies;

    this.loading = false;
  },

  methods: {
    selectCurrency(currencyName) {
      if (this.selectedCurrency.value === currencyName) {
        this.selectedCurrency.value = '';
      } else {
        this.selectedCurrency.value = currencyName;
        this.getPriceInfoWithCurrency(currencyName);
      }
    },

    async getPriceInfoWithCurrency(currencyName) {
      const response = await api.get(
        `/profit_center_variants/get_price_buildup_price_info?currency_type=${currencyName}&id=${this.data.id}`
      );
      this.data.price_info = response.data;
    },

    defineQuoteSales() {
      return this.data.country?.name === 'Belarus'
        ? 'Source: Intellix'
        : 'Source: SAP BW';
    },

    hasCompetitors() {
      return (
        this.data && this.data.competitors && this.data.competitors.length > 0
      );
    },

    async getData() {
      const response = await api.get(`${this.$route.path}.json`);
      this.data = response.data.data;
    }
  }
};
</script>
