<template>
  <div class="profit-center-variant-detail">
    <h1 class="profit-center-variant-detail-title">Price Buildup Detail</h1>

    <div class="d-flex justify-content-left mt-30 mb-30 ms-56">
      <button
        class="buildup-card-right-panel-button button-no-style vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon left-arrow-toggler display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Price Buildups list
        </span>
      </button>
    </div>

    <div class="profit-center-variant-detail-header">
      <div class="profit-center-variant-detail-title-header">
        <Multiselect
          v-model="status.value"
          :options="status.options"
          select-label
          placeholder="Status"
          :show-labels="false"
        >
          <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <i class="icon icon-down-arrow" />
            </div>
          </template>
        </Multiselect>

        <Multiselect
          v-model="pricingCondition.value"
          :options="pricingCondition.options"
          select-label
          placeholder="Pricing condition"
          :show-labels="false"
        >
          <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <i class="icon icon-down-arrow" />
            </div>
          </template>
        </Multiselect>

        <DatePicker
          v-model:value="range"
          :clearable="false"
          input-class="mx-input profit-center-variant-detail-picker"
          type="date"
          :disabled-date="disabledEndDate"
          range
          placeholder="Select period"
        />

        <button class="btn btn-green" @click="creatingNewField = true">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.83333 7.16667H14.6667C15.1269 7.16667 15.5 7.53976 15.5 8C15.5 8.46024 15.1269 8.83333 14.6667 8.83333H8.83333V14.6667C8.83333 15.1269 8.46024 15.5 8 15.5C7.53976 15.5 7.16667 15.1269 7.16667 14.6667V8.83333H1.33333C0.873096 8.83333 0.5 8.46024 0.5 8C0.5 7.53976 0.873096 7.16667 1.33333 7.16667H7.16667V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V7.16667Z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div v-if="!creatingNewField" class="d-flex flex-column">
        <div
          v-for="element in priceBuildupCardList"
          :key="element"
          class="d-flex flex-column green-line-item"
        >
          <span class="green-line-span">
            {{ element }}
            <i class="icon point-dnd" />
          </span>
          <div v-if="element !== 1" class="green-line" />
        </div>
      </div>

      <!-- <div v-if="!creatingNewField" class="d-flex flex-column">
        <PriceBuildupCard
          v-for="(value, key) in data.pbi_info_data"
          :key="key"
          :data="value"
          :creating-new-field="false"
          @edit-field="editField"
        />
      </div> -->

      <Draggable
        v-if="!creatingNewField && pbiInfoData.length"
        v-model="pbiInfoData"
        item-key="id"
        group="people"
        @start="drag = true"
        @end="drag = false"
      >
        <template #item="{ element }">
          <PriceBuildupCard
            :data="element.data"
            :creating-new-field="false"
            :currency-sign="sign"
            @edit-field="editField"
          />
        </template>
      </Draggable>

      <div v-else class="creating-new-price-buildup">
        <PriceBuildupCard
          :creating-new-field="true"
          :data="editableField"
          :editing-field="true"
          :currency-sign="sign"
          @update-field="updateField"
        />
      </div>

      <InfoCard :data="data" :columns-number="1" />
    </div>
  </div>
</template>

<script>
import PriceBuildupCard from '@/components/PriceBuildupList/PriceBuildupCard.vue';
import InfoCard from '@/components/InfoCard.vue';

export default {
  name: 'PriceBuildupDetailsPage',
  components: { InfoCard, PriceBuildupCard },
  data() {
    return {
      status: {
        value: '',
        options: [1, 2, 3, 4],
      },
      pricingCondition: {
        value: '',
        options: [1, 2, 3, 4],
      },
      range: null,
      priceBuildupCardList: [14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      loading: false,
      data: {},
      creatingNewField: false,
      disabledBefore: new Date(2012, 1, 1),
      disabledAfter: new Date(2026, 1, 1),
      editableField: '',
      currencySign: '',
    };
  },
  async beforeMount() {
    this.getPriceBuildup();
  },
  mounted() {
    // console.log('data:', JSON.parse(JSON.stringify(this.data)));
    // console.log(
    //   'data.pbi_info_data:',
    //   this.data?.pbi_info_data
    //     ? JSON.parse(JSON.stringify(this.data.pbi_info_data))
    //     : undefined
    // );
  },
  computed: {
    pbiInfoData: {
      get() {
        if (!this.data.hasOwnProperty('pbi_info_data')) return [];

        const transformedArray = this.data.pbi_info_data
          ? Object.entries(this.data.pbi_info_data).map(([key, value]) => ({
              id: key,
              data: value,
            }))
          : [];

        // console.log('transformedArray', transformedArray);
        return transformedArray;
      },
      set(value) {
        if (!this.data.hasOwnProperty('pbi_info_data')) return;

        // TODO take a look at this
        this.data.pbi_info_data = value;
      },
    },
    sign() {
      return this.$helpers.getCurrencySymbol(this.data.currency_short_name)
    },
  },
  methods: {
    async getPriceBuildup() {
      this.loading = true;
      let result = await fetch(`${this.$route.path}/get_buildup_data.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      });
      result = await result.json();
      this.data = result.data;
      this.fillEnumerator(Object.keys(this.data.pbi_info_data).length);
      this.loading = false;
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    editField(item) {
      this.editableField = this.data.pbi_info_data[item];
      this.creatingNewField = true;
    },
    async updateField(item) {
      let result = await fetch(
        `/profit_center_variants/${this.$route.params.id}/price_buildups/${this.$route.params.pb_id}/update_field`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
              .content,
          },
          body: JSON.stringify({
            object: this.id,
            key: item.changeName,
            value: item.changeValue,
          }),
        }
      );

      if (result.ok) {
        this.getPriceBuildup();
        this.creatingNewField = false;
      }
    },
    returnToList() {
      this.$router.push(
        `/profit_center_variants/${this.$route.params.id}/price_buildups`
      );
    },
    fillEnumerator(length) {
      let a = Array.from(Array(length + 1).keys());
      a.shift();
      this.priceBuildupCardList = a.reverse();
    },
  },
};
</script>
