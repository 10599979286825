<template>
  <div class="products-table">
    <div class="products-table-title" :class="{ loading: loading.selectors }">
      <h1 v-if="!loading.selectors">
        {{ titleWithoutDashes }}
      </h1>
    </div>

    <!--  Filters, sorting and ordering options  -->
    <HierarchyItemListHeader
      :loading="loading.selectors"
      :dropdowns-widths="dropdownsWidths"
      :model-keys-array="modelKeysArray"
      :model-values="modelValues"
      :model-options="modelOptions"
      :order-value="orderValue"
      :order-options="orderOptions"
      :items-per-page-value="itemsPerPageValue"
      :items-per-page-options="itemsPerPageOptions"
      :user-info="userInfo"
      @order-changed="orderChanged"
      @open-create-new-modal="openCreateNewModal"
      @clear-filters="clearFilters"
    />

    <div class="mb-30">
      <HierarchyItemListTable
        :loading="loading.table"
        :items="products"
        :title="title"
        :user-info="userInfo"
        @open-edit-modal="openEditModal"
        @delete-confirmation-modal="deleteConfirmationModal"
      />
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <button
        v-if="!loading.selectors && !loading.table"
        class="btn btn-green load-more-button"
        @click="loadMore"
      >
        <span>Load more</span>
      </button>
    </div>

    <teleport to="body">
      <Modal
        v-if="showDeleteConfirmationModal"
        :data="{ maxContent: true }"
        @close="showDeleteConfirmationModal = false"
      >
        <div class="d-flex flex-column align-items-center">
          Are you sure you want to delete {{ title }} {{ deletionId }}?
          <div class="mt-2">
            <button
              class="button-no-style modal-button me-5"
              @click="confirmDeletion(deletionId)"
            >
              Yes
            </button>
            <button
              class="button-no-style modal-button"
              @click="cancelDeletion"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </teleport>
    <teleport to="body">
      <Modal
        v-if="showEditModal || showCreateNewModal"
        class="edit-modal"
        @close="
          (e) => (showEditModal ? closeEditModal(e) : closeCreateNewModal(e))
        "
      >
        <EditNewForm
          v-model:new-item-form="newItemForm"
          :show-edit-modal="showEditModal"
          :dropdowns-options="dropdownsOptions"
          @update-item="updateItem"
          @add-item="addItem"
        />
      </Modal>
    </teleport>
  </div>
</template>

<script>
import HierarchyItemListTable from '@/components/HierarchyItemList/HierarchyItemListTable.vue';
import HierarchyItemListHeader from '@/components/HierarchyItemList/HierarchyItemListHeader.vue';
import Modal from '@/components/Modals/index.vue';
import EditNewForm from '@/components/Modals/EditNewForm.vue';
import { editFormFields } from '@/helpers/constants';
import api from '@/helpers/api';
import { loadDropdowns } from '@/helpers/apiFunctions';

// Universal list page for brands, products, product variants.
// Could be extended: response.data must include to_json property.
export default {
  name: 'HierarchyItemList',
  components: {
    HierarchyItemListHeader,
    HierarchyItemListTable,
    Modal,
    EditNewForm
  },

  props: {
    userInfo: Object,
  },

  data() {
    return {
      title: '',
      products: [],
      modelKeysArray: [],
      modelOptions: {},
      modelValues: {},
      itemsPerPageOptions: [20, 40, 60, 80, 100],
      itemsPerPageValue: 20,
      orderOptions: [],
      orderValue: { direction: 'ASC', field_name: 'id', full_name: 'id up' },
      loading: {
        selectors: false,
        table: false,
        currencies: false,
      },
      showCreateNewModal: false,
      newItemForm: {},
      showDeleteConfirmationModal: false,
      showEditModal: false,
      deletionId: null,
      editId: null,
      dropdownsOptions: {},
      page: 1,
      dropdownsWidths: {},
      preSelectedFilter: ''
    };
  },

  computed: {
    titleWithoutDashes() {
      let title = this.$route.path.slice(1);
      if (title == 'products') return 'Sub-brands';
      return this.$helpers.capitalWithoutDashes(title);
    }
  },

  async beforeMount() {
    this.loading.selectors = true;

    let { data: response } = await api.get(`${this.$route.path}/selector_data`);

    this.orderOptions = response.order_options.options.sort(
      this.$helpers.dynamicSort('full_name')
    );

    const modelOptions = response.model_options.field_data;
    modelOptions['order_by'] = response.order_options.options.map(
      (orderOption) => orderOption.full_name
    );
    if (Object.prototype.hasOwnProperty.call(modelOptions, 'is_liquid')) {
      modelOptions.is_liquid = modelOptions.is_liquid.map((v) =>
        v === false ? 'false' : v
      );
    }
    for (const option in modelOptions) {
      modelOptions[option] = modelOptions[option].filter(
        (optionItem) => optionItem !== null && optionItem !== ''
      );
      if (typeof modelOptions[option][0] === 'string') {
        modelOptions[option] = modelOptions[option].sort();
      } else if (typeof modelOptions[option][0] === 'number') {
        modelOptions[option] = modelOptions[option].sort((a, b) => a - b);
      }
    }

    for (const prop in modelOptions) {
      this.modelOptions[prop] = modelOptions[prop];
      const modelOptionsCopy = [...modelOptions[prop]];
      modelOptionsCopy.push(prop);
      this.dropdownsWidths[prop] =
        modelOptionsCopy
          .filter((item) => item !== null)
          .reduce(function (a, b) {
            return a.length > b.length ? a : b;
          }).length * 9.5;
    }

    response.model_options.fields.map((field) => {
      this.modelValues[field] = '';
    });

    this.modelKeysArray = [
      ...this.modelKeysArray,
      ...response.model_options.field_names,
    ];

    this.title = response.model_options.model_name;
    editFormFields[this.title].map((field) => {
      this.newItemForm[field] = '';
    });

    this.loading.selectors = false;
  },

  methods: {
    async search(set = true) {
      if (set) {
        this.loading.table = true;
        this.loading.currencies = true;
        this.page = 1;
      }

      const orderBy = {};
      if (this.orderValue.direction) {
        orderBy['order_direction'] = this.orderValue.direction;
        orderBy['order_by'] = this.orderValue.field_name;
      }

      const serializedObject = this.$helpers.serialize({
        ...this.modelValues,
        page: this.page,
        items_per_page: this.itemsPerPageValue,
        ...orderBy,
      });
      
      const url = `${this.$route.path}.json${
        serializedObject === '' ? '' : `?${serializedObject}`
      }`;

      let { data: response } = await api.get(url);
      const products = response.map((p) => p.to_json);

      if (set) {
        this.products = products;
        this.loading.table = false;
        this.loading.currencies = false;
      } else {
        return products;
      }

      if (
        this.products.length &&
        this.$helpers.isEmptyObject(this.dropdownsOptions)
      ) {
        this.dropdownsOptions = await loadDropdowns(this.newItemForm);
      }
    },

    clearFilters() {
      for (const value in this.modelValues) {
        if (value === 'items_per_page') {
          this.modelValues[value] = 20;
        } else {
          this.modelValues[value] = '';
        }
      }
      this.orderValue = {
        direction: 'ASC',
        field_name: 'id',
        full_name: 'id up',
      };
      this.preSelectedFilter = '';
      this.search();
    },

    orderChanged(prop, val) {
      this[prop] = val;

      if (val.name != '' || val.short_name != '') {
        this.preSelectedFilter = 'Show all';
        this.search();
      } else {
        this.preSelectedFilter = '';
        this.search();
      }
    },

    async addItem() {
      const data = {};
      for (const prop in this.newItemForm) {
        data[prop] =
          this.newItemForm[prop] && this.newItemForm[prop].id
            ? this.newItemForm[prop].id
            : this.newItemForm[prop];
      }

      try {
        await api.post(`${this.$route.path}.json`, data);

        for (const prop in this.newItemForm) {
          this.newItemForm[prop] = '';
        }

        this.search();
        this.showCreateNewModal = false;
        this.$helpers.notifySuccess('Item has been added');
      } catch (e) {
        const errorsData = e.response.data;
        for (const prop in errorsData) {
          this.$helpers.notifyError(prop + ' ' + errorsData[prop].join('; ') + '.');
        }
      }
    },

    openCreateNewModal() {
      for (const prop in this.newItemForm) {
        this.newItemForm[prop] = '';
      }
      this.showCreateNewModal = true;
    },

    closeCreateNewModal() {
      this.showCreateNewModal = false;
    },

    async deleteItem(id) {
      try {
        await api.delete(`${this.$route.path}/${id}.json`);
        await this.search();
      } catch (e) {
        console.log(e);
      }
    },

    async confirmDeletion(id) {
      await this.deleteItem(id);
      this.showDeleteConfirmationModal = false;
    },

    deleteConfirmationModal(id) {
      this.deletionId = id;
      this.showDeleteConfirmationModal = true;
    },

    cancelDeletion() {
      this.deletionId = null;
      this.showDeleteConfirmationModal = false;
    },

    async updateItem() {
      const data = {};
      for (const prop in this.newItemForm) {
        data[prop] =
          this.newItemForm[prop] && this.newItemForm[prop].id
            ? this.newItemForm[prop].id
            : this.newItemForm[prop];
      }

      try {
        await api.put(`${this.$route.path}/${this.editId}.json`, data);
        this.showEditModal = false;
        this.search();
        this.$helpers.notifySuccess('Item has been updated');
      } catch (e) {
        const errorsData = e.response.data;
        for (const prop in errorsData) {
          this.$helpers.notifyError(prop + ' ' + errorsData[prop].join('; ') + '.');
        }
      }
    },

    openEditModal(id) {
      this.editId = id;

      const item = this.products.find((item) => item.id === id);
      for (const prop in this.newItemForm) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          if (prop.split('_').pop() === 'id') {
            this.newItemForm[prop] = this.dropdownsOptions[prop].find((p) => {
              return p.id === item[prop];
            });
          } else {
            this.newItemForm[prop] = item[prop];
          }
        }
      }

      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    async loadMore() {
      this.page += 1;
      const newProducts = await this.search(false);
      if (newProducts) {
        this.products = [...this.products, ...newProducts];
      } else {
        this.page -= 1;
      }
    }
  }
};
</script>
