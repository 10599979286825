<template>
  <div class="products-table plan-price-planning">
    <div class="products-table-title">
      <h1>{{ pcv?.gpms_name }} Plan Price Planning</h1>
      <br />
      <h4>{{ pcv?.status }}</h4>
    </div>

    <div class="plan-price-planning__buttons mt-30 mb-30" style="justify-content: flex-start !important;">
      <button
        class="buildup-card-right-panel-button btn btn-outline-dark vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Products
        </span>
      </button>
      <button class="btn btn-success" @click="openApproveModal" style="margin-left: 20px !important;" :disabled="!updated">Save</button>
      <button class="btn btn-warning" @click="openDiscardModal" style="margin-left: 10px !important;" v-floating-tooltip="'Rollback to previously saved changes'" :disabled="!updated">Clear changes</button>
    </div>

    <div v-if="loading" class="flex-center">
      <b-spinner type="border" class="tab-spinner" />
    </div>
    <b-container v-if="!loading" class="pp-planning-table">
      <b-row class="pp-planning-table__header">
        <b-col
          v-for="(yearData, index) in yearlyPlanPrices"
          :key="yearData[0]"
          cols="2"
          class="pp-planning-table__column"
        >
          {{ yearData[0] }}
          <button class="button-no-style" @click="toggleDatePicker(index)">
            <i class="property-icon add-icon" />
          </button>
            <DatePicker
              type="month"
              :clearable="false"
              placeholder="Choose month"
              :open="yearModalOpen[index]"
              :disabled-date="disabledDates"
              :default-value="new Date(yearData[0])"
              @change="addMonth"
              @close="hideDatePicker()"
            >
            </DatePicker>
        </b-col>
      </b-row>
      <b-row class="pp-planning-table__body">
        <b-col
          v-for="yearData in yearlyPlanPrices"
          :key="`${yearData[0]}${yearData[1]}`"
          cols="2"
          class="pp-planning-table__column"
        >
          <PlanPriceLevelCard
            v-for="monthData in yearData[1]"
            :key="`${monthData[0]}${monthData[1]}`"
            class="pp-planning-table__price-container"
            :month-key="monthData[0]"
            :price-data="monthData[1]"
            :currencies="currencies"
            @save="saveValue"
            @deleteValue="deleteValue"
          />
        </b-col>
      </b-row>
      <b-row class="pp-planning-table__body">
        <b-col
          v-for="yearData in finishedYearlyPrices"
          :key="`${yearData[0]}${yearData[1]}`"
          cols="2"
          class="pp-planning-table__column"
        >
          <PlanPriceLevelCard
            v-for="monthData in yearData[1]"
            :key="`${monthData[0]}${monthData[1]}`"
            class="pp-planning-table__price-container"
            :month-key="monthData[0]"
            :price-data="monthData[1]"
            :currencies="currencies"
          />
        </b-col>
      </b-row>
    </b-container>
    <PopupModal
      v-if="popupModalVisible"
      v-model:changeRequest="changeRequest"
      :key="componentKey"
      :top="150"
      :left="650"
      :object-name="modalObjectName"
      :user-info="userInfo"
      :modal-action="modalAction"
      @close="popupModalVisible = false"
      @remove-all-requests="removeAllRequests"
      @approve-changes="approveChanges"
      @open-warning-modal="openWarningModal"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import PlanPriceLevelCard from '@/components/PricePlanning/PlanPriceLevelCard.vue';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';

export default {
  name: 'PlanPricePlanning',
  components: { PlanPriceLevelCard, PopupModal },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      loading: true,
      pcv: {},
      currencies: [],
      planPrices: [],
      finishedPrices: [],
      finishedYearlyPrices: [],
      yearlyPlanPrices: [],
      open: false,
      yearModalOpen: [],
      errorMessages: {},
      popupModalVisible: false,
      modalAction: '',
      modalObjectName: '',
      componentKey: Math.random(),
      changeRequest: {},
      defaultPlanPrices: {},
      updated: false,
      main_data_change_request_id: null,
      disabledBefore: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ),
      disabledAfter: new Date(new Date().getFullYear() + 5, 11, 31),
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.getPlanPrices();
    this.loading = false;
  },
  methods: {
    async getPlanPrices() {
      const result = await api.get(`${this.$route.path}`);
      this.updatePricesFrom(result.data);
    },
    updatePricesFrom(data) {
      this.pcv = data.pcv;
      this.currencies = data.currencies;
      this.planPrices = data.plan_prices;
      this.defaultPlanPrices = JSON.parse(JSON.stringify(data.plan_prices));
      this.main_data_change_request_id = data.main_data_change_request_id;

      this.finishedPrices = data.finished;
      const finished = this.groupPricesByYear(this.finishedPrices);
      this.finishedYearlyPrices = this.uniquePricesByYear(finished);

      this.mixInErrorPrices(this.planPrices, this.errorMessages);
      this.updateYearlyPlanPrices(this.planPrices);
      this.prefillYearModalOpen();

      if (this.main_data_change_request_id !== null) {
        this.openWarningModal()
      }
    },
    disabledDates(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    mixInErrorPrices(planPrices, errorMessages) {
      let replacedPrice, replacedPriceEur, replacedSource, currentError;
      planPrices.forEach((priceObj) => {
        const monthKey = priceObj[0];
        if (errorMessages[monthKey]) {
          replacedPrice = priceObj[1].plan_price;
          replacedPriceEur = priceObj[1].plan_price_eur;
          replacedSource = priceObj[1].source;
          currentError = errorMessages[monthKey];
          priceObj[1].source = 'error';
          priceObj[1].plan_price = currentError.plan_price;
          priceObj[1].message = currentError.errors.plan_price[0];
          return;
        }
        if (
          currentError &&
          replacedPrice === priceObj[1].plan_price &&
          replacedPriceEur === priceObj[1].plan_price_eur &&
          replacedSource === priceObj[1].source
        ) {
          priceObj[1].plan_price = currentError.plan_price;
          priceObj[1].message = currentError.errors.plan_price[0];
        } else {
          currentError = null;
        }
      });
    },
    openWarningModal() {
      this.popupModalVisible = true
      this.modalAction = 'openWarningModal';
    },
    returnToList() {
      this.$router.push(
        `/price_plannings/${this.$route.params.price_planning_id}/product_data_change_requests?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
      );
    },
    groupPricesByYear(prices) {
      const pricesHash = prices.reduce((acc, val) => {
        const monthKey = val[0];
        const year = monthKey.slice(0, 4);
        if (!acc[year]) acc[year] = [];
        acc[year].push(val);

        return acc;
      }, {});

      return Object.entries(pricesHash);
    },
    uniquePricesByYear(grouped) {
      let prevMonthObj = {};
      return grouped.map((yearData) => {
        const year = yearData[0];
        const months = yearData[1];
        const uniqueMonths = months.reduce((acc, month) => {
          var plan_price = month[1]['plan_price']
          var prev_plan_price = prevMonthObj['plan_price']

          if (
            parseFloat(plan_price).toFixed(2) !== parseFloat(prev_plan_price).toFixed(2) ||
            month[1]['source'] === 'user' ||
            month[1]['source'] === 'error'
          ) {
            month[1]['previousValue'] = prevMonthObj;
            month[1]['percent'] = month[1]['percent'] || this.diff(plan_price, prev_plan_price);
            acc.push(month);
          } else if (parseFloat(month[1]['rate']).toFixed(2) !== parseFloat(prevMonthObj['rate']).toFixed(2) && plan_price) {
            month[1]['previousValue'] = prevMonthObj;
            month[1]['percent'] = month[1]['percent'] || this.diff(plan_price, prev_plan_price);
            acc.push(month);
          }
          prevMonthObj = month[1];
          return acc;
        }, []);

        return [year, uniqueMonths];
      });
    },
    updateYearlyPlanPrices(prices) {
      const grouped = this.groupPricesByYear(prices);
      this.yearlyPlanPrices = this.uniquePricesByYear(grouped);
    },
    diff(price, prevPrice) {
      if (!prevPrice) return 0.0;

      return +(price / prevPrice - 1).toFixed(4);
    },
    getLastMonthKey() {
      let t = new Date(this.planPrices[this.planPrices.length - 1][0])
      t = new Date(t.setMonth(t.getMonth() + 1))
      t = `${t.getFullYear()}-${t.getMonth() + 1}`

      return t;
    },

    saveValue(monthKey, value, percent, autofill, overwrite, currency) {
      this.updated = true;
      let valueToReplace = null;
      let eurValueToReplace = null;
      let mixedPrices;

      // Combined array of prices, taken from existing(analysis, finished) and newly created items
      let startIndex = this.planPrices.indexOf(this.planPrices.filter(a => a[0] == monthKey)[0]);
      let currentValue = this.planPrices.find(el => el[0] == monthKey)[1]['plan_price']
      let endMonth = this.planPrices.find((el, i) => el[1]['plan_price'] != currentValue && i > startIndex) || this.getLastMonthKey();
      if (overwrite) {
        endMonth = this.getLastMonthKey();
      }

      if (autofill) {
        let i = this.planPrices.map(a => a[0]).indexOf(monthKey);
        const beginningPart = JSON.parse(JSON.stringify(this.planPrices)).slice(0, i);

        let n;
        if (endMonth && !overwrite) {
          n = this.planPrices.map(a => a[0]).indexOf(endMonth);
        } else {
          n = this.planPrices.length;
        }
        const middlePart = JSON.parse(JSON.stringify(this.finishedPrices)).slice(i, n);

        const endingPart = JSON.parse(JSON.stringify(this.planPrices)).slice(n);

        let prevMonthObj = {};
        middlePart.forEach((entry) => {
          entry[1]['percent'] = entry[1]['percent'] || prevMonthObj['percent'] || 0.0;
          this.changeValue(parseFloat(value).toFixed(1), entry[1], currency);
          prevMonthObj = entry[1];
        })

        mixedPrices = Object.assign([], [...beginningPart, ...middlePart, ...endingPart]);
      } else {
        if (overwrite) {
          let i = this.planPrices.map(a => a[0]).indexOf(monthKey);
          const beginningPart = JSON.parse(JSON.stringify(this.planPrices)).slice(0, i);
          let n = this.planPrices.length;
          let middlePart = JSON.parse(JSON.stringify(this.planPrices)).slice(i, n);
          middlePart = middlePart.map(a => [a[0],{ rate: a[1]['rate'] }]);

          mixedPrices = Object.assign([], [...beginningPart, ...middlePart]);
        } else {
          mixedPrices = JSON.parse(JSON.stringify(this.planPrices));
        }
      }

      // Keep uniq values?
      mixedPrices.forEach((entry) => {
        if (endMonth && !overwrite && (new Date(entry[0]) >= new Date(endMonth))) {
          return
        }

        if (
          (valueToReplace !== null &&
            entry[1]['plan_price'] !== valueToReplace) ||
          (eurValueToReplace !== null &&
            entry[1]['plan_price_eur'] !== eurValueToReplace) &&
          (valueToReplace !== null && entry[1]['source'] === 'user')
        ) {
          valueToReplace = null;
          eurValueToReplace = null;
        }
        if (valueToReplace !== null && entry[1]['plan_price'] === valueToReplace) {
          this.changeValue(parseFloat(value).toFixed(2), entry[1], currency);
          entry[1]['source'] = '';
          entry[1]['status'] = 'new';
        }
        if (monthKey === entry[0]) {
          valueToReplace = entry[1]['plan_price'];
          eurValueToReplace = entry[1]['plan_price_eur'];
          this.changeValue(parseFloat(value).toFixed(2), entry[1], currency);
          entry[1]['source'] = '';
          entry[1]['status'] = 'new';
        }
      });

      // Recalculate values according new percentage
      if (autofill) {
        let previousValue = {};
        let newValue = null;
        mixedPrices.forEach((entry) => {
          if (endMonth && !overwrite && (new Date(entry[0]) >= new Date(endMonth))) {
            return
          }
          if (!isNaN(previousValue['percent']) && !isNaN(entry[1]['percent']) && (previousValue['percent'] != entry[1]['percent'])) {
            newValue = +(previousValue['plan_price'] * (1.0 + entry[1]['percent'])).toFixed(2);
          }

          if (newValue) {
            this.changeValue(newValue, entry[1], currency);
            entry[1]['status'] = 'new';
          }
          previousValue = entry[1];
        });
      }

      this.planPrices = [...mixedPrices];
      this.updateYearlyPlanPrices(this.planPrices);
    },
    changeValue(newValue, priceObj, currency) {
      priceObj['plan_price'] = newValue;

      if (priceObj['rate'] && this.currencies.length > 1) {
        priceObj['plan_price_eur'] = (newValue / priceObj['rate']).toFixed(2);
      }
    },
    deleteValue(monthKey){
      this.updated = true;

      let valueToRemove = null;
      let valueTakenFromPrevMonth = {};
      let delMonthKey = null;

      let deleteblaArray = [...this.planPrices]
      deleteblaArray.forEach((entry, idx) => {
        if (entry[0] == monthKey) {
          valueToRemove = entry[1]['plan_price'];

          let prevDate = new Date(new Date(monthKey).getFullYear(), new Date(monthKey).getMonth() - 1);
          let prevMonthKey = `${prevDate.getFullYear()}-${prevDate.getMonth() + 1}`;
          valueTakenFromPrevMonth = this.planPrices.filter(e => e[0] == prevMonthKey)[0][1] || {};
          entry[1] = valueTakenFromPrevMonth;
          delMonthKey = entry[0];
        }

        if (this.deletionValidity(monthKey, entry, delMonthKey, valueToRemove)) {
          entry[1] = valueTakenFromPrevMonth;
          delMonthKey = entry[0];
        }
      });
      this.planPrices = deleteblaArray;
      this.updateYearlyPlanPrices(this.planPrices);
    },
    deletionValidity(monthKey, entry, prevMonthKey, valueToRemove) {
      let isFollowingMonth = null;

      if (prevMonthKey) {
        isFollowingMonth = (new Date(monthKey) < new Date(entry[0])) &&
          this.monthDiff(new Date(entry[0]), new Date(prevMonthKey)) == 1;
      } else {
        isFollowingMonth = false;
      }

      return isFollowingMonth && (parseFloat(valueToRemove).toFixed(2) == parseFloat(entry[1]['plan_price']).toFixed(2));
    },
    monthDiff(d2, d1) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    openDiscardModal() {
      this.popupModalVisible = true;
      this.modalAction = 'removeAll';
      this.modalObjectName = this.pcv.gpms_name;
    },
    removeAllRequests() {
      this.componentKey = Math.random();
      this.planPrices = JSON.parse(JSON.stringify(this.defaultPlanPrices));
      this.popupModalVisible = false;
      this.updated = false;
      this.updateYearlyPlanPrices(this.planPrices);
    },
    prefillYearModalOpen() {
      this.yearlyPlanPrices.forEach((entry, idx) => {
        this.yearModalOpen[idx] = false;
      });
    },
    toggleDatePicker(index) {
      this.yearlyPlanPrices.forEach((entry, idx) => {
        this.yearModalOpen[idx] =
          idx === index && this.yearModalOpen[index] === false;
        // Vue.set(
        //   this.yearModalOpen,
        //   idx,
        //   idx === index && this.yearModalOpen[index] === false
        // );
      });
    },
    hideDatePicker() {
      this.yearlyPlanPrices.forEach((entry, idx) => {
        this.yearModalOpen[idx] = false;
        // Vue.set(this.yearModalOpen, idx, false);
      });
    },
    isDateDisabled(yearData) {
      return (date) => {
        if (date < new Date(yearData[0], 0)) return true;
        if (date > new Date(yearData[0], 11)) return true;
        return yearData[1].some((monthData) => {
          if (new Date(monthData[0]).toDateString() === date.toDateString())
            return true;
          return false;
        });
      };
    },
    addMonth(date) {
      let idx = this.planPrices.findIndex((r) => {
        return new Date(r[0]).toDateString() === date.toDateString();
      });
      let array = JSON.parse(JSON.stringify(this.planPrices))
      array[idx][1]['source'] = 'user';
      array[idx][1]['status'] = 'new';
      this.planPrices = array
      this.updateYearlyPlanPrices(this.planPrices);
      this.hideDatePicker();
    },
    openApproveModal() {
      this.componentKey = Math.random();
      this.changeRequest = this.pcv;
      this.popupModalVisible = true;
      this.modalAction = 'approveFiveYears';
      this.modalObjectName = this.pcv.name;
    },
    async approveChanges() {
      const payload = {
        plan_prices: this.planPrices.map((pp) => [pp[0], pp[1]['plan_price']]),
      };
      this.loading = true;
      this.popupModalVisible = false;

      this.componentKey = Math.random();
      await api
        .patch(`${this.$route.path}`, payload)
        .then((result) => {
          this.updatePricesFrom(result.data);
          this.$helpers.notifyWarning('Saved');
          this.returnToList();
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          if (Object.keys(err.response.data).length > 1) {
            this.errorMessages = err.response.data['errors'] || {};
            this.updatePricesFrom(err.reponse.data, this.errorMessages)
          }
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
          this.returnToList();
        });

      this.loading = false;
    },
  },
};
</script>
