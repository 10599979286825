import { currencyArray } from '@/lib/currencies'
import { countriesArray } from '@/lib/countries'
import { accountTypesMap } from '@/lib/accounts'
import { sources } from '@/lib/competitorsSource'
import { notify } from '@kyvg/vue3-notification'

export function includedInRoleArray(roleArray, accountType) {
  return roleArray?.some(role => accountType?.includes(role))
}

export function getCurrencySymbol(shortName = 'EUR') {
  const result = currencyArray.find(currency => currency.code == shortName)
  return result ? result.symbol : shortName
}

export function valueWithCurrency(value, currencyShort, showSymbol = true) {
  const currency = showSymbol ? getCurrencySymbol(currencyShort) : currencyShort
  return `${value} ${currency}`
}

export function getCountryName(code = 'GE') {
  const result = countriesArray.find(country => country.code == code)
  return result ? result.name : code
}

export function countryNamesArray() {
  return countriesArray
}

export function isDeviationType(fullName, required_type) {
  const rawName = fullName.toLowerCase()
  const gapMatch = rawName.match("gap in")
  const perMatch = rawName.match("per product")
  let retValue = false

  if (!perMatch && gapMatch) {
    const euroMatch = rawName.match("in eur")
    const realMatch = rawName.match("(real dev)")

    if (required_type === 'euroCurrency' && euroMatch && !realMatch) {
      retValue = true
    } else if (required_type === 'euroReal' && euroMatch && realMatch) {
      retValue = true
    } else if (required_type === 'localCurrency' && !euroMatch && !realMatch) {
      retValue = true
    } else if (required_type === 'localReal' && !euroMatch && realMatch) {
      retValue = true
    }
  }

  return retValue
}

export function getTableName(type, switchCurrencyShortName = nil) {
  switch (type) {
    case 'actual_forecasts_in_currency':
      return 'Forecasts in ' + switchCurrencyShortName
    case 'forecasts_in_currency':
      return 'Previous Forecasts in ' + switchCurrencyShortName
    case 'sales_in_currency':
      return 'Sales in ' + switchCurrencyShortName
    case 'budgets_in_currency':
      return 'Budgets in ' + switchCurrencyShortName
    default:
      return type.replace(/_/g, ' ')
  }
}

export function isEmptyArray(array) {
  return Array.isArray(array) && array.length === 0
}

export function uniqArray(array) {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}

export function dateToString(date) {
  if (!(date instanceof Date)) {
    let message = 'date must be an instance of Date'
    notifyError(message)
    throw new Error(message)
  }
  const yyyy = date.getFullYear()
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  return `${yyyy}-${(mm < 10) ? ('0' + mm) : mm}-${(dd < 10) ? ('0' + dd) : dd}`
}

export function monthYear(str) {
  if (str == '') {
    return str
  } else {
    const date = new Date(str)
    const mm = date.getMonth() + 1
    const yy = date.getFullYear()
    return mm + '/' + yy
  }
}

export function dayMonthYear(str) {
  if (str == '') {
    return str
  } else {
    const date = new Date(str);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
  }
}

export function isEmptyObject(obj) {
  if (!obj) {
    return true
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function serialize(obj) {
  if (obj.constructor !== Object) {
    throw new Error('obj must be an instance of Object')
  }
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== '') {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  return str.join('&')
}

export function firstLetterCapital(str) {
  return str[0].toUpperCase() + str.slice(1)
}

export function firstLetterLowercase(str) {
  return str[0].toLowerCase() + str.slice(1)
}

// Beatifies product_variants => Product variants
export function capitalWithoutDashes(str) {
  const firstLetterCapital = str[0].toUpperCase() + str.slice(1)
  return firstLetterCapital.split('_').join(' ')
}

// Thousand number divider
export function numberWithCommas(x) {
  if (Math.abs(x).toFixed().length > 5) x = Math.round(x)
  if (!x && x !== 0) {
    return x
  }

  let valueToFloat = parseFloat(x)
  if (isNaN(valueToFloat)) return x
  return valueToFloat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
}

export function numberWithoutCommas(x) {
  if (Math.abs(x).toFixed().length > 0) x = Math.round(x)
  if (!x && x !== 0) {
    return x
  }

  let valueToFloat = parseFloat(x)
  if (isNaN(valueToFloat)) return x
  return valueToFloat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
}

export function numberWithCommasFloat(x) {
  if (Math.abs(x).toFixed().length > 5) x = Math.round(x)
  if (!x && x !== 0) {
    return x
  }

  return forceZeroes(x)
}

export function forceZeroes(x) {
  let valueToFloat = parseFloat(x)
  if (isNaN(valueToFloat)) return x
  return valueToFloat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// Thousand number divider
export function numberWithCommasAndZeros(x) {
  if (!x && x !== 0) {
    return x
  }

  let valueToFloat = parseFloat(x)
  if (isNaN(valueToFloat)) return x
  return valueToFloat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function restructureAndShowFieldValue(value, type, currencyShort, showCurrencySymbol) {
  let price = {}

  price['value'] = value
  price['type'] = type

  return showFieldValue(price, currencyShort, showCurrencySymbol)
}

// Shows price with a currency or percent sign or just value
export function showFieldValue(price, currencyShort, showCurrencySymbol) {
  let priceHasValue = price => price.value || price.value === 0

  if (!price || !price.type || (!priceHasValue(price))) {
    return '-'
  }

  if (price.type === 'currency') {
    let currency = showCurrencySymbol ? getCurrencySymbol(currencyShort) : currencyShort
    return `${numberWithCommasAndZeros(price.value)} ${currency}`
  } else if (price.type === 'percent') {
    return `${numberWithCommasAndZeros(price.value)}%`
  } else if (price.type === 'number' || price.type === 'text') {
    return numberWithCommasAndZeros(price.value)
  }
}

export function getAvailableDeviation(plannedData, realData) {
  if (isEmptyOrZeroValue(plannedData) || isEmptyOrZeroValue(realData)) return '-'

  const deviation = ((realData.value / plannedData.value - 1) * 100).toFixed(2)
  const intDev = parseInt(deviation)
  const absVal = Math.abs(intDev)

  return absVal > 100 ? (">" + Math.ceil(deviation / 100) * 100 + " %") : (deviation + " %")
}

export function fromCamelCaseToKebabCase(string) {
  string = string[0].toLowerCase() + string.slice(1)
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

// Sort array of objects by property name alphabetically ascending, or add '-' at the start for descending sort
export const dynamicSort = (property) => {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    const propA = a[property].toLowerCase(), propB = b[property].toLowerCase()
    let result
    if (propA < propB) {
      result = -1
    } else if (propA > propB) {
      result = 1
    } else {
      result = 0
    }

    return result * sortOrder
  }
}

export function removeDashes(string) {
  return string.replace(/_/g, " ")
}

function isEmptyOrZeroValue(obj) {
  if (!obj || !obj.value || parseInt(obj.value) == 0) return '-'
}

export function sumArrayValues(array) {
  return Object.values(array).reduce((a, b) => a + b, 0)
}

export function sumArraySingleValue(array, key) {
  return array.reduce(function (a, b) {
    return a + b[key];
  }, 0);
}

export function filterAllowedKeys(array, allowed) {
  return array.map(item => {
    return Object.keys(item)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: item[key],
        }
      }, {})
  })
}

export function setColorByName(label) {
  let color
  const currentYear = new Date().getFullYear();

  if (['sales in currency', 'sales in units', `FC ${currentYear}`].includes(label)) {
    color = '#FFD300'
  } else if (['forecasts in currency', 'forecasts in units', `BU ${currentYear}`].includes(label)) {
    color = '#00457D'
  } else if (['budgets in currency', 'budgets in units', `${currentYear - 2}`].includes(label)) {
    color = '#F39910'
  } else if (['previous forecast in currency', 'previous forecast in units', `${currentYear - 1}`].includes(label)) {
    color = '#77AD1C'
  }
  return color
}

export function setColorByIndex(index) {
  let color
  if (index === 0) {
    color = '#4C89FE'
  } else if (index === 1) {
    color = '#38D4B4'
  } else if (index === 2) {
    color = '#94E563'
  } else if (index === 3) {
    color = '#8D9FFF'
  } else if (index === 4) {
    color = '#58CCFF'
  } else if (index === 5) {
    color = '#FFB571'
  } else if (index === 6) {
    color = '#FFE560'
  } else {
    let colorArray = defaultColorPalette()
    color = colorArray[index]
  }
  return color
}


export function defaultColorPalette() {
  return [
    '#FFE560', '#4C89FE', '#38D4B4', '#58CCFF', '#8D9FFF', '#FFB571',
    '#313645', '#ecdc17', '#ff8752', '#4c4cc9', '#1db9ff', '#76a5af', '#ead1dc', '#ffd966', '#0c343d', '#fff2cc',
    '#90a68d', '#f2e5d5', '#f2b279', '#d99b84', '#d97b73', '#ce7e00', '#ffe599', '#8fce00', '#b4a7d6', '#0c343d',
    '#F44336', '#FFEBEE', '#FFCDD2', '#EF9A9A', '#CFD8DC', '#B0BEC5', '#90A4AE', '#E0E0E0', '#BDBDBD', '#78909C',
    '#E57373', '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828', '#B71C1C', '#FF8A80', '#FF5252', '#FF1744',
    '#D50000', '#E91E63', '#FCE4EC', '#F8BBD0', '#F48FB1', '#F06292', '#EC407A', '#E91E63', '#D81B60', '#C2185B',
    '#283593', '#1A237E', '#8C9EFF', '#536DFE', '#3D5AFE', '#304FFE', '#2196F3', '#E3F2FD', '#BBDEFB', '#90CAF9',
    '#64B5F6', '#42A5F5', '#2196F3', '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#82B1FF', '#448AFF', '#2979FF',
    '#2962FF', '#03A9F4', '#E1F5FE', '#B3E5FC', '#81D4FA', '#4FC3F7', '#29B6F6', '#03A9F4', '#039BE5', '#0288D1',
    '#A5D6A7', '#81C784', '#66BB6A', '#4CAF50', '#43A047', '#388E3C', '#2E7D32', '#1B5E20', '#B9F6CA', '#69F0AE',
    '#00E676', '#00C853', '#8BC34A', '#F1F8E9', '#DCEDC8', '#C5E1A5', '#AED581', '#9CCC65', '#8BC34A', '#9E9E9E',
    '#7CB342', '#689F38', '#558B2F', '#33691E', '#CCFF90', '#B2FF59', '#76FF03', '#64DD17', '#FAFAFA', '#F5F5F5',
    '#CDDC39', '#F9FBE7', '#F0F4C3', '#E6EE9C', '#DCE775', '#D4E157', '#CDDC39', '#C0CA33', '#AFB42B', '#9E9D24',
    '#827717', '#F4FF81', '#EEFF41', '#C6FF00', '#AEEA00', '#FFEB3B', '#FFFDE7', '#FFF9C4', '#FFF59D', '#FFF176',
    '#FFEE58', '#FFEB3B', '#FDD835', '#FBC02D', '#F9A825', '#F57F17', '#FFFF8D', '#FFFF00', '#616161', '#424242',
    '#FFEA00', '#FFD600', '#FFC107', '#FFF8E1', '#FFECB3', '#FFE082', '#FFD54F', '#FFCA28', '#FFC107', '#FFB300',
    '#FFA000', '#FF8F00', '#FF6F00', '#FFE57F', '#FFD740', '#FFC400', '#FFAB00', '#FF9800', '#FFF3E0', '#FFE0B2',
    '#FFCC80', '#FFB74D', '#FFA726', '#FF9800', '#FB8C00', '#F57C00', '#EF6C00', '#E65100', '#FFD180', '#FFAB40',
    '#FF9100', '#FF6D00', '#FF5722', '#FBE9E7', '#FFCCBC', '#FFAB91', '#FF8A65', '#FF7043', '#37474F', '#263238',
    '#FF5722', '#F4511E', '#E64A19', '#D84315', '#BF360C', '#FF9E80', '#FF6E40', '#FF3D00', '#DD2C00', '#795548',
    '#EFEBE9', '#D7CCC8', '#BCAAA4', '#A1887F', '#8D6E63', '#795548', '#6D4C41', '#5D4037', '#4E342E', '#3E2723',
  ]
}

export function isCurrenciesListPage(path) {
  return path == '/currencies'
}

export function dateToLocaleDate(updatedAt) {
  const date = new Date(updatedAt)
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Berlin' };
  return date.toLocaleDateString('en-EN', dateOptions)
}

export function fieldToSpecificName(fieldStr) {
  if (fieldStr === 'exch_fixed') {
    return 'LE exchange rate'
  } else if (fieldStr === 'exch_bu') {
    return 'BU exchange rate'
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function localizeTimeBerlin(date) {
  return new Date(date).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
}

export function groupBy(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function hasAdminAccess(user) {
  return includedInRoleArray(['admin', 'gpo', 'dcr_admin'], user.account_types)
}

export function hasCurrencyApproveAccess(types) {
  return includedInRoleArray(['mob', 'mob_dep', 'mob_gb_dep', 'mob_gb', 'dcr_admin'], types)
}

export function hasCurrencyRequestFinishAccess(type) {
  return includedInRoleArray(['gpo', 'mob_dep', 'mob', 'mob_gb', 'cfo',
    'controlling_manager', 'country_support_manager', 'global_foreast_manager', 'dcr_admin',
    'head_of_global_marketing', 'country_head', 'deputy_country_head', 'head_of_marketing'], type)
}

export function hasPricePlanningAccess(type) {
  return includedInRoleArray(['admin', 'brand_manager', 'area_sales_manager',
    'head_of_marketing', 'marketing_analyst', 'distribution_manager', 'dcr_admin',
    'country_head', 'deputy_country_head', 'gpo', 'mob', 'mob_dep', 'mob_gb', 'mob_gb_dep'], type)
}

export function accountTypeToAbbreviation(term) {
  return accountTypesMap[term]
}

export function getIconStatusClass(booleanValue) {
  if (booleanValue) {
    return 'icon principles-ok-icon'
  } else {
    return 'icon principles-wrong-icon'
  }
}

export function getStatusClass(booleanValue) {
  if (booleanValue) {
    return 'icon ok-big-icon'
  } else {
    return 'icon wrong-big-icon'
  }
}

export function getStatusColor(status) {
  if (['finished', 'finished_one'].includes(status)) {
    return '#007bff'
  } else if (['approved', 'approved_one', 'approved_two'].includes(status)) {
    return '#77ad1c'
  } else if (status == 'rejected') {
    return '#ffc107'
  } else if (status == 'initialized') {
    return '#839AB5'
  } else {
    return ''
  }
}

export function formatStatus(status) {
  if (status == 'approved_one') {
    return 'Approved #1'
  } else if (status == 'approved_two') {
    return 'Approved #2'
  } else if (status == 'finished_one') {
    return 'Finished #1'
  } else if (status == 'finished_two') {
    return 'Finished #2'
  } else if (status == '') {
    return ''
  } else {
    return firstLetterCapital(status)
  }
}

export function defineQuote(data) {
  let source = sources[data.country?.name]
  return source ? source : 'Source: IQVIA'
}

export function statusLineWithReviewerRole(item) {
  if (!isEmptyArray(item.data_change_request)) {
    if (item.status == 'approved_two') {
      return formatStatus(item.status) + ' (by ' +
        humanizeUserRole(item.data_change_request.corrections[0].user.account_types) + ', ' +
        humanizeUserRole(item.data_change_request.corrections[1].user.account_types) + ')'
    } else {
      return formatStatus(item.status) + byRoleLine(item.data_change_request.corrections[0].user.account_types)
    }
  } else {
    return formatStatus(item.status)
  }
}

export function byRoleLine(accountTypes) {
  return ' (by ' + humanizeUserRole(accountTypes) + ')'
}

export function humanizeUserRole(roles) {
  return Object.keys(accountTypesMap).find(key => accountTypesMap[key] === roles[0])
}

export function toAbc(num) {
  let arr = {
    1: 'JAN', 2: 'FEB', 3: 'MAR', 4: 'APR', 5: 'MAY', 6: 'JUN',
    7: 'JUL', 8: 'AUG', 9: 'SEP', 10: 'OCT', 11: 'NOV', 12: 'DEC'
  }
  return arr[num]
}

export function toString(data) {
  return JSON.stringify(data);
}

export function notifyError(message) {
  notify({ title: "Error!", type: 'error', text: message, duration: 7000 });
}

export function notifySuccess(message) {
  notify({ title: "Success!", type: 'success', text: message, duration: 7000 });
}

export function notifyWarning(message) {
  notify({ title: "Warning!", type: 'warn', text: message, duration: 7000 });
}

export function toHandleDate(date) {
  return date.toISOString().split('T')[0];
}

export function toNewArr(arr) {
  return JSON.parse(JSON.stringify(arr));
}

export function fileContentType(file_ext) {
  const contentTypeLookup = {
    pdf:  'application/pdf',
    xls:  'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    png:  'image/png',
    jpg:  'image/jpeg',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  return contentTypeLookup[fileExt];
}
