<template>
  <div class="container">
    <div
      class="hierarchy-item-title fixed"
      :class="{ scrolled: currentScrollPos < 40 }"
    >
      <h1 :class="{ loading }">
        {{
          data.full_name
            ? data.full_name
            : data.name
            ? data.name
            : data.is_bundle_pack
            ? data.sap_name
            : data.gpms_name
        }}
      </h1>
    </div>

    <div :class="'grid-container-customers mb-30'">
      <div class="grid-info-card mb-30">
        <InfoCard
          v-if="Object.keys(data).length"
          :data="data"
          :columns-number="1"
        />
      </div>
      <div class="grid-graph mb-30">
        <div class="hierarchy-item-chart" style="height: auto">
          <div style="margin-top: 5.3%">
            <Chart
              object="sales"
              :show-switch="true"
              :style-object="{ width: '100%' }"
              :quote="$helpers.defineQuote(data)"
              :page-name="data.name"
            />
          </div>
        </div>
      </div>
    </div>

    <b-card no-body class="tabs-card">
      <b-tabs
        card
        content-class="mt-3"
        fill
        active-nav-item-class="font-weight-bold text-uppercase"
      >
        <b-tab no-body>
          <template #title>
            <b-spinner
              v-if="loading"
              type="border"
              small
              class="tab-spinner"
            />

            <svg
              v-else
              style="margin-right: 10px"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                :fill="'#839AB5'"
              />
            </svg>
            <div class="tab-title">Products</div>
          </template>
          <div
            v-if="data['profit_center_variants']"
            class="row small-table-row"
          >
            <div class="col-md-12">
              <HierarchyItemTab
                v-if="data['profit_center_variants']"
                :items="data['profit_center_variants']"
                :table-sortable="'status'"
                :table-keys="[
                  'name',
                  'month_eur',
                  'month_units',
                  'ytd_eur',
                  'ytd_units',
                  'status',
                  'visible',
                ]"
                :name="'profit_center_variants'"
                :object-type="itemType"
                :object-id="itemId"
                :show-title="false"
              />
            </div>
          </div>
        </b-tab>
        <b-tab no-body>
          <template #title>
            <b-spinner
              v-if="loading"
              type="border"
              small
              class="tab-spinner"
            />
            <svg
              v-else
              style="margin-right: 10px"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                :fill="'#839AB5'"
              />
            </svg>
            <div class="tab-title">Brands</div>
          </template>
          <div v-if="data['brands']" class="row small-table-row">
            <div class="col-md-12">
              <HierarchyItemTab
                v-if="data['brands']"
                :items="data['brands']"
                :table-keys="[
                  'name',
                  'month_eur',
                  'month_units',
                  'ytd_eur',
                  'ytd_units',
                  'visible',
                ]"
                :name="'brands'"
                :object-type="itemType"
                :object-id="itemId"
                :show-title="false"
              />
            </div>
          </div>
        </b-tab>
        <b-tab no-body>
          <template #title>
            <b-spinner
              v-if="loading"
              type="border"
              small
              class="tab-spinner"
            />
            <svg
              v-else
              style="margin-right: 10px"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8756 1.60024L14.7594 3.20557C15.1838 3.56727 15.7115 3.78586 16.2674 3.83022L18.7346 4.0271C19.3954 4.07984 19.9202 4.60459 19.9729 5.26543L20.1698 7.73259C20.2142 8.28847 20.4327 8.81619 20.7944 9.24063L22.3998 11.1244C22.8298 11.629 22.8298 12.3711 22.3998 12.8757L20.7944 14.7594C20.4327 15.1839 20.2142 15.7116 20.1698 16.2675L19.9729 18.7346C19.9202 19.3954 19.3954 19.9202 18.7346 19.9729L16.2674 20.1698C15.7115 20.2142 15.1838 20.4328 14.7594 20.7945L12.8756 22.3998C12.3711 22.8298 11.6289 22.8298 11.1244 22.3998L9.2406 20.7945C8.81616 20.4328 8.28844 20.2142 7.73256 20.1698L5.2654 19.9729C4.60457 19.9202 4.07981 19.3954 4.02707 18.7346L3.83019 16.2675C3.78583 15.7116 3.56724 15.1839 3.20554 14.7594L1.60021 12.8757C1.17022 12.3711 1.17022 11.629 1.60021 11.1244L3.20554 9.24063C3.56724 8.81619 3.78583 8.28847 3.83019 7.73259L4.02707 5.26543C4.07981 4.6046 4.60456 4.07984 5.2654 4.0271L7.73256 3.83022C8.28844 3.78586 8.81616 3.56727 9.2406 3.20557L11.1244 1.60024C11.6289 1.17025 12.3711 1.17025 12.8756 1.60024Z"
                stroke="#839AB5"
                stroke-width="1.3"
                fill-opacity="0.0"
              />
              <path
                d="M9.22461 16.3231C9.46484 16.3231 9.61133 16.2352 9.78125 15.9715L12.3887 12.116L14.832 8.53009C14.9199 8.39532 14.9668 8.27228 14.9668 8.13165C14.9668 7.83282 14.7266 7.61603 14.416 7.61603C14.1406 7.61603 14 7.70978 13.8184 7.97931L11.3047 11.7059L8.7793 15.4031C8.69141 15.532 8.64453 15.6434 8.64453 15.8133C8.64453 16.1297 8.91992 16.3231 9.22461 16.3231ZM8.75586 12.1805C9.92773 12.1805 10.666 11.3133 10.666 9.89532C10.666 8.45978 9.92188 7.62775 8.75586 7.62775C7.58398 7.62775 6.83984 8.46564 6.83984 9.89532C6.83984 11.3192 7.57812 12.1805 8.75586 12.1805ZM8.75586 11.2313C8.3457 11.2313 8.09375 10.8094 8.09375 9.89532C8.09375 8.98126 8.35156 8.57697 8.75586 8.57697C9.16016 8.57697 9.41211 8.98126 9.41211 9.89532C9.41211 10.8094 9.16016 11.2313 8.75586 11.2313ZM14.873 16.3172C16.0508 16.3172 16.7891 15.45 16.7891 14.0262C16.7891 12.5906 16.0391 11.7586 14.873 11.7586C13.707 11.7586 12.957 12.5965 12.957 14.0262C12.957 15.4559 13.6953 16.3172 14.873 16.3172ZM14.873 15.368C14.4688 15.368 14.2109 14.9402 14.2109 14.032C14.2109 13.1121 14.4688 12.7078 14.873 12.7078C15.2773 12.7078 15.5352 13.1121 15.5352 14.0262C15.5352 14.9402 15.2773 15.368 14.873 15.368Z"
                fill="#839AB5"
              />
            </svg>
            <div class="tab-title">Discounts & Sales Deductions</div>
          </template>
          <div
            v-if="data['discounts_and_deductions']"
            class="row small-table-row"
          >
            <div class="col-md-12">
              <div class="row mb-30 infoHeader">
                <h3 class="infoTitle">
                  Please note: The information on this page is still under
                  review.
                </h3>
              </div>

              <HierarchyItemTab
                v-if="data['discounts_and_deductions']"
                :items="data['discounts_and_deductions']"
                :table-sortable="'status'"
                :table-keys="[
                  'name',
                  'amount_in_eur_month',
                  'amount_in_percent_month',
                  'amount_in_eur_ytd',
                  'amount_in_percent_ytd',
                ]"
                name="Discount in invoice & Sales deductions"
                :object-type="'customers'"
                :show-charts="false"
                :show-analysis-modal="true"
                :show-title="false"
                :show-total="false"
              />
            </div>
          </div>
        </b-tab>

        <b-tab no-body>
          <template #title>
            <b-spinner
              v-if="loading"
              type="border"
              small
              class="tab-spinner"
            />
            <svg
              v-else
              style="margin-right: 10px"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.73082 0.835571C5.11052 0.835571 5.41832 1.14338 5.41832 1.52307V1.81078C7.24117 2.1362 8.6249 3.72942 8.6249 5.64582V6.5637C8.6249 6.9434 8.31709 7.2512 7.9374 7.2512C7.5577 7.2512 7.2499 6.9434 7.2499 6.5637V5.64582C7.2499 4.49258 6.47549 3.52022 5.41832 3.22038V9.56931L6.17902 9.94023C7.67551 10.6699 8.6249 12.189 8.62491 13.8539L8.62491 14.3346C8.62491 16.2499 7.24227 17.844 5.41832 18.1696V18.477C5.41832 18.8567 5.11052 19.1645 4.73082 19.1645C4.35112 19.1645 4.04332 18.8567 4.04332 18.477V18.1703C2.21983 17.8465 0.833252 16.2533 0.833252 14.3346V13.4355C0.833252 13.0558 1.14106 12.748 1.52075 12.748C1.90045 12.748 2.20825 13.0558 2.20825 13.4355V14.3346C2.20825 15.4897 2.98491 16.4625 4.04332 16.761V10.4286L3.27914 10.056C1.78265 9.32635 0.833252 7.80719 0.833252 6.14229V5.64582C0.833252 3.7282 2.21875 2.13417 4.04332 1.81016V1.52307C4.04332 1.14338 4.35112 0.835571 4.73082 0.835571ZM2.20825 5.64582C2.20825 4.49132 2.98437 3.51808 4.04332 3.2194V8.89888L3.88175 8.8201C2.85784 8.32086 2.20825 7.28144 2.20825 6.14229V5.64582ZM5.41832 16.76V11.0991L5.57641 11.1761C6.60032 11.6754 7.2499 12.7148 7.24991 13.8539L7.24991 14.3346C7.24991 15.4872 6.47604 16.46 5.41832 16.76Z"
                :fill="'#839AB5'"
              />
              <path
                d="M12.2916 10C12.2916 9.84556 12.295 9.69244 12.3018 9.5408L13.8941 9.54168C14.2738 9.54189 14.5818 9.23426 14.582 8.85456C14.5822 8.47486 14.2746 8.16689 13.8949 8.16668L12.4587 8.16589C12.6848 6.95354 13.1281 5.8832 13.7119 5.04561C14.5776 3.80356 15.7067 3.125 16.8749 3.125C17.3028 3.125 17.7669 3.24989 18.1008 3.46991C18.4179 3.67883 18.8443 3.59116 19.0532 3.2741C19.2621 2.95705 19.1744 2.53067 18.8574 2.32175C18.2675 1.93306 17.5315 1.75 16.8749 1.75C15.1321 1.75 13.6259 2.76436 12.5839 4.2594C11.837 5.33098 11.3052 6.67621 11.0632 8.16512L9.77131 8.16441C9.39161 8.1642 9.08364 8.47183 9.08343 8.85153C9.08322 9.23123 9.39085 9.5392 9.77055 9.53941L10.9256 9.54004C10.9196 9.69241 10.9166 9.84577 10.9166 10C10.9166 10.1537 10.9196 10.3065 10.9256 10.4584L9.77093 10.4584C9.39124 10.4584 9.08343 10.7662 9.08343 11.1459C9.08343 11.5256 9.39123 11.8334 9.77093 11.8334L11.063 11.8334C11.3049 13.3229 11.8367 14.6687 12.5839 15.7406C13.6259 17.2356 15.1321 18.25 16.8749 18.25C17.5124 18.25 18.2659 18.096 18.8753 17.6721C19.187 17.4553 19.2639 17.0268 19.047 16.7151C18.8302 16.4034 18.4018 16.3265 18.0901 16.5434C17.7756 16.7621 17.322 16.875 16.8749 16.875C15.7067 16.875 14.5776 16.1964 13.7119 14.9544C13.128 14.1166 12.6846 13.046 12.4585 11.8334L13.8945 11.8334C14.2742 11.8334 14.582 11.5256 14.582 11.1459C14.582 10.7662 14.2742 10.4584 13.8945 10.4584L12.3017 10.4584C12.295 10.307 12.2916 10.1542 12.2916 10Z"
                :fill="'#839AB5'"
              />
            </svg>
            <div class="tab-title">Currency exchange rate effects</div>
          </template>
          <div
            v-if="data['currency_exchange_effects']"
            class="row small-table-row"
          >
            <div class="col-md-12">
              <HierarchyItemTab
                v-if="data['currency_exchange_effects']"
                :items="data['currency_exchange_effects']"
                :table-keys="[
                  'currency',
                  'sales_effect_month',
                  'sales_effect_ytg',
                ]"
                :name="'sales_exchange_rate_effects'"
                :use-stacked-chart="true"
                :show-title="false"
                :show-total="false"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <div
      v-if="!pagesStack.length"
      class="card price-build-table"
    >
      <div class="price-build-header">
        <h2 class="display-block">Price Build up</h2>
      </div>
      <div class="analysis-row">
        <AnalysisPriceBuildupTable
          v-if="data['price_buildups']"
          :price-info="data['price_buildups']"
          :exchange-rate-type-options="data['price_info_currencies']"
          :loading-price-buildup-currency="loadingPriceBuildupCurrency"
          :currency="countryPriceBuildupCurrency"
          @stack-page="stackPage"
          @select-price-buildup-currency="selectPriceBuildupCurrency"
        />
      </div>
    </div>

    <div class="row small-table-row" style="margin-top: 50px">
      <HierarchyItemDetailsTable
        v-if="data[hierarchy[$route.path.split('/')[1]]]"
        :items="data[hierarchy[$route.path.split('/')[1]]]"
        :name="hierarchy[$route.path.split('/')[1]]"
      />
    </div>

    <div v-show="pagesStack.length" class="analysis">
      <button
        class="button-no-style link d-flex align-items-center card analysis-back-button"
        @click="unstackPage"
      >
        <span class="text-uppercase me-2">Details</span>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :style="{ width: '10px', height: '20px' }"
        >
          <path
            d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
            fill="#9DAFC2"
          />
        </svg>
      </button>

      <div v-if="pagesStack.length" class="analysis-row">
        <div
          v-show="pagesStack.length"
          ref="linePdfRoot"
          class="analysis-content"
          :loading="loading"
        >
          <Chart
            class="w-100"
            object="analysisLineChart"
            :title="currentDetailsPage[0]"
            :pages-stack="pagesStack[0][1]"
            :ids="currentDetailsIds"
            :pdf-root-ref="$refs.linePdfRoot"
            :show-additional-info="true"
            :fetched-results-type="dataType"
            search-path="/profit_center_variants"
            force-pcv-graph
            :override-currency="countryPriceBuildupCurrency"
          />
        </div>
      </div>

      <div class="analysis-row">
        <div
          v-show="pagesStack.length"
          ref="pdfRoot"
          class="analysis-content"
          :loading="loading"
        >
          <div
            v-if="
              pagesStack.length &&
              data['price_buildups'][currentDetailsPage[0]].type !== 'text'
            "
            class="analysis-row"
          >
            <AnalysisDetailsChart
              v-if="!$helpers.isEmptyArray(currentDetailsItems)"
              :title="currentDetailsPage[0]"
              :items="currentDetailsItems"
              :data-type="dataType"
              :pdf-root-ref="$refs.pdfRoot"
              :currency="countryPriceBuildupCurrency"
            />
          </div>
          <div class="analysis-row">
            <AnalysisDetailsTable
              v-if="currentDetailsPage"
              :loading="false"
              :title="currentDetailsPage[0]"
              :table-style="{ width: '100%' }"
              :items="currentDetailsItems"
              :data-type="dataType"
              :currency="countryPriceBuildupCurrency"
              @scroll-to-top="scrollToTop"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard.vue';
import Chart from '@/components/Chart/index.vue';
import HierarchyItemDetailsTable from '@/components/HierarchyItemList/HierarchyItemDetailsTable.vue';
import HierarchyItemTab from '@/components/HierarchyItemList/HierarchyItemTab.vue';
import { hierarchy, currenciesShortISO4217 } from '@/helpers/constants';
import api from '@/helpers/api';
import AnalysisPriceBuildupTable from '@/components/Search/AnalysisPriceBuildupTable.vue';
import AnalysisDetailsChart from '@/components/Search/AnalysisDetailsChart.vue';
import AnalysisDetailsTable from '@/components/Search/AnalysisDetailsTable.vue';

export default {
  name: 'HierarchyItemCustomerDetails',
  components: {
    InfoCard,
    Chart,
    HierarchyItemDetailsTable,
    HierarchyItemTab,
    AnalysisPriceBuildupTable,
    AnalysisDetailsChart,
    AnalysisDetailsTable
  },
  props: {
    currentScrollPos: Number,
  },
  emits: ['close'],
  data() {
    return {
      data: {},
      hierarchy,
      loading: false,
      currency: {
        value: '',
        options: currenciesShortISO4217,
      },
      pagesStack: [],
      items: [],
      currentDetailsItems: [],
      countryPriceBuildupCurrency: 'EUR',
      currentDetailsIds: [],
      loadingPriceBuildupCurrency: false,
    };
  },
  computed: {
    itemType() {
      return this.$route.path.split('/')[1];
    },
    itemId() {
      return this.$route.path.split('/')[2];
    },
    currentDetailsPage() {
      return this.pagesStack[this.pagesStack.length - 1];
    },
    dataType() {
      return this.data['price_buildups'][this.currentDetailsPage[0]].type;
    },
  },

  async beforeMount() {
    this.loading = true;

    await this.getData();

    this.loading = false;
  },

  methods: {
    stackPage(page) {
      this.pagesStack.push(page);
      const plannedItems =
        this.data['price_buildups'][this.currentDetailsPage[0]];
      const realItems =
        this.data['price_buildups'][this.currentDetailsPage[0] + ' real'];
      this.items = {
        planned: plannedItems.items.sort((a, b) => a.value - b.value),
        real: realItems
          ? realItems.items.sort((a, b) => a.value - b.value)
          : [],
      };
      this.setCurrentDetailsItems(this.items);
    },

    unstackPage() {
      if (this.pagesStack.length) {
        this.pagesStack.pop();
      } else {
        this.$emit('close');
      }
    },

    setCurrentDetailsItems(currentDetailsItems) {
      this.currentDetailsItems = currentDetailsItems;
      this.currentDetailsIds = currentDetailsItems.planned.map(
        (item) => item.id
      );
    },

    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.analysis.scrollTop = 0;
      });
    },

    async getData() {
      const response = await api.get(`${this.$route.path}.json`);
      this.data = response.data.data;
    },

    async selectPriceBuildupCurrency(currency) {
      this.loadingPriceBuildupCurrency = true;
      const response = await api.get(
        `/countries/${this.data.id}/price_buildups?currency_short=${currency}`
      );
      this.data.price_buildups = response.data.price_buildups;
      this.countryPriceBuildupCurrency = currency;
      this.loadingPriceBuildupCurrency = false;
    },
  },
};
</script>
