<template>
  <div class="table-card little-table-container">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="m-25">
      <div>
        <h5>Currency effect date:</h5>
      </div>

      <div class="dashboard-table-header">
        <div>
          <DatePicker
            v-model:value="exchangeRateEffectDate"
            :clearable="false"
            type="date"
            :disabled-date="disabledEndDate"
            placeholder="Date Select"
            @change="getCurrencyData"
          >
            <template #icon-calendar>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                  :fill="
                    range[0] !== null && range[1] !== null
                      ? '#003B6A'
                      : '#839AB5'
                  "
                />
              </svg>
            </template>
            <template #icon-clear>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                  fill="#003B6A"
                />
              </svg>
            </template>
          </DatePicker>
        </div>
        <div>
          <Multiselect
            v-if="!$helpers.isEmptyObject(filterOptions)"
            v-model="selectedFilter"
            :options="filterOptions"
            select-label
            placeholder
            :show-labels="false"
            class="display-inline"
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" @click="refreshOptions" />
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
    </div>
    <h4 class="info-quote">
      Effect calculation is based on the whole month sales amount
    </h4>
    <h4 class="info-quote">Last updated on {{ updatedAt }}</h4>
    <table
      v-if="!$helpers.isEmptyArray(currencyEffects.results)"
      class="table table-striped-small borderless"
    >
      <thead>
        <tr>
          <th>Currency</th>
          <th>Actual exchange rate</th>
          <th>LE exchange rate effect</th>
        </tr>
      </thead>
      <tbody class="scrollbar">
        <tr
          v-for="currency in currencyEffects.results"
          :key="`${currency.name}${currency.short_name}${currency.exch_rate_actual}`"
        >
          <td class="link" @click="$router.push(`/currencies/${currency.id}`)">
            {{ currency.name }} ({{ currency.short_name }})
          </td>
          <td>{{ currency.exch_rate_actual }}</td>
          <td>
            {{ $helpers.numberWithCommas(currency.exch_rate_effect, 'EUR') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '@/helpers/api';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
export default {
  name: 'ExchangeRateEffects',
  components: { FadeLoader },
  props: {
    items: {},
    preSelectedFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      exchangeRateEffectDate: new Date(),
      disabledBefore: new Date(2012, 0, 1),
      disabledAfter: new Date(),
      currencyEffects: {
        type: Object,
        default: null,
      },
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      selectedFilter: '',
      tableSortable: 'type',
      filterOptions: [],
    };
  },
  computed: {
    filteredResults() {
      var result = [];

      if (this.selectedFilter !== 'Show all') {
        result = this.items.filter(
          (row) => row[this.tableSortable] == this.selectedFilter
        );
      } else {
        result = this.items;
      }
      return result;
    },
    updatedAt() {
      if (
        this.currencyEffects.results &&
        !this.$helpers.isEmptyArray(this.currencyEffects.results)
      ) {
        const uniqueData = [
          ...new Set(
            this.currencyEffects.results.map((item) => item.updated_at)
          ),
        ];
        return this.$helpers.dateToLocaleDate(
          uniqueData.map((date) => new Date(date)).sort((a, b) => b - a)[0]
        );
      } else {
        return '-';
      }
    },
  },
  watch: {
    async selectedFilter() {
      await this.getCurrencyData();
    },
    async items() {
      await this.getCurrencyData();
    },
    preSelectedFilter() {
      if (this.preSelectedFilter != '') {
        this.selectedFilter = this.preSelectedFilter;
      }
    },
  },
  async mounted() {
    if (this.preSelectedFilter != '') {
      this.selectedFilter = this.preSelectedFilter;
    } else {
      this.selectedFilter = 'Show all';
    }

    if (this.items.length > 0) {
      let array = ['Show all'];
      this.items.map((item) => array.push(item[this.tableSortable]));
      this.filterOptions = [...new Set(array)];
    }
  },
  methods: {
    async getCurrencyData() {
      this.loading = true;

      let array = this.filterData();

      const response = await api.post(
        `/currencies/daily_exchange_rate_effects`,
        JSON.stringify({
          date: this.$helpers.dateToString(this.exchangeRateEffectDate),
        ids: array.map((i) => i.id)
        })
      );

      this.currencyEffects = response.data
      this.loading = false;
    },

    async refreshOptions() {
      if (this.filteredResults.length > 0 && this.filterOptions.length == 0) {
        let array = ['Show all'];
        this.filteredResults.map((item) =>
          array.push(item[this.tableSortable])
        );
        this.filterOptions = [...new Set(array)];
      }
    },

    filterData() {
      let array = [];
      if (this.selectedFilter !== 'Show all') {
        array = this.items.filter(
          (row) => row[this.tableSortable] == this.selectedFilter
        );
      } else {
        array = this.items;
      }
      return array;
    },

    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    }
  },
};
</script>
