<template>
  <div class="comment" :class="withImage ? '' : 'comment-with-avatar'">
    <div class="comment-author-and-date">
      <i v-if="withImage" class="icon comment-avatar" />
      <div class="comment-header">
        <div class="comment-author-info">
          <span class="comment-author">{{ commentData.author }}</span
          >, <span class="comment-position">{{ commentData.position }}</span>
        </div>
        <div class="comment-date">
          <span>{{ commentData.date }}</span>
        </div>
      </div>
    </div>
    <div v-if="isEditable" class="comment-edit">
      <textarea
        v-model="editInput"
        class="comment-input"
        placeholder="Add a comment..."
      />
      <div class="actions-area">
        <span class="save-comment-icon" @click="updateComment">
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 24 24">
              <path
                fill="#96DE5C"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
              />
            </svg>
          </i>
        </span>
        <span class="close-comment-icon" @click="onClose">
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </i>
        </span>
      </div>
    </div>

    <div v-else class="comment-show">
      <p class="comment-text mb-0" style="overflow-wrap: break-word;">
        {{ commentData.text }}
      </p>
      <div class="actions-area">
        <span
          v-if="editable && hasAccess()"
          class="edit-comment-icon"
          @click="onEdit"
        >
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 24 24">
              <path
                fill="#96DE5C"
                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
              />
            </svg>
          </i>
        </span>
        <span
          v-if="withRemove && hasAccess()"
          ref="confirmIcon"
          class="remove-comment-icon"
          @click="showConfirmModal"
        >
          <i class="svg-icon link">
            <svg class="flex-svg-icon" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
              />
            </svg>
          </i>
        </span>
      </div>
    </div>
    <CommentsModal
      v-if="confirmModalVisible"
      :top="confirmIconTop"
      :left="confirmIconLeft + 120"
      :container-width="200"
      @close="onCloseConfirmModal"
    >
      <div class="confirm-container">
        <span class="comment-number">This message will be removed</span>
        <div class="confirm-actions">
          <button class="btn btn-green" @click="onDelete">Yes</button>
          <button class="button-no-style" @click="confirmModalVisible = false">
            Cancel
          </button>
        </div>
      </div>
    </CommentsModal>
  </div>
</template>

<script>
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';

export default {
  name: 'Comment',
  components: { CommentsModal },
  props: {
    userInfo: Object,
    commentData: Object,
    withImage: Boolean,
    withRemove: Boolean,
    editable: Boolean,
  },
  emits: ['updateComment', 'onDelete'],
  data() {
    return {
      confirmModalVisible: false,
      confirmIconTop: 0,
      confirmIconLeft: 0,
      isEditable: false,
      editInput: '',
    };
  },

  methods: {
    hasAccess() {
      return (
        this.userInfo.id === this.commentData.author_id ||
        this.$helpers.hasAdminAccess(this.userInfo)
      );
    },
    showConfirmModal() {
      this.confirmIconTop = this.$refs.confirmIcon.getBoundingClientRect().top;
      this.confirmIconLeft =
        this.$refs.confirmIcon.getBoundingClientRect().left;
      this.confirmModalVisible = true;
    },
    onEdit() {
      this.editInput = this.commentData.text || '';
      this.isEditable = true;
    },
    updateComment() {
      this.$emit('updateComment', this.commentData.id, this.editInput);
      this.onClose();
    },
    onClose() {
      this.isEditable = false;
    },
    onDelete() {
      this.$emit('onDelete', this.commentData.id);
      this.onCloseConfirmModal();
    },
    onCloseConfirmModal() {
      this.confirmModalVisible = false;
    },
  },
};
</script>
