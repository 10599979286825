<template>
  <AutoComplete
    v-model="query"
    :input-id="name"
    aria-label="Search"
    :delay="500"
    :placeholder="placeholder"
    :suggestions="suggestions"
    :class="baseClass || 'autocomplete'"
    :input-class="`autocomplete-input ${inputClass || ''}`"
    :panel-class="`autocomplete-result-list ${panelClass || ''}`"
    @focus="(e) => $emit('focusEvent', e)"
    @blur="(e) => $emit('blurEvent', e)"
    @complete="search($event)"
    @item-select="
      (e) => {
        $emit('submit', e.value || query);
        if (clearOnSelect) query = '';
      }
    "
  />
</template>

<script>
export default {
  name: 'AutocompleteBar',
  props: {
    searchArray: Array,
    name: String,
    baseClass: String,
    inputClass: String,
    panelClass: String,
    focusEvent: Function,
    blurEvent: Function,
    clearOnSelect: Boolean,
    placeholder: {
      type: String,
      default: () => 'Search',
    },
  },
  emits: ['submit', 'focusEvent', 'blurEvent'],
  data() {
    return {
      query: null,
      suggestions: null,
    };
  },
  methods: {
    search(event) {
      if (!event.query.trim().length) {
        this.suggestions = [...this.searchArray];
      } else {
        this.suggestions = [...this.searchArray].filter((country) => {
          return country.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
  },
};
</script>
