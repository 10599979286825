<template>
  <transition name="modal">
    <div>
      <div @click.self="$emit('close')">
        <div
          :class="{
            'max-content': data?.maxContent,
            [parentClass]: parentClass,
          }"
        >
          <div
            :class="['slide-modal-body', { open }]"
            :style="{
              marginLeft: indentWidth + 'px',
              width: windowWidth - indentWidth + 'px',
            }"
          >
            <slot> default body </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { navbarWidth, collapsedNavbarWidth } from '@/helpers/constants';

export default {
  name: 'AnalysisModal',
  props: {
    data: Object,
    open: Boolean,
    collapsed: Boolean,
    parentClass: String,
  },
  emits: ['close'],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    indentWidth() {
      const extraSpace = 100;
      return extraSpace + (this.collapsed ? collapsedNavbarWidth : navbarWidth);
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>
