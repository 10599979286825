import { notifyError } from '@/helpers/index';

export function processUserUpdateError(e) {
  let message = 'Unknown error.'

  try {
    message = JSON.stringify(e.response.data.message).replace(/[\]\[\\\"\{\}]/gi,'')
  } catch {
    console.log('Error recieving error message from backend...')
  }

  notifyError(`There has been an error updating your account data: ${message} Please try again!`)
}
