<template>
  <div class="profit-center-variant-detail price-change-request" style="margin: 60px 80px 60px 0px !important;">
    <div class="d-flex align-items-start">
      <div class="card" style="margin-right: 0px !important">
        <div class="table-responsive overflow-x-clip">
          <table class="table table-notstriped-details borderless">
            <thead>
              <tr>
                <th
                  v-for="(key, i) in tableKeys"
                  :key="`${key}${$route.path}`"
                  scope="col"
                  class="clickable link"
                  @click="sortByRow(key)"
                >
                  <span class="display-inline" style="font-size: 15px !important">
                    {{
                      $helpers
                        .capitalWithoutDashes(key)
                    }}
                  </span>
                  <span
                    class="display-inline"
                    style="vertical-align: middle; margin-left: 10px"
                  >
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-down-arrow open-details-cell-icon clickable link"
                      :class="{
                        'rotate-tab-arrow': sortDir === 'desc' && sortKey === key,
                      }"
                    >
                      <path
                        d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                        fill="#9CB2CB"
                      />
                    </svg>
                  </span>
                </th>
                <th>actions</th>
              </tr>
            </thead>

            <tbody>
              <template
                v-for="(item, idx) in filteredResults"
                :key="`${item.name}${item.id}${idx}`"
              >
                <tr>
                  <td @click="openDetailsPopup(item)">
                    <div class="link-with-hover link_underlined">{{ item.name }}</div>
                  </td>
                  <td style="background: #f4fcee" @click="openDetailsPopup(item)">
                    <div class="link-with-hover link_underlined">{{ item.id }}</div>
                  </td>
                  <td style="background: #f4fcee">
                    {{ item.state }}
                  </td>
                  <td style="background: #f4fcee">
                    {{ item.created_at }}
                  </td>
                  <td style="background: #f4fcee">
                    {{ item.updated_at }}
                  </td>
                  <td style="background: #f4fcee">
                    {{ item.duration }}
                  </td>
                  <td style="background: #f4fcee">
                    {{ item.created_by }}
                  </td>
                  <td style="background: #f4fcee">
                      <button
                        :disabled="!(item.state == 'initialized')"
                        class="btn btn-success btn-sm ms-10 with-tooltip"
                        @click="$emit('executeProcessorChain', idx)"
                      >
                        <i class="icon mark-icon" />
                      </button>

                      <button
                        :disabled="!(item.state == 'initialized')"
                        class="btn btn-warning btn-sm ms-10 with-tooltip"
                        @click="$emit('removeProcessorChain', item.id)"
                      >
                        <i class="icon reject-icon" />
                      </button>

                      <label class="btn btn-outline-secondary btn-sm ms-10">
                        <RequestComments
                          style="padding-right: -10px"
                          :request="item"
                          :user-info="userInfo"
                          :commentable-type="'Hub'"
                        />
                      </label>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <button
        class="btn btn-green load-more-button mt-3"
        @click="$emit('loadMore')"
      >
        <span>Load more</span>
      </button>
    </div>
    <DetailsPopup
      v-if="detailsPopupVisible"
      :item="detailsItem"
      @close="closeModal"/>
  </div>
</template>


<script>
import { defineAsyncComponent } from 'vue';
import RequestComments from '@/components/PricePlanning/RequestComments.vue';
import DetailsPopup from '@/components/ProcessorSequences/DetailsPopup.vue';

export default {
  name: 'ListPage',
  components: {
    RequestComments,
    DetailsPopup
  },
  props: {
    items: Array,
    name: String,
    tableKeys: Array,
    userInfo: Object,
  },
  data() {
    return {
      detailsItem: {},
      detailsPopupVisible: false,
      sortKey: 'created at',
      sortDir: 'desc',
    };
  },
  computed: {
    filteredResults() {
      let result = [];
      result = this.items;
      result = result.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      return result;
    },
  },
  methods: {
    openDetailsPopup(item) {
      this.detailsItem = item;
      this.detailsPopupVisible = true;
    },
    closeModal() {
      this.detailsItem = {};
      this.detailsPopupVisible = false;
    },
    sortByRow(key) {
      this.$emit('close-filters');
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },
  },
};
</script>
