<template>
  <div class="profit-center-variant-detail price-change-request" style="margin: 60px 80px 60px 0px !important;">
    <div class="card info-card p-0 m-0">
      <span class="object-information">
        {{ title }}
        <div class="display-inline">
          <button
            v-if="
              showCreate &&
              $helpers.includedInRoleArray(
                ['dcr_admin', 'head_of_marketing', 'brand_manager', 'area_sales_manager'],
                userInfo.account_types
              ) &&
              !item.data_change_request
            "
            v-floating-tooltip="'Initialize request'"
            class="btn btn-warning"
            @click="$emit('createParentRequest')"
            style="min-width: 41px; min-height:41px;"
          >
            <i class="icon plus-icon" />
          </button>

          <button
            v-floating-tooltip="'Submit request'"
            class="btn btn-success ms-10"
            @click="$emit('submitParentRequest', item.data_change_request)"
            style="min-width: 41px; min-height:41px;"
            :disabled="!submitButtonVisible()"
          >
            <i class="icon mark-icon" />
          </button>

          <button
            :disabled="!discardButtonVisible()"
            v-floating-tooltip="'Remove request'"
            class="btn btn-danger ms-10"
            @click="$emit('openDiscardModal', item.data_change_request)"
            style="min-width: 41px; min-height:41px;"
          >
            <i class="icon trash-icon" />
          </button>

          <button
            v-if="attachmentsButtonVisible()"
            :disabled="!item.data_change_request"
            v-floating-tooltip="'View attachments'"
            class="btn btn-attachment ms-10"
            @click="$emit('openAttachmentsModal', item.data_change_request)"
            style="min-width: 41px; min-height: 41px;"
          >
            <i class="icon attachment-icon" />
          </button>
        </div>

        <span 
            v-if="showStatusNotification()" 
            class="title"
            :class="{ longTitle: true }"
            style="color: rgb(119, 173, 28); padding-left: 10px;"
          >
            {{ $helpers.formatStatus(item.data_change_request.status) }}
          </span>
      </span>
      <div
        class="info"
        :style="{
          gridTemplateRows: `repeat(${2}, auto)`,
          gridTemplateColumns: `repeat(${2}, minmax(0, 1fr))`,
        }"
      >
        <div class="card-item">
          <span class="title" :class="{ longTitle: true }">
            <IconText title="Brand" icon="brand-icon" />
          </span>
          <span class="pricing-info-card-value">
            {{ item.name }}
          </span>
        </div>

        <div class="card-item">
          <span class="title" :class="{ longTitle: true }">
            <IconText title="Price Change Status" icon="status-icon" />
          </span>
          <span v-if="item.data_change_request" class="pricing-info-card-value">
            <div
              v-floating-tooltip="statusComment(item)"
              class="with-tooltip"
              :style="`color: ${$helpers.getStatusColor(
                item.data_change_request.status
              )};`"
            >
              {{ $helpers.formatStatus(item.data_change_request.status) }}
              <i
                v-if="
                  item.data_change_request.correction &&
                  item.data_change_request.corrections[0].comment.length > 0
                "
                class="icon search-loope-icon display-inline"
              />
            </div>
          </span>
        </div>

        <div class="card-item">
          <span class="title" :class="{ longTitle: true }">
            <IconText title="Reviewed" icon="status-icon" />
          </span>
          <span class="pricing-info-card-value">
            <i :class="$helpers.getStatusClass(item.reviewed)" />
          </span>
        </div>

        <div
          v-if="item.data_change_request && item.data_change_request.comments"
          class="card-item"
        >
          <span class="title" :class="{ longTitle: true }">
            <IconText title="Comments" icon="status-icon" />
          </span>
          <span
            class="pricing-info-card-value" style="margin-left: -8px !important; white-space: normal;">
            <RequestComments
              :class="'ms-10'"
              :request="item.data_change_request"
              :user-info="userInfo"
              :commentable-type="commentableType"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import RequestComments from '@/components/PricePlanning/RequestComments.vue';

export default {
  name: 'HeaderModal',
  components: { IconText, RequestComments },
  props: {
    item: Object,
    showCreate: Boolean,
    showSubmit: Boolean,
    title: String,
    userInfo: Object,
    commentableType: String,
  },
  emits: ['clearFilters', 'createParentRequest', 'submitParentRequest', 'openAttachmentsModal'],
  data() {
    return {};
  },
  methods: {
    clearFilters() {
      this.$emit('clearFilters');
    },
    statusComment(item) {
      if (item.data_change_request.corrections) {
        return item.data_change_request.corrections[0].comment;
      }
    },
    submitButtonVisible() {
      let condition1 =
        this.submitButtonVisibleCondition() &&
        this.$helpers.includedInRoleArray(
          ['dcr_admin', 'head_of_marketing'],
          this.userInfo.account_types
        ) &&
        this.$route.path.includes('brand_data_change_requests');

      let condition2 =
        this.submitButtonVisibleCondition() &&
        this.$helpers.includedInRoleArray(
          ['dcr_admin', 'brand_manager', 'area_sales_manager'],
          this.userInfo.account_types
        ) &&
        this.$route.path.includes('product_data_change_requests');

      if (condition1 || condition2) {
        return true;
      } else {
        return false;
      }
    },
    submitButtonVisibleCondition() {
      return this.showSubmit && this.item.data_change_request &&
        ['rejected', 'initialized'].includes(this.item.data_change_request.status)
    },
    showStatusNotification() {
      return this.item.data_change_request &&
        ['submitted', 'approved', 'approved_one', 'approved_two'].includes(this.item.data_change_request.status)
    },
    discardButtonVisible() {
      let condition1 = this.$helpers.includedInRoleArray(['dcr_admin', 'brand_manager', 'area_sales_manager', 'gpo', 'admin'], this.userInfo.account_types) && this.item.data_change_request && this.$route.path.includes('product_data_change_requests');
      let condition2 = this.$helpers.includedInRoleArray(['dcr_admin', 'brand_manager', 'area_sales_manager', 'head_of_marketing', 'country_head', 'gpo', 'admin'], this.userInfo.account_types) && this.item.data_change_request && this.$route.path.includes('brand_data_change_requests');
      if (condition1 || condition2) {
        return true;
      } else {
        return false;
      }
    },
    attachmentsButtonVisible() {
      return this.item.data_change_request && this.item.data_change_request.changeable_type == 'Country';
    },
  },
};
</script>
