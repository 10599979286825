<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          class="comments-modal-container change-request-modal"
          style="width: 70% !important; margin-left: 0% !important;"
          :class="{ 'max-content': true }"
          :style="{
            width: '65%',
            alignSelf: 'center',
            marginLeft: '0%',
            marginTop: '-20%',
            height: '650px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="closePopup()"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <div class="form-group" style="width: 50%; display: flex; align-items: center;">
              <input v-model="name" class="form-control text-input" placeholder="Enter sequence name" style="flex: 1;" />
              <button
                :disabled="name.length == 0"
                class="btn btn-success ms-30"
                @click="createProcessorChain()"
                v-floating-tooltip="saveButtonTooltip"
              >
                Save
              </button>
            </div>

            <div>
              <div v-for="(item, blockIndex) in itemsBlock" class="item-block-chain">
                <div class="d-flex justify-content-between align-items-start">
                  <div class="block-number">#{{ blockIndex + 1 }}</div>
                  <button
                    v-floating-tooltip="'Remove block'"
                    class="btn btn-danger ms-20"
                    @click="removeBlock(blockIndex)"
                  >
                    <i class="icon trash-icon" />
                  </button>
                </div>
                <div v-for="(item, index) in optionsList[blockIndex].blocks" class="multiselect-row">
                  <Multiselect
                    v-model="value[blockIndex].blocks[index]"
                    placeholder="Select Processor"
                    label="name"
                    :options="options[blockIndex]"
                    :multiple="false"
                    :taggable="false"
                    @select="selectedProccessor(blockIndex ,$event)"
                    class="wider-multiselect"
                  >
                    <template #option="{ option }">
                      <span class="multiselect__element" v-floating-tooltip="option.description">{{ option.name }}</span>
                    </template>
                  </Multiselect>
                  <div v-for="(option, optionIndex) in value[blockIndex]?.blocks[index]?.options">
                    <DatePicker
                      v-if="dateTypes(option)"
                      v-model:value="dates[blockIndex].blocks[index].date[optionIndex]"
                      type="date"
                      :clearable="false"
                      :placeholder="this.value[blockIndex].blocks[index]?.options[optionIndex]"
                      @change="selectDate(index, optionIndex, blockIndex)"
                      style="margin-left: 10px;"
                    />
                    <input
                      style="margin-left: 10px;"
                      v-if="numericTypes(option)"
                      :placeholder="option"
                      type="number"
                      class="mx-input-wrapper multiselect-input"
                      @blur="handleInput(blockIndex, index, optionIndex, $event)"
                    />
                    <Multiselect
                      v-if="!dateTypes(option) && !isDate(option) && !numericTypes(option) && !isNumeric(option) && !isArray(option)"
                      v-model="value[blockIndex].blocks[index].options[optionIndex].values"
                      :placeholder="Object.keys(option)[0]"
                      :options="option[Object.keys(option)[0]]"
                      :multiple="true"
                      :taggable="false"
                      @select="selectOptions(blockIndex, index, optionIndex)"
                      class="wider-multiselect"
                      style="max-width: 230px !important; margin-left:20px;"
                    />
                  </div>
                  <svg
                    @click="removeItem(index, blockIndex)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                    v-floating-tooltip="'Remove processor from the block'"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#EC4899"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
                <svg
                  @click="addItem(blockIndex)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
                  style="margin-left: 5px;"
                  v-floating-tooltip="'Add another processor to the block'"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="green"
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

              </div>
              <button id="add-item" class="btn btn-attachment" @click="addBlock()" style="margin-left: 10px;" v-floating-tooltip="'Add new block'">
                Add Block
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/helpers/api';

export default {
  name: 'NewSequencePopup',
  props: {
    processorOptions: Array,
    userInfo: Object,
  },
  data() {
    return {
      itemsBlock: [],
      optionsList: [],
      value: [],
      options: [],
      dates: [],
      selected: [],
      lastSelected: null,
      name: '',
    };
  },
  mounted() {
    this.options.push(JSON.parse(JSON.stringify(this.processorOptions)));
    this.optionsList.push({ blocks: [{}] });
    this.itemsBlock.push({});
    this.value.push({ blocks: [{}] });
    this.selected.push({blocks: []});
    this.dates.push({blocks: []});
  },
  computed: {
    saveButtonTooltip() {
      let tooltip
      if (this.name.length > 0)
        tooltip = 'Save processor sequence without launching it';
      else
        tooltip = 'Enter name';
      return tooltip
    }
  },
  methods: {
    addBlock() {
      this.value.push({ blocks: [{}] });
      this.itemsBlock.push({});
      this.options.push(JSON.parse(JSON.stringify(this.processorOptions)));
      this.optionsList.push({ blocks: [{}] });
      this.selected.push({blocks: []});
      this.dates.push({blocks: []});
    },
    removeBlock(idx) {
      this.value.splice(idx, 1);
      this.itemsBlock.splice(idx, 1);
      this.options.splice(idx, 1);
      this.optionsList.splice(idx, 1);
      this.selected.splice(idx, 1);
      this.dates.splice(idx, 1);
    },
    selectedProccessor(blockIndex, event) {
      const selectedValue = event.options;
      const filteredArrayLength = selectedValue.filter((item) => this.dateKeys().includes(item)).length;
      this.dates[blockIndex].blocks.push({ date: []});
    },
    closePopup() {
      this.$emit('close');
    },
    addItem(blockIndex) {
      this.optionsList[blockIndex].blocks.push({});
      const lastItem = this.value[blockIndex].blocks[this.value[blockIndex].blocks.length - 1];
      const updatedOptions = this.options[blockIndex].filter(item => item !== lastItem);
      this.options[blockIndex] = updatedOptions;
    },
    handleInput(blockIndex, index, optionIndex, event) {
      const inputValue = event.target.value;
      const inputName = event.target.placeholder;
      this.selected[blockIndex].blocks.push({ optionIndex, inputName });
      this.value[blockIndex].blocks[index].options.push({ values: [inputValue] });
    },
    async createProcessorChain() {
      this.lastSelected = JSON.parse(JSON.stringify(this.selected));
      let hub_process_queue = this.parseHubProcessQueue();
      await api
        .post('/processor_sequences',
              JSON.stringify({
                hub: {
                  name: this.name,
                  hub_process_queue: hub_process_queue,
                  created_by: this.userInfo.id
                }
              })
             )
        .then(_response => {
          this.clearData();
          this.closePopup();
          this.$emit('saved');
          this.$helpers.notifySuccess('Chain Proccessor successfully created!');
        })
        .catch(err => {
          this.selected = JSON.parse(JSON.stringify(this.lastSelected));
          const errorField = err.response.data;
          this.error = errorField;
          this.$helpers.notifyError(errorField);
        });
    },
    parseHubProcessQueue(){
      const hubProcessQueue = this.value.map((item, blockIndex) => {
        const blocks = item.blocks.map((block) => {
          const options = block.options.length > 0 ? this.nullOptions(block.options, blockIndex) : undefined;

          return {
            processor: block.full_name,
            options: options
          };
        })

        return blocks;
      });

      return hubProcessQueue;
    },
    selectOptions(blockIndex, index, optionIndex) {
      const optionKey = Object.keys(this.value[blockIndex].blocks[index].options[optionIndex])[0];
      const isKeyPresent = this.selected[blockIndex].blocks.some(item => item.optionKey === optionKey && item.index === index);
      if (!isKeyPresent) {
        this.selected[blockIndex].blocks.push({ optionIndex, optionKey, index });
      }
    },
    selectDate(index, optionIndex, blockIndex) {
      const optionKey = this.value[blockIndex].blocks[index].options[optionIndex];
      const optIndex = optionIndex;
      this.selected[blockIndex].blocks.push({ optIndex, optionKey });
      const optionValue = new Date(this.dates[blockIndex].blocks[index].date[optionIndex]);
      this.value[blockIndex].blocks[index].options.push({ [optionKey]: optionValue, values: [optionValue] });
    },
    removeItem(index, blockIndex) {
      if(this.optionsList[blockIndex].blocks.length > 1) {
        if (typeof this.value[blockIndex].blocks[index] !== 'undefined') {
          this.options[blockIndex].splice(index, 0, this.value[blockIndex].blocks[index]);
        }
        this.value[blockIndex].blocks.splice(index, 1);
        this.optionsList[blockIndex].blocks.splice(index, 1);
      }
    },
    dateTypes(options) {
      return this.dateKeys().includes(options)
    },
    clearData() {
      this.optionsList = null;
      this.value = null;
      this.options = null;
      this.dates = null;
      this.selected = null;
      this.lastSelected = null;
    },
    numericTypes(options) {
      return this.numericKeys().includes(options)
    },
    isDate(options) {
      return options.values ? options.values[0] instanceof Date : false
    },
    isNumeric(options) {
      return  options.values ? !isNaN(options.values[0]) : false
    },
    isArray(options) {
      return this.arrayTypes().includes(options)
    },
    createParams(convertedItem) {
      if(convertedItem.values.length > 1) {
        return convertedItem.values.map(value => value.toString());
      }else{
        return convertedItem.values[0]
      }
    },
    nullOptions(options, index) {
      if(options.length === 0){
        return [];
      }else{
        return this.parseArrayOptions(options, index)
      }
    },
    parseArrayOptions(options, blockIndex) {
      let counter = 0
      const filteredArray = options.filter(item => !this.singleFilterKeys().includes(item) && !this.arrayTypes().includes(item));
      const convertedArray = filteredArray.map((item, index) => {
        counter += 1
        const newObj = {
          values: { [Object.keys(item)[0]]: this.createParams(item) },
        };
        return newObj;
      });

      const hash = convertedArray.reduce((acc, curr) => {
      const key = Object.keys(curr.values)[0];
      const value = curr.values[key];
        return { ...acc, [key]: value };
      }, {});

      this.selected[blockIndex].blocks = this.selected[blockIndex].blocks.slice(counter)

      return hash;
    },
    dateKeys() {
      return ['import_from','from', 'to', 'import_to']
    },
    singleFilterKeys() {
      return ['import_from', 'from', 'to', 'import_to', 'job_size', 'from_year', 'data_type', 'historic_years']
    },
    numericKeys() {
      return ['job_size', 'from_year', 'historic_years']
    },
    arrayTypes() {
      return ['ids', 'pcv_ids', 'pcs_to_import']
    }
  },
};
</script>
