<template>
  <div>
    <DatePicker
      v-if="showCalendar"
      v-model:value="range"
      :clearable="false"
      type="date"
      range
      :disabled-date="disabledEndDate"
      placeholder="Data Select"
      @change="getAndRedraw"
    >
      <template #icon-calendar>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
            :fill="
              range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
            "
          />
        </svg>
      </template>
      <template #icon-clear>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path
            d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
            fill="#003B6A"
          />
        </svg>
      </template>
    </DatePicker>
    <div id="chartdiv" class="chartdiv" />
    <h4 class="info-quote">Click on tooltip to open product details.</h4>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5pie from '@amcharts/amcharts5/percent';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { getCountryName } from '@/helpers';

export default {
  name: 'WholesalerChart',
  props: {
    startDate: Date,
    endDate: Date,
    searchPath: String,
    object: String,
    showCalendar: Boolean,
    objectType: String,
    objectId: {
      type: String,
      default: '',
    },
    objectName: String,
    fetchedResultsType: String,
    union: {
      type: String,
      default: null,
    },
    selectedFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: {},
      dataCollection: [],
      range: [
        this.startDate
          ? this.startDate
          : new Date(new Date().getFullYear(), 0, 1),
        this.endDate ? this.endDate : new Date(),
      ],
      loading: false,
    };
  },
  created() {
    this.chart = {};
    this.xAxis = {};
    this.yAxis = {};
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  async mounted() {
    await this.getData(
      this.$helpers.dateToString(this.range[0]),
      this.$helpers.dateToString(this.range[1])
    );
    this.root = am5.Root.new('chartdiv');
    this.buildChart();
    this.addAxises();
    this.convertToDataCollection();
    this.fillData();
    this.addCursor();
    this.addLegend();
    this.chart.appear(1000, 100);
  },
  methods: {
    redraw() {
      this.reloadChart();
      this.convertToDataCollection();
      this.fillData();
      this.reloadLegend();
    },
    async getAndRedraw() {
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1])
      );
      this.redraw();
    },
    async getData(fromDate, toDate) {
      this.loading = true;

      let result;

      var path = this.searchPath || this.$route.path;
      result = await fetch(
        `${path}/graph_data?object=${this.object}&from_date=${fromDate}&to_date=${toDate}&type=${this.currencyType}&currency_short_name=${this.switchCurrencyShortName}&object_type=${this.objectType}&id=${this.objectId}&condition_type=${this.conditionType}&object_name=${this.objectName}&buildup_data_type=${this.buildupDataType}&union=${this.union}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      );
      this.data = await result.json();
      this.loading = false;
    },
    convertToDataCollection() {
      this.dataCollection = [];
      const resultsObject = this.data.results;
      let dateKeys = Object.keys(resultsObject);
      let results = Object.values(resultsObject);
      let names = [...new Set(results.map((r) => Object.keys(r)).flat())];

      names.forEach((name) => {
        this.dataCollection[name] = [];
        dateKeys.forEach((dateKey) => {
          let year = dateKey.split('-')[0];
          let month = dateKey.split('-')[1];
          let date = new Date(year, month - 1, 1);
          date.setHours(0, 0, 0, 0);
          am5.time.add(date, 'day', 1);
          this.dataCollection[name].push({
            date: date.getTime(),
            value: resultsObject[dateKey][name],
          });
        });
      });
    },
    buildChart() {
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panY: false,
          wheelY: 'zoomX',
          layout: this.root.verticalLayout,
          maxTooltipDistance: 0,
        })
      );

      this.chart
        .get('colors')
        .set('colors', [
          am5.color(0x095256),
          am5.color(0x087f8c),
          am5.color(0x5aaa95),
          am5.color(0x86a873),
          am5.color(0xbb9f06),
          am5.color(0x2b4584),
          am5.color(0x30547a),
          am5.color(0x356370),
          am5.color(0x3b7266),
          am5.color(0x40805c),
          am5.color(0x2b4584),
          am5.color(0x458f52),
          am5.color(0x4a9e48),
          am5.color(0x2b4584),
          am5.color(0x1cc747),
          am5.color(0x29a95c),
          am5.color(0x4e4d99),
        ]);
    },
    addAxises() {
      this.yAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          extraTooltipPrecision: 1,
          numberFormat: "#.00'%'",
          renderer: am5xy.AxisRendererY.new(this.root, {}),
        })
      );

      this.xAxis = this.chart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          baseInterval: { timeUnit: 'month', count: 1 },
          renderer: am5xy.AxisRendererX.new(this.root, {
            minGridDistance: 50,
          }),
        })
      );
    },
    fillData() {
      let productKeys = Object.keys(this.dataCollection);
      productKeys.forEach((key) => {
        this.createSeries(this.dataCollection[key], key);
      });
    },
    createSeries(data, key) {
      let countryName =
        this.$helpers.getCountryName(key.slice(-2)) || key.slice(-2);
      let tooltip = am5.Tooltip.new(this.root, {
        labelText: '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}',
      });
      let productId = key.split('::')[0];
      tooltip.events.on(
        'click',
        function (e) {
          this.$router.push(`/profit_center_variants/${productId}`);
        },
        this
      );
      var series = this.chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: countryName + ' - ' + key.split('::')[1],
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: 'value',
          valueXField: 'date',
          calculateAggregates: true,
          tooltip: tooltip,
          legendLabelText: '[{stroke}]{name}:[/]',
          legendValueText: '{valueY}%',
          legendRangeValueText: '{valueYClose}%',
        })
      );

      series.data.setAll(data);
    },
    addCursor() {
      this.chart.set(
        'cursor',
        am5xy.XYCursor.new(this.root, {
          behavior: 'zoomXY',
          xAxis: this.xAxis,
        })
      );
    },
    addLegend() {
      this.legend = this.chart.rightAxesContainer.children.push(
        am5.Legend.new(this.root, {
          width: 300,
          paddingLeft: 15,
          height: am5.percent(100),
          centerY: am5.percent(50),
          y: am5.percent(50),
          layout: this.root.verticalLayout,
          verticalScrollbar: am5.Scrollbar.new(this.root, {
            orientation: 'vertical',
          }),
        })
      );

      this.legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: '300',
      });

      var chart = this.chart;
      this.legend.itemContainers.template.events.on(
        'pointerover',
        function (e) {
          var itemContainer = e.target;

          // As series list is data of a legend, dataContext is series
          var series = itemContainer.dataItem.dataContext;

          chart.series.each(function (chartSeries) {
            if (chartSeries != series) {
              chartSeries.strokes.template.setAll({
                strokeOpacity: 0.15,
                stroke: am5.color(0x000000),
              });
            } else {
              chartSeries.strokes.template.setAll({
                strokeWidth: 3,
              });
            }
          });
        }
      );

      this.legend.itemContainers.template.events.on('pointerout', function (e) {
        var itemContainer = e.target;
        var series = itemContainer.dataItem.dataContext;

        chart.series.each(function (chartSeries) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: chartSeries.get('fill'),
          });
        });
      });

      this.legend.itemContainers.template.set('width', am5.p100);
      this.legend.valueLabels.template.setAll({
        width: am5.p100,
        textAlign: 'right',
      });

      this.legend.data.setAll(this.chart.series.values);
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    reloadChart() {
      while (this.chart.series.length > 0) {
        this.chart.series.removeIndex(0).dispose();
      }
    },
    reloadLegend() {
      while (this.legend.data.length > 0) {
        this.legend.data.removeIndex(0).dispose();
      }
      this.legend.data.setAll(this.chart.series.values);
    },
  },
};
</script>

<style>
#chartdiv {
  width: 100%;
  height: 600px;
  max-width: 100%;
}
</style>
