<template>
  <div class="card">
    <div class="chart-block" style="box-shadow: unset">
      <div class="chart-header">
        <span>
          <h2 class="m-0">{{ name }}</h2>
        </span>
      </div>
    </div>

    <HierarchyItemDetailsTable
      v-if="detailsModalVisible"
      :items="brandsList"
      :name="'brands'"
      :show-title="false"
    />
    <table
      class="table table-striped-small borderless little-table"
      style="margin-top: 1rem"
    >
      <thead>
        <tr>
          <th
            v-for="(tableKey, i) in tableKeys"
            :key="tableKey + $route.path"
            class="clickable link"
            :style="{
              width: getHeaderNameWidth(i),
              textAlign: getTextAlign(i, tableKeys.length),
            }"
            scope="col"
            @click="sortByRow(tableKey)"
          >
            <span class="display-inline" style="font-size: 15px !important">
              {{
                $helpers
                  .capitalWithoutDashes(tableKey)
                  .replace('percent', '%')
                  .replace('eur', 'EUR')
                  .replace('ytd', 'YTD')
                  .replace('ytg', 'YTG')
                  .replace('Ytd', 'YTD')
              }}
            </span>
            <span class="display-inline">
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-down-arrow open-details-cell-icon clickable link"
                :class="{
                  'rotate-tab-arrow':
                    sortDir === 'desc' && sortKey === tableKey,
                }"
              >
                <path
                  d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                  fill="#9CB2CB"
                />
              </svg>
            </span>
          </th>
          <th />
        </tr>
      </thead>

      <tbody class="scrollbar" style="height: 300px">
        <template v-for="row in filteredResults" :key="row.name + $route.path">
          <tr :style="rowStyle(row)">
            <td
              v-for="(tableKey, index) in tableKeys"
              :key="tableKey + row[tableKey] + $route.path"
              :class="
                index === 0 && row[tableKey] !== 'Total'
                  ? 'link-with-hover'
                  : ''
              "
              :style="{
                width: getHeaderNameWidth(index),
                textAlign: getTextAlign(index, tableKeys.length),
              }"
            >
              <span class="display-inline">{{ row[tableKey] }}</span>
            </td>
            <td>
              <span v-if="row.name !== 'Total'" @click="openDetailsModal(row)">
                <i class="icon link ms-10" />
                <div class="info-quote-clickable">Show details</div>
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import HierarchyItemDetailsTable from '@/components/HierarchyItemList/HierarchyItemDetailsTable.vue';

export default {
  name: 'HierarchyItemCompetitorsTable',
  components: {
    HierarchyItemDetailsTable,
  },
  props: {
    items: Array,
    name: String,
    tableKeys: Array,
  },
  data() {
    return {
      sortKey: 'ytd_eur',
      sortDir: 'desc',
      detailsModalVisible: false,
      brands: [],
    };
  },
  computed: {
    brandsList() {
      return this.brands.map((item) => {
        return [item.id, item.full_name];
      });
    },

    filteredResults() {
      let result = this.items;
      result = result.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      result = result.sort((a, b) => {
        return b.visible - a.visible;
      });
      return result;
    },
  },
  methods: {
    openDetailsModal(row) {
      this.brands = row.brands;
      this.detailsModalVisible = true;
    },
    getHeaderNameWidth(index) {
      if (index === 0 && this.$helpers.isCurrenciesListPage(this.$route.path)) {
        return '200px';
      }
    },
    getTextAlign(index, length) {
      if (index + 1 === length) {
        return 'center';
      } else if (index > 0) {
        return 'right';
      } else {
        return 'left';
      }
    },
    rowStyle(row) {
      if (row.visible === false) {
        return 'background: #EEEEEE';
      } else {
        return '';
      }
    },
    sortByRow(key) {
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },
  },
};
</script>
