<template>
  <div v-if="!loading" :class="{ loading }" :style="tableStyle">
    <div class="card table-card mb-30">
      <div class="price-build-header">
        <span>
          <h4 class="m-0">Current valid values list in {{ sign }}</h4>
        </span>
        <div class="d-flex align-items-center justify-content-between">
          <div
            class="hierarchy-item-currency-selector hierarchy-item-exchange-rate-type-selector"
          >
            <div class="switch-radio-buttons">
              <ToggleSwitch
                v-model="buildupDataType"
                :show-switch="true"
                :switch-options="buildupDataOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-striped borderless table-card-table analysis-details-table"
        >
          <thead>
            <tr>
              <th scope="col">
                <IconText title="ID" icon="id-icon" />
              </th>
              <th scope="col">
                <IconText
                  title="Product name"
                  icon="profit-center-variant-icon"
                />
              </th>
              <th scope="col">
                <IconText :title="`${title} in ${sign}`" />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in filteredData" :key="item.id">
              <td>{{ item.sap_id }}</td>
              <td
                class="link"
                @click="$router.push(`profit_center_variants/${item.id}`)"
              >
                {{ item.gpms_name }}
              </td>
              <td>
                {{
                  $helpers.forceZeroes($helpers.numberWithCommas(item.value)) ||
                  '-'
                }}{{ sign }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      v-if="pageCount > 1"
      :page-number="pageNumber"
      :page-count="pageCount"
      @load-page="loadPage"
    />
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';
import Pagination from '@/components/Pagination.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'AnalysisDetailsTable',
  components: {
    Pagination,
    IconText,
    ToggleSwitch,
  },
  props: {
    items: {},
    title: String,
    dataType: String,
    tableStyle: Object,
    loading: Boolean,
    currency: {
      type: String,
      default: 'EUR',
    },
  },
  emits: ['setCurrentDetailsItems', 'scrollToTop'],
  data() {
    return {
      buildupDataOptions: ['real', 'planned'],
      buildupDataType: 'planned',
      currentItems: [],
      pageCount: 1,
      pageNumber: 1,
    };
  },
  computed: {
    filteredData() {
      return this.items[this.buildupDataType];
    },
    sign() {
      return this.dataType == 'percent'
        ? ' %'
        : ` ${this.$helpers.getCurrencySymbol(this.currency)}`;
    },
  },
  mounted() {
    this.pageCount = Math.ceil(this.filteredData.length / 20);
    this.$emit('setCurrentDetailsItems', this.filteredData);
  },

  methods: {
    loadPage(page) {
      if (page > this.pageNumber) {
        this.currentItems = this.filteredData.slice((page - 1) * 20, page * 20);
      } else {
        this.currentItems = this.filteredData.slice((page - 1) * 20, page * 20);
      }
      this.$emit('setCurrentDetailsItems', this.currentItems);
      this.$emit('scrollToTop');
      this.pageNumber = page;
    },
  },
};
</script>
