console.log('Vite ⚡️ Rails')

import '@assets/stylesheets/application.scss'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'vue-multiselect/dist/vue-multiselect.css';
import 'vue-datepicker-next/index.css';
import 'primevue/resources/primevue.min.css';
import 'floating-vue/dist/style.css'

import BootstrapVueNext from 'bootstrap-vue-next'
import Multiselect from 'vue-multiselect'
import PrimeVueConfig from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import DatePicker from 'vue-datepicker-next';
import draggable from 'vuedraggable';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { VTooltip, Tooltip } from 'floating-vue';
import * as helpers from '@/helpers';
import * as constants from '@/helpers/constants';
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import directives from '@/directives';
import Notifications from '@kyvg/vue3-notification';
import FloatingVue from 'floating-vue'

// import * as ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
// require("channels");

const app = createApp(App);

app.config.globalProperties.$constants = constants;
app.config.globalProperties.$helpers = helpers;

directives.forEach((directive) => {
    app.directive(directive.name, directive);
});

app.directive('floating-tooltip', VTooltip)

app.use(BootstrapVueNext)
app.use(PrimeVueConfig)
app.use(Notifications)
app.use(DatePicker)
app.use(FloatingVue)
app.component('tooltip', Tooltip)
app.component('AutoComplete', AutoComplete)
app.component('PulseLoader', PulseLoader)
app.component('Draggable', draggable)
app.component('Multiselect', Multiselect)

app.use(router);

app.mount('#app')




