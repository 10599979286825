<template>
  <div class="d-flex mb-1" :class="{ 'align-items-center': centered }">
    <i v-if="hasIconSlot" :class="icon">
      <slot name="icon"></slot>
    </i>
    <i v-else-if="icon" class="me-2" :class="`property-icon ${icon}`" />
    <label
      class="property-title-label m-0"
      :for="labelFor"
      :style="`color: ${textColor};`"
    >
      {{ title }}
    </label>
  </div>
</template>

<script>
// Text with icon, for example: ☑ IRP
export default {
  name: 'IconText',
  props: {
    centered: {
      type: Boolean,
      default: true,
    },
    labelFor: String,
    title: {
      type: String,
      required: true,
    },
    icon: String,
    textColor: {
      type: String,
      default: '#003B6A',
    },
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
};
</script>
