<template>
  <div>
    <div v-if="actualRateUpdatedAt" class="quote-line-grayed">
      Conversion to Actual rate EUR: Updated on {{ actualRateUpdatedAt }}
    </div>
    <div v-if="apiRateUpdatedAt" class="quote-line-grayed">
      Conversion to API rate EUR: Updated on {{ apiRateUpdatedAt }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LastUpdatedArea',
  props: {
    pricesData: Array,
  },
  computed: {
    actualRateUpdatedAt() {
      return this.rateUpdatedAt('actual_rate_updated_at');
    },
    apiRateUpdatedAt() {
      return this.rateUpdatedAt('api_rate_updated_at');
    },
  },
  methods: {
    rateUpdatedAt(type) {
      if (!this.pricesData) return null;

      let result = null;
      this.pricesData.forEach((priceHash) => {
        if (priceHash[type]) {
          const updatedAt = new Date(priceHash[type]);
          result =
            !result || (updatedAt && updatedAt < result) ? updatedAt : result;
        }
      });

      if (result) {
        return this.$helpers.dateToLocaleDate(result);
      }

      return result;
    },
  },
};
</script>
