<template>
  <div
    ref="pdfRoot"
    class="chart-block card-card m-0 pt-0 px-0"
    :style="styleObject"
    v-if="true"
  >
    <div class="chart-header">
      <h1 class="m-0">Dosages Graph</h1>

      <div class="chart-settings">
        <template v-if="!loading">
          <ToggleSwitch
            v-model="switchCurrencyShortName"
            :show-switch="showSwitch"
            :switch-options="switchTypes"
            @change:model-value="switchCurrencyChartData"
          />
        </template>
      </div>
    </div>

    <div :class="['chart-content', { grayscale: updating }]">
      <!-- Chart: Bar view & Lines view -->
      <div v-if="loading" class="skeleton-chart">
        <div>
          <b-skeleton height="400px" />
        </div>
        <div>
          <b-skeleton height="100%" width="100%" />
        </div>
      </div>
      <div
        v-else
        data-pdf="chart"
        :class="['chart-container', { 'd-none': showTable }]"
      >
        <div :class="'chart'" :style="{ width: setEmptyWidth, height: '90%' }">
          <div
            class="d-flex justify-content-center align-items-center mb-3"
            v-if="labelIds.length > 8 && !disableLabelMultiselect"
          >
            <span class="color-aaa me-4" style="font-size: 14px">
              Select which labels
              <br />
              to show on graph:
            </span>
            <div class="d-flex align-items-center">
              <Multiselect
                v-model="selectedLabelIds"
                :options="chartSelectOptions"
                track-by="name"
                label="name"
                select-label
                placeholder="All"
                :multiple="true"
                :show-labels="false"
                :close-on-select="false"
              >
                <template #selection="{ values, isOpen }">
                  <span
                    v-if="values.length && !isOpen"
                    class="multiselect__single"
                  >
                    {{ values.length }} label{{ values.length > 1 ? 's' : '' }}
                    selected
                  </span>
                </template>
                <template #caret="{ toggle }">
                  <div
                    class="multiselect__select"
                    @mousedown.prevent.stop="toggle"
                  >
                    <i class="icon icon-down-arrow" />
                  </div>
                </template>
              </Multiselect>
              <button
                class="btn btn-green display-inline ms-4"
                style="width: 175px !important; line-height: 1.2"
                @click="() => redraw()"
              >
                Update chart
              </button>
            </div>
          </div>
          <canvas ref="chart" />
        </div>
      </div>
    </div>

    <div class="chart-quote">
      <h4 data-pdf="sub-chart" class="info-quote">
        {{ defineLegendCompetitors }}
      </h4>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import Chart from 'chart.js';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import { isEmptyObject } from '@/helpers';

export default {
  name: 'ChartDosage',
  components: { ToggleSwitch },
  props: {
    object: String,
    styleObject: Object,
    showSwitch: Boolean,
    disableLabelMultiselect: {
      type: Boolean,
      default: false,
    },
    fetchedResults: Object,
    fetchedResultsType: String,
    switchOptions: Array,
    objectName: String,
    overrideCurrency: {
      type: String,
      default: null,
    },
    quote: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      labelIds: [],
      selectedLabelIds: [],
      datacollection: null,
      data: null,
      type: 'bar',
      myChart: null,
      loading: true,
      updating: false,
      showTable: false,
      results: {},
      labels: [],
      labelClicks: [],
      averageValuesCollapsed: false,
      stacked: false,
      preloadedData: this.fetchedResults,
      preloadedDataType: this.fetchedResultsType,
      switchCurrencyShortName: '',
      priceType: 'ret_price_incl_vat_stat'
    };
  },
  computed: {
    chartSelectOptions() {
      return this.labelIds.map((option) => ({
        name: option,
        id: option,
      }));
    },
    switchTypes() {
      return this.data || this.switchOptions
        ? this.switchOptions || this.data.currencies
        : [];
    },
    filteredResults() {
      if (this.results.length) {
        return this.results;
      } else {
        return [];
      }
    },
    sign() {
      let currency = this.data.currency;
      let currencySign = this.$helpers.getCurrencySymbol(currency) || '€';
      let result =
        this.fetchedResultsType === 'percent' ? ' %' : ' ' + currencySign;
      return result;
    },
    defineLegendCompetitors() {
      return [
        this.quote,
        `Last data update: ${this.getLastDataUpdate()}`
      ].join('\xa0'.repeat(3));
    }
  },
  watch: {
    ids: {
      handler() {
        this.$nextTick(async () => {
          await this.getDataAndDrawSetup();
        });
      }
    }
  },
  mounted() {
    if (this.switchCurrencyShortName == '') {
      this.switchCurrencyShortName = this.overrideCurrency || 'EUR';
    }

    this.getDataAndDrawSetup();
  },

  beforeUnmount() {
    if (this.myChart) {
      for (const chart of Object.values(window.Chart.instances)) {
        window.Chart.animationService.cancelAnimation(chart);
      }
      this.myChart.destroy();
    }
  },

  methods: {
    async getDataAndDrawSetup() {
      this.loading = true;
      await this.getData().then(() => (this.loading = false));

      this.redraw(true);
    },
    async getData() {
      this.updating = true;
      let result = await api.get(
        `${this.$route.path}/graph_data?object=${this.object}&currency_short_name=${this.switchCurrencyShortName}&object_name=${this.objectName}&price_type=${this.priceType}`
      );
      this.data = result.data;
      this.updating = false;
    },
    redraw(mounted) {
      this.fillData();

      if (!this.disableLabelMultiselect) {
        if (mounted)
          this.selectedLabelIds = [
            ...this.labelIds
              .slice(0, 8)
              .map((label) => ({ name: label, id: label })),
          ];

        if (this.labelIds.length > 8) {
          this.datacollection = {
            ...this.datacollection,
            data: {
              ...this.datacollection.data,
              datasets: this.datacollection?.data?.datasets.filter((el) =>
                this.selectedLabelIds.find((label) => label.name === el.label)
              ),
            },
          };
        }
      }

      if (this.type) this.createChart(this.datacollection);
    },
    fillData() {
      const resultsObject = isEmptyObject(this.preloadedData)
        ? this.data.results
        : this.preloadedData;
      const results = Object.values(resultsObject);
      const obj = {};

      for (const res in results[0]) {
        obj[res] = [];
      }

      for (const result in results) {
        for (const objProp in results[result]) {
          obj[objProp].push(results[result][objProp]);
        }
      }

      const labels = Object.keys(resultsObject);
      this.labels = labels;

      const arr = Object.entries(obj);

      this.results = arr;

      const avg = (arr) =>
        arr.reduce(function (p, c, i) {
          return p + (c - p) / (i + 1);
        }, 0);
      const sortedArr = arr.sort((a, b) => {
        return avg(a[1]) - avg(b[1]);
      });

      let filteredData = sortedArr;

      const datasets = filteredData
        .sort((a, b) => b - a)
        .map((line, index) => {
          const label = line[0].replace(/_/g, ' ');
          let data = line[1];
          let color;
          let yAxisID = 'y-axis-1';

          if (this.objectName === label) {
            color = '#e85412';
          } else {
            let colorArray = this.$helpers.defaultColorPalette();
            color = colorArray[index];
          }

          return {
            label,
            borderWidth: 5,
            backgroundColor: color,
            borderColor: color,
            data,
            fill: false,
            hidden: this.labelClicks.includes(index),
            yAxisID,
          };
        });

      const yAxes =
          [
            {
              display: true,
              position: 'left',
              id: 'y-axis-1',
              ticks: {
                callback: (value) =>
                  self.$helpers.numberWithCommas(value) +
                  (self.preloadedDataType === 'percent' ? ' %' : ''),
                suggestedMax: this.currentMax(),
              },
              stacked: false,
            },
          ];

      const self = this;
      this.labelIds = datasets.map((el) => el.label).sort();

      if (this.labelIds.find(element => element === this.objectName)) {
        this.labelIds = [this.objectName, ...[...this.labelIds].filter((el) => el !== this.objectName)];
      };

      this.datacollection = {
        type: this.type,
        data: {
          labels,
          datasets,
        },
        options: {
          plugins: {
            datalabels: {
              display: false,
            },
          },
          responsive: true,
          responsiveAnimationDuration: 500,
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, chart) {
                let datasetLabel =
                  chart.datasets[tooltipItem.datasetIndex].label || '';
                let currency = self.sign;

                return (
                  datasetLabel +
                  ': ' +
                  self.$helpers.numberWithCommas(tooltipItem.yLabel) +
                  currency
                );
              },
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Month',
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Value',
              },
            },
            xAxes: [
              {
                stacked: this.stacked,
              },
            ],
            yAxes,
          },
          legend: {
            onClick: function (e, legendItem) {
              if (self.labelClicks.includes(legendItem.datasetIndex)) {
                self.labelClicks = self.labelClicks.filter(
                  (ind) => ind !== legendItem.datasetIndex
                );
              } else {
                self.labelClicks.push(legendItem.datasetIndex);
              }

              this.chart.options.scales.yAxes[0].ticks.suggestedMax = self.currentMax();

              Chart.defaults.global.legend.onClick.call(this, e, legendItem);
            },
          },
        },
      };
    },
    createChart(chartData) {
      const ctx = this.$refs.chart?.getContext('2d');

      if (!ctx) return;

      if (this.myChart) {
        for (const chart of Object.values(window.Chart.instances)) {
          window.Chart.animationService.cancelAnimation(chart);
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },
    async switchCurrencyChartData() {
      await this.getData();
      this.redraw();
    },
    currentMax(label) {
      let max = 0;

      if (label) {
        const result = this.filteredResults.find(
          (result) => result[0] === label
        );
        const data = result[1];
        const localMax = Math.max(...data);
        max = localMax > max ? localMax : max;
      } else {
        this.filteredResults.forEach((label, labelIndex) => {
          if (!this.labelClicks.includes(labelIndex)) {
            const data = label[1];
            const localMax = Math.max(...data);
            max = localMax > max ? localMax : max;
          }
        });
      }
      return max * 1.1;
    },
    setEmptyWidth() {
      let width = this.styleObject.width;
      if (width !== null && width === '') {
        return width;
      }

      return this.averageValuesCollapsed ? '60vw' : '50vw !important';
    },
    getLastDataUpdate() {
      return this.data && (this.data.last_data_update || '');
    }
  }
};
</script>

<style scoped lang="scss">
.skeleton-chart {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  & > div:first-child {
    padding: 30px;
  }
  & > div:last-child {
    padding: 30px;
  }
}
</style>
