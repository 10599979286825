<template>
  <div class="products-table">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="products-table-title">
      <h1>Price Planning - {{ country.name }} - {{ brand.name }}</h1>
      <br />
      <span class="color-aaa">
        Overview of products and product data change requests
      </span>
    </div>

    <div class="d-flex justify-content-left mt-30 mb-30 ms-30">
      <button
        class="buildup-card-right-panel-button btn btn-outline-dark vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Brands Level
        </span>
      </button>
    </div>

    <div class="step-container" style="position: sticky; top: 0;">
        <ul class="step-progressbar">
          <li class="popup-trigger" style="width: 23%;" v-floating-tooltip="'Brand manager: create product price change requests.'">
            <span class="progress-bar-text">
              Brand manager: create
            </span>
          </li>
          <li class="popup-trigger" style="width: 23%;" v-floating-tooltip="'Brand manager: approve or skip product price change requests.'">
            <span class="progress-bar-text">
              Brand manager: approve
            </span>
          </li>
          <li class="popup-trigger" style="width: 23%;" v-floating-tooltip="'Brand manager: submit brand price change request.'">
            <span class="progress-bar-text">
              Brand manager: submit
            </span>
          </li>
          <li class="popup-trigger" style="width: 23%;" v-floating-tooltip="'Marketing Analyst/Distribution Manager: approve brand request.'"
            v-if="country && (country.user_roles?.includes('marketing_analyst') || country?.user_roles?.includes('distribution_manager'))">
            <span class="progress-bar-text">
              MA/DM: approve
            </span>
          </li>
          <li class="popup-trigger" style="width: 23%;" v-floating-tooltip="'Head of marketing: approve brand request.'"
              v-else>
            <span class="progress-bar-text">
              Head of marketing: approve
            </span>
          </li>
        </ul>
        <div id="popup" class="hidden"></div>
    </div>

    <div>
      <Header
        v-if="!$helpers.isEmptyObject(brand)"
        :item="brand"
        title="Brand Data Change Request Status"
        :show-create="showCreateBrandRequestButton"
        :show-submit="showSubmitBrandRequestButton"
        :user-info="userInfo"
        :commentable-type="'BrandDataChangeRequest'"
        @create-parent-request="createParentRequest"
        @submit-parent-request="submitParentRequest"
        @open-discard-modal="openDiscardModal"
      />

      <ProductRequestsList
        :key="'ProductRequestsList'"
        :items-list="itemsList"
        :user-info="userInfo"
        :brand-request-status="brand.data_change_request?.status"
        @open-price-buildup="openPriceBuildup"
        @open-create-modal="openCreateModal"
        @open-approve-modal="openApproveModal"
        @open-reject-modal="openRejectModal"
        @open-discard-modal="openDiscardModal"
        @skip-product="skipProduct"
        @open-remove-all-modal="openRemoveAllModal"
        @open-approve-all-modal="openApproveAllModal"
        style="width:1140px;"
      />
    </div>
    <PopupModal
      v-if="popupModalVisible"
      v-model:changeRequest="changeRequest"
      :key="componentKey"
      :top="150"
      :left="650"
      :object-name="modalObjectName"
      :user-info="userInfo"
      :modal-action="modalAction"
      :calendarType="calendarType"
      :reject-type="rejectType"
      @close="popupModalVisible = false"
      @approve-changes="approveChanges"
      @create-data-change-request="createDataChangeRequest"
      @reject-product-changes="rejectProductChanges"
      @discard-changes="discardChanges"
      @remove-all-requests="removeAllRequests"
      @approve-all-requests="approveAllRequests"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import ProductRequestsList from '@/components/PricePlanning/ProductRequestsList.vue';
import Header from '@/components/PricePlanning/Header.vue';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import { stepCompletedList, clearStepCompletedList } from '@/helpers/stepProgressBar';

export default {
  name: 'ProductPricePlanning',
  components: { Header, ProductRequestsList, PopupModal, FadeLoader },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      itemsList: [],
      loading: false,
      popupModalVisible: false,
      brand: {},
      country: Object,
      showCreateBrandRequestButton: false,
      showSubmitBrandRequestButton: false,
      modalAction: String,
      modalObjectName: String,
      changeRequest: {},
      rejectType: '',
      componentKey: 0,
    };
  },
  watch: {
    itemsList() {
      if (!this.$helpers.isEmptyArray(this.itemsList)) {
        let statuses = this.itemsList
          .filter((e) => e.skip_review == false)
          .map((i) => i.status);
        let unique = [...new Set(statuses)];

        this.showCreateBrandRequestButton =
          (unique.length == 1 &&
            unique[0] == 'approved' &&
            this.$helpers.isEmptyObject(this.brand.data_change_request)) ||
          this.$helpers.isEmptyArray(unique);
        this.showSubmitBrandRequestButton =
          (unique.length == 1 && unique[0] == 'approved') ||
          this.$helpers.isEmptyArray(unique);
      } else {
        this.showCreateBrandRequestButton = false;
        this.showSubmitBrandRequestButton = false;
      }
    },
  },
  async beforeMount() {
    await this.loadDataChangeRequests();
  },
  methods: {
    returnToList() {
      this.$router.push(
        `/price_plannings/${this.$route.params.id}/brand_data_change_requests?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
      );
    },
    openPriceBuildup(item) {
      this.$router.push(
        `/price_plannings/${this.$route.params.id}/product_data_change_requests/${item.id}?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}&profit_center_variant_id=${item.changeable_id}`
      );
    },
    async loadDataChangeRequests() {
      this.loading = true;

      const url = `${this.$route.path}/get_planning_data?price_planning_id=${this.$route.params.id}&country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`;

      let { data: response } = await api.get(url);
      this.itemsList = response.data.items_list;
      this.brand = response.data.brand;
      this.country = response.data.country;

      if (this.brand?.data_change_request != null) {
        // await stepCompletedList(this.completed[this.brand?.data_change_request?.status][1]);
        await stepCompletedList(this.brand.data_change_request_status_index, this.brand.data_change_request?.status == 'approved')
      } else {
        await clearStepCompletedList();
      }

      this.loading = false;
    },
    openCreateModal(item, type) {
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'create';
      this.calendarType = type;
      this.modalObjectName = item.name;
    },
    async createDataChangeRequest(dates, item, calendarType) {
      let profit_center_variant_id = item.id;
      let valid_from = this.$helpers.dateToString(dates[0]);
      let valid_until = this.$helpers.dateToString(dates[1]);
      let country_id = this.$route.query.country_id;
      let brand_id = this.$route.query.brand_id;
      let calendar_type = this.calendarType;

      const response = await api
        .post(`${this.$route.path}`, {
          profit_center_variant_id,
          valid_from,
          valid_until,
          country_id,
          brand_id,
          calendar_type
        })
        .then((response) => {
          this.popupModalVisible = false;
          this.$helpers.notifySuccess('Product Data Change Request Created');
          this.openPriceBuildup(response.data);
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      await this.loadDataChangeRequests();
    },
    openApproveModal(item) {
      this.componentKey = Math.random();
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'approve';
      this.modalObjectName = item.name;
    },
    async approveChanges(item) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/${item.id}/approve`
        )
        .then(() => {
          this.$helpers.notifySuccess('Product Data Change Request Approved');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      this.loadDataChangeRequests();
    },
    openRejectModal(item, rejectType) {
      this.rejectType = rejectType;
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'reject';
      this.modalObjectName = item.object_name;
    },
    async rejectProductChanges(item, comment) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/${item.id}/reject`,
          { comment }
        )
        .then(() => {
          this.$helpers.notifyWarning('Product Data Change Request Rejected');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openDiscardModal(item) {
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'discard';
      this.modalObjectName = item.type == 'BrandDataChangeRequest' ? item.object : item.object_name;
    },
    async discardChanges(item) {
      let pathObject = item.type.replace(/[A-Z]/g, (letter, index) => { return index == 0 ? letter.toLowerCase() : '_'+ letter.toLowerCase();});
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/${pathObject}s/${item.id}/discard`
        )
        .then(() => {
          this.$helpers.notifyWarning('Product Data Change Request Removed');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async skipProduct(item, skip) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/skip?pcv_id=${item.id}&country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`,
          {
            skip,
          }
        )
        .then((response) => {
          if (response.data.skip_review) {
            this.$helpers.notifyWarning('Product will be skipped');
          } else {
            this.$helpers.notifyWarning('Product not skipped');
          }
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openRemoveAllModal(item) {
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'removeAll';
      this.modalObjectName = item.type == 'BrandDataChangeRequest' ? item.object : item.object_name;
    },
    async removeAllRequests(item) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/remove_requests?pcv_id=${item.id}&country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
        )
        .then((response) => {
          this.$helpers.notifyWarning('Product requests removed');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openApproveAllModal(item) {
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'approveAll';
      this.modalObjectName = item.type == 'BrandDataChangeRequest' ? item.object : item.object_name;
    },
    async approveAllRequests(item) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/approve_requests?pcv_id=${item.id}&country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
        )
        .then((response) => {
          this.$helpers.notifyWarning('Product requests approved');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async createParentRequest() {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/brand_data_change_requests?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
        )
        .then(() => {
          this.$helpers.notifySuccess('Brand Data Change Request initialized');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async submitParentRequest(item) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/brand_data_change_requests/${item.id}/submit`
        )
        .then((response) => {
          this.$helpers.notifySuccess('Brand Data Change Request submitted');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
  },
};
</script>
