<template>
  <div class="plan-prices" style="position: relative;">
    <div v-if="!loaded" class="disable" />
    <fade-loader :loading="!loaded" :color="'#fff'" />
    <div class='country-plan-prices__title' style="z-index: 100;">
      <ExportToPdf
        :loaded="loaded"
        :country="country"
        :canvas-element="$refs.pdfRoot"
      />
      <ExcelXlsxButton
        class="toggle-button"
        style="position: absolute; top: 12px; right: 50px;"
        :data="xlsxData"
        :columns="xlsxColumns"
        :file-name="xlsxFileName"
        sheet-name="Default"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22px"
          height="22px"
          viewBox="0 0 26 26"
          :stroke="'#3b7d15'"
        >
          <path
            style="fill: #030104"
            d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
          />
          <path
            style="fill: #ffffff"
            d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
          />
        </svg>
      </ExcelXlsxButton>
    </div>

    <div class="table-plan-price-scrolling" :style="`${setTableHeight}`">
      <table
        ref="pdfRoot"
        class="plan-prices__table table table-striped-plan-price table-hover table-sm"
        style="white-space: nowrap; width: 100%;"
      >
        <thead style="z-index: 50;">
          <tr>
            <th class="frozen-element-left" style="background: #f2efaa; top: 0; border-left: 0px !important;">GPMS name</th>
            <th class="frozen-element-top" v-for="yearTitle in yearTitles" :key="yearTitle">
              {{ yearTitle }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pcv in pcvsFiltered" :key="pcv.id">
            <td class="frozen-element-left" :style="{ 'background': 'inherit', 'border-left': '0px !important' }" @click="onClick(pcv.id)">{{ pcv.gpms_name }}</td>
            <td
              v-for="yearData in pcv.years_data"
              :key="`${pcv.id}_${yearData.year}`"
            >
              <table style="border: none !important">
                <tr
                  style="background: inherit !important; border: none !important"
                >
                  <td
                    v-for="priceChange in priceChanges(yearData)"
                    :key="`${priceChange.eur_diff}${priceChange.local_diff}${yearData.year}`"
                    style="
                      background: inherit !important;
                      border: none !important;
                    "
                  >
                    <div class="d-flex align-self-start">
                      <div v-if="checkPriceChange(priceChange, yearData)" class="price-planning-details__diff_left">
                        <span
                          v-if="priceChange.eur_diff"
                          :class="diffClass(priceChange.eur_diff)"
                        >
                          {{ toSignedPercent(priceChange.eur_diff) }}
                        </span>
                        <br>
                        <span
                          v-if="priceChange.local_diff"
                          :class="diffClass(priceChange.local_diff)"
                        >
                          {{ toSignedPercent(priceChange.local_diff) }}
                        </span>
                      </div>
                      <div
                        v-if="
                          (priceChange.priceEur && priceChange.priceEur.value != null) ||
                          (priceChange.priceLocal && priceChange.priceLocal.value != null)
                        "
                        class="price-planning-details__price display-inline"
                      >
                        <span class="description-quote"
                          >{{ monthShort(priceChange) }}:</span
                        >
                        <div
                          v-if="
                            priceChange.priceEur &&
                            priceChange.priceEur.value != null
                          "
                          class="price-planning-details__eur-mean"
                        >
                          <span
                            style="font-weight: bold"
                            :class="fontSizeSmall(priceChange.priceEur.value)"
                          >
                            {{ formatPrice(priceChange.priceEur.value) }}
                            {{
                              $helpers.getCurrencySymbol(
                                priceChange.priceEur.currency
                              )
                            }}
                          </span>
                        </div>
                        <div
                          v-if="priceChange.priceLocal && priceChange.priceLocal.value != null"
                          class="price-planning-details__local-mean"
                          style="text-align: right; margin-right: 8px"
                        >
                          {{ formatPrice(priceChange.priceLocal.value) }}
                          {{
                            $helpers.getCurrencySymbol(
                              priceChange.priceLocal.currency
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import ExportToPdf from '@/components/PricePlanning/Modals/ExportToPdf.vue';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import 'jspdf-autotable';

function generateYearArray(numOfYears = 6) {
  const year = new Date().getFullYear() - 1;
  const yearArray = [];

  for (let i = 0; i <= numOfYears; i++) {
    yearArray.push(`${year + i}`);
  }

  return yearArray;
}

export default {
  name: 'ListPricesTable',
  components: {
    FadeLoader,
    ExportToPdf,
    ExcelXlsxButton
  },
  props: {
    country: Object
  },
  data() {
    return {
      pcvs: [],
      loaded: false,
      priceKey: 'lp1',
      yearTitles: generateYearArray(),
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    pcvsFiltered() {
      return this.pcvs.filter(
        (pcv) =>
          pcv.years_data
            .map((d) =>
              d[this.priceKey].map((p) => p.prices.map((v) => v.value))
            )
            .flat()
            .reduce((a, b) => a + b, 0) !== 0
      );
    },
    xlsxFileName() {
      const isoCode = this.pcvs.length > 0 ? this.pcvs[0].iso_code : '';
      return `List prices (lp1) ${isoCode}`;
    },
    xlsxColumns() {
      const year = String(this.currentYear)
      const prev_year = String((this.currentYear - 1))
      const next_year = String((this.currentYear + 1))
      const plus_two_years = String((this.currentYear + 2))
      const plus_three_years = String((this.currentYear + 3))
      const plus_four_years = String((this.currentYear + 4))
      const plus_five_years = String((this.currentYear + 5))

      return [
        { label: 'GPMS name', field: 'gpms_name' },
        { label: 'Sap ID', field: 'sap_id' },
        { label: 'SAP Name', field: 'sap_name' },
        { label: prev_year, field: prev_year },
        { label: year, field: year },
        { label: next_year, field: next_year },
        { label: plus_two_years, field: plus_two_years },
        { label: plus_three_years, field: plus_three_years },
        { label: plus_four_years, field: plus_four_years },
        { label: plus_five_years, field: plus_five_years }
      ]
    },
    xlsxData() {
      let pcvsXlsxData = [];
      const prevYear = new Date().getFullYear() - 1;

      this.pcvsFiltered.forEach((pcvInfo) => {
        let pcvXlsxData = []
        pcvInfo.years_data.forEach((yearData) => {
          const listPriceInfo = yearData.lp1;
          listPriceInfo.forEach((priceInfo) => {
            priceInfo.prices.forEach((obj) => {
              const curr = obj.currency;
              if(curr !== 'EUR') { return; };

              pcvXlsxData.push({
                value: obj.value,
                currency_name: curr,
                year: yearData.year,
                sap_id: pcvInfo.sap_id,
                sap_name: pcvInfo.sap_name,
                gpms_name: pcvInfo.gpms_name,
                month: this.monthShort(priceInfo),
                currency: this.$helpers.getCurrencySymbol(curr)
              })
            })
          });
        });

        let result = {}
        const last2022EuroIndex = pcvXlsxData.findLastIndex(obj => obj.year === 2022 && obj.currency_name === 'EUR');
        pcvXlsxData = pcvXlsxData.filter((obj, index) => (obj.year !== 2022 || index === last2022EuroIndex));

        pcvXlsxData.forEach((obj) => {
          if (!result[obj.year]) {
            if (obj.year === prevYear) {
              result[obj.year] = {
                sap_id: obj.sap_id,
                sap_name: obj.sap_name,
                gpms_name: obj.gpms_name,
                [obj.year]: `${obj.month} ${obj.value}${obj.currency}`
              };
            } else {
              result[obj.year] = {
                sap_id: obj.sap_id,
                sap_name: obj.sap_name,
                gpms_name: obj.gpms_name,
                [obj.year]: `${obj.month} ${obj.value}${obj.currency}`
              };
            }
          } else {
            if (obj.year === prevYear) {
              result[obj.year][obj.year] += ` | ${obj.month} ${obj.value}${obj.currency}`;
            } else {
              result[obj.year][obj.year] += ` | ${obj.month} ${obj.value}${obj.currency}`;
            }
          }
        });

        const convertedData = Object.values(result).map(obj => ({ ...obj }));
  
        const mergedData = convertedData.reduce((mergedObjects, obj) => {  
          const key = obj.gpms_name;  
          if (!mergedObjects[key]) {  
              mergedObjects[key] = obj;  
          } else {  
              mergedObjects[key] = { ...mergedObjects[key], ...obj };  
          }  
          return mergedObjects;  
        }, {});

        pcvsXlsxData.push(Object.values(mergedData))
      });

      return pcvsXlsxData.flat();
    },
    setTableHeight() {
      const pcvLength = this.pcvsFiltered.length

      if (pcvLength >= 7) {
        return 'height: 450px'
      } else {
        return 'height: 100%;'
      }
    }
  },
  mounted() {
    this.getListPrices();
  },
  methods: {
    async getListPrices() {
      if (!this.country) {
        this.loaded = true;
        return false;
      }

      let from = new Date(new Date().getFullYear() - 1, 0, 1);
      let to = new Date(new Date().getFullYear() + 5, 11, 31);
      const response = await api.get(
        `/countries/${this.country.id}/list_prices?from=${from}&to=${to}`
      );
      this.pcvs = response.data.sort((a, b) =>
        a.gpms_name.localeCompare(b.gpms_name)
      );
      this.loaded = true;
    },
    priceChanges(yearData) {
      let data = yearData[this.priceKey].map((monthPrice) => {
        return Object.assign({}, monthPrice, {
          priceEur: this.eurPrice(monthPrice.prices),
          priceLocal: this.nonEurPrice(monthPrice.prices),
        });
      });

      if (yearData.year >= new Date().getFullYear()) return data;
      else return [data.slice(-1)[0]];
    },
    eurPrice(pricesArr) {
      return pricesArr.find((v) => v.currency === 'EUR');
    },
    nonEurPrice(pricesArr) {
      return pricesArr.find((v) => v.currency !== 'EUR');
    },
    formatPrice(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        useGrouping: false,
      });
    },
    onClick(pcvId) {
      window.location.href = `/profit_center_variants/${pcvId}`;
    },
    diffClass(price) {
      return {
        'plan-prices__diff-positive': price > 0,
        'plan-prices__diff-negative': price < 0,
      };
    },
    fontSizeSmall(price) {
      return {
        smaller_font: price.toString().length > 5,
      };
    },
    toSignedPercent(value) {
      return `${value > 0 ? '+' : ''}${this.formatPrice(
        (value * 100).toFixed(2)
      )}%`;
    },
    monthShort(priceChange) {
      return this.$helpers.toAbc(parseInt(priceChange.date.split('-')[1]));
    },
    checkPriceChange(priceChange, yearData) {
      return ((priceChange.eur_diff !== null && priceChange.eur_diff != 0) ||
              (priceChange.local_diff !== null && priceChange.local_diff != 0)) &&
                          yearData.year >= new Date().getFullYear()
    }
  }
};
</script>
