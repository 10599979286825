<template>
  <div class="container">
    <div
      class="hierarchy-item-title fixed"
      :class="{ scrolled: currentScrollPos < 40 }"
    >
      <h1 :class="{ loading }">
        {{
          data.full_name
            ? data.full_name
            : data.name
            ? data.name
            : data.is_bundle_pack
            ? data.sap_name
            : data.gpms_name
        }}
      </h1>
    </div>

    <div :class="'grid'">
      <div v-if="!loading" class="grid-pic">
        <i class="card icon products-image m-0" />
      </div>
      <div class="grid-info-card mb-30">
        <InfoCard
          v-if="Object.keys(data).length"
          :data="data"
          :columns-number="1"
        />
      </div>
      <div class="grid-graph mb-30">
        <div class="hierarchy-item-chart" style="height: auto">
          <div style="margin-top: 5.3%">
            <Chart
              object="sales"
              :show-switch="true"
              :style-object="{ width: '100%' }"
              :quote="$helpers.defineQuote(data)"
              :page-name="data.name"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!pagesStack.length"
      class="card price-build-table"
    >
      <div class="price-build-header">
        <h2 class="display-block">Price Build up</h2>
      </div>
      <div class="analysis-row">
        <AnalysisPriceBuildupTable
          v-if="data['price_buildups']"
          :price-info="data['price_buildups']"
          :exchange-rate-type-options="data['price_info_currencies']"
          :loading-price-buildup-currency="loadingPriceBuildupCurrency"
          :currency="countryPriceBuildupCurrency"
          @stack-page="stackPage"
          @select-price-buildup-currency="selectPriceBuildupCurrency"
        />
      </div>
    </div>

    <div v-if="!isSalesPage" class="row small-table-row" style="margin-top: 50px">
      <HierarchyItemDetailsTable
        v-if="data[hierarchy[$route.path.split('/')[1]]]"
        :items="data[hierarchy[$route.path.split('/')[1]]]"
        :name="hierarchy[$route.path.split('/')[1]]"
      />
    </div>

    <div v-show="pagesStack.length" class="analysis">
      <button
        class="button-no-style link d-flex align-items-center card analysis-back-button"
        @click="unstackPage"
      >
        <span class="text-uppercase me-2">Details</span>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :style="{ width: '10px', height: '20px' }"
        >
          <path
            d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
            fill="#9DAFC2"
          />
        </svg>
      </button>

      <div v-if="pagesStack.length" class="analysis-row">
        <div
          v-show="pagesStack.length"
          ref="linePdfRoot"
          class="analysis-content"
          :loading="loading"
        >
          <Chart
            class="w-100"
            object="analysisLineChart"
            :title="currentDetailsPage[0]"
            :pages-stack="pagesStack[0][1]"
            :ids="currentDetailsIds"
            :pdf-root-ref="$refs.linePdfRoot"
            :show-additional-info="true"
            :fetched-results-type="dataType"
            search-path="/profit_center_variants"
            force-pcv-graph
            :override-currency="countryPriceBuildupCurrency"
          />
        </div>
      </div>

      <div class="analysis-row">
        <div
          v-show="pagesStack.length"
          ref="pdfRoot"
          class="analysis-content"
          :loading="loading"
        >
          <div
            v-if="
              pagesStack.length &&
              data['price_buildups'][currentDetailsPage[0]].type !== 'text'
            "
            class="analysis-row"
          >
            <AnalysisDetailsChart
              v-if="!$helpers.isEmptyArray(currentDetailsItems)"
              :title="currentDetailsPage[0]"
              :items="currentDetailsItems"
              :data-type="dataType"
              :pdf-root-ref="$refs.pdfRoot"
              :currency="countryPriceBuildupCurrency"
            />
          </div>
          <div class="analysis-row">
            <AnalysisDetailsTable
              v-if="currentDetailsPage"
              :loading="false"
              :title="currentDetailsPage[0]"
              :table-style="{ width: '100%' }"
              :items="currentDetailsItems"
              :data-type="dataType"
              :currency="countryPriceBuildupCurrency"
              @scroll-to-top="scrollToTop"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard.vue';
import Chart from '@/components/Chart/index.vue';
import HierarchyItemDetailsTable from '@/components/HierarchyItemList/HierarchyItemDetailsTable.vue';
import { hierarchy, currenciesShortISO4217 } from '@/helpers/constants';
import api from '@/helpers/api';
import AnalysisPriceBuildupTable from '@/components/Search/AnalysisPriceBuildupTable.vue';
import AnalysisDetailsChart from '@/components/Search/AnalysisDetailsChart.vue';
import AnalysisDetailsTable from '@/components/Search/AnalysisDetailsTable.vue';

export default {
  name: 'HierarchyItemDetails',
  components: {
    InfoCard,
    Chart,
    HierarchyItemDetailsTable,
    AnalysisPriceBuildupTable,
    AnalysisDetailsChart,
    AnalysisDetailsTable
  },
  props: {
    currentScrollPos: Number,
  },
  emits: ['close'],
  data() {
    return {
      data: {},
      hierarchy,
      loading: false,
      currency: {
        value: '',
        options: currenciesShortISO4217,
      },
      pagesStack: [],
      items: [],
      currentDetailsItems: [],
      countryPriceBuildupCurrency: 'EUR',
      currentDetailsIds: [],
      loadingPriceBuildupCurrency: false,
    };
  },
  computed: {
    isSalesPage() {
      return this.$route.path.includes('sales');
    },
    currentDetailsPage() {
      return this.pagesStack[this.pagesStack.length - 1];
    },
    dataType() {
      return this.data['price_buildups'][this.currentDetailsPage[0]].type;
    },
  },
  async beforeMount() {
    this.loading = true;

    await this.getData();

    this.loading = false;
  },
  methods: {
    stackPage(page) {
      this.pagesStack.push(page);
      const plannedItems =
        this.data['price_buildups'][this.currentDetailsPage[0]];
      const realItems =
        this.data['price_buildups'][this.currentDetailsPage[0] + ' real'];
      this.items = {
        planned: plannedItems.items.sort((a, b) => a.value - b.value),
        real: realItems
          ? realItems.items.sort((a, b) => a.value - b.value)
          : [],
      };
      this.setCurrentDetailsItems(this.items);
    },

    unstackPage() {
      if (this.pagesStack.length) {
        this.pagesStack.pop();
      } else {
        this.$emit('close');
      }
    },

    setCurrentDetailsItems(currentDetailsItems) {
      this.currentDetailsItems = currentDetailsItems;
      this.currentDetailsIds = currentDetailsItems.planned.map(
        (item) => item.id
      );
    },

    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.analysis.scrollTop = 0;
      });
    },

    async getData() {
      const response = await api.get(`${this.$route.path}.json`);
      this.data = response.data.data;
    },

    async selectPriceBuildupCurrency(currency) {
      this.loadingPriceBuildupCurrency = true;
      const response = await api.get(
        `/countries/${this.data.id}/price_buildups?currency_short=${currency}`
      );
      this.data.price_buildups = response.data.price_buildups;
      this.countryPriceBuildupCurrency = currency;
      this.loadingPriceBuildupCurrency = false;
    },
  },
};
</script>
