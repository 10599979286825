<template>
  <div>
    <div class="average">
      <span
        ref="commentsIcon"
        class="card-product-variants-item"
        @click="onCommentsClick(request.id)"
      >
        <i class="icon link comment-icon-big" />
        <span class="comment-icon-count-big">
          {{ commentsCount() }}
        </span>
      </span>
    </div>
    <CommentsModal
      v-if="commentsModalVisible"
      :top="commentsIconTop"
      :left="commentsIconLeft + 300"
      :container-width="780"
      @close="commentsModalVisible = false"
    >
      <div class="comments-container">
        <span class="comment-number">Comments ({{ commentsData.length }})</span>
        <div v-for="comment in commentsData" :key="comment.id">
          <Comment
            :user-info="userInfo"
            :comment-data="comment"
            :with-image="true"
            :editable="true"
            :with-remove="true"
            @on-delete="deleteComment"
            @update-comment="updateComment"
          />
        </div>

        <div class="comment-input-wrapper">
          <textarea
            style="position: relative !important; opacity: 100 !important; pointer-events: auto !important;"
            ref="addComment"
            v-model="commentText"
            class="comment-input"
            placeholder="Add a comment..."
            @focus="inputHasFocus = true"
          />
          <button class="button-no-style">
            <i
              v-if="inputHasFocus"
              class="icon send-text-icon"
              @click="createComment()"
            />
            <i v-else class="icon send-text-inactive-icon" />
          </button>
        </div>
      </div>
    </CommentsModal>
  </div>
</template>

<script>
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import Comment from '@/components/Modals/CommentsModal/Comment.vue';
import api from '@/helpers/api';

export default {
  name: 'RequestComments',
  components: { CommentsModal, Comment },
  props: {
    request: Object,
    userInfo: Object,
    commentableType: String,
  },
  data() {
    return {
      commentsModalVisible: false,
      commentsIconTop: 0,
      commentsIconLeft: 0,
      commentsList: [],
      commentText: '',
      inputHasFocus: false,
      commentsData: [],
    };
  },
  beforeMount() {
    this.commentsData = this.request?.comments || [];
  },
  methods: {
    onCommentsClick() {
      this.commentsIconTop =
        this.$refs.commentsIcon.getBoundingClientRect().top;
      this.commentsIconLeft =
        this.$refs.commentsIcon.getBoundingClientRect().left;
      this.commentsModalVisible = true;
    },
    async updateComment(id, comment) {
      if (this.commentText.text !== '') {
        const result = await api.put(
          `/comments/${id}`,
          JSON.stringify({
            comment: { message: comment },
          })
        );
        if (result.data) {
          this.commentsData = this.request.comments.map((e) => {
            if (e.id === id) {
              e.text = comment;
            }
            return e;
          });
        }
      }
    },
    async createComment() {
      if (this.commentText !== '') {
        const result = await api.post(
          `/comments/`,
          JSON.stringify({
            comment: {
              message: this.commentText,
              commentable_type: this.commentableType,
              commentable_id: this.request.id,
              commentable_key: 'price_planning',
            },
          })
        );
        this.commentsData.unshift(result.data);
        this.commentText = '';
      }
    },
    commentsCount() {
      if (this.commentsData?.length && this.commentsData?.length > 99) {
        return '99+';
      }
      return this.commentsData?.length || '';
    },
    async deleteComment(id) {
      await api.delete(`/comments/${id}.json`);
      this.commentsData = this.commentsData.filter((e) => e.id !== id);
    },
  },
};
</script>
