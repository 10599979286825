<template>
  <div class="results-and-filters d-flex p-0">
    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span>
        <PulseLoader :loading="loading" />
      </span>
    </div>
    <div
      class="results scrollbar"
      :style="analysisModalVisible && 'overflow: hidden;'"
      :class="filtersPanelOpenedMobile && 'results-filters-opened'"
    >
      <div
        class="results-header d-flex justify-content-between align-items-center"
      >
        <span><b>Results</b> {{ results.count }}</span>
        <div class="results-header-right">
          <div class="results-header-select hierarchy-item-currency-selector">
            <Multiselect
              v-model="filtersFormModel.highlightProperty.value"
              :options="filtersFormModel.highlightProperty.options"
              select-label
              placeholder="Highlight"
              :show-labels="false"
            >
              <template #caret="{ toggle }">
                <div
                  class="multiselect__select"
                  @mousedown.prevent.stop="toggle"
                >
                  <i class="icon icon-down-arrow" />
                </div>
              </template>
            </Multiselect>
          </div>
          <span class="label mx-1 results-header-label">Sort by</span>
          <div
            class="results-header-select hierarchy-item-currency-selector me-2"
          >
            <Multiselect
              v-model="filtersFormModel.orderBy.value"
              :options="filtersFormModel.orderBy.options"
              select-label
              placeholder
              hide-selected
              label="full_name"
              track-by="internal_name"
              taggable
              :show-no-results="false"
            >
              <template #caret="{ toggle }">
                <div
                  class="multiselect__select"
                  @mousedown.prevent.stop="toggle"
                >
                  <i class="icon icon-down-arrow" />
                </div>
              </template>
            </Multiselect>
          </div>
          <span class="label me-1 results-header-label">Show by</span>
          <div
            id="items-per-page"
            class="results-header-select hierarchy-item-currency-selector"
          >
            <Multiselect
              v-model="filtersFormModel.itemsPerPage.value"
              :options="filtersFormModel.itemsPerPage.options"
              select-label
              placeholder
              :show-no-results="false"
              hide-selected
            >
              <template #caret="{ toggle }">
                <div
                  class="multiselect__select"
                  @mousedown.prevent.stop="toggle"
                >
                  <i class="icon icon-down-arrow" />
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>

      <Chips
        :chips="appState.chips"
        @delete-tag="
          (selectedOption, id) => $emit('deleteTag', selectedOption, id)
        "
      />

      <div class="search-results-header-buttons">
        <div class="search-results-header-analysis-button">
          <button
            v-if="!tooManyItems"
            class="btn btn-green"
            :disabled="disableButton"
            @click="analysisModalVisible = true"
          >
            View Analysis Data
          </button>

          <div
            v-if="!tooManyItems && disableButton"
            class="search-results-header-analysis-button__spinner"
          >
            <b-spinner type="border" small class="tab-spinner" />
          </div>

          <button v-if="tooManyItems" class="btn btn-disabled" disabled>
            {{ `Too many results ${results.count}` }}
          </button>

          <AnalysisModal
            v-if="results.ids"
            :open="analysisModalVisible"
            :collapsed="appState.collapsed"
            parent-class="analysis-slide-modal"
          >
            <Analysis
              ref="analysis"
              :ids="results.ids"
              :chips="appState.chips"
              :open="analysisModalVisible"
              @delete-tag="
                (selectedOption, id) => $emit('deleteTag', selectedOption, id)
              "
              @close="analysisModalVisible = false"
              @lock-details-button="lockDetailsButton"
            />
          </AnalysisModal>
        </div>

        <div
          v-if="$helpers.includedInRoleArray(userInfo.account_types, ['admin'])"
        >
         <button
            v-if="!tooManyItems"
            class="btn btn-navbar-item"
            v-floating-tooltip="'Copy direct link to share filtered products'"
            :disabled="disableButton"
            @click="copyLink"
          >
            Copy search link
          </button>
        </div>
      </div>

      <div class="search-results-cards">
        <SearchResultsCard
          v-for="item in results.items"
          :key="item.id"
          :search-result="item"
          :highlight-property="filtersForm.highlightProperty.value"
          :loading="loading"
          :comments="comments[item.id] || []"
          :user-info="userInfo"
          @show-i-r-p-modal="showIRPModal"
          @open-principles-modal="openPrinciplesModal"
        />
      </div>

      <Pagination
        v-if="appState.pageCount > 1"
        :page-number="appState.pageNumber"
        :page-count="appState.pageCount"
        @load-page="(page) => $emit('loadPage', page)"
      />

      <SlideModal
        v-if="windowWidth <= 1366"
        parent-class="filters-slide-modal"
        :open="filtersPanelOpenedMobile"
        @close="toggleFiltersPanel(false)"
      >
        <SearchFiltersForm
          :filters-panel-opened-mobile="filtersPanelOpenedMobile"
          :filters-form="filtersForm"
          @clear-filters="$emit('clearFilters')"
          @search-tag="
            (selectedOption, id) => $emit('searchTag', selectedOption, id)
          "
          @selected-tag="
            (selectedOption, id) => $emit('selectedTag', selectedOption, id)
          "
          @deleted-tag="
            (selectedOption, id) => $emit('deletedTag', selectedOption, id)
          "
          @checkbox-tag="
            (selectedOption, id) => $emit('checkboxTag', selectedOption, id)
          "
          @toggle-filters-panel="toggleFiltersPanel"
        />
      </SlideModal>
      <CommentsModal
        v-if="IRPModalVisible"
        parent-class="principles-modal"
        :top="IRPIconTop"
        :left="IRPIconLeft"
        :container-width="315"
        @close="IRPModalVisible = false"
      >
        <div v-for="country in IRPCountries" :key="country">{{ country }}</div>
      </CommentsModal>
      <CommentsModal
        v-if="showPrinciplesModal"
        :top="principlesButtonTop"
        :left="principlesButtonLeft"
        :container-width="315"
        parent-class="principles-modal"
        @close="closePrinciplesModal"
      >
        <h1>Pricing Principles</h1>
        <div
          v-for="(principle, index) in pricingPrincipleInfo"
          :key="`${principle.value} + ${principle.principle_name}`"
          @click="showPrinciplesMessages(index)"
        >
          <span
            class="d-flex align-items-center justify-content-between link principles-modal__principle_text"
          >
            {{ principle.principle_name }}

            {{ principle.value }}
            <i
              class="icon ms-2"
              :class="principle.status ? 'yes-icon' : 'no-icon'"
            />
          </span>
          <p
            v-if="displayPrinciplesMessages.includes(index)"
            class="principle-message"
            :style="
              principle.status
                ? 'color: rgb(107, 170, 58);'
                : 'color: rgb(233, 84, 37);'
            "
          >
            {{ principle.message }}
          </p>
        </div>
      </CommentsModal>
    </div>

    <SearchFiltersForm
      v-if="windowWidth > 1366"
      v-model:filters-form="filtersFormModel"
      :filters-panel-opened-mobile="filtersPanelOpenedMobile"
      @clear-filters="$emit('clearFilters')"
      @search-tag="
        (selectedOption, id) => $emit('searchTag', selectedOption, id)
      "
      @selected-tag="
        (selectedOption, id) => $emit('selectedTag', selectedOption, id)
      "
      @deleted-tag="
        (selectedOption, id) => $emit('deletedTag', selectedOption, id)
      "
      @checkbox-tag="
        (selectedOption, id) => $emit('checkboxTag', selectedOption, id)
      "
      @toggle-filters-panel="toggleFiltersPanel"
    />
  </div>
</template>

<script>
import SearchFiltersForm from './SearchFiltersForm.vue';
import SearchResultsCard from './SearchResultsCard.vue';
import Pagination from '@/components/Pagination.vue';
import Modal from '@/components/Modals/index.vue';
import CommentsModal from '@/components/Modals/CommentsModal/index.vue';
import SlideModal from '@/components/Modals/SlideModal/index.vue';
import AnalysisModal from '@/components/Modals/SlideModal/AnalysisModal.vue';
import Analysis from './Analysis.vue';
import Chips from './Chips.vue';
import { newItemFields } from '@/helpers/constants';
import { loadDropdowns } from '@/helpers/apiFunctions';
import api from '@/helpers/api';
import { isEmptyArray } from '@/helpers';

export default {
  name: 'Search',
  components: {
    Analysis,
    SearchFiltersForm,
    SearchResultsCard,
    Pagination,
    Modal,
    CommentsModal,
    SlideModal,
    Chips,
    AnalysisModal,
  },
  props: {
    filtersForm: Object,
    appState: Object,
    results: Object,
    comments: Object,
    loading: Boolean,
    userInfo: Object,
  },
  emits: [
    'clearFilters',
    'searchTag',
    'selectedTag',
    'deletedTag',
    'checkboxTag',
    'loadPage',
    'deleteTag',
    'update:filtersForm',
  ],
  data() {
    return {
      analysisModalVisible: false,

      IRPModalVisible: false,
      IRPIconTop: 0,
      IRPIconLeft: 0,
      IRPCountries: [],
      allIRPCountries: [],

      showPrinciplesModal: false,
      pricingPrincipleInfo: [],
      principlesButtonTop: 0,
      principlesButtonLeft: 0,
      displayPrinciplesMessages: [],

      filtersPanelOpenedMobile: false,
      windowWidth: window.innerWidth,
      newItemForm: {},
      dropdownsOptions: {},
      detailsButtonLocked: false,
    };
  },
  computed: {
    disableButton() {
      return this.detailsButtonLocked;
    },
    tooManyItems() {
      return this.results.count > 150;
    },
    filtersFormModel: {
      get() {
        return this.filtersForm;
      },
      set(value) {
        this.$emit('update:filtersForm', value);
      },
    },
  },
  created() {
    window.addEventListener('resize', this.windowResized);
  },
  async mounted() {
    newItemFields['ProfitCenterVariant'].map((field) => {
      this.newItemForm[field] = '';
    });
    this.dropdownsOptions = await loadDropdowns(this.newItemForm);
  },
  unmounted() {
    window.removeEventListener('resize', this.windowResized);
  },
  methods: {
    windowResized() {
      this.windowWidth = window.innerWidth;
    },
    copyLink() {
      const link = `${window.location.href}profit_center_variants/copy`;
      const params = Object.fromEntries(Object.entries(this.filtersForm).map(([key, value]) => (
        [key, value === undefined ? null : `'${value}'`]))
      )
      const modifiedLink = this.modifyQuery(link, params);
      const tempInput = document.createElement('input');
      tempInput.setAttribute('value', modifiedLink);
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    },
    modifyQuery(url, object) {
      const existing = (url.lastIndexOf('?') > url.lastIndexOf('#')) ?
        url.substr(url.lastIndexOf('?') + 1) : '';

      const query = new URLSearchParams(existing);
      for (const [key, _value] of Object.entries(object)) {
        let value = this.actualizeValue(key)
        query.set(key, value);
      }

      return `${url.replace(`?${existing}`, '')}?${query.toString()}`;
    },
    actualizeValue(key) {
      if (['referenceCountries', 'isMainCompetitors'].includes(key)) {
        return this.filtersForm[key] || null;
      } else if (key === 'orderBy') {
        return this.validateValue(key).internal_name
      } else {
        return this.validateValue(key)
      }
    },
    validateValue(key) {
      return isEmptyArray(this.filtersForm[key].value) ? null : this.filtersForm[key].value;
    },
    showIRPModal(IRPCountries, top, left) {
      this.IRPIconTop = top;
      this.IRPIconLeft = left;
      this.IRPCountries = IRPCountries;
      this.IRPModalVisible = true;
    },
    openPrinciplesModal(pricingPrincipleInfo, top, left) {
      this.principlesButtonTop = top;
      this.principlesButtonLeft = left;
      this.pricingPrincipleInfo = pricingPrincipleInfo;
      this.showPrinciplesModal = true;
    },
    showPrinciplesMessages(index) {
      if (this.displayPrinciplesMessages.includes(index)) {
        this.displayPrinciplesMessages = this.displayPrinciplesMessages.filter(
          (p) => p !== index
        );
      } else {
        this.displayPrinciplesMessages.push(index);
      }
    },
    closePrinciplesModal() {
      this.displayPrinciplesMessages.length = 0;
      this.showPrinciplesModal = false;
    },
    toggleFiltersPanel(opened) {
      this.filtersPanelOpenedMobile = opened;
    },
    lockDetailsButton(loadingBuildup) {
      this.detailsButtonLocked = loadingBuildup;
    }
  },
};
</script>
