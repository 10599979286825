<template>
  <div class="chart-block card-card m-0 pt-0 px-0">
    <div class="chart-header">
      <h1 class="m-0">{{ name }}</h1>
      <div class="chart-calendar-switcher-buttons">
        <ExcelXlsxButton
          v-if="!loading"
          class="toggle-button-xlsx"
          style="border: 1px solid $main-blue;"
          :data="items"
          :columns="xlsxColumns"
          :file-name="'Brand Sales'"
          sheet-name="Default"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22px"
            height="22px"
            viewBox="0 0 26 26"
            :stroke="'#3b7d15'"
          >
            <path
              style="fill: #030104"
              d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
            />
            <path
              style="fill: #ffffff"
              d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
            />
          </svg>
        </ExcelXlsxButton>
        <DatePicker
          v-if="showCalendar"
          v-model:value="range"
          :clearable="false"
          type="date"
          range
          :disabled-date="disabledEndDate"
          placeholder="Data Select"
          @change="getDataAndDraw"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>
        <div class="chart-toggle-range-buttons">
          <button
            class="toggle-button"
            style="border-left: 0px"
            @click="switchDatePickerType()"
          >
            YTD
          </button>
        </div>
      </div>
    </div>

    <div class="chart-content" :style="showTable && 'display: none;'">
      <div class="chart-container" :class="{ 'd-none': showTable }">
        <div class="pie-chart" :style="loading && 'display: none;'">
          <canvas ref="chart" width="500" height="320" />
        </div>
        <div
          v-if="loading"
        >
          <b-spinner
            type="border"
            small
            class="tab-spinner"
          />
        </div>
        <div
          v-if="!loading && data && data.sum_of_sales && data.sum_of_units"
          class="chart-summary"
        >
          <span>Summary</span>
          <h3>€{{ $helpers.numberWithCommas(summary.sales.toFixed(2)) }}</h3>
          <span class="chart-summary-units"
            >{{ $helpers.numberWithCommas(summary.items) }} Units</span
          >
        </div>
      </div>
    </div>
  </div>

  <div v-if="!loading && checkRoute()" class="row small-table-row">
    <div class="col-md-12">
      <HierarchyItemTabBrandCake
        :items="items"
        :table-keys="[
          'name',
          'ytd_eur',
          'ytd_units',
        ]"
        :name="'brands_cake'"
        :show-title="false"
      />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import api from '@/helpers/api';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';
import { brandColors, countryColors } from '@/helpers/constants';
import HierarchyItemTabBrandCake from '@/components/HierarchyItemList/HierarchyItemTabBrandCake.vue';
import { setColorByName } from '@/helpers';
import router from '~/router';

export default {
  components: { ExcelXlsxButton, HierarchyItemTabBrandCake },
  name: 'PieChartWithDate',
  plugins: [ChartDataLabels],
  props: {
    object: String,
    name: {
      type: String,
      default: 'Portfolio shar',
    },
    state: {
      type: String,
      default: 'euro',
    },
  },
  data() {
    return {
      datacollection: null,
      data: null,
      items: [],
      type: 'doughnut',
      myChart: null,
      loading: false,
      showTable: false,
      datasets: [],
      labels: [],
      summary: {
        items: 0,
        sales: 0,
      },
      showCalendar: {
        type: Boolean,
        default: true,
      },
      disabledBefore: new Date(2012, 0, 1),
      disabledAfter: new Date(2026, 0, 1),
      range: [
        this.startDate
          ? this.startDate
          : new Date(new Date().getFullYear(), 0, 1),
        this.endDate ? this.endDate : new Date(),
      ],
      colors: [
        '#333FFF',
        '#58CFFF',
        '#B8FFF2',
        '#7A79FF',
        '#FFB0E3',
        '#23B899',
      ]
    };
  },

  computed: {
    xlsxColumns() {
      const name = this.checkRoute() ? 'Brands' : 'Country'

      return [
        { label: `${name} name`, field: 'name' },
        { label: 'Brand Sales in EUR', field: 'ytd_eur' },
        { label: 'Brand Sales in Units', field: 'ytd_units' }
      ]
    }
  },

  async mounted() {
    await this.getDataAndDraw();
  },

  beforeUnmount() {
    if (this.myChart) {
      for (const chart of Object.values(window.Chart.instances)) {
        window.Chart.animationService.cancelAnimation(chart);
      }
      this.myChart.destroy();
    }
  },

  methods: {
    async getDataAndDraw() {
      await this.getData(
        this.$helpers.dateToString(this.range[0]),
        this.$helpers.dateToString(this.range[1])
      );
      this.createChart(this.datacollection);
    },
    async getData(fromDate, toDate, withReload = true) {
      if (withReload) this.loading = true;
      if (this.datasets) {
        this.datasets = [];
      }
      if (this.labels) {
        this.labels = [];
      }

      const url = `${this.$route.path}.json?from_date=${fromDate}&to_date=${toDate}`;
      let { data: response } = await api.get(url);

      let result = this.extractResponse(response);
      this.items = result.filter((v) => v.ytd_eur > 0 && v.ytd_units > 0);
      this.items = this.items.sort((a, b) => b.ytd_eur - a.ytd_eur);

      this.fillData();

      if (withReload) this.loading = false;
    },
    extractResponse(response) {
      let path = this.$route.path;
      if (path.includes('/brands/')) {
        return response.data;
      } else {
        return response.map((p) => p.to_json);
      }
    },
    async switchDatePickerType() {
      let year = new Date().getFullYear();
      this.range = [new Date(year, 0, 1), new Date()];

      await this.getDataAndDraw();
    },
    fillData() {
      this.datasets.push(this.prepareDataSet());

      const self = this;
      this.datacollection = {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          layout: {
            padding: {
              left: 10,
              bottom: 10,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right',
            align: 'left',
            labels: {
              fontSize: 14,
            },
          },
          title: {
            text: `YTD sales in ${this.state}`,
            display: true,
            position: 'right',
            fontSize: '15',
            textDirection: 'ltr',
          },
          tooltips: {
            enabled: true,
            position: 'average',
            caretSize: 15,
            caretPadding: 2,
            mode: 'point',
            intersect: false,
            titleFontSize: 8,
            titleFontColor: '#003B6A',
            backgroundColor: '#a5b4c6',
            callbacks: {
              label: function (item, ctx) {
                let sum = 0;
                let dataArr = ctx.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let value = ctx.datasets[0].data[item.index];
                let label = ctx.labels[item.index];
                let percentage = (value * 100) / sum;

                return (
                  label +
                  ': ' +
                  self.$helpers.numberWithCommas(value.toFixed(2)) +
                  '(' +
                  self.$helpers.numberWithCommas(percentage.toFixed(2)) +
                  '%)'
                );
              },
            },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = (value * 100) / sum;

                if (percentage > 5) {
                  return percentage.toFixed(2) + '%';
                } else if (percentage > 1) {
                  return '<5%';
                } else {
                  return '';
                }
              },
              color: '#ffffff',
              anchor: 'center',
              offset: 1,
              opacity: 1,
              textShadowBlur: 1,
              textShadowColor: '#fafafa',
              font: {
                size: 11,
                style: 'bold',
              },
            },
          },
        },
      };
    },
    prepareDataSet() {
      let set = { data: [], backgroundColor: [] };

      if (this.state === 'euro') {
        this.items
          .filter(
            (item) =>
              item.month_eur + item.ytd_eur > 0 && item.name != 'Undefined'
          )
          .slice(0, 8)
          .forEach((item, i) => {
            this.labels.push(item.name);
            set['data'].push(item.ytd_eur);
            set['backgroundColor'].push(this.setColor(item.name, i));
          });
      } else {
        this.items
          .filter(
            (item) =>
              item.month_units + item.ytd_units > 0 && item.name != 'Undefined'
          )
          .slice(0, 8)
          .forEach((item, i) => {
            this.labels.push(item.name);
            set['data'].push(item.ytd_units);
            set['backgroundColor'].push(this.setColor(item.name, i));
          });
      }

      if (this.items.length > 8) {
        set = this.addOthers(set);
      }

      return set;
    },
    addOthers(set) {
      let sum = 0;
      this.labels.push('Others');

      this.items.slice(8, this.items.length).forEach((item, i) => {
        sum += this.state === 'euro' ? item.ytd_eur : item.ytd_units;
      });

      set['data'].push(sum);
      set['backgroundColor'].push('#aaaaaa');

      return set;
    },
    setColor(itemName, index) {
      itemName = itemName
        .split(' ')
        .sort((a, b) => b.length - a.length)[0]
        .toLowerCase()
        .replace('s-', '');
      return (
        brandColors[itemName] || countryColors[itemName] || this.colors[index]
      );
    },
    createChart(chartData) {
      const ctx = this.$refs.chart?.getContext('2d');

      if (!ctx) return;

      if (this.myChart) {
        for (const chart of Object.values(window.Chart.instances)) {
          window.Chart.animationService.cancelAnimation(chart);
        }
        this.myChart.destroy();
      }

      this.myChart = new Chart(ctx, chartData);
    },
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    checkRoute() {
      return this.$route.path.split('/').pop() === 'brands'
    }
  },
};
</script>
