<template>
  <div class="card info-card p-0 m-0">
    <span class="object-information">Information</span>
    <div
      class="info"
      :style="{
        gridTemplateRows: `repeat(${rowsNumber}, auto)`,
        gridTemplateColumns: `repeat(${columnsNumber}, minmax(0, 1fr))`,
        padding: setPadding()
      }"
    >
      <div
        v-if="data.id && $route.path.includes('profit_center_variants')"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="SAP-ID" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.sap_id }}
        </span>
      </div>

      <div
        v-if="data.sap_name && $route.path.includes('profit_center_variants')"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="SAP-NAME" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.sap_name }}
        </span>
      </div>

      <div
        v-if="data.cid && $route.path.includes('profit_center_variants')"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="PZN" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.cid }}
        </span>
      </div>

      <div v-if="data.eng_name" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="English name" icon="product-variant-icon" />
        </span>
        <span v-floating-tooltip="`${data.eng_name}`" class="info-card-value">
          {{ data.eng_name }}
        </span>
      </div>

      <div v-if="data.name" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="name" icon="country-icon" />
        </span>
        <span class="info-card-value link clickable">
          {{ data.name }}
        </span>
      </div>

      <div v-if="data.country" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="country" icon="country-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/countries/${data.country.id}`)"
        >
          {{ data.country.name }}
        </span>
      </div>

      <div v-if="data.countries" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="countries" icon="country-icon" />
        </span>
        <span class="info-card-value">
          <span
            v-for="(country, i) in data.countries"
            :key="country.id"
            class="link clickable"
            @click="$router.push(`/countries/${country.id}`)"
          >
            {{ i > 0 ? ', ' : '' }}{{ country.name }}
          </span>
        </span>
      </div>

      <div v-if="data.external_id" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="ID" icon="id-icon" />
        </span>
        <span class="info-card-value link clickable">
          {{ data.external_id }}
        </span>
      </div>

      <div v-if="data.reference_countries" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="IRP" icon="reference-countries-icon" />
        </span>
        <span class="info-card-value">
          {{ data.reference_countries.length ? '✓' : '-' }}
        </span>
      </div>

      <div
        v-if="data.country && data.country.reference_countries"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="IRP" icon="reference-countries-icon" />
        </span>
        <span class="info-card-value">
          {{ data.country.reference_countries.length ? '✓' : '-' }}
        </span>
      </div>

      <div v-if="data.region" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="region" icon="region-icon" />
        </span>
        <span class="info-card-value">
          {{ data.region.name }}
        </span>
      </div>

      <div v-if="data.country && data.country.region" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="region" icon="region-icon" />
        </span>
        <span class="info-card-value">
          {{ data.country.region.name }}
        </span>
      </div>

      <div
        v-if="$route.path.includes('data_change_requests')"
        class="card-item"
      >
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Changeable" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.changeable }}
        </span>
      </div>

      <div
        v-if="$route.path.includes('data_change_requests')"
        class="card-item"
      >
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Field" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ $helpers.fieldToSpecificName(data.field) }}
        </span>
      </div>

      <div
        v-if="$route.path.includes('data_change_requests')"
        class="card-item"
      >
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Valid from" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.valid_from }}
        </span>
      </div>

      <div
        v-if="$route.path.includes('data_change_requests')"
        class="card-item"
      >
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Valid from" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.valid_until }}
        </span>
      </div>

      <div v-if="data.manufacturer" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="manufacturer" icon="manufacturer-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/manufacturers/${data.manufacturer.id}`)"
        >
          {{ data.manufacturer.name }}
        </span>
      </div>

      <div v-else-if="data.manufacturer_id" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="manufacturer" icon="manufacturer-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/manufacturers/${data.manufacturer_id}`)"
        >
          {{ data.manufacturer_name }}
        </span>
      </div>

      <div
        v-if="data.trade_unions && Object.keys(data.trade_unions).length > 0"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="economic union" icon="trade-union-icon" />
        </span>
        <span class="info-card-value">
          {{ data.trade_unions[0].name }}
        </span>
      </div>

      <div v-if="data.business_model" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="business model" icon="business-model-icon" />
        </span>
        <span class="info-card-value">
          {{ data.business_model }}
        </span>
      </div>

      <div
        v-if="
          data.country &&
          data.country.trade_unions &&
          Object.keys(data.country.trade_unions).length > 0
        "
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="economic union" icon="trade-union-icon" />
        </span>
        <span
          v-floating-tooltip="`${data.country.trade_unions[0].name}`"
          class="info-card-value"
        >
          {{ data.country.trade_unions[0].name }}
        </span>
      </div>

      <div v-if="data.country && data.country.business_model" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="business model" icon="business-model-icon" />
        </span>
        <span class="info-card-value">
          {{ data.country.business_model }}
        </span>
      </div>

      <div v-if="data.brand" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="brand" icon="brand-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/brands/${data.brand.id}`)"
        >
          {{ data.brand.name }}
        </span>
      </div>

      <div v-else-if="data.brand_id" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="brand" icon="brand-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/brands/${data.brand_id}`)"
        >
          {{ data.brand_name }}
        </span>
      </div>

      <div v-if="data.brand && data.brand.product_category" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="product category" icon="product-category-icon" />
        </span>
        <span class="info-card-value">
          {{ data.brand.product_category }}
        </span>
      </div>

      <div v-if="data.product" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="sub-brand" icon="product-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/products/${data.product.id}`)"
        >
          {{ data.product.name }}
        </span>
      </div>

      <div
        v-if="
          data.product_variant &&
          data.product_variant.full_name &&
          data.product_variant.id
        "
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="product variant" icon="product-variant-icon" />
        </span>
        <span
          v-floating-tooltip="`${data.product_variant.full_name}`"
          class="info-card-value link clickable"
          @click="$router.push(`/product_variants/${data.product_variant.id}`)"
        >
          {{ data.product_variant.full_name }}
        </span>
      </div>

      <div
        v-if="data.product_variant && data.product_variant.dosage_type"
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="dosage forms" icon="dosage-forms-icon" />
        </span>
        <span class="info-card-value">
          {{ data.product_variant.dosage_type }}
        </span>
      </div>

      <div v-if="data.dosage_type" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="dosage forms" icon="dosage-forms-icon" />
        </span>
        <span class="info-card-value">
          {{ data.dosage_type }}
        </span>
      </div>

      <div v-if="data.product_variant" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="package size" icon="package-size-icon" />
        </span>
        <span class="info-card-value">
          {{ data.product_variant.package_size }}
        </span>
      </div>

      <div v-if="data.package_size" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="package size" icon="package-size-icon" />
        </span>
        <span class="info-card-value">
          {{ data.package_size }}
        </span>
      </div>

      <div v-if="data.status" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="status" icon="status-icon" />
        </span>
        <span class="info-card-value">
          {{ data.status }}
        </span>
      </div>

      <div v-if="data.currency_id" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="currency" icon="currency-icon" />
        </span>
        <span
          class="info-card-value link clickable"
          @click="$router.push(`/currencies/${data.currency_id}`)"
        >
          {{ data.currency_name }}
        </span>
      </div>

      <div v-if="$route.path.includes('product_variants')" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="dosage daily" icon="dosage-daily-icon" />
        </span>
        <span class="info-card-value"> - </span>
      </div>

      <div v-if="data.num_of_products" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="num of sub-brands" icon="num-icon" />
        </span>
        <span class="info-card-value">
          {{ data.num_of_products }}
        </span>
      </div>

      <div v-if="data.num_of_product_variants" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="num of product variants" icon="num-icon" />
        </span>
        <span class="info-card-value">
          {{ data.num_of_product_variants }}
        </span>
      </div>

      <div v-if="data.num_of_profit_center_variants" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="num of products" icon="num-icon" />
        </span>
        <span class="info-card-value">
          {{ data.num_of_profit_center_variants }}
        </span>
      </div>

      <div v-if="data.num_of_customers" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="num of customers" icon="num-icon" />
        </span>
        <span class="info-card-value">
          {{ data.num_of_customers }}
        </span>
      </div>

      <div v-if="data.num_of_brands" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="num of brands" icon="num-icon" />
        </span>
        <span class="info-card-value">
          {{ data.num_of_brands }}
        </span>
      </div>

      <div v-if="data.avg_margin_bno" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="avg margin bno" />
        </span>
        <span class="info-card-value">
          {{ data.avg_margin_bno }}
        </span>
      </div>

      <div v-if="data.month_in_euro >= 0" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="month in euro" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ $helpers.numberWithCommas(data.month_in_euro) }}
        </span>
      </div>

      <div v-if="data.month_in_units >= 0" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="month in units" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ $helpers.numberWithCommas(data.month_in_units) }}
        </span>
      </div>

      <div v-if="data.ytd_in_euro >= 0" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="YTD in euro" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ $helpers.numberWithCommas(data.ytd_in_euro) }}
        </span>
      </div>

      <div v-if="data.ytd_in_units >= 0" class="card-item">
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="YTD in units" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ $helpers.numberWithCommas(data.ytd_in_units) }}
        </span>
      </div>

      <div v-if="data.department" class="card-item">
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Department" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ data.department }}
        </span>
      </div>

      <div v-if="data.main_divison" class="card-item">
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Main Divison" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ data.main_divison }}
        </span>
      </div>

      <div v-if="data.area" class="card-item">
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Area" icon="num-icon" />
        </span>
        <span class="info-card-value link">
          {{ data.area }}
        </span>
      </div>
      <div
        v-if="data.max_daily_dosage || data.max_daily_dosage === 0"
        class="card-item"
      >
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Max Daily Dosage" icon="dosage-daily-icon" />
        </span>
        <span class="info-card-value link">
          {{ data.max_daily_dosage }}
        </span>
      </div>
      <div v-if="data.reach || data.reach === 0" class="card-item">
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Reach" icon="dosage-forms-icon" />
        </span>
        <span class="info-card-value link">
          {{ data.reach }}
        </span>
      </div>
      <div v-if="data.daily_dosage_price" class="card-item">
        <span class="title" :class="{ longTitle: false }">
          <IconText title="Daily Dosage Price" icon="currency-icon" />
        </span>
        <span class="info-card-value link">
          {{ dailyDosagePrice }}
        </span>
      </div>
      <div
        v-if="
          data.is_bundle_pack && $route.path.includes('profit_center_variants')
        "
        class="card-item"
      >
        <span
          class="title"
          :class="{ longTitle: data.num_of_profit_center_variants }"
        >
          <IconText title="Package" icon="id-icon" />
        </span>
        <span class="info-card-value">
          {{ data.is_bundle_pack ? 'Bundle Pack' : 'None' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';

export default {
  name: 'InfoCard',
  components: { IconText },
  props: {
    data: Object,
    columnsNumber: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      rowsNumber: null,
    };
  },
  computed: {
    dailyDosagePrice() {
      if (!this.data.daily_dosage_price.eur) return '-';

      let euroPrice = `${this.$helpers.numberWithCommas(
        this.data.daily_dosage_price.eur.toFixed(2)
      )} ${this.$helpers.getCurrencySymbol()}`;

      if (this.data.currency_short === 'EUR') return euroPrice;

      if (!this.data.daily_dosage_price.loc_cur) return '-';

      return `${this.$helpers.numberWithCommas(
        this.data.daily_dosage_price.loc_cur.toFixed(2)
      )} ${this.$helpers.getCurrencySymbol(
        this.data.currency_short
      )} (${euroPrice})`;
    },
  },
  mounted() {
    this.$nextTick(function () {
      const cardItems = this.$el.querySelectorAll('.card-item').length;
      this.rowsNumber = Math.ceil(cardItems / this.columnsNumber);
    });
  },
  methods: {
    setPadding() {
      if (this.rowsNumber == 8) {
       return '10px 20px 45px' 
      } else if (this.rowsNumber == 9) {
        return '10px 20px 7px' 
      }
    }
  }
};
</script>
