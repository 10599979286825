<template>
  <div class="container">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div
      class="hierarchy-item-title fixed"
      :class="{ scrolled: currentScrollPos < 40 }"
    >
      <h1 :class="{ loading }">
        Price change request for {{
          changeRequest.full_name
            ? changeRequest.full_name
            : changeRequest.name
            ? changeRequest.name
            : changeRequest.is_bundle_pack
            ? changeRequest.sap_name
            : changeRequest.gpms_name
            ? changeRequest.gpms_name
            : changeRequest.changeable
        }}
      </h1>
    </div>

    <div :class="'grid'">
      <div class="grid-info-card mb-30">
        <InfoCard
          v-if="Object.keys(changeRequest).length"
          :data="changeRequest"
          :columns-number="1"
        />
      </div>
    </div>
    <div class="d-flex align-items-start">
      <button
        v-if="changeRequest.type == 'CountryDataChangeRequest'"
        v-floating-tooltip="'View attachments'"
        class="btn btn-attachment btn-sm ms-10"
        @click="openAttachmentsModal(changeRequest)"
        style="display: flex;justify-content: center;align-items: center;"
      >View attachments<i class="icon attachment-icon display-inline ms-3" />
      </button>
      <button
        v-if="changeRequest && changeRequest.comments"  
        class="btn btn-no-style btn-sm ms-10"
      >
        <p class="display-inline">Comments</p>
        <RequestComments
          :request="changeRequest"
          :user-info="userInfo"
          :commentable-type="changeRequest.type"
          class="display-inline ms-10"
        />
      </button>
    </div>
    <RequestDetails
      v-if="changeRequest && !$helpers.isEmptyArray(userInfo)"
      :change-request="changeRequest"
      :field="changeRequest.field"
      :row-index="0"
      :user-info="userInfo"
      :currency-sign="$helpers.getCurrencySymbol(changeRequest.object_name)"
      @approve-changes="approveChanges"
      @submit-changes="submitChanges"
      @reject-changes="rejectChanges"
      @open-finish-modal="openFinishModal"
      @discard-request="discardRequest"
    />
    <AttachmentsModal
      v-if="attachmentsModalVisible"
      v-model:attachmentObject="attachmentObject"
      :key="componentKey"
      :top="150"
      :left="650"
      :type="'Country'"
      :user-info="userInfo"
      @close="attachmentsModalVisible = false"
    />
    <FinishPopupModal
      v-if="changeRequest &&finishPopupVisible"
      :changeRequest="changeRequest"
      :user-info="userInfo"
      :modal-action="modalAction"
      :top="250"
      :left="650"
      @finish-request="finishRequest"
      @close="closeModal"/>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard.vue';
import { hierarchy, currenciesShortISO4217 } from '@/helpers/constants';
import api from '@/helpers/api';
import AttachmentsModal from '@/components/PricePlanning/Modals/AttachmentsModal.vue';
import RequestComments from '@/components/PricePlanning/RequestComments.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import RequestDetails from '@/components/CurrencyDataChangeRequest/RequestDetails.vue';
import FinishPopupModal from '@/components/CurrencyDataChangeRequest/FinishPopupModal.vue';

export default {
  name: 'DataChangeRequestDetails',
  components: {
    InfoCard,
    AttachmentsModal,
    RequestComments,
    FadeLoader,
    RequestDetails,
    FinishPopupModal
  },
  props: {
    currentScrollPos: Number,
    userInfo: Object,
  },
  emits: ['close'],
  data() {
    return {
      changeRequest: {},
      hierarchy,
      loading: false,
      currency: {
        value: '',
        options: currenciesShortISO4217,
      },
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      pagesStack: [],
      items: [],
      currentDetailsItems: [],
      countryPriceBuildupCurrency: 'EUR',
      currentDetailsIds: [],
      loadingPriceBuildupCurrency: false,
      attachmentsModalVisible: false,
      componentKey: Math.random(),
      finishPopupVisible: false,
      modalAction: '',
    };
  },
  computed: {
    currentDetailsPage() {
      return this.pagesStack[this.pagesStack.length - 1];
    },
    dataType() {
      return this.changeRequest['price_buildups'][this.currentDetailsPage[0]].type;
    },
  },
  async beforeMount() {
    this.loading = true;

    await this.getData();

    this.loading = false;
  },
  methods: {
    async getData() {
      const response = await api.get(`${this.$route.path}.json`);
      response.data.data.range = this.updateRange(response.data.data);
      this.changeRequest = response.data.data;
    },

    async submitChanges() {
      this.loading = true;

      const result = await api
        .post(
          `/data_change_requests/`,
          JSON.stringify({
            object: this.changeRequest,
            object_id: this.changeRequest.id,
            field: this.changeRequest.field,
            comment: this.changeRequest.comment,
            valid_from: this.$helpers.dateToString(this.changeRequest.range[0]),
            valid_until: this.$helpers.dateToString(
              this.changeRequest.range[1]
            ),
            values: {
              [this.field]: this.changeRequest.amount,
            },
          })
        )
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          this.showErrors(err.response.data);
        });

      this.loading = false;
    },

    async rejectChanges() {
      this.loading = true;

      const result = await api
        .post(
          `/data_change_requests/${this.changeRequest.id}/reject`,
          JSON.stringify({
            comment: this.changeRequest.comment,
            valid_from: this.$helpers.dateToString(this.changeRequest.range[0]),
            valid_until: this.$helpers.dateToString(
              this.changeRequest.range[1]
            ),
            values: {
              [this.field]: this.changeRequest.amount,
            },
          })
        )
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          this.showErrors(err.response.data);
        });

      this.loading = false;
    },

    async approveChanges() {
      this.loading = true;

      const result = await api.post(
        `/data_change_requests/${this.changeRequest.id}/approve`,
        JSON.stringify({
          comment: this.changeRequest.comment,
        })
      );

      this.loading = false;
      this.getData();
    },

    openFinishModal(item) {
      this.componentKey = Math.random();
      this.finishPopupVisible = true;
      this.modalAction = 'finish';
    },

    async finishRequest() {
      this.finishPopupVisible = false;
      this.loading = true;

      const result = await api.post(
        `/data_change_requests/${this.changeRequest.id}/finish`,
        JSON.stringify({
          comment: this.changeRequest.comment,
          valid_from: this.$helpers.dateToString(this.changeRequest.range[0]),
          valid_until: this.$helpers.dateToString(this.changeRequest.range[1]),
        })
      );
      this.loading = false;
      this.getData();
    },

    async discardRequest() {
      this.loading = true;

      await api
        .post(`/data_change_requests/${this.changeRequest.id}/discard`)
        .then((response) => {
          this.$router.push(`/data_change_requests`);
        });

      this.loading = false;
    },

    showErrors(errors) {
      Object.values(errors).forEach(v => { this.$helpers.notifyError(v[0]) });
      this.requestInProgress = false;
    },

    stackPage(page) {
      this.pagesStack.push(page);
      const plannedItems =
        this.changeRequest['price_buildups'][this.currentDetailsPage[0]];
      const realItems =
        this.changeRequest['price_buildups'][this.currentDetailsPage[0] + ' real'];
      this.items = {
        planned: plannedItems.items.sort((a, b) => a.value - b.value),
        real: realItems
          ? realItems.items.sort((a, b) => a.value - b.value)
          : [],
      };
      this.setCurrentDetailsItems(this.items);
    },

    unstackPage() {
      if (this.pagesStack.length) {
        this.pagesStack.pop();
      } else {
        this.$emit('close');
      }
    },

    setCurrentDetailsItems(currentDetailsItems) {
      this.currentDetailsItems = currentDetailsItems;
      this.currentDetailsIds = currentDetailsItems.planned.map(
        (item) => item.id
      );
    },

    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.analysis.scrollTop = 0;
      });
    },

    updateRange(request) {
      const range = [
        new Date(request.valid_from),
        new Date(request.valid_until),
      ];

      return range;
    },

    async selectPriceBuildupCurrency(currency) {
      this.loadingPriceBuildupCurrency = true;
      const response = await api.get(
        `/countries/${this.changeRequest.id}/price_buildups?currency_short=${currency}`
      );
      this.changeRequest.price_buildups = response.data.price_buildups;
      this.countryPriceBuildupCurrency = currency;
      this.loadingPriceBuildupCurrency = false;
    },

    openAttachmentsModal(countryChangeRequest) {
      this.attachmentObject = countryChangeRequest;
      this.attachmentsModalVisible = true;
    },

    closeModal() {
      this.finishPopupVisible = false;
    },

    showErrors(errors) {
      Object.values(errors).forEach(v => { this.$helpers.notifyError(v[0]) });
      this.requestInProgress = false;
    },
  },
};
</script>
