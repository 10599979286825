<template>
  <form
    @submit.prevent="showEditModal ? $emit('updateItem') : $emit('addItem')"
  >
    <div>
      <h2>{{ showEditModal ? 'Edit' : 'Add' }} Profit Center Variant</h2>
      <div
        v-for="field in Object.keys(newItemForm)"
        :key="field"
        :class="['form-group', { 'modal-checkbox': field === 'is_visible' }]"
      >
        <label v-if="field === 'sap_id'" :for="field" class="modal-label">{{
          field
        }}</label>
        <label v-else :for="field" class="modal-label">{{
          field || withoutId(field)
        }}</label>

        <Multiselect
          v-if="
            dropdownsOptions.hasOwnProperty(field) &&
            newItemForm.hasOwnProperty(field)
          "
          v-model="newItemFormProp[field]"
          :options="dropdownsOptions[field]"
          label="name"
          track-by="id"
          :show-labels="false"
        >
          <template #caret="{ toggle }">
            <div class="multiselect__select" @mousedown.prevent.stop="toggle">
              <i class="icon icon-down-arrow" />
            </div>
          </template>
        </Multiselect>

        <label v-else-if="field === 'is_visible'" class="checkbox">
          <div class="checkbox__input me-2">
            <input
              id="checkbox"
              v-model="newItemFormProp[field]"
              type="checkbox"
              name="checked"
              class="checkboxInput"
            />
            <div class="checkbox__control">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  d="M1.73 12.91l6.37 6.37L22.79 4.59"
                />
              </svg>
            </div>
          </div>
        </label>

        <input
          v-else
          :id="field"
          v-model="newItemFormProp[field]"
          class="form-control text-input"
        />
      </div>
    </div>
    <button type="submit" class="btn btn-green modal-button">
      {{ showEditModal ? 'Save' : 'Add' }}
    </button>
  </form>
</template>

<script>
export default {
  name: 'EditNewForm',
  props: {
    showEditModal: Boolean,
    newItemForm: Object,
    dropdownsOptions: Object
  },
  emits: ['updateItem', 'addItem', 'update:newItemForm'],
  computed: {
    newItemFormProp: {
      get() {
        return this.newItemForm;
      },
      set(value) {
        this.$emit('update:newItemForm', value);
      },
    },
    withoutId(title) {
      if (!title) return 'No title';
      if (title.split('_').pop() === 'id' && title !== 'id') {
        return title.slice(0, -3);
      }
      return title;
    },
  },
};
</script>
