<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="switchUserModalContainer"
          class="comments-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '45%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <div>
              <h3>Login As</h3>
              <div class="second-title-grayed mb-30">
                -> {{ switchUser.first_name }} {{ switchUser.last_name }} {{ switchUser.roles.map(a => a.title) }}
              </div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10 ">
                  <label for="commentInput"
                    >Are you sure you want to switch users?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-danger normal-button"
                  @click="$emit('becomeUser', switchUser)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    switchUser: Object,
    top: Number,
    left: Number,
    userInfo: Object,
  },
  emits: [
    'close',
    'becomeUser',
  ],
  data() {
    return {
    };
  },
  methods: {
    rejectChanges(changeRequest) {
      let actionName = this.rejectTypeAction();
      this.$emit(actionName, changeRequest, changeRequest.comment);
    },
  },
};
</script>
