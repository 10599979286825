<template>
  <div class="feedback">
    <div
      :class="['formButtonContainer', { active: formVisible }]"
      @click="toggleFormVisiblity()"
    >
      <div class="feedbackInfo">
        {{ getFeedbackButtonInfo() }}
      </div>
      <h3 class="feedbackTitle">
        <IconText class="formIcon" icon="status-icon" title="" />
      </h3>
    </div>
    <div v-if="formVisible" class="formContentWrapper">
      <div class="col col-xs-12 headerCol">
        <h3>{{ getFeedbackTitle() }}</h3>
      </div>
      <div class="col col-xs-12 formCol">
        <div class="form-group">
          <div class="typeSelect">
            <ToggleSwitch
              v-model="feedbackTypeSelect"
              :show-switch="true"
              :switch-options="feedbackTypeOptions"
            />
          </div>

          <textarea
            v-model="feedbackMessage"
            class="form-control text-input messageBox"
            placeholder="Your message goes here..."
          />

          <input
            type="submit"
            class="btn btn-submit"
            value="Send Feedback"
            @click="sendForm()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import IconText from '@/components/IconText.vue';

export default {
  name: 'FeedbackForm',
  components: {
    IconText,
    ToggleSwitch,
  },
  props: {
    feedbackType: {
      type: String,
      default: 'feedback',
    },
    firstName: String,
    lastName: String,
    email: String,
    message: String,
  },
  data() {
    return {
      data: {},
      formVisible: false,
      feedbackTypeSelect: 'feedback',
      feedbackTypeOptions: ['feedback', 'bug'],
      feedbackMessage: '',
    };
  },

  methods: {
    toggleFormVisiblity() {
      this.formVisible = !this.formVisible;
    },
    changeFeedbackType(selectedType) {
      this.feedbackTypeSelect = selectedType;
    },
    getFeedbackTitle() {
      return this.feedbackTypeSelect == 'feedback'
        ? 'Leave Feedback'
        : 'Report a bug';
    },
    getFeedbackButtonTitle() {
      return this.formVisible ? 'Close x' : 'Feedback';
    },
    getFeedbackButtonInfo() {
      return this.formVisible
        ? 'Click to close feedback form!'
        : 'Click to leave feedback or report a bug!';
    },
    async sendForm() {
      this.loading = true;


      let result = await api.post(
        `/feedbacks`,
        JSON.stringify({
          feedback_type: this.feedbackTypeSelect,
          message: this.feedbackMessage
        })
      );

      if (result.status == 200) {
        this.formVisible = false;
        this.feedbackMessage = '';
        this.feedbackTypeSelect = 'feedback';
        this.$helpers.notifySuccess(`Thank you for your ${this.feedbackTypeSelect}. It has been succesfuly send.`);
      } else {
        this.$helpers.notifyError(`There has been an error sending your ${this.feedbackTypeSelect}. Please try again later.`);
      }

      this.loading = false;
    },
  },
};
</script>
