<template>
  <div class="card table-card little-table-container">
    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span>
        <PulseLoader :loading="loading" />
      </span>
    </div>
    <b-card no-body>
      <b-tabs
        card
        content-class="mt-3"
        fill
        active-nav-item-class="font-weight-bold text-uppercase"
      >
        <b-tab
          v-for="principle in pricingPrinciples"
          :key="principle.name"
          no-body
        >
          <template #title>
            <b-spinner v-if="loading" type="border" small class="tab-spinner" />
              <svg
              v-else 
              style="margin-right: 10px"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7498 0C18.9925 0 19.9998 1.00736 19.9998 2.25V7.71196C19.9998 8.57377 19.6575 9.4003 19.0482 10.0098L10.5472 18.5129C9.2777 19.7798 7.22195 19.7807 5.95079 18.5143L1.48909 14.0592C0.218623 12.7913 0.216995 10.7334 1.48531 9.46316L9.98498 0.953336C10.5946 0.342966 11.4218 0 12.2845 0H17.7498ZM17.7498 1.5H12.2845C11.82 1.5 11.3745 1.68467 11.0463 2.01333L2.53412 10.5358C1.86389 11.2207 1.86898 12.3191 2.54884 12.9977L7.01006 17.4522C7.69493 18.1345 8.8033 18.134 9.48697 17.4518L17.9874 8.94924C18.3155 8.62107 18.4998 8.17601 18.4998 7.71196V2.25C18.4998 1.83579 18.164 1.5 17.7498 1.5ZM14.9998 3.50218C15.8282 3.50218 16.4998 4.17374 16.4998 5.00216C16.4998 5.83057 15.8282 6.50213 14.9998 6.50213C14.1714 6.50213 13.4998 5.83057 13.4998 5.00216C13.4998 4.17374 14.1714 3.50218 14.9998 3.50218Z"
                  :fill="'#839AB5'"
                />
              </svg>
            <div class="tab-title">{{ principle.name }}</div>
          </template>
          <div class="row small-table-row">
            <div class="col-md-12">
              <div class="table-header justify-content-right">
                <div v-if="!countriesListPage" class="table-header-right">
                  <Multiselect
                    v-if="!$helpers.isEmptyObject(filterOptions)"
                    v-model="selectedFilter"
                    :options="filterOptions"
                    select-label
                    placeholder
                    :show-labels="false"
                    class="display-inline"
                  >
                    <template #caret="{ toggle }">
                      <div
                        class="multiselect__select"
                        @mousedown.prevent.stop="toggle"
                      >
                        <i class="icon icon-down-arrow" />
                      </div>
                    </template>
                  </Multiselect>
                </div>
              </div>

              <div
                class="table-card little-table-container"
                style="border-radius: none !importnant"
              >
                <div class="table-responsive m-0">
                  <table
                    v-if="principle.data.length > 0"
                    class="table table-striped-small borderless little-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col" @click="reverseList()">
                          <template v-if="countriesListPage">Country</template>
                          <template v-else>Product</template>
                          <span class="display-inline">
                            <svg
                              width="12"
                              height="7"
                              viewBox="0 0 12 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-down-arrow open-details-cell-icon clickable link"
                              :class="{
                                'rotate-tab-arrow': sortDir === 'desc',
                              }"
                            >
                              <path
                                d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                                fill="#9CB2CB"
                              />
                            </svg>
                          </span>
                        </th>
                        <th scope="col">
                          <template
                            v-if="
                              principle.name === 'European Union' ||
                              principle.union === 'European Union'
                            "
                            >Reference: Germany +/-10%</template
                          >
                          <template v-else>Reference: Russia +/-10%</template>
                        </th>
                        <th scope="col">
                          <template
                            v-if="
                              principle.name === 'European Union' ||
                              principle.union === 'European Union'
                            "
                            >Reference: European Union +/-10%</template
                          >
                          <template v-else
                            >Reference: Eurasian Economic Union +/-10%</template
                          >
                        </th>
                        <th>Reference: Germany +/-15%</th>
                        <th v-if="!countriesListPage" scope="col">Status</th>
                        <th />
                      </tr>
                    </thead>

                    <CorridorWholesalerTab
                      :data="filteredPrinciples(principle)"
                      :reverse="reverse"
                      :countries-list-page="countriesListPage"
                      :object-type="objectType"
                      :union="principle.union"
                      :selected-filter="selectedFilter"
                      @open-principles-modal="openPrinciplesModal"
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <div class="row small-table-row">
      <div class="col-md-12" />
    </div>

    <div class="row small-table-row">
      <div class="col-md-12" />
    </div>
  </div>
</template>

<script>
import CorridorWholesalerTab from './CorridorWholesalerTab.vue';

export default {
  name: 'CorridorWholesalerTable',
  components: { CorridorWholesalerTab },
  props: {
    objectType: String,
    pricingPrinciples: Array,
    loading: Boolean,
    preSelectedFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
        id: null,
      },
      selectedFilter: 'active',
      showPrinciplesModal: false,
      reverse: false,
      sortDir: 'desc',
    };
  },
  computed: {
    filterOptions() {
      if (this.pricingPrinciples.length > 0) {
        let array = ['Show all'];

        this.pricingPrinciples[0].data.map((item) => array.push(item.status));
        return [...new Set(array)];
      } else {
        return [];
      }
    },
    countriesListPage() {
      return this.objectType == 'country';
    },
  },
  watch: {
    selectedFilter() {
      this.openedDetails.row = -1;
    },
  },
  async mounted() {
    if (this.preSelectedFilter != '') {
      this.selectedFilter = this.preSelectedFilter;
    }
  },
  methods: {
    getClass(value) {
      if (value) {
        return 'icon principles-ok-icon';
      } else {
        return 'icon principles-wrong-icon';
      }
    },
    openPrinciplesModal(pricingPrincipleInfo, top, left) {
      this.principlesButtonTop = top;
      this.principlesButtonLeft = left;
      this.pricingPrincipleInfo = pricingPrincipleInfo;
      this.showPrinciplesModal = true;
    },
    reverseList() {
      this.reverse = !this.reverse;
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
    },
    filteredPrinciples(principles) {
      if (this.selectedFilter == 'Show all' || this.countriesListPage) {
        return principles.data;
      } else if (this.selectedFilter != '') {
        return principles.data.filter(
          (row) => row.status == this.selectedFilter
        );
      }
    },
  },
};
</script>
