<template>
  <div class="country-icons-box">
    <div v-if="title && title.length > 0" class="country-icons-box__title">
      <span>{{ title }}</span>
    </div>
    <table class="price-referencing-table">
      <thead v-if="withPrices">
        <tr>
          <th>
            <IconText title="Country" />
          </th>
          <th>
            <IconText title="Referencing price level" :centered="false" />
          </th>
          <th>
            <IconText title="Price" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.iso_code3"
          class="price-referencing-table__middle"
        >
          <td>
            <div class="price-referencing-table__icon">
              <i class="flag" :class="countryFlagCss(item)" />
              <span>{{ item.iso_code3 }}</span>
            </div>
          </td>
          <td class="price-referencing-table__text">
            {{ countryInfo(item) }}
          </td>
          <td v-if="withPrices" class="price-referencing-table__price">
            {{ countryPrice(item) }}
          </td>
        </tr>
        <tr
          v-for="(pricesArr, i) in maxRegPrices"
          :key="i"
          class="price-referencing-table__result country-summary"
        >
          <td colspan="2">Max registered {{ pricesArr[0]['full_name'] }}</td>
          <td class="country-summary__prices">
            <div
              v-for="price in pricesArr"
              :key="`${price.value}${price.currency}`"
            >
              {{
                $helpers.valueWithCurrency(
                  price.value,
                  price.currency,
                  price.currency == 'EUR'
                )
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="withPrices" class="country-icons-box__footer">
      <span>Based on latest date of selected time period.</span>
      <LastUpdatedArea :prices-data="[summary]" />
    </div>
  </div>
</template>

<script>
import { searchResultsCardProps as props } from '@/helpers/propsConstants';
import LastUpdatedArea from '@/pages/PriceReferencing/LastUpdatedArea.vue';
import IconText from '@/components/IconText.vue';

export default {
  name: 'CountryIconsBox',
  components: {
    IconText,
    LastUpdatedArea,
  },
  props: {
    withPrices: {
      type: Boolean,
      default: false,
    },
    items: Array,
    summary: Object,
    itemKey: String,
    localCurrency: Object,
    title: String,
  },
  computed: {
    maxRegPrices() {
      if (
        !this.summary ||
        !this.summary.prices ||
        this.summary.prices.length === 0
      ) {
        return [];
      }

      return this.$helpers.groupBy(this.summary.prices, (p) => p.key);
    },
  },
  methods: {
    countryInfo(item) {
      let name = item[this.itemKey];
      let priceInfo = props.searchResult['price_info'].find(
        (d) => d.short_name === name
      );
      return priceInfo ? priceInfo.full_name : name;
    },
    countryPrice(item) {
      return item.data ? this.$helpers.valueWithCurrency(item.data) : '-';
    },
    countryFlagCss(item) {
      if (item.image_url) {
        return `flag-${item.image_url}`;
      }
    },
  },
};
</script>
