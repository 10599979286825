<template>
  <div class="search-result-pagination">
    <button
      :disabled="isFirst(pageNumber)"
      :class="['button-no-style', { 'is-gray': isFirst(pageNumber) }]"
      @click="$emit('loadPage', pageNumber - 1)"
    >
      <i class="icon left-arrow pagination-button" />
      <span class="pagination-button">Prev</span>
    </button>

    <template v-for="page in pageCountFiltered" :key="page">
      <span
        v-if="
          pageNumber > 5 && page === pageNumber - 1 && pageNumber !== pageCount
        "
      >
        ...
      </span>
      <span
        v-if="
          pageNumber === pageCount && page === pageCount - 2 && pageCount !== 3
        "
      >
        ...
      </span>

      <button
        class="pagination-button"
        :disabled="page === pageNumber"
        :class="page === pageNumber && 'active'"
        @click="$emit('loadPage', page)"
      >
        {{ page }}
      </button>

      <span v-if="pageNumber === 1 && page === 3 && pageCount !== 3">...</span>
      <span
        v-if="
          pageNumber < pageCount - 4 &&
          page === pageNumber + 1 &&
          pageNumber !== 1
        "
      >
        ...
      </span>
    </template>

    <button
      :disabled="isLast(pageNumber)"
      :class="['button-no-style', { 'is-gray': isLast(pageNumber) }]"
      @click="$emit('loadPage', pageNumber + 1)"
    >
      <span class="pagination-button">Next</span>
      <i class="icon right-arrow pagination-button" />
    </button>
  </div>
</template>

<script>
// Custom pagination component
export default {
  name: 'Pagination',
  props: {
    pageNumber: Number,
    pageCount: Number,
  },
  emits: ['loadPage'],
  computed: {
    pageCountFiltered() {
      return [...Array(this.pageCount).keys()]
        .map((i) => ++i)
        .filter((count) => this.shouldIDraw(count));
    },
  },
  methods: {
    isFirst(page) {
      return page === 1;
    },
    isLast(page) {
      return page === this.pageCount;
    },
    shouldIDraw(page) {
      return (
        page < 4 ||
        page > this.pageCount - 3 ||
        (page > this.pageNumber - 2 && page < this.pageNumber + 2)
      );
    },
  },
};
</script>
