<template>
  <div class="plan-price-level" :class="{ 'plan-price-level--error': isError }">
    <div class="header" style="height: 10px !important;" :style="backgroundColor()"></div>

    <div class="plan-price-level__buttons" style="margin-top: 5px !important;">
      <button v-if="!editMode && showEdit()" class="button-no-style" @click="editField">
        <i class="icon status-icon" />
      </button>
      <button v-if="editMode" class="button-no-style" @click="saveChange" :disabled="(!priceValue || isNaN(priceValue) || (priceData['plan_price'] == priceValue && !overwrite && !autofill)) && (!euroValue || isNaN(euroValue) || (priceData['plan_price_eur'] == euroValue && !overwrite && !autofill))">
        <i class="icon yes-icon-green" v-if="(priceValue && !isNaN(priceValue) && (priceData['plan_price'] !== priceValue || autofill || overwrite)) || (euroValue && !isNaN(euroValue) && (priceData['plan_price_eur'] !== euroValue || autofill || overwrite))"/>
        <i class="icon yes-icon-gray" v-else/>
      </button>

      <button v-if="editMode" class="button-no-style" @click="cancelEdit">
        <i class="icon close-tag-icon-big" />
      </button>
    </div>


    <div class="plan-price-level__month">
      <div>{{ monthKey }}</div>
      <div v-if="isError" v-floating-tooltip="errorMessage">
        <i class="icon principles-wrong-icon with-tooltip ms-10" />
      </div>
    </div>


    <ToggleSwitch
      v-if="editMode && currencies.length > 1"
      v-model="currency"
      :show-switch="true"
      :switch-options="switchOptions"
      :options-with-label="true"
      @change:model-value="switchCurrency()"
      class="mb-10 mt-10"
    />


    <div v-if="!selectedCurrencyEuro">
      <span>
        <span v-if="!editMode">{{ priceValue }}</span>
        <input
          v-if="editMode"
          v-model="priceValue"
          size="5"
          @input="onPriceValueChange"
        />
      </span>
      <span>{{ $helpers.getCurrencySymbol(currency) }}</span>
      <template v-if="showPercent">
        <span
          v-if="editMode || plusMode"
          class="price-level-input__plus-sign"
          :class="{ 'price-level-input__active': plusMode }"
          @click="onChangeSign"
        >
          +
        </span>
        <span v-if="editMode">/</span>
        <span
          v-if="editMode || !plusMode"
          class="price-level-input__minus-sign"
          :class="{ 'price-level-input__active': !plusMode }"
          @click="onChangeSign"
        >
          -
        </span>
        <span>
          <span v-if="!editMode">{{ pricePercent }}</span>
          <input
            v-if="editMode"
            v-model="pricePercent"
            size="3"
            @input="onPricePercentChange"
          />
        </span>
        <span class="price-level-input__percent">%</span>
      </template>
    </div>
    <span v-if="!selectedCurrencyEuro">
      <div v-if="!isNaN(euroValue)">
        <span>{{ euroValue }}€ </span>
        <span v-show="euroPercent != 0.0"> {{ euroPercentSign }}{{ euroPercentAbs }}%</span>
      </div>
    </span>


    <span v-if="selectedCurrencyEuro">
      <div v-if="currencies.length > 1 && !isNaN(priceValue)">
        <span>{{ priceValue }}{{ $helpers.getCurrencySymbol(currencies.filter(c => c.short_name != 'EUR')[0].short_name) }} </span>
        <span v-show="pricePercent != 0.0"> {{ percentSign }}{{ pricePercentAbs }}%</span>
      </div>
    </span>
    <div v-if="selectedCurrencyEuro">
      <span>
        <span v-if="!editMode">{{ euroValue }}</span>
        <input
          v-if="editMode"
          v-model="euroValue"
          size="5"
          @input="onEuroValueChange"
        />
      </span>
      <span>{{ $helpers.getCurrencySymbol(currency) }}</span>
      <template v-if="showPercent">
        <span
          v-if="editMode || plusMode"
          class="price-level-input__plus-sign"
          :class="{ 'price-level-input__active': plusMode }"
          @click="onChangeSign"
        >
          +
        </span>
        <span v-if="editMode">/</span>
        <span
          v-if="editMode || !plusMode"
          class="price-level-input__minus-sign"
          :class="{ 'price-level-input__active': !plusMode }"
          @click="onChangeSign"
        >
          -
        </span>
        <span>
          <span v-if="!editMode">{{ euroPercent }}</span>
          <input
            v-if="editMode"
            v-model="euroPercent"
            size="3"
            @input="onEuroPercentChange"
          />
        </span>
        <span class="price-level-input__percent">%</span>
      </template>
    </div>


    <span class="label me-1 results-header-label">{{ priceData.status }}</span>


    <div v-if="editMode" class="search-filters-with-checkbox-checkbox-wrapper">
      <label class="checkbox ms-20">
        <div class="checkbox__input me-2">
          <input
            id="autofill_checkbox"
            v-model="autofill"
            type="checkbox"
            name="checked"
            class="checkboxInput"
            @change="pristine = false"
          />
          <div class="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </div>
        </div>
        <span class="radio__label property-title"> autofill </span>
      </label><br>

      <label class="checkbox ms-20">
        <div class="checkbox__input me-2">
          <input
            id="overwrite_checkbox"
            v-model="overwrite"
            type="checkbox"
            name="checked"
            class="checkboxInput"
            @change="pristine = false"
          />
          <div class="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </div>
        </div>
        <span class="radio__label property-title"> overwrite </span>
      </label><br>


      <label
          class="btn btn-danger btn-sm"
          @click="this.$emit('deleteValue', this.monthKey)"
        >
        <i class="icon trash-icon" />
      </label>
    </div>

  </div>
</template>

<script>
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'PlanPriceLevelCard',
  props: {
    monthKey: String,
    priceData: Object,
    currencies: Array,
  },
  components: { ToggleSwitch },
  emits: ['save', 'deleteValue'],
  data() {
    return {
      editMode: false,
      priceValue: 0.0,
      pricePercent: null,
      euroValue: 0.0,
      euroPercent: 0.0,
      plusMode: true,
      euroPlusMode: true,
      showPercent: true,
      isError: false,
      errorMessage: '',
      autofill: false,
      overwrite: false,
      pristine: true,
      selectedCurrencyEuro: false,
      currency: 'EUR',
      switchOptions: [],
    };
  },
  computed: {
    euroPercentAbs() {
      return Math.abs(this.euroPercent);
    },
    euroPercentSign() {
      return this.euroPlusMode ? '+' : '-';
    },
    percentSign() {
      return this.plusMode ? ' +' : ' -'
    },
    pricePercentAbs() {
      return Math.abs(this.pricePercent);
    },
  },
  watch: {
    priceData: {
      deep: true,
      handler() {
        this.updateValuesFromData();
      },
    },
  },
  mounted() {
    this.updateValuesFromData();
    this.defaultSwitchOptions();
  },
  methods: {
    switchCurrency() {
      this.selectedCurrencyEuro = this.currency == 'EUR'
    },
    defaultSwitchOptions() {
      this.currency = this.currencies[this.currencies.length - 1]['short_name'];
      this.switchOptions = this.currencies.map(function(c) { return { label: c.short_name, value: c.short_name } });
    },
    updateValuesFromData() {
      this.priceValue = this.priceData['plan_price'];
      this.euroValue = this.priceData['plan_price_eur']

      this.euroPercent = this.diff(
        this.priceData['previousValue']['plan_price_eur'],
        this.euroValue
      );
      this.euroPlusMode = this.euroPercent >= 0;
      this.euroPercent = Math.abs(this.euroPercent);

      this.isError = this.priceData['source'] === 'error';
      this.errorMessage = this.priceData['message'];
      if (
        !this.priceData['previousValue'] ||
        !this.priceData['previousValue']['plan_price']
      ) {
        this.showPercent = false;
        return;
      }

      const percent = this.priceData['percent']
        ? +(this.priceData['percent'] * 100.0).toFixed(2)
        : 0.0;
      this.plusMode = percent >= 0;
      this.pricePercent = Math.abs(percent);
    },
    onChangeSign() {
      if (!this.editMode) return;

      this.plusMode = !this.plusMode;

      if (this.currencies.length > 1) {
        this.onEuroPercentChange();
      }
      this.onPricePercentChange();
    },
    onEuroPercentChange() {
      let currentPercent = parseFloat(this.euroPercent);
      if (isNaN(currentPercent)) return;

      this.euroValue = (
        this.priceData['previousValue']['plan_price_eur'] *
        (currentPercent / 100.0 + 1)
      ).toFixed(2);

      this.changePriceValue();
      this.setPercentFromPrice();
    },
    onPricePercentChange() {
      let currentPercent = parseFloat(this.pricePercent);
      if (isNaN(currentPercent)) return;

      currentPercent = this.plusMode ? currentPercent : -currentPercent;
      this.priceValue = (
        this.priceData['previousValue']['plan_price'] *
        (currentPercent / 100.0 + 1)
      ).toFixed(2);

      this.changeEuroValue();
      this.setPercentFromEuro();
    },
    onEuroValueChange() {
      this.changePriceValue();
      this.setPercentFromEuro();
      this.setPercentFromPrice();
    },
    onPriceValueChange() {
      this.changeEuroValue();
      this.setPercentFromEuro();
      this.setPercentFromPrice();
    },
    changePriceValue() {
      if (isNaN(this.euroValue) || this.euroValue == '') return;

      if (this.priceData['rate']) {
        this.priceValue = (this.euroValue * this.priceData['rate']).toFixed(2);
      } else {
        this.priceValue = Number(this.euroValue).toFixed(2);
      }
    },
    changeEuroValue() {
      if (isNaN(this.priceValue) || this.priceValue == '') return;

      if (this.priceData['rate']) {
        this.euroValue = (this.priceValue / this.priceData['rate']).toFixed(2);
      } else {
        this.euroValue = Number(this.priceValue).toFixed(2);
      }
    },
    setPercentFromEuro() {
      const currentValue = parseFloat(this.euroValue);
      if (isNaN(currentValue)) return;

      let a = this.diff(
        this.priceData['previousValue']['plan_price_eur'],
        currentValue
      );
      this.euroPlusMode = a >= 0;
      this.euroPercent = Math.abs(a);
    },
    setPercentFromPrice() {
      const currentValue = parseFloat(this.priceValue);
      if (isNaN(currentValue)) return;

      let b = this.diff(
        this.priceData['previousValue']['plan_price'],
        currentValue
      );
      this.pricePercent = Math.abs(b);
    },
    editField() {
      this.editMode = true;
      this.defaultSwitchOptions();
    },
    cancelEdit() {
      this.editMode = false;
      this.selectedCurrencyEuro = false;
      this.updateValuesFromData();
      this.defaultSwitchOptions();
    },
    saveChange() {
      const percent =
        parseFloat(this.pricePercent || 0.0) * (this.plusMode ? 0.01 : -0.01);

      this.$emit('save', this.monthKey, this.priceValue * 1, percent, this.autofill, this.overwrite, this.currency);
      this.editMode = false;
      this.selectedCurrencyEuro = false;
      this.defaultSwitchOptions();
    },
    diff(v1, v2) {
      if (isNaN(v1) || isNaN(v2)) { return 0.0 }
      return ((v2 / v1 - 1) * 100.0).toFixed(2);
    },
    showEdit() {
      return this.priceData.status != 'finished' && this.priceData.reviewed != 'false' && this.priceData.status != 'analysis' && this.priceData.status != 'approved' || (this.priceData.source == 'user' && this.priceData.status =='approved')
    },
    backgroundColor() {
      let color = '#67B84A';

      if (this.priceData.status == 'finished') {
        color = '#5E6C84';
      } else if (this.priceData.status == 'analysis') {
        color = '#003B6A';
      }

      return `background: ${color};`
    },
    // headerTitle() {
    //   let title = 'Price Change Request';
    //   if (this.priceData.status == 'analysis') {
    //     title = 'SAP pricing';
    //   }

    //   return title;
    // },
  },
};
</script>
