<template>
  <div class="container">
    <div class="row mb-30">
      <h1>Pharma International - Sales deductions & discount in invoice</h1>
    </div>

    <div class="flex-between">
      <div class="row mb-30 infoHeader w-80">
        <h3 class="infoTitle">
          Please note: The information on this page is still under review.
        </h3>
      </div>
      <div class="ms-20">
        <ToggleSwitch
          v-model="source"
          :show-switch="true"
          :loading="loading"
          :switch-options="['sales','konditions']"
          @change:model-value="
            () =>
              getData(source)
          "
        />
      </div>
    </div>

    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span>
        <PulseLoader :loading="loading" />
      </span>
    </div>

    <div v-if="data['sales']" class="row small-table-row">
      <div class="col-md-8 col-lg-4">
        <SalesInfoCard
          title-first="Discount in invoice"
          title-second="Sales deductions"
          :sales-discounts="data['sales']['discount_in_invoice']"
          :sales-deductions="data['sales']['sales_deductions']"
        />
      </div>
      <div class="col-md-8 col-lg-4">
        <SalesInfoCard
          title-first="FC discount in invoice"
          title-second="FC sales deductions"
          :sales-discounts="data['sales']['FC_discount_in_invoice']"
          :sales-deductions="data['sales']['FC_sales_deductions']"
        />
      </div>
      <div class="col-md-8 col-lg-4">
        <SalesInfoCard
          title-first="BU discount in invoice"
          title-second="BU sales deductions"
          :sales-discounts="data['sales']['BU_discount_in_invoice']"
          :sales-deductions="data['sales']['BU_sales_deductions']"
        />
      </div>
    </div>

    <div class="row small-table-row">
      <div v-if="!loading" class="card small-table-card">
        <SalesPricingSummaryTable :data="data['sales']['pricing_summary']" />
      </div>
    </div>

    <div class="row small-table-row">
      <div class="col-md-12">
        <SalesListTable
          v-if="data['countries_sales']"
          :items="data['countries_sales']"
          :table-keys="[
            'country',
            'discount_in_invoice',
            'sales_deductions',
            'FC_discount_in_invoice',
            'FC_sales_deductions',
            'BU_discount_in_invoice',
            'BU_sales_deductions',
          ]"
          name="country_discounts_and_deductions_YTD"
          :show-analysis-modal="true"
          object-type="countries"
        />
      </div>
    </div>

    <div class="row small-table-row">
      <div class="col-md-12">
        <SalesListTable
          v-if="data['discount_in_invoice']"
          :items="data['discount_in_invoice']"
          :table-keys="[
            'discount_type',
            'in_EUR_month',
            'in_percent_month',
            'in_EUR_YTD',
            'in_percent_YTD',
          ]"
          name="discounts_in_invoice"
          object-type="discounts"
        />
      </div>
    </div>

    <div class="row small-table-row">
      <div class="col-md-12">
        <SalesListTable
          v-if="data['sales_deduction']"
          :items="data['sales_deduction']"
          :table-keys="[
            'deduction_type',
            'in_EUR_month',
            'in_percent_month',
            'in_EUR_YTD',
            'in_percent_YTD',
          ]"
          name="sales_deductions"
          object-type="deductions"
        />
      </div>
    </div>

    <div class="row small-table-row">
      <div class="col-md-12">
        <div v-if="customersLoading" class="loading-roll is-active">
          <div class="loading-background" />
          <span>
            <PulseLoader :loading="customersLoading" />
          </span>
        </div>

        <SalesListTable
          v-if="!customersLoading && customersData.length > 0"
          :loading="customersLoading"
          :items="customersData"
          :table-keys="[
            'customer_name',
            'discount_in_invoice_YTD',
            'discount_in_percent_YTD',
            'sales_deductions_YTD',
            'sales_deductions_percent_YTD',
            'share',
          ]"
          name="customer_discounts_and_deductions"
          object-type="customers"
        />
        <div class="d-flex align-items-center justify-content-center">
          <button
            v-if="!loading"
            class="btn btn-green load-more-button mt-3"
            @click="loadMore()"
          >
            <span>Load more customers</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalesListTable from '@/components/DiscountsAndDeductions/SalesListTable.vue';
import SalesInfoCard from '@/components/DiscountsAndDeductions/SalesInfoCard.vue';
import SalesPricingSummaryTable from '@/components/DiscountsAndDeductions/SalesPricingSummaryTable.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import api from '@/helpers/api';

export default {
  name: 'DiscountsAndDeductions',
  components: { SalesListTable, SalesInfoCard, SalesPricingSummaryTable, ToggleSwitch },
  data() {
    return {
      data: {},
      customersData: [],
      loading: false,
      customersLoading: false,
      source: 'sales',
      page: 1,
      itemsPerPageValue: 10,
    };
  },
  async beforeMount() {
    await this.getData(this.source);
  },
  methods: {
    async getData(source) {
      await this.getMainData(this.source);
      await this.getCustomersData(this.source);
    },
    async getMainData(source) {
      this.loading = true;

      const response = await api.get(`${this.$route.path}/selector_data?source=${this.source}`);
      this.data = response.data;

      this.loading = false;
    },
    async getCustomersData(source) {
      this.customersLoading = true;

      const serializedObject = this.$helpers.serialize({
        page: this.page,
        items_per_page: this.itemsPerPageValue,
        source: this.source

      });
      const url = `${this.$route.path}/customers_data.json${
        serializedObject === '' ? '' : `?${serializedObject}`
      }`;

      const response = await api.get(url);
      console.log('response.data', response.data);
      this.customersData = response.data;

      this.customersLoading = false;
    },
    async loadMore() {
      this.page += 1;

      const oldItems = this.customersData

      await this.getCustomersData(this.source);

      if (!this.$helpers.isEmptyArray(this.customersData)) {
        this.customersData = [...this.customersData, ...oldItems];
      } else {
        this.page -= 1;
      }
    },
  }

};
</script>
