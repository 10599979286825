export const resultsDummy = {
  items: [
    {
      '0': {
        'id': 148,
        'sap_id': '12410',
        'sap_name': 'Klimadynon 60 FT BY',
        'status': 'active',
        'product_variant': {
          'id': 52,
          'package_size': 60,
          'name': 'Fil',
          'dosage_type': 'Filmtabletten',
          'is_liquid': false,
        },
        'product': {'id': 12, 'name': 'FT', 'short_name': null},
        'brand': {
          'id': 3,
          'name': 'Klimadynon',
          'short_name': 'KLI',
          'product_category': 'Gynecology',
          'product_cat_short': 'GYN',
        },
        'manufacturer': {'id': 1, 'name': 'Bionorica', 'short_name': 'BNO', 'is_internal': true},
        'country': {
          'id': 169,
          'name': 'Belarus',
          'iso_code': 'BY',
          'iso_code3': 'BLR',
          'business_model': 'Representative Office',
          'is_active': true,
          'reference_countries': [],
          'region': {
            'id': 16,
            'name': 'Eastern Europe',
            'parent_region': 'Europe',
            'continent': 'Europe',
            'priority': 0,
          },
          'trade_unions': [{'id': 6, 'name': 'Union State', 'short_name': null, 'priority': 0}, {
            'id': 7,
            'name': 'Commonwealth of Independent States',
            'short_name': null,
            'priority': 0,
          }, {'id': 9, 'name': 'Eurasian Economic Union', 'short_name': null, 'priority': 0}, {
            'id': 9,
            'name': 'Eurasian Economic Union',
            'short_name': null,
            'priority': 0,
          }],
          'currency': {
            'id': 2,
            'name': 'Belarusian Ruble',
            'short_name': 'BYN',
            'num': 933,
            'is_main': false,
            'exch_rate': 0,
          },
        },
      },
    }, {
      '1': {
        'id': 149,
        'sap_id': '12411',
        'sap_name': 'S-Klimadynon Trpf. 50 ml BY',
        'status': 'material deleted',
        'product_variant': {
          'id': 11,
          'package_size': 50,
          'name': 'Tro',
          'dosage_type': 'Tropfen',
          'is_liquid': true,
        },
        'product': {'id': 3, 'name': 'default', 'short_name': null},
        'brand': {
          'id': 3,
          'name': 'Klimadynon',
          'short_name': 'KLI',
          'product_category': 'Gynecology',
          'product_cat_short': 'GYN',
        },
        'manufacturer': {'id': 1, 'name': 'Bionorica', 'short_name': 'BNO', 'is_internal': true},
        'country': {
          'id': 169,
          'name': 'Belarus',
          'iso_code': 'BY',
          'iso_code3': 'BLR',
          'business_model': 'Representative Office',
          'is_active': true,
          'reference_countries': [],
          'region': {
            'id': 16,
            'name': 'Eastern Europe',
            'parent_region': 'Europe',
            'continent': 'Europe',
            'priority': 0,
          },
          'trade_unions': [{'id': 6, 'name': 'Union State', 'short_name': null, 'priority': 0}, {
            'id': 7,
            'name': 'Commonwealth of Independent States',
            'short_name': null,
            'priority': 0,
          }, {'id': 9, 'name': 'Eurasian Economic Union', 'short_name': null, 'priority': 0}, {
            'id': 9,
            'name': 'Eurasian Economic Union',
            'short_name': null,
            'priority': 0,
          }],
          'currency': {
            'id': 2,
            'name': 'Belarusian Ruble',
            'short_name': 'BYN',
            'num': 933,
            'is_main': false,
            'exch_rate': 0,
          },
        },
      },
    }, {
      '2': {
        'id': 150,
        'sap_id': '12412',
        'sap_name': 'Mastodynon Trpf. 50 ml BY',
        'status': 'active',
        'product_variant': {
          'id': 53,
          'package_size': 50,
          'name': 'Tro',
          'dosage_type': 'Tropfen',
          'is_liquid': true,
        },
        'product': {'id': 10, 'name': 'default', 'short_name': null},
        'brand': {
          'id': 8,
          'name': 'Mastadynon',
          'short_name': 'MAS',
          'product_category': 'Gynecology',
          'product_cat_short': 'GYN',
        },
        'manufacturer': {'id': 1, 'name': 'Bionorica', 'short_name': 'BNO', 'is_internal': true},
        'country': {
          'id': 169,
          'name': 'Belarus',
          'iso_code': 'BY',
          'iso_code3': 'BLR',
          'business_model': 'Representative Office',
          'is_active': true,
          'reference_countries': [],
          'region': {
            'id': 16,
            'name': 'Eastern Europe',
            'parent_region': 'Europe',
            'continent': 'Europe',
            'priority': 0,
          },
          'trade_unions': [{'id': 6, 'name': 'Union State', 'short_name': null, 'priority': 0}, {
            'id': 7,
            'name': 'Commonwealth of Independent States',
            'short_name': null,
            'priority': 0,
          }, {'id': 9, 'name': 'Eurasian Economic Union', 'short_name': null, 'priority': 0}, {
            'id': 9,
            'name': 'Eurasian Economic Union',
            'short_name': null,
            'priority': 0,
          }],
          'currency': {
            'id': 2,
            'name': 'Belarusian Ruble',
            'short_name': 'BYN',
            'num': 933,
            'is_main': false,
            'exch_rate': 0,
          },
        },
      },
    }],
}

export const singleFilterKeys = ['inclCompetitors']

export const filtersFormEmpty = {
  search: {
    value: [],
    options: [],
  },
  id: {
    value: [],
    options: [],
  },
  country: {
    value: [],
    options: [],
  },
  customer: {
    value: [],
    options: [],
  },
  referenceCountries: false,
  region: {
    value: [],
    options: [],
  },
  tradeUnion: {
    value: [],
    options: [],
  },
  businessModel: {
    value: [],
    options: [],
  },
  brand: {
    value: [],
    options: [],
  },
  originalBrands: {
    value: [],
    options: [],
  },
  productCategory: {
    value: [],
    options: [],
  },
  product: {
    value: [],
    options: [],
  },
  dosageForms: {
    value: [],
    options: [],
  },
  isLiquid: {
    value: [],
    options: [],
  },
  inclCompetitors: {
    value: 'All',
    options: []
  },
  isMainCompetitors: false,
  isBundlePack: {
    value: [],
    options: [],
  },
  packageSize: {
    value: [],
    options: [],
  },
  status: {
    //value: ['active', 'material expires', 'material in change'],
    value: [], //TODO: Use the preset above with correct search chips.
    options: [],
  },
  currency: {
    value: [],
    options: [],
  },
  itemsPerPage: {
    value: 20,
    options: [20, 40, 60, 80, 100],
  },
  orderBy: {
    value: {
      full_name: 'ascending by SAP ID',
      internal_name: 'sap_id ASC',
    },
    options: [],
  },
  highlightProperty: {
    value: '',
    options: [
      'Name',
      'ID',
      'Status',
      'Margin BNO',
      'List price gross I',
      'List price gross II',
      'Real list price gross II',
      'Wholesaler purchase price net',
      'Pharmacy purchase price net',
      'Retail price',
      'Country',
      'Brand',
      'Product',
      'Product variant',
      'IRP',
    ],
  },
}

export const hierarchy = {
  manufacturers: 'brands',
  brands: 'products',
  customers: 'customers',
  products: 'product_variants',
  profit_centers: 'profit_center_variants',
  product_variants: 'profit_center_variants',
  countries: 'profit_centers',
  currencies: 'countries',
}

export const newItemFields = {
  Product: ['name', 'short_name', 'brand_id'],
  Brand: ['name', 'short_name', 'manufacturer_id'],
  ProductVariant: ['package_size', 'width', 'height', 'depth', 'weight', 'product_id', 'name', 'concentration', 'dosage_type', 'is_liquid'],
  Country: ['name', 'iso_code', 'iso_code3', 'business_model', 'country_region_id', 'is_active', 'reference_countries'],
  Customer: ['name', 'customer_group', 'country'],
  Currency: ['name', 'short_name', 'type'],
  ProfitCenterVariant: ['profit_center_id', 'product_variant_id', 'sap_id', 'sap_name', 'status', 'is_visible', 'short_name', 'gpms_name'],
  DataChangeRequest: ['id', 'object', 'field', 'valid_from', 'valid_until', 'status', 'last_modified'],
}

export const editFormFields = {
  Product: ['name', 'short_name', 'brand_id'],
  Brand: ['name', 'short_name', 'manufacturer_id'],
  ProductVariant: ['package_size', 'width', 'height', 'depth', 'weight', 'product_id', 'name', 'concentration', 'dosage_type', 'is_liquid'],
  Country: ['name', 'iso_code', 'iso_code3', 'business_model', 'is_active'],
  Customer: ['name', 'customer_group', 'country'],
  Currency: ['name', 'short_name', 'type'],
  ProfitCenterVariant: ['profit_center_id', 'product_variant_id', 'sap_id', 'sap_name', 'status', 'is_visible', 'short_name', 'gpms_name'],
  DataChangeRequest: ['id', 'object', 'field', 'valid_from', 'valid_until', 'status', 'last_modified'],
}

export const searchResultsCardFields = [
  'List price gross I', 'List price gross II', 'List price gross II real',
  'Wholesaler purchase price net', 'Pharmacy purchase price net', 'Retail price incl. VAT'
]

export const searchResultsCompetitorsCardFields = [
  'Wholesaler purchase price net real', 'Pharmacy purchase price net real', 'Retail price incl. VAT real'
]

export const navbarWidth = 290

export const collapsedNavbarWidth = 210

export const currenciesShortISO4217 = [
  'AFN',  'EUR',  'ALL',  'DZD',  'USD',  'EUR',  'AOA',  'XCD',  'XCD',  'ARS',  'AMD',  'AWG',  'AUD',  'EUR',  'AZN',  'BSD',  'BHD',  'BDT',
  'BBD',  'BYN',  'EUR',  'BZD',  'XOF',  'BMD',  'INR',  'BTN',  'BOB',  'BOV',  'USD',  'BAM',  'BWP',  'NOK',  'BRL',  'USD',  'BND',  'BGN',
  'XOF',  'BIF',  'CVE',  'KHR',  'XAF',  'CAD',  'KYD',  'XAF',  'XAF',  'CLP',  'CLF',  'CNY',  'AUD',  'AUD',  'COP',  'COU',  'KMF',  'CDF',
  'XAF',  'NZD',  'CRC',  'XOF',  'HRK',  'CUP',  'CUC',  'ANG',  'EUR',  'CZK',  'DKK',  'DJF',  'XCD',  'DOP',  'USD',  'EGP',  'SVC',  'USD',
  'XAF',  'ERN',  'EUR',  'SZL',  'ETB',  'EUR',  'FKP',  'DKK',  'FJD',  'EUR',  'EUR',  'EUR',  'XPF',  'EUR',  'XAF',  'GMD',  'GEL',  'EUR',
  'GHS',  'GIP',  'EUR',  'DKK',  'XCD',  'EUR',  'USD',  'GTQ',  'GBP',  'GNF',  'XOF',  'GYD',  'HTG',  'USD',  'AUD',  'EUR',  'HNL',  'HKD',
  'HUF',  'ISK',  'INR',  'IDR',  'XDR',  'IRR',  'IQD',  'EUR',  'GBP',  'ILS',  'EUR',  'JMD',  'JPY',  'GBP',  'JOD',  'KZT',  'KES',  'AUD',
  'KPW',  'KRW',  'KWD',  'KGS',  'LAK',  'EUR',  'LBP',  'LSL',  'ZAR',  'LRD',  'LYD',  'CHF',  'EUR',  'EUR',  'MOP',  'MKD',  'MGA',  'MWK',
  'MYR',  'MVR',  'XOF',  'EUR',  'USD',  'EUR',  'MRU',  'MUR',  'EUR',  'XUA',  'MXN',  'MXV',  'USD',  'MDL',  'EUR',  'MNT',  'EUR',  'XCD',
  'MAD',  'MZN',  'MMK',  'NAD',  'ZAR',  'AUD',  'NPR',  'EUR',  'XPF',  'NZD',  'NIO',  'XOF',  'NGN',  'NZD',  'AUD',  'USD',  'NOK',  'OMR',
  'PKR',  'USD',  'PAB',  'USD',  'PGK',  'PYG',  'PEN',  'PHP',  'NZD',  'PLN',  'EUR',  'USD',  'QAR',  'EUR',  'RON',  'RUB',  'RWF',  'EUR',
  'SHP',  'XCD',  'XCD',  'EUR',  'EUR',  'XCD',  'WST',  'EUR',  'STN',  'SAR',  'XOF',  'RSD',  'SCR',  'SLL',  'SGD',  'ANG',  'XSU',  'EUR',
  'EUR',  'SBD',  'SOS',  'ZAR',  'SSP',  'EUR',  'LKR',  'SDG',  'SRD',  'NOK',  'SEK',  'CHF',  'CHE',  'CHW',  'SYP',  'TWD',  'TJS',  'TZS',
  'THB',  'USD',  'XOF',  'NZD',  'TOP',  'TTD',  'TND',  'TRY',  'TMT',  'USD',  'AUD',  'UGX',  'UAH',  'AED',  'GBP',  'USD',  'USD',  'USN',
  'UYU',  'UYI',  'UYW',  'UZS',  'VUV',  'VES',  'VND',  'USD',  'USD',  'XPF',  'MAD',  'YER',  'ZMW',  'ZWL',  'XBA',  'XBB',  'XBC',  'XBD',
  'XTS',  'XXX',  'XAU',  'XPD',  'XPT',  'XAG',  'AFA',  'FIM',  'ALK',  'ADP',  'ESP',  'FRF',  'AOK',  'AON',  'AOR',  'ARA',  'ARP',  'ARY',
  'RUR',  'ATS',  'AYM',  'AZM',  'RUR',  'BYB',  'BYR',  'RUR',  'BEC',  'BEF',  'BEL',  'BOP',  'BAD',  'BRB',  'BRC',  'BRE',  'BRN',  'BRR',
  'BGJ',  'BGK',  'BGL',  'BUK',  'HRD',  'HRK',  'CYP',  'CSJ',  'CSK',  'ECS',  'ECV',  'GQE',  'EEK',  'XEU',  'FIM',  'FRF',  'FRF',  'FRF',
  'GEK',  'RUR',  'DDM',  'DEM',  'GHC',  'GHP',  'GRD',  'FRF',  'GNE',  'GNS',  'GWE',  'GWP',  'ITL',  'ISJ',  'IEP',  'ILP',  'ILR',  'ITL',
  'RUR',  'RUR',  'LAJ',  'LVL',  'LVR',  'LSM',  'ZAL',  'LTL',  'LTT',  'LUC',  'LUF',  'LUL',  'MGF',  'MWK',  'MVQ',  'MLF',  'MTL',  'MTP',
  'FRF',  'MRO',  'FRF',  'MXP',  'RUR',  'FRF',  'MZE',  'MZM',  'NLG',  'ANG',  'NIC',  'PEH',  'PEI',  'PEN',  'PES',  'PLZ',  'PTE',  'FRF',
  'ROK',  'ROL',  'RON',  'RUR',  'FRF',  'FRF',  'FRF',  'ITL',  'STD',  'CSD',  'EUR',  'SKK',  'SIT',  'ZAL',  'SDG',  'RHD',  'ESA',  'ESB',
  'ESP',  'SDD',  'SDP',  'SRG',  'SZL',  'CHC',  'RUR',  'TJR',  'IDR',  'TPE',  'TRL',  'TRY',  'RUR',  'TMM',  'UGS',  'UGW',  'UAK',  'SUR',
  'USS',  'UYN',  'UYP',  'RUR',  'VEB',  'VEF',  'VEF',  'VEF',  'VNC',  'YDD',  'YUD',  'YUM',  'YUN',  'ZRN',  'ZRZ',  'ZMK',  'ZWC',  'ZWD',
  'ZWD',  'ZWN',  'ZWR',  'XFO',  'XRE',  'XFU',
]

export const dumbCommentData = {
  author: 'Julia',
  position: 'Project Manager',
  date: 'Sep 14, 2020',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a neque scelerisque ultricies ' +
      'est fames sit. Diam ultricies lacus sit sit tristique adipiscing eu. Convallis at est ut ' +
      'ac suspendisse tincidunt.',
}

export const brandColors = {
  sinupret: '#97BF0D',
  bisolsinus: '#97BF0D',
  sinuxol: '#97BF0D',
  sinux: '#97BF0D',
  sinova: '#97BF0D',
  bronchipret: '#4B6006',
  solvopret: '#4B6006',
  mucopret: '#4B6006',
  bronchilin: '#4B6006',
  bronchithym: '#4B6006',
  tonsipret: '#CFF352',
  gingopret: '#F6B757',
  imupret: '#598215',
  tonsilgon: '#598215',
  immunopret: '#598215',
  rinupret: '#CDEE95',
  canephron: '#FFD300',
  cystipret: '#FFD300',
  agnucaston: '#A05599',
  cyclodynon: '#A05599',
  cyclofemina: '#A05599',
  cyclopret: '#A05599',
  mastodynon: '#774072',
  klimadynon: '#DABAD7',
  mensifem: '#DABAD7',
  menofem: '#DABAD7',
  menopret: '#DABAD7',
  allunapret: '#00457D',
  lunapret: '#00457D',
  assalix: '#E85412',
  flexipret: '#E85412',
  silimarit: '#F39910',
  helarium: '#F3A227',
  neuropret: '#F3A227',
  digestopret: '#9FC5E8',
  gastropret: '#9FC5E8',
  noricaven: '#E06677',
  dermaline: '#AFA300',
}

export const countryColors = {
  russia: '#97BF0D',
  germany: '#4B6006',
  uzbekistan: '#CFF352',
  kazakhstan: '#F6B757',
  ukraine: '#598215',
  austria: '#CDEE95',
  belarus: '#FFD300',
  moldova: '#A05599',
  lithuania: '#774072',
  kyrgyzstan: '#DABAD7',
  turkmenistan: '#00457D',
  mongolia: '#E85412',
  latvia: '#F39910',
  turkey: '#9FC5E8',
  romania: '#E06677',
}

export const pulseAnimationPages = []
