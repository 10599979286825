export const sources = {
  'Romania': 'Cegedim',
  'Russia': 'Source: DSM',
  'Belarus': 'Source: Zoom',
  'Ukraine': 'Source: Morion',
  'Estonia': 'Source: Medicube',
  'Latvia': 'Source: Pharmazoom',
  'Lithuania': 'Source: Pharmazoom',
  'Poland': 'Source: PEX Pharma Sequence'
}
