<template>
  <div ref="analysis" class="analysis scrollbar">
    <button
      class="button-no-style link d-flex align-items-center card analysis-back-button"
      @click="unstackPage"
    >
      <template v-if="pagesStack.length">
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :style="{ width: '10px', height: '20px' }"
        >
          <path
            d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
            fill="#9DAFC2"
          />
        </svg>

        <span class="text-uppercase me-2">Close Details</span>
      </template>
      <template v-else>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :style="{ width: '10px', height: '20px' }"
        >
          <path
            d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
            fill="#9DAFC2"
          />
        </svg>

        <span class="text-uppercase me-2">Back To Search</span>
      </template>
    </button>

    <div v-show="!pagesStack.length" class="analysis-content">
      <div class="analysis-row">
        <h1>Analysis Details</h1>
      </div>

      <div class="analysis-row">
        <Chips :chips="chips" :deletion="false" />
      </div>

      <div class="analysis-row cards" :class="{ loading }">
        <!-- <DashboardInfoCard v-if="!loading" title="Sales last month" :value="cardsData.sales.sales_this_month"
                           img="little-info-card-pic-1" :percentage="cardsData.sales.difference"
                           :up="cardsData.sales.direction === 'up'"
        />
        <DashboardInfoCard v-if="!loading" title="Items sold last month" :value="cardsData.items.units_this_month"
                           img="little-info-card-pic-2" :percentage="cardsData.items.difference"
                           :up="cardsData.items.direction === 'up'"
        />
         <DashboardInfoCard v-if="!loading" title="Average margin" :value="cardsData.margin.margin_this_month"
                           img="little-info-card-pic-2" :percentage="cardsData.margin.difference"
                           :up="cardsData.margin.direction === 'up'"
        /> -->
        <AnalysisInfoCard v-if="!loading" :counter-data="cardsData.counter" />
      </div>

      <div class="analysis-row">
        <Chart
          v-if="open"
          object="sales"
          :ids="ids"
          :style-object="{
            width: '100%',
            alignSelf: 'center',
            margin: '0 0 30px 0',
          }"
        />
      </div>

      <div class="analysis-row" :class="{ loading }">
        <AnalysisPriceBuildupTable
          v-if="!loading && !loadingBuildup"
          :price-info="buildupData"
          @stack-page="stackPage"
          @get-price-buildup-details="getPriceBuildupDetails"
        />
      </div>
    </div>

    <div v-show="pagesStack.length" ref="pdfRoot" class="analysis-content">
      <div v-if="pagesStack.length" class="analysis-row">
        <p class="color-aaa">Select which products to show on graph:</p>
        <div class="analysis-chart display-inline">
          <Multiselect
            v-model="theIds"
            :options="lineChartSelectOptions"
            track-by="name"
            label="name"
            select-label
            placeholder="All"
            :multiple="true"
            :show-labels="false"
            :close-on-select="false"
            class="display-inline"
            style="width: 400px"
          >
            <template #selection="{ values, isOpen }">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} products selected
              </span>
            </template>
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" />
              </div>
            </template>
          </Multiselect>
          <button
            class="btn btn-green display-inline"
            style="width: 175px !important; margin-left: 10px"
            @click="showLineChart"
          >
            View products
          </button>
        </div>
        <div class="analysis-chart display-inline">
          <button
            class="btn btn-green display-inline"
            style="width: 175px !important; margin-left: 10px"
            @click="showAllProductsChart"
          >
            View all products
          </button>
        </div>
      </div>
      <div
        v-if="pagesStack.length && lineChartActive && !loadingBuildup"
        class="analysis-row"
      >
        <Chart
          object="analysisLineChart"
          :title="currentDetailsPage"
          :pages-stack="pagesStack[0][1]"
          disable-label-multiselect
          :ids="validIds"
          :show-additional-info="true"
          :fetched-results-type="dataType"
        />
      </div>
      <div
        v-if="
          pagesStack.length &&
          buildupData[currentDetailsPage].type !== 'text' &&
          !loadingBuildup
        "
        class="analysis-row"
      >
        <AnalysisDetailsChart
          :title="currentDetailsPage"
          :items="currentDetailsItems"
          :pdf-root-ref="$refs.pdfRoot"
          :countries="countriesName"
          :data-type="dataType"
        />
      </div>
      <div
        v-if="
          pagesStack.length &&
          buildupData[currentDetailsPage].type !== 'text' &&
          !loadingBuildup
        "
        class="analysis-row"
      >
        <AnalysisDetailsTable
          :loading="false"
          :title="currentDetailsPage"
          :table-style="{ width: '100%' }"
          :items="currentDetailsItems"
          :data-type="dataType"
          @scroll-to-top="scrollToTop"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import Chart from '@/components/Chart/index.vue';
import AnalysisDetailsTable from './AnalysisDetailsTable.vue';
import AnalysisInfoCard from './AnalysisInfoCard.vue';
import Chips from './Chips.vue';
import AnalysisDetailsChart from './AnalysisDetailsChart.vue';
import AnalysisPriceBuildupTable from './AnalysisPriceBuildupTable.vue';
import { isEmptyObject } from '@/helpers';

export default {
  name: 'Analysis',
  components: {
    AnalysisPriceBuildupTable,
    AnalysisDetailsChart,
    AnalysisInfoCard,
    AnalysisDetailsTable,
    Chart,
    Chips,
  },
  props: {
    ids: Array,
    chips: Array,
    open: Boolean,
  },
  emits: ['close', 'lockDetailsButton'],
  data() {
    return {
      pagesStack: [],
      cardsData: {
        sales: {},
        items: {},
        margin: {},
        counter: {},
      },
      buildupData: {},
      countries: {},
      loading: false,
      items: {},
      currentDetailsItems: [],
      theIds: [],
      validIds: [],
      lineChartActive: true,
      loadingBuildup: false,
    };
  },
  computed: {
    currentDetailsPage() {
      return this.pagesStack[this.pagesStack.length - 1][0];
    },
    lineChartSelectOptions() {
      let pcvNames = this.currentDetailsItems['real'].map((pcv) => ({
        name: pcv.gpms_name,
        id: pcv.id,
      }));
      return [...new Set(pcvNames)];
    },
    dataType() {
      return this.buildupData[this.currentDetailsPage].type;
    },
    countriesName() {
      return this.countries;
    },
  },
  watch: {
    ids: {
      immediate: true,
      handler() {
        if (this.ids && this.ids.length > 0 && this.ids.length <= 150) {
          this.getData();
        }
      },
    },
  },
  methods: {
    stackPage(page) {
      this.pagesStack.push(page);
      let currentDetailsPageKey = this.currentDetailsPage.replace(/ real$/, '');

      this.items = {
        planned: this.buildupData[currentDetailsPageKey].items.sort(
          (a, b) => a.value - b.value
        ),
        real: this.buildupData[currentDetailsPageKey + ' real'].items.sort(
          (a, b) => a.value - b.value
        ),
      };
      this.setCurrentDetailsItems(this.items);
      this.theIds = this.lineChartSelectOptions.slice(0, 10);
      this.validIds = this.theIds.map((a) => a.id);
      this.scrollToTop();
    },
    unstackPage() {
      this.items = {};
      this.currentDetailsItems = {};
      this.theIds = [];
      this.validIds = [];
      if (this.pagesStack.length) {
        this.pagesStack.pop();
      } else {
        this.$emit('close');
      }
    },
    setCurrentDetailsItems(currentDetailsItems) {
      this.currentDetailsItems = currentDetailsItems;
    },
    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.analysis.scrollTop = 0;
      });
    },
    showLineChart() {
      this.validIds = this.theIds.map((a) => a.id);
      this.lineChartActive = false;
      this.lineChartActive = true;
    },
    showAllProductsChart() {
      this.theIds = this.lineChartSelectOptions;
      this.validIds = this.theIds.map((a) => a.id);
      this.lineChartActive = false;
      this.lineChartActive = true;
    },
    async getData() {
      this.loading = true;

      let result = await api.post(
        '/profit_center_variants/get_info_card_content',
        JSON.stringify({
          type: 'counter',
          ids: this.ids
        })
      );

      this.cardsData.counter = result.data;

      this.getPriceBuildupDetails(new Date());

      this.loading = false;
    },
    async getPriceBuildupDetails(date) {
      this.loadingBuildup = true;
      this.$emit('lockDetailsButton', this.loadingBuildup);

      let result = await api.post(
        '/profit_center_variants/get_search_buildup_info',
        JSON.stringify({
          ids: this.ids,
          date: date
        })
      );

      const buildupData = result.data;
      this.countries = buildupData.countries;
      this.buildupData = buildupData.buildup_data;

      this.loadingBuildup = false;
      this.$emit('lockDetailsButton', this.loadingBuildup);
    },
  },
};
</script>
