<template>
  <div class="chips" :class="!chips.length ? 'm-0' : ''">
    <span
      v-for="chip in chips"
      :key="chip.selectedOption + chip.id"
      class="multiselect__tag"
    >
      <IconText
        :icon="`${normalizeChips(chip.id)}-icon`"
        :title="'' + chip.selectedOption"
      />

      <i
        v-if="deletion"
        aria-hidden="true"
        tabindex="1"
        class="icon close-tag-icon close-tag-button"
        @click="$emit('deleteTag', chip.selectedOption, chip.id)"
      />
    </span>
  </div>
</template>

<script>
import IconText from '@/components/IconText.vue';

// Expected chips object properties example: { "id": "product", "selectedOption": "Extract" }
export default {
  name: 'Chips',
  components: {
    IconText,
  },
  props: {
    chips: Array,
    deletion: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['deleteTag'],
  methods: {
    normalizeChips(chipId) {
      chipId = chipId == 'originalBrands' ? 'brand' : chipId;
      return this.$helpers.fromCamelCaseToKebabCase(chipId);
    },
  },
};
</script>
