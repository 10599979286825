<template>
  <div class="edit-user">
    <form @submit.prevent="updateUser">
      <h2>Account</h2>
      <div class="edit-user-avatar">
        <i class="icon icon-avatar-image" />
        <button class="edit-user-avatar-upload button-no-style" type="button">
          Upload
        </button>
        <button class="button-no-style gray" type="button">Delete</button>
      </div>
      <div class="edit-user-firstname-and-lastname">
        {{ userInfo.first_name }} {{ userInfo.last_name }}
      </div>
      <div class="edit-user-account-type">
        {{ userInfo.account_types[0] }}
      </div>
      <div class="edit-user-fields">
        <div class="edit-user-field field">
          <label>
            <span>First name</span>
            <input v-model="firstName" type="text" />
          </label>
        </div>
        <div class="edit-user-field field">
          <label>
            <span>Last name</span>
            <input v-model="lastName" type="text" />
          </label>
        </div>
        <div class="edit-user-field field">
          <label>
            <span>Email</span>
            <input v-model="email" type="text" />
          </label>
        </div>
        <div class="edit-user-field field">
          <label>
            <span>Password</span>
            <input v-model="password" type="text" />
          </label>
        </div>
        <div class="edit-user-field field">
          <label>
            <span>Password Confirmation</span>
            <input v-model="passwordConfirmation" type="text" />
          </label>
        </div>
      </div>
      <div class="edit-user-submit">
        <button type="submit" class="button-no-style">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/helpers/api';
import { processUserUpdateError } from '@/helpers/usersHelper';

export default {
  name: 'EditUser',
  props: {
    userInfo: Object,
    adminInterface: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateUser'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    };
  },
  watch: {
    userInfo: function () {
      if (this.userInfo && this.userInfo.first_name) {
        this.setDataFromUserInfo();
      }
    },
  },
  mounted() {
    this.setDataFromUserInfo();
  },
  methods: {
    async updateUser() {
      if (this.adminInterface) {
        this.$emit('updateUser', this.userInfo.id, this.updatedUserParams());
      } else {
        await this.updateUserInApi();
      }
    },
    async updateUserInApi() {
      try {
        await api.put(
          '/users/personal_update',
          JSON.stringify({
            user: this.updatedUserParams(),
          })
        );
        this.$router.push('/edit_user');
        this.$helpers.notifySuccess('Your account data was succesfully updated!');
      } catch (e) {
        processUserUpdateError(e);
      }
    },
    setDataFromUserInfo() {
      if (!this.userInfo) return;

      this.firstName = this.userInfo.first_name;
      this.lastName = this.userInfo.last_name;
      this.email = this.userInfo.email;
    },
    updatedUserParams() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };
    },
  },
};
</script>
