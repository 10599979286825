export async function stepCompletedList(step, completed) {
  clearStepCompletedList();
  const ul = document.querySelector('.step-progressbar');

  const liArray = Array.from(ul.getElementsByTagName('li'));

  for (let i = 0; i < step && i < liArray.length; i++) {
    const li = liArray[i];

    if ((i == step - 1) && (completed == false)) {
      li.classList.add('active-await-step');
    } else {
      li.classList.add('active-step');
    }
  }
  if (step == null) { liArray[0].classList.add('active-await-step') };
}

export async function clearStepCompletedList() {
  const ul = document.querySelector('.step-progressbar');
  const liArray = Array.from(ul.getElementsByTagName('li'));

  liArray.forEach(li => {
    li.classList.remove('active-step');
    li.classList.remove('active-await-step');
  });
}

