<template>
  <div v-if="loading">loading...</div>
  <b-form-radio-group
    v-else-if="displayingCheck"
    v-model="value"
    :options="options"
    class="switch-radio-buttons"
    buttons
    size="xl"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface IToggleSwitchProps {
  /**
   * Show or hide ToggleSwitch
   * @defaultValue true
   */
  showSwitch?: boolean;
  /**
   * Array of options to toggle
   * @example
   * ```ts
   * ["USD", "EUR"] or [{label: "One", value: 1}, {label: "Two", value: 2}]
   * ```
   * @defaultValue undefined
   */
  switchOptions?: any[];
  /**
   * Indicates that you are passing an array of the following type to switchOptions:
   * ```ts
   * [{label: "One", value: 1}, ...]
   * ```
   * @defaultValue false
   */
  optionsWithLabel?: boolean;
  /**
   * Passing loading state to delay ToogleSwitch rendering
   * @defaultValue false
   */
  loading?: boolean;
  /**
   * ## This is the most important property.
   *
   * There is two cases how to pass modelValue to ToggleSwitch:
   *
   * First example with `v-model`
   * @example
   *
   * ```ts
   * <ToggleSwitch
   *   v-model="currentValue"
   *   :switch-options="switchOptions"
   * />
   * ```
   *
   * Second example with `:modelValue`
   * @example
   *
   * ```ts
   * <ToggleSwitch
   *   :modelValue="currentValue"
   *   :switch-options="switchOptions"
   *   `@update:model-value`="(value) => currentValue = value"
   * />
   * ```
   */
  modelValue: string;
}

interface IToggleSwitchEmits {
  (e: 'update:modelValue', value: string): void;
  (e: 'change:modelValue', value: string): void;
}

const {
  modelValue,
  switchOptions,
  showSwitch = true,
  optionsWithLabel = false,
} = defineProps<IToggleSwitchProps>();

const emit = defineEmits<IToggleSwitchEmits>();

const value = computed({
  get() {
    return modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    emit('change:modelValue', value);
  },
});

const displayingCheck = computed(() => {
  if (switchOptions?.length <= 1) return false;
  return showSwitch;
});

const options = computed(() => {
  if (!switchOptions?.length) return [];
  let valuesHash = [];
  if (optionsWithLabel) {
    valuesHash = switchOptions.map((el) => ({
      text: el.label,
      value: el.value,
    }));
  } else {
    switchOptions.forEach(
      (currencyName, i) =>
        (valuesHash[i] = { text: currencyName, value: currencyName })
    );
  }
  return valuesHash;
});
</script>
