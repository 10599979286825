<template>
  <div v-if="items.length" class="card table-card little-table-container">
    <div class="table-responsive m-0">
      <div class="dashboard-table-header">
        <h2>{{ $helpers.capitalWithoutDashes(name) }}</h2>
      </div>

      <AnalysisModal
        v-if="showAnalysisModal"
        :open="analysisModalVisible"
        :collapsed="true"
        parent-class="analysis-slide-modal"
      >
        <SalesDetailsModal
          v-if="showAnalysisModal"
          :open="analysisModalVisible"
          :data="salesDetailsData"
          :object-type="objectType"
          @close="analysisModalVisible = false"
        />
      </AnalysisModal>

      <table class="table table-striped-small borderless little-table">
        <thead>
          <tr>
            <th
              v-for="(key, i) in tableKeys"
              :key="`${key}${$route.path}`"
              scope="col"
              class="clickable link"
              @click="sortByRow(key)"

            >
              {{
                $helpers
                  .capitalWithoutDashes(key)
                  .replace('percent', '%')
                  .replace('eur', 'EUR')
              }}
              <span
                class="display-inline"
                style="vertical-align: middle; margin-left: 10px"
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-down-arrow open-details-cell-icon clickable link"
                  :class="{
                    'rotate-tab-arrow': sortDir === 'desc' && sortKey === key,
                  }"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </span>
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody class="scrollbar" style="height: 600px">
          <template v-for="(item, idx) in filteredResults" :key="idx">
            <tr>
              <td style='position: relative;'
                v-for="(row, i) in tableKeys"
                :key="row"
                @click="redirectToObjectpage(item)"
              >
                <div :style="setStyle(i)">
                  {{ $helpers.numberWithCommasFloat(item[row]) }} {{ percentSign(i) }}
                </div>
              </td>
              <td v-if="showAnalysisModal" class="open-details-cell">
                <svg
                  v-floating-tooltip="
                    `Show ${
                      openedDetails.row === idx ? 'less' : 'more'
                    } information`
                  "
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-side-arrow clickable link"
                  :class="{ 'rotate-arrow': openedDetails.row === idx }"
                  @click="openDetailsModal(item)"
                >
                  <path
                    d="M0.528636 1.47138C0.268287 1.21103 0.268287 0.788925 0.528636 0.528575C0.788986 0.268226 1.2111 0.268226 1.47145 0.528575L5.47145 4.52858C5.73179 4.78892 5.73179 5.21103 5.47145 5.47138L1.47145 9.47138C1.2111 9.73173 0.788986 9.73173 0.528636 9.47138C0.268287 9.21103 0.268287 8.78893 0.528636 8.52858L4.05723 4.99998L0.528636 1.47138Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </td>
              <td v-if="!showAnalysisModal" class="open-details-cell">
                <button
                  v-floating-tooltip="
                    `Show ${
                      openedDetails.row === idx ? 'less' : 'more'
                    } information`
                  "
                  class="btn btn-show-chart btn-sm ms-10 with-tooltip"
                  @click="openDetailsRow(idx, item[0])"
                >
                  <i class="icon show-chart-icon" />
                </button>
              </td>
            </tr>
            <tr v-if="!showAnalysisModal && openedDetails.row === idx && !openDetailsLoading" class="no-hover">
              <td
                colspan="7"
                class="opened-details"
                :class="{ 'is-closed': openedDetails.row !== idx }"
              >
                <div
                  class="opened-details-container"
                >
                  <div style="z-index: 1">
                    <StackedChart
                      v-if="objectType == 'customers'"
                      object="sales"
                      :object-type="objectType"
                      :object-id="item['id']"
                      :style-object="{
                        width: '100%',
                        alignSelf: 'center',
                        margin: '0 0 30px 0',
                        position: 'none !importnant',
                      }"
                      :end-date="range[1]"
                      :start-date="range[0]"
                      :quote="'Source: SAP BW & SAP ERP'"
                    />
                    <Chart
                      v-else
                      :search-path="`/discounts_and_deductions`"
                      :end-date="range[1]"
                      :start-date="range[0]"
                      :show-switch="true"
                      :object-type="objectType"
                      :table-mode="false"
                      :show-calendar="true"
                      :style-object="{
                        boxShadow: 'none',
                        border: 'none',
                        position: 'none !importnant',
                      }"
                      :object="objectType"
                      :condition-type="tableKeys[0]"
                      :object-name="item[tableKeys[0]]"
                      :object-id="item['id']"
                      :quote="'Source: SAP BW & SAP ERP'"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import Chart from '@/components/Chart/index.vue';
import StackedChart from '@/components/StackedChart.vue';

export default {
  name: 'SalesListTable',
  components: {
    AnalysisModal: defineAsyncComponent(() =>
      import('@/components/Modals/SlideModal/AnalysisModal.vue')
    ),
    SalesDetailsModal: defineAsyncComponent(() =>
      import('@/components/DiscountsAndDeductions/SalesDetailsModal.vue')
    ),
    StackedChart,
    Chart,
  },
  props: {
    items: Array,
    name: String,
    objectType: String,
    tableKeys: Array,
    showAnalysisModal: {
      type: Boolean,
      default: false,
    },
    loading: false,
  },
  data() {
    return {
      filterOptions: [],
      openDetailsLoading: false,
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
      },
      sortKey: 'in_EUR_YTD',
      sortDir: 'desc',
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      analysisModalVisible: false,
      salesDetailsData: this.items[0],
    };
  },
  computed: {
    filteredResults() {
      let result = this.items.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      return result;
    }
  },
  methods: {
    openDetailsModal(row) {
      this.salesDetailsData = row;
      this.analysisModalVisible = true;
    },
    percentSign(i) {
      if ((i === 2 || i === 4 || i == 5) && !this.showAnalysisModal) {
        return '%';
      }
    },
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
    redirectToObjectpage(item) {
      if (['customers', 'countries'].includes(this.objectType)) {
        this.$router.push(`/${this.objectType}/${item['id']}`);
      }
    },
    sortByRow(key) {
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },
    getTextAlign(index, length) {
      if (index + 1 == length) {
        return 'center';
      } else if (index > 0) {
        return 'right';
      } else {
        return 'left';
      }
    },
    setStyle(index) {
      if (index !== 0) {
        return 'position: relative; float: right; right: 50%;'
      }

      return ''
    }
  },
};
</script>
