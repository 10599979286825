<template>
  <tbody class="scrollbar" style="height: 800px !important">
    <template
      v-for="(item, idx) in sortedPrinciples"
      :key="$helpers.toString(item)"
    >
      <tr>
        <td @click="$router.push(getUrl(item))">
          <div class="link-with-hover">{{ item.name }}</div>
          <br />
          <template v-if="item.share">
            <span
              class="button-no-style clear-button"
              style="cursor: default !important"
              >mean year share:</span
            >
            {{ item.share }}%
          </template>
        </td>
        <template v-if="$route.path.includes('standartization_of_pricing')">
          <td>
            <i :class="getClass(item.difference.valid_limit)" />
            {{ item.difference.value }}%
          </td>
        </template>
        <template v-else>
          <td>
            <i :class="getClass(item.month.valid_limit)" />
            <span
              class="button-no-style clear-button"
              style="cursor: default !important"
              >BNO margin real:</span
            >
            {{ item.month.value }}%
            <br />
            <template v-if="item.weighted_margin_month">
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >weighted BNO margin:</span
              >
              {{ item.weighted_margin_month }}%
            </template>
            <template v-if="item.partner_margin_month">
              <i :class="getClass(item.partner_margin_month.valid_limit)" />
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >partner margin real:</span
              >
              {{ item.partner_margin_month.value }}%
              <br />
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >weighted partner margin:</span
              >
              {{ item.weighted_partner_margin_month }}%
            </template>
          </td>
          <td>
            <i :class="getClass(item.year.valid_limit)" />
            <span
              class="button-no-style clear-button"
              style="cursor: default !important"
              >BNO margin real:</span
            >
            {{ item.year.value }}%
            <br />
            <template v-if="item.weighted_margin_year">
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >weighted BNO margin:</span
              >
              {{ item.weighted_margin_year }}%
            </template>
            <template v-if="item.partner_margin_year">
              <i :class="getClass(item.partner_margin_year.valid_limit)" />
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >partner margin real:</span
              >
              {{ item.partner_margin_year.value }}%
              <br />
              <span
                class="button-no-style clear-button"
                style="cursor: default !important"
                >weighted partner margin:</span
              >
              {{ item.weighted_partner_margin_year }}%
            </template>
          </td>
        </template>
        <td v-if="item.status">{{ item.status }}</td>
        <td class="open-details-cell">
          <span
            v-if="!$route.path.includes('standartization_of_pricing')"
            class="info-quote-clickable"
            @click="openDetailsRow(idx, item.id)"
          >
            <i class="icon little-info-card-pic-1 link ms-10" />
            <div>{{ openedDetails.row === idx ? 'Hide ' : 'Show ' }} chart</div>
          </span>
        </td>
      </tr>

      <tr class="no-hover">
        <td
          colspan="4"
          class="opened-details"
          :class="{ 'is-closed': openedDetails.row !== idx }"
        >
          <div
            v-if="openedDetails.row === idx"
            class="opened-details-container"
          >
            <div style="v-index: 1">
              <Chart
                ref="objectType + item.id.toString() + countryName"
                :search-path="path"
                :end-date="range[1]"
                :start-date="range[0]"
                :show-switch="true"
                object="principles"
                :table-mode="false"
                :show-calendar="true"
                :object-type="objectType"
                :object-id="item.id.toString()"
                :object-name="countryName"
                :style-object="{ boxShadow: 'none', border: 'none' }"
                :fetched-results-type="'percent'"
              />
            </div>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import Chart from '@/components/Chart/index.vue';

export default {
  name: 'BottomAndTopPrincipleTab',
  components: { Chart },
  props: {
    data: {},
    reverse: Boolean,
    countriesListPage: Boolean,
    objectType: String,
    selectedFilter: String,
  },
  data() {
    return {
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
        id: null,
      },
      range: [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31),
      ],
      objectId: null,
    };
  },
  computed: {
    path() {
      return `/pricing_principles/${this.$route.path.split('/')[2]}/`;
    },
    sortedPrinciples() {
      let principles = this.data;
      principles.sort((a, b) => {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      if (this.reverse) {
        return principles.reverse();
      } else {
        return principles;
      }
    },
    countryName() {
      if (this.$route.query.name) {
        return this.$route.query.name;
      } else {
        return '';
      }
    },
  },
  watch: {
    selectedFilter() {
      this.openedDetails.row = -1;
    },
  },
  methods: {
    getClass(value) {
      if (value) {
        return 'icon principles-ok-icon';
      } else {
        return 'icon principles-wrong-icon';
      }
    },
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
    getUrl(object) {
      if (this.countriesListPage) {
        return `/pricing_principles/${
          this.$route.path.split('/')[2]
        }/details?name=${object.name}`;
      } else {
        return `/profit_center_variants/${object.id}`;
      }
    },
  },
};
</script>
