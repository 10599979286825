<template>
  <div class="profit-center-variant-detail price-change-request price-planning-table" style="margin: 60px 10px 60px 0px !important;">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="d-flex align-items-start">
      <div class="card">
        <b-card no-body>
          <b-tabs
            card
            content-class="mt-3"
            fill
            active-nav-item-class="font-weight-bold text-uppercase"
          >
            <b-tab no-body>
              <template #title>
                <div class="country-tab-title" @click="invertShowList()">
                  {{ type }}
                  <span class="ms-10 mt-20">
                    <svg
                      v-floating-tooltip="
                        `Show ${showList ? 'less' : 'more'} information`
                      "
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-down-arrow open-details-cell-icon clickable link mt-20"
                      :class="{ rotator: showList }"
                    >
                      <path
                        d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                        fill="#9CB2CB"
                      />
                    </svg>
                  </span>
                </div>
              </template>

              <div v-show="showList" class="table-responsive overflow-x-clip">
                <table class="table table-striped-pricing">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Changes</th>
                      <th>Period</th>
                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(country, idx) in countries"
                      :key="`${country.name}_${country.id}_${idx}`"
                    >
                      <tr>
                        <td @click="openItemPage(country)">
                          <div class="link-with-hover">{{ country.name }}</div>
                          <progress-bar
                            :bgcolor="'#A6DE7A'"
                            :completed="completed[country?.status][0]"
                            :notice="completed[country?.status][1]"
                            v-if="country && country?.status"/>
                        </td>
                        <td>
                          <div
                            v-if="country.status"
                            v-floating-tooltip="
                              revisionTooltip(country.reviewed)
                            "
                            class="d-flex help"
                            :style="`color: ${$helpers.getStatusColor(
                              country.status
                            )};`"
                          >
                            {{ $helpers.statusLineWithReviewerRole(country) }}
                            <i
                              :class="
                                $helpers.getIconStatusClass(country.reviewed)
                              "
                              class="ms-10 with-tooltip"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            v-if="![''].includes(country.status)"
                            @click="openDetailsRow(idx, country.id)"
                          >
                            <div class="info-quote-clickable d-flex">
                              {{ openedDetails.row == idx ? 'Hide' : 'Show' }} changes
                              <svg
                                v-floating-tooltip="
                                  `Show ${
                                    openedDetails.row == idx ? 'less' : 'more'
                                  } information`
                                "
                                width="12"
                                height="7"
                                viewBox="0 0 12 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-down-arrow clickable link ms-10"
                                :class="{ rotator: openedDetails.row == idx }"
                              >
                                <path
                                  d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                                  fill="#9CB2CB"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span v-if="country.valid_from">
                            {{ country.valid_from }} —
                            {{ country.valid_until }}
                          </span>
                        </td>
                        <td>
                          <button
                            v-if="showFinishButton(country)"
                            class="btn btn-primary btn-sm ms-10"
                            @click="$emit('openFinishModal', country)"
                          >
                            {{ finishedButtonName() }}
                          </button>

                          <button
                            v-if="showApproveButton(country)"
                            class="btn btn-success btn-sm ms-10"
                            @click="$emit('openApproveModal', country)"
                          >
                            {{ approveButtonName() }}
                          </button>

                          <button
                            v-if="
                              [
                                'approved',
                                'approved_one',
                                'finished_one',
                                'submitted',
                              ].includes(country.status) &&
                              $helpers.includedInRoleArray(
                                ['dcr_admin', 'gpo', 'country_head', 'deputy_country_head'],
                                userInfo.account_types
                              )
                            "
                            class="btn btn-warning btn-sm ms-10"
                            @click="
                              $emit(
                                'openRejectModal',
                                country.data_change_request,
                                'country'
                              )
                            "
                          >
                            <i class="icon reject-icon" />
                          </button>
                          <button
                            v-if="country.status != ''"
                            v-floating-tooltip="'Remove request'"
                            class="btn btn-danger btn-sm ms-10"
                            @click="$emit('openDiscardModal', country)"
                          >
                            <i class="icon trash-icon" />
                          </button>
                          <button
                            v-if="country.status != ''"
                            v-floating-tooltip="'View attachments'"
                            class="btn btn-attachment btn-sm ms-10"
                            @click="$emit('openAttachmentsModal', country.data_change_request)"
                          >
                            <i class="icon attachment-icon" />
                          </button>
                        </td>
                        <td>
                          <span class="display-inline" v-if="country.status != ''">
                            <RequestComments
                              :request="country.data_change_request"
                              :user-info="userInfo"
                              :commentable-type="'CountryDataChangeRequest'"
                            />
                          </span>

                          <i
                            class="icon history-icon link ms-10 display-inline"
                            @click="openHistoryModal(country)"
                          />

                          <i
                            class="icon chart-bar link ms-10 display-inline"
                            @click="openPlanPricesModal(country)"
                          />
                        </td>
                      </tr>
                      <!-- TODO: v-if user to be "!$helpers.isEmptyArray(countryPeriodicalData)" -->
                      <tr
                        v-if="true"
                        :key="`${country.name}_${country.id}_${idx}_1`"
                        class="no-hover"
                      >
                        <td
                          v-show="openedDetails.row === idx"
                          colspan="5"
                          class="opened-details"
                        >
                          <div class="card plan-prices-table" style="max-width:90%">
                            <ProductPriceOverview
                              :periodical-data="countryPeriodicalData"
                              :user-info="userInfo"
                              @open-price-buildup="
                                (request) => $emit('openPriceBuildup', request)
                              "
                              @open-reject-modal="
                                (request) =>
                                  $emit('openRejectModal', request, 'product')
                              "
                            />
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import ProductPriceOverview from '@/components/PricePlanning/ProductPriceOverview.vue';
import RequestComments from '@/components/PricePlanning/RequestComments.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'CountryRequestsList',
  components: { RequestComments, ProductPriceOverview, FadeLoader, ProgressBar },
  props: {
    countries: {},
    userInfo: Object,
    type: String,
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'openRejectModal',
    'openPriceBuildup',
    'openFinishModal',
    'openApproveModal',
    'openHistoryModal',
    'openPlanPricesModal',
  ],
  data() {
    return {
      objectId: null,
      openedDetails: {
        row: -1,
        id: null,
      },
      changeRequest: Object,
      loading: false,
      showList: false,
      countryPeriodicalData: [],
      completed: {
        "initialized": [30,"Brand manager: submit brand request."],
        "rejected": [60,"Head of marketing: submit country request."],
        "submitted": [75,"Country head: approve country request."],
        "approved_one": [90,"GPO: approve country request."],
        "approved": [95,"MOB: finish country request."],
        "finished_one": [99,"MOB: finish country request."],
      }
    };
  },
  beforeMount() {
    this.showList = this.isActive;
  },
  watch: {
    countries() {
      if (this.openedDetails.id) this.getPeriodicalData(this.openedDetails.id);
    }
  },
  methods: {
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
        this.countryPeriodicalData = {};
      } else {
        this.getPeriodicalData(id);

        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
    async getPeriodicalData(id) {
      this.loading = true;

      this.countryPeriodicalData = [];
      const url = `${this.$route.path}/get_country_data?country_id=${id}`;
      let { data: response } = await api.get(url);
      this.countryPeriodicalData = response;

      this.loading = false;
    },
    openItemPage(country) {
      this.$router.push(this.getItemUrl(country));
    },
    getItemUrl(country) {
      return `/price_plannings/${this.$route.params.id}/brand_data_change_requests?country_id=${country.id}`;
    },
    productLevel() {
      return this.$route.path.includes('product_data_change_requests');
    },
    revisionTooltip(reviewed) {
      if (reviewed) {
        return 'Country changes passed a review. Ready for approval.';
      } else {
        return 'Brand changes must be reviewed.';
      }
    },
    approveButtonName() {
      return `Approve`;
    },
    finishedButtonName() {
      return 'Finish';
    },
    showApproveButton(country) {
      return (
        country.data_change_request &&
        ((this.$helpers.includedInRoleArray(
          ['dcr_admin', 'country_head', 'deputy_country_head'],
          this.userInfo.account_types
        ) &&
          ['submitted'].includes(country.status)) ||
          (this.$helpers.includedInRoleArray(
            ['dcr_admin', 'gpo'],
            this.userInfo.account_types
          ) &&
            ['dcr_admin', 'approved_one'].includes(country.status)))
      );
    },
    showFinishButton(country) {
      if (this.$helpers.includedInRoleArray(['dcr_admin'], this.userInfo.account_types)) {
        return (
          ['approved', 'finished_one'].includes(country.status) && country.data_change_request
        );
      } else {
        return (
          ['approved', 'finished_one'].includes(country.status) &&
          country.data_change_request &&
          !this.$helpers.includedInRoleArray(
            country.data_change_request.corrections[0].user.account_types,
            this.userInfo.account_types
          ) &&
          this.$helpers.includedInRoleArray(
            ['dcr_admin', 'mob', 'mob_dep', 'mob_gb', 'mob_gb_dep'],
            this.userInfo.account_types
          )
        );
      }
    },
    invertShowList() {
      this.showList = !this.showList;
    },
    openHistoryModal(country) {
      this.$emit('openHistoryModal', country);
    },
    openPlanPricesModal(country) {
      this.$emit('openPlanPricesModal', country);
    },
  },
};
</script>
