<template>
  <div>
    <div @click="onPrinciplesClick(data.products)">
      <i
        ref="principlesIcon"
        :class="getClass(statusValue)"
        @click="onPrinciplesClick(data.products)"
      />
      <template v-if="showDetails">
        <i class="icon search-loope-icon display-inline" />
        <span class="button-no-style clear-button">show details</span>
      </template>
    </div>
    <p v-if="showDetails">
      {{ (data.difference && data.difference.value) || data.value }}%
    </p>
  </div>
</template>

<script>
export default {
  name: 'LineComponent',
  components: {},
  props: {
    data: {},
    statusValue: Boolean,
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['openPrinciplesModal'],
  methods: {
    getClass(value) {
      let status = value ? ' principles-ok-icon' : ' principles-wrong-icon';
      let cursorStyle = this.showDetails ? '' : ' no-pointer';

      return 'icon' + status + cursorStyle;
    },
    onPrinciplesClick(pricingPrincipleInfo) {
      this.$emit(
        'openPrinciplesModal',
        pricingPrincipleInfo,
        this.$refs.principlesIcon.getBoundingClientRect().top,
        this.$refs.principlesIcon.getBoundingClientRect().left
      );
    },
  },
};
</script>
