<template>
  <div class="profit-center-variant-detail price-change-request" style="margin: 60px 10px 60px 0px !important;">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="d-flex align-items-start">
      <div class="card" style="margin-right: 0px !important">
        <div class="table-responsive overflow-x-clip">
          <table class="table table-notstriped-details borderless">
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(product, idx) in itemsList"
                :key="`${product.name}_${product.id}_${idx}`"
              >
                <tr>
                  <td style="background: #f4fcee">
                    {{ product.name }}
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-floating-tooltip="revisionTooltip(product.reviewed)"
                      class="d-flex help"
                      :style="`color: ${$helpers.getStatusColor(
                        product.status
                      )};`"
                    >
                      {{ $helpers.formatStatus(product.status) }}
                      <i
                        :class="$helpers.getIconStatusClass(product.reviewed)"
                        class="ms-10 with-tooltip"
                      />
                    </div>
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-if="product.status != '' && product.status != 'skipped'"
                      @click="openDetailsRow(idx, product.id)"
                    >
                      <div class="info-quote-clickable d-flex">
                        {{ openedDetails.row == idx ? 'Hide' : 'Show' }} product
                        price changes
                        <svg
                          v-floating-tooltip="
                            `Show ${
                              openedDetails.row == idx ? 'less' : 'more'
                            } information`
                          "
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-down-arrow clickable link ms-10"
                          :class="{ rotator: openedDetails.row == idx }"
                        >
                          <path
                            d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                            fill="#9CB2CB"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-if="
                        $helpers.includedInRoleArray(
                          ['dcr_admin', 'brand_manager', 'area_sales_manager'],
                          userInfo.account_types
                        )
                      "
                      class="d-flex justify-content-left"
                    >
                      <button
                        :disabled="product.skip_review || !['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                        v-floating-tooltip="'New product data change request'"
                        class="btn btn-green btn-sm ms-10"
                        @click="$emit('openCreateModal', product, 'month')"
                      >
                        <i class="icon plus-icon" />
                      </button>
                      <button
                        :disabled="product.specific_days || product.skip_review || !['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                        v-floating-tooltip="'5-years-planning disabled for plannings with specific days'"
                        class="btn btn-green btn-price-planning ms-10"
                        @click="openPricePlanning(product)"
                      >
                        5-years price planning
                      </button>
                    </div>
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-if="
                        $helpers.includedInRoleArray(
                          ['dcr_admin', 'brand_manager', 'area_sales_manager'],
                          userInfo.account_types
                        )
                      "
                      class="d-flex justify-content-left"
                    >
                      <div class="custom-control custom-switch ms-30">
                        <input
                          :disabled="!['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                          :id="`${product.id}-skip-switch`"
                          v-model="product.skip_review"
                          type="checkbox"
                          class="custom-control-input"
                          @change="
                            $emit('skipProduct', product, product.skip_review)
                          "
                        />
                        <label
                          class="skip-checkbox"
                          :for="`${product.id}-skip-switch`"
                        >
                          SKIP
                        </label>
                      </div>
                      <button
                        :disabled="product.status == '' || product.skip_review || !['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                        v-floating-tooltip="'Remove all requests'"
                        class="btn btn-danger ms-20"
                        @click="$emit('openRemoveAllModal', product)"
                      >
                        <i class="icon trash-icon" />
                      </button>
                      <button
                        :disabled="product.status == '' || product.skip_review || !['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                        v-floating-tooltip="'Approve all requests'"
                        class="btn btn-success ms-10"
                        @click="$emit('openApproveAllModal', product)"
                      >
                        <i class="icon mark-icon" />
                      </button>
                      <button
                        :disabled="product.skip_review || !['initialized', 'rejected', null, ''].includes(brandRequestStatus)"
                        v-floating-tooltip="'New product data change request'"
                        class="btn btn-calendar ms-10"
                        @click="$emit('openCreateModal', product, 'day')"
                      >
                        <i class="icon calendar-days-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="!$helpers.isEmptyArray(productPeriodicalData)"
                  :key="`${product.name}_${product.id}_${idx}_1`"
                  class="no-hover"
                >
                  <td
                    v-show="openedDetails.row === idx"
                    colspan="5"
                    class="opened-details"
                  >
                    <div class="plan-prices-table" style="max-width:90%">
                      <ProductPriceOverview
                        :periodical-data="productPeriodicalData"
                        :user-info="userInfo"
                        @open-price-buildup="
                          (request) => $emit('openPriceBuildup', request)
                        "
                        @approve-changes="
                          (request) => $emit('openApproveModal', request, 'product')
                        "
                        @open-reject-modal="
                          (request) => $emit('openRejectModal', request, 'product')
                        "
                        @open-discard-modal="
                          (request) => $emit('openDiscardModal', request)
                        "
                      />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import ProductPriceOverview from '@/components/PricePlanning/ProductPriceOverview.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';

export default {
  name: 'ProductRequestsList',
  components: { ProductPriceOverview, FadeLoader },
  props: {
    itemsList: {},
    userInfo: Object,
    brandRequestStatus: {
      type: String,
      default: ''
    },
  },
  emits: [
    'openPriceBuildup',
    'openApproveModal',
    'openRejectModal',
    'openDiscardModal',
    'skipProduct',
    'openCreateModal',
    'openRemoveAllModal',
    'openApproveAllModal'
  ],
  data() {
    return {
      openedDetails: {
        row: -1,
        id: null,
      },
      productPeriodicalData: [],
      loading: false,
    };
  },
  watch: {
    itemsList() {
      if (this.openedDetails.id) this.getPeriodicalData(this.openedDetails.id);
    }
  },
  methods: {
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.getPeriodicalData(id);
        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
    async getPeriodicalData(id) {
      this.loading = true;

      this.productPeriodicalData = [];
      const url = `${this.$route.path}/get_product_data?pcv_id=${id}&country_id=${this.$route.query.country_id}`;
      let { data: response } = await api.get(url);
      this.productPeriodicalData = response;

      this.loading = false;
    },
    revisionTooltip(reviewed) {
      if (reviewed) {
        return 'Product changes passed a review. Ready for approval.';
      } else {
        return 'Product changes must be reviewed.';
      }
    },
    openPricePlanning(product) {
      this.$router.push(
        `/price_plannings/${this.$route.params.id}/plan_price_planning/${product.id}?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
      );
    },
  },
};
</script>
