<template>
  <transition name="modal">
    <div class="comments-modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          ref="changeRequestModalContainer"
          class="comments-modal-container change-request-modal"
          :class="{ 'max-content': true }"
          :style="{
            width: '45%',
            alignSelf: 'center',
            marginLeft: '20%',
            marginTop: '-10%',
          }"
        >
          <div class="modal-body">
            <button
              class="comments-modal-close-button button-no-style"
              style="z-index: 1000"
              @click="$emit('close')"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.64844 1L12.0007 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3523 1L1 11.3523"
                  stroke="#DBE1E9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <template v-if="modalAction == 'create'">
              <h3>Create Price Change Request</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Price Buildup Validity Period:</label
                  ><br />
                  <DatePicker
                    ref="datepicker"
                    v-model:value="validDates"
                    :clearable="false"
                    :type="calendarType || 'month'"
                    range
                    :disabled-date="disabledDates"
                    placeholder="Date Select"
                    :open="isVisibleDatePicker"
                    :close="!isVisibleDatePicker"
                    @close="isVisibleDatePicker = false"
                    @open="isVisibleDatePicker = true"
                  >
                    <template #icon-calendar>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                          :fill="
                            range[0] !== null && range[1] !== null
                              ? '#003B6A'
                              : '#839AB5'
                          "
                        />
                      </svg>
                    </template>
                    <template #icon-clear>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                          fill="#003B6A"
                        />
                      </svg>
                    </template>
                  </DatePicker>
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-green normal-button"
                  @click="
                    $emit(
                      'createDataChangeRequest',
                      validDates,
                      changeRequestProp,
                      calendarType
                    )
                  "
                >
                  Create
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'approve'">
              <h3>Appove Price Change Request</h3>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to approve request?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-success normal-button"
                  @click="$emit('approveChanges', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'reject'">
              <h3>Reject Price Change Request</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput">Comment for rejection:</label>
                  <textarea
                    id="commentInput"
                    v-model="changeRequestProp.comment"
                    class="form-control text-input"
                    rows="2"
                  />
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-warning normal-button align-right"
                  @click="rejectChanges(changeRequestProp)"
                >
                  Reject
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'finish'">
              <h3>Finish Price Change Request</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to finish
                    {{ changeRequestProp.name }}?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-primary normal-button"
                  @click="$emit('finishChanges', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'discard'">
              <h3>{{ changeRequestProp.type == 'ProductDataChangeRequest' ? 'Discard': 'Remove'}} Price Change Request</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >All related changes will be lost and you won't be able to rollback the action.</label
                  >
                </div>
                <div class="col-lg-10" v-if="changeRequestProp.type != 'ProductDataChangeRequest'"><br>
                  <label for="commentInput"
                    >Country Data Change Request will not automatically be removed as well.</label
                  ><br><br>
                </div>
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to {{ changeRequestProp.type == 'ProductDataChangeRequest' ? 'discard' : 'remove'}} request?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-danger normal-button"
                  @click="$emit('discardChanges', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'removeAll'">
              <h3>Remove all Price Change Requests</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10 mt-30"><br>
                  <label for="commentInput"
                    >Are you sure you want to remove all changes?</label
                  >
                </div>
                <div class="col-lg-10 mt-30"><br>
                  <label for="commentInput"
                    >If a Country and Brand Data change requests exist, they will not be automatically removed.</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-danger normal-button"
                  @click="$emit('removeAllRequests', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'approveAll'">
              <h3>Price Change Request</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to approve all product requests?</label>
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-success normal-button"
                  @click="$emit('approveAllRequests', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'removeAttachment'">
              <h3>Remove file</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to remove file?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-danger normal-button"
                  @click="$emit('removeAttachment', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'approveFiveYears'">
              <h3>{{ $route.path.includes('plan_price_planning') ? '5-Years Planning' : 'Product Price Planning'}}</h3>
              <div class="second-title-grayed mb-30">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Brand and Country Change Requests will be created if they haven't been yet.</label
                  >
                </div>
              </div>
              <div class="form-group row ms-0 mt-30"><br>
                <div class="col-lg-10">
                  <label for="commentInput"
                    >Are you sure you want to {{ $route.path.includes('plan_price_planning') ? 'save' : 'approve'}} changes?</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-success normal-button"
                  @click="$emit('approveChanges', changeRequestProp)"
                >
                  Yes
                </button>
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  No
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'openWarningModal'">
              <h3>Warning!</h3>
              <div class="form-group row ms-0 mt-30">
                <div class="col-lg-10">
                  <label for="commentInput"
                    >The period you selected contains variables with values (FX rate, Discount in the Invoice) that differ over time. Your price request has been automatically divided into the different periods.</label
                  >
                </div>
              </div>
              <div class="align-right w100">
                <button
                  class="btn btn-light normal-button"
                  @click="$emit('close')"
                >
                  Close
                </button>
              </div>
            </template>

            <template v-if="modalAction == 'history'">
              <h3>Finished periods</h3>
              <div class="second-title-grayed">{{ objectName }}</div>
              <div class="form-group row ms-0 mt-30">
                <div
                  v-for="cdcrData in changeRequestProp.history_data"
                  :key="cdcrData.id"
                  class="chart-summary"
                >
                  <div
                    class="chart-summary-toggler link"
                    @click="
                      $router.push(`/data_change_requests/${cdcrData.id}`)
                    "
                  >
                    Country request finished on
                    <b>{{ cdcrData.finished_at }}</b>
                  </div>
                  <template
                    v-for="(bdrcData, bdrcId) in cdcrData.periodical_data"
                    :key="bdrcId"
                  >
                    <div class="ms-10">
                      <div
                        class="chart-summary-toggler link"
                        @click="$router.push(`/data_change_requests/${bdrcId}`)"
                      >
                        Brand request for {{ bdrcData.name }}
                      </div>
                      <div
                        v-for="pdcr in bdrcData.periodical_data"
                        :key="pdcr.id"
                        class="chart-summary-toggler link ms-30"
                        @click="
                          $router.push(`/data_change_requests/${pdcr.id}`)
                        "
                      >
                        {{ pdcr.name }}
                        <b>{{ pdcr.valid_from }} - {{ pdcr.valid_until }}</b>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    objectName: String,
    changeRequest: Object,
    top: Number,
    left: Number,
    userInfo: Object,
    modalAction: String,
    rejectType: String,
    calendarType: String
  },
  emits: [
    'close',
    'approveChanges',
    'discardChanges',
    'finishChanges',
    'createDataChangeRequest',
    'update:changeRequest',
    'removeAllRequests',
    'approveAllRequests',
    'removeAttachment',
    'openWarningModal'
  ],
  data() {
    return {
      isVisibleDatePicker: false,
      validDates: [],
      disabledBefore: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ),
      disabledAfter: new Date(new Date().getFullYear() + 5, 11, 31),
      range: [new Date(), new Date(new Date().getFullYear(), 12, 31)],
    };
  },
  computed: {
    changeRequestProp: {
      get() {
        return this.changeRequest;
      },
      set(value) {
        this.$emit('update:changeRequest', value);
      },
    },
  },
  watch: {
    validDates() {
      this.isVisibleDatePicker = false;
    },
  },
  methods: {
    disabledDates(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    rejectChanges(changeRequest) {
      let actionName = this.rejectTypeAction();
      this.$emit(actionName, changeRequest, changeRequest.comment);
    },
    rejectTypeAction() {
      if (this.rejectType == 'product') {
        return 'rejectProductChanges';
      } else if (this.rejectType == 'brand') {
        return 'rejectBrandChanges';
      } else if (this.rejectType == 'country') {
        return 'rejectCountryChanges';
      }
    },
  },
};
</script>
