<template>
  <div class="statistic-bar-chart">
    <div
      :id="`statistic-bar-chart-${selectedType}`"
      class="statistic-bar-chart"
    />
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
  name: 'StatisticBarChart',
  components: {},
  props: {
    selectedType: String,
    countries: Array,
    params: Object,
  },
  watch: {
    countries: {
      deep: true,
      handler() {
        this.updateData();
      },
    },
  },
  created() {
    this.root = null;
    this.chart = null;
    this.chartSeries = {};
    this.chartParams = {
      xAxis: null,
      yAxis: null,
      legend: null,
    };
  },
  mounted() {
    this.setChart();
  },
  methods: {
    goToCountry(id) {
      this.$router.push(`/countries/${id}`);
    },
    createTooltip() {
      let tooltip = am5.Tooltip.new(this.root, {
        autoTextColor: false,
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        getLabelFillFromSprite: false,
        fill: am5.color(0x000000),
        pointerOrientation: 'horizontal',
      });
      tooltip.get('background').setAll({
        fill: am5.color(0xffffff),
      });
      tooltip.label.setAll({
        text: '[bold]{categoryY}[/] (please click for details)',
        fill: am5.color(0x000000),
      });
      tooltip.label.adapters.add('text', (text) => {
        this.chart.series.each((series) => {
          text +=
            '\n[' +
            series.get('stroke').toString() +
            ']●[/] [bold width:100px]' +
            '[' +
            series.get('stroke').toString() +
            ']' +
            series.get('name') +
            ':[/] {' +
            series.get('valueXField') +
            '}' +
            this.xAxisPostfix();
        });
        return text;
      });
      return tooltip;
    },
    createSeries(field, name) {
      let tooltip = this.createTooltip();
      let series = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: name,
          xAxis: this.chartParams.xAxis,
          yAxis: this.chartParams.yAxis,
          valueXField: field,
          categoryYField: 'name',
          sequencedInterpolation: true,
          alternativeText: am5.color(0x50b300),
          fill: am5.color(this.$helpers.setColorByName(name)),
          stroke: am5.color(this.$helpers.setColorByName(name)),
          arrangeTooltips: false,
          tooltip: tooltip,
        })
      );
      series.columns.template.setAll({
        height: am5.p100,
      });
      series.columns.template.events.on('click', (e) => {
        this.goToCountry(e.target.dataItem.dataContext.id);
      });
      series.data.setAll(this.countries);
      series.appear(1000);
      this.chartSeries[field] = series;
    },
    setChart() {
      this.root = am5.Root.new(`statistic-bar-chart-${this.selectedType}`);
      this.root.setThemes([am5themes_Animated.new(this.root)]);
      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: false,
          panY: false,
          wheelX: false,
          wheelY: false,
          maxTooltipDistance: -1,
          layout: this.root.verticalLayout,
        })
      );
      this.chartParams.yAxis = this.chart.yAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: 'name',
          renderer: am5xy.AxisRendererY.new(this.root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
          }),
        })
      );
      this.chartParams.xAxis = this.chart.xAxes.push(
        am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererX.new(this.root, {}),
          numberFormat: `#,###. ${this.xAxisPostfix()}`,
          min: 0,
        })
      );
      let legend = this.chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      this.chartParams.yAxis.data.setAll(this.countries);
      Object.keys(this.params).forEach((key, index) => {
        this.createSeries(
          this.dataAttr(this.params[key]),
          `${this.params[key]}`,
          index
        );
      });
      legend.data.setAll(this.chart.series.values);
      let cursor = this.chart.set(
        'cursor',
        am5xy.XYCursor.new(this.root, {
          behavior: 'zoomY',
        })
      );
      cursor.lineY.set('forceHidden', true);
      cursor.lineX.set('forceHidden', true);
      this.chart.appear(1000, 100);
    },
    updateData() {
      this.chartParams.yAxis.data.setAll(this.countries);
      Object.keys(this.params).forEach((key) => {
        this.chartSeries[this.dataAttr(this.params[key])].data.setAll(
          this.countries
        );
      });
    },
    xAxisPostfix() {
      return this.selectedType === 'euro'
        ? this.$helpers.getCurrencySymbol()
        : '';
    },
    dataAttr(attr) {
      return this.selectedType === 'euro' ? `${attr}` : `${attr}_units`;
    },
  },
};
</script>

<style scoped>
.statistic-bar-chart {
  width: 100%;
  height: 645px;
}
</style>
