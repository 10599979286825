<template>
  <div class="products-table">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="products-table-title">
      <h1>Global Price Planning</h1>
      <br />
      <span class="color-aaa"> Overview of countries </span>
    </div>
    <div v-if="false" class="d-flex justify-content-left mt-30 mb-30 ms-30">
      <button
        class="buildup-card-right-panel-button btn btn-outline-dark vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Global Level
        </span>
      </button>
    </div>

    <CountryRequestsList
      :key="'CountryRequestsListOwn'"
      :countries="ownBusiness"
      type="Own Business"
      :is-active="true"
      :user-info="userInfo"
      @open-approve-modal="openApproveModal"
      @open-finish-modal="openFinishModal"
      @open-reject-modal="openRejectModal"
      @open-discard-modal="openDiscardModal"
      @open-history-modal="openHistoryModal"
      @open-plan-prices-modal="openPlanPricesModal"
      @open-attachments-modal="openAttachmentsModal"
    />

    <CountryRequestsList
      :key="'CountryRequestsListPartner'"
      :countries="partnerBusiness"
      type="Partner Business"
      :is-active="false"
      :user-info="userInfo"
      @open-approve-modal="openApproveModal"
      @open-finish-modal="openFinishModal"
      @open-reject-modal="openRejectModal"
      @open-discard-modal="openDiscardModal"
      @open-history-modal="openHistoryModal"
      @open-plan-prices-modal="openPlanPricesModal"
      @open-attachments-modal="openAttachmentsModal"
    />

    <PopupModal
      v-if="popupModalVisible"
      v-model:changeRequest="changeRequest"
      :key="componentKey"
      :top="150"
      :left="650"
      :object-name="modalObjectName"
      :user-info="userInfo"
      :modal-action="modalAction"
      :reject-type="rejectType"
      @close="popupModalVisible = false"
      @approve-changes="approveChanges"
      @reject-country-changes="rejectCountryChanges"
      @reject-product-changes="rejectProductChanges"
      @finish-changes="finishChanges"
      @discard-changes="discardChanges"
    >
    </PopupModal>

    <AttachmentsModal
      v-if="attachmentsModalVisible"
      v-model:attachmentObject="attachmentObject"
      :key="componentKey"
      :top="150"
      :left="650"
      :type="'Country'"
      :user-info="userInfo"
      @close="attachmentsModalVisible = false"
    />

    <AnalysisModal
      v-if="priceModalVisible"
      :open="priceModalVisible"
      :collapsed="true"
      parent-class="analysis-slide-modal"
    >
      <PricePlanningModal
        :open="priceModalVisible"
        :country="priceModalCountry"
        @close="priceModalVisible = false"
      />
    </AnalysisModal>
  </div>
</template>

<script>
import api from '@/helpers/api';
import CountryRequestsList from '@/components/PricePlanning/CountryRequestsList.vue';
import AttachmentsModal from '@/components/PricePlanning/Modals/AttachmentsModal.vue';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import AnalysisModal from '@/components/Modals/SlideModal/AnalysisModal.vue';
import PricePlanningModal from '@/components/PricePlanning/Modals/PricePlanningModal.vue';

export default {
  name: 'CountryPricePlanning',
  components: {
    CountryRequestsList,
    AttachmentsModal,
    PopupModal,
    FadeLoader,
    AnalysisModal,
    PricePlanningModal,
  },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      ownBusiness: {
        type: Array,
        default: [],
      },
      partnerBusiness: {
        type: Array,
        default: [],
      },
      pricePlanning: {
        type: Object,
        default: {},
      },
      loading: false,
      popupModalVisible: false,
      modalAction: String,
      modalObjectName: String,
      rejectType: '',
      componentKey: 0,
      analysisModalVisible: false,
      priceModalCountry: Object,
      priceModalVisible: false,
      attachmentsModalVisible: false,
      attachmentObject: {}
    };
  },
  async beforeMount() {
    await this.loadDataChangeRequests();
  },
  methods: {
    returnToList() {
      this.$router.push(`/price_plannings`);
    },
    async loadDataChangeRequests() {
      this.loading = true;

      const url = `${this.$route.path}/get_planning_data`;
      let { data: response } = await api.get(url);
      this.ownBusiness = response.own_business;
      this.partnerBusiness = response.partner_business;
      this.pricePlanning = response.price_planning;

      this.loading = false;
    },
    openApproveModal(item) {
      this.componentKey = Math.random();
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'approve';
      this.modalObjectName = item.name;
    },
    async approveChanges(item) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests/${item.data_change_request.id}/approve`
        )
        .then(() => {
          this.$helpers.notifySuccess('Country Data Change Request Approved');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openFinishModal(changeRequest) {
      this.changeRequest = changeRequest;
      this.modalAction = 'finish';
      this.modalObjectName = changeRequest.object_name;
      this.popupModalVisible = true;
    },
    async finishChanges(item) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests/${item.data_change_request.id}/finish`
        )
        .then(() => {
          this.$helpers.notifySuccess('Country Data Change Request Finished');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openRejectModal(changeRequest, rejectType) {
      this.rejectType = rejectType;
      this.changeRequest = changeRequest;
      this.modalAction = 'reject';
      this.modalObjectName = changeRequest.object_name;
      this.popupModalVisible = true;
    },
    async rejectCountryChanges(item, comment) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests/${item.id}/reject`,
          {
            comment,
          }
        )
        .then(() => {
          this.$helpers.notifyWarning('Country Data Change Request Rejected');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async rejectProductChanges(item, comment) {
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/product_data_change_requests/${item.id}/reject`,
          {
            comment,
          }
        )
        .then(() => {
          this.$helpers.notifyWarning('Product Data Change Request Rejected');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    openDiscardModal(country) {
      this.changeRequest = country;
      this.popupModalVisible = true;
      this.modalAction = 'discard';
      this.modalObjectName = country.name;
    },
    openAttachmentsModal(countryChangeRequest) {
      this.attachmentObject = countryChangeRequest;
      this.attachmentsModalVisible = true;
    },
    async discardChanges(item) {
      this.componentKey = Math.random();
      await api
        .post(
          `/price_plannings/${this.$route.params.id}/country_data_change_requests/${item.data_change_request.id}/discard`
        )
        .then(() => {
          this.$helpers.notifyWarning('Country Data Change Request removed');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
      await this.loadDataChangeRequests();
    },
    async openHistoryModal(country) {
      this.loading = true;

      const url = `${this.$route.path}/get_history_data?country_id=${country.id}`;
      let { data: response } = await api.get(url);

      this.changeRequest = response;
      this.modalAction = 'history';
      this.modalObjectName = response.object.name;

      this.loading = false;
      this.popupModalVisible = true;
    },
    async openPlanPricesModal(country) {
      this.priceModalCountry = country;
      this.priceModalVisible = true;
    },
  },
};
</script>
