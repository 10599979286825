<template>
  <div class="comment comment-with-avatar" style="margin-left: 2px !important">
    <div class="comment-author-and-date">
      <i class="icon default-avatar" />
      <div class="comment-header">
        <div class="comment-author-info">
          <span class="comment-author">
            {{ correction.user.first_name }}
            {{ correction.user.last_name }}
          </span>
          ,
          <span class="comment-position">{{ correction.status }}</span>
        </div>
        <div class="comment-date">
          <span>
            {{ $helpers.dateToString(new Date(correction.created_at)) }}
          </span>
        </div>
      </div>
    </div>
    <p
      v-for="(value, key) in correction.values"
      :key="value"
      class="comment-text mb-0"
    >
      Proposed {{ $helpers.fieldToSpecificName(key) }} value:
      <b>{{ value }}{{ currencySign }}</b>
      <br />
      valid between:
      <b>
        {{ $helpers.dateToString(new Date(correction.valid_from)) }} -
        {{ $helpers.dateToString(new Date(correction.valid_until)) }}
      </b>
    </p>
    <p class="comment-text mb-0">{{ correction.comment }}</p>
  </div>
</template>

<script>
export default {
  name: 'Correction',
  props: {
    correction: Object,
    currencySign: {
      type: String,
      default: '',
    },
  },
  emits: ['onDelete'],
  data() {
    return {
      confirmModalVisible: false,
      confirmIconTop: 0,
      confirmIconLeft: 0,
    };
  },
  methods: {
    showConfirmModal() {
      this.confirmIconTop = this.$refs.confirmIcon.getBoundingClientRect().top;
      this.confirmIconLeft =
        this.$refs.confirmIcon.getBoundingClientRect().left;
      this.confirmModalVisible = true;
    },
    onDelete() {
      this.$emit('onDelete', this.commentData.id);
      this.onCloseConfirmModal();
    },
    onCloseConfirmModal() {
      this.confirmModalVisible = false;
    },
  },
};
</script>
