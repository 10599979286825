<template>
  <div class="options-select">
    <div class="hierarchy-item-currency-selector" style="width: 300px">
      <div class="multiselect">
        <div class="multiselect__tags">
          <span class="multi-select__placeholder">Filter Elements</span>
          <div class="multiselect__select" @click="selectToggle()">
            <i class="icon icon-down-arrow" />
          </div>
        </div>
        <div
          id="options-wrapper"
          ref="options-wrapper"
          class="multiselect__content-wrapper"
        >
          <div class="multiselect-body">
            <div class="multiselect clear-all">
              <ul class="multiselect__content">
                <li class="multiselect__element">
                  <span class="multiselect__option" @click="clearFilter()">
                    Clear All
                  </span>
                  <div class="multiselect__select" @click="clearFilter()">
                    <i class="icon close-tag-icon" />
                  </div>
                </li>
              </ul>
            </div>

            <div
              v-for="key in modelKeysArray"
              :key="key.field_name + key.nice_name"
              class="multiselect-nested"
            >
              <Multiselect
                :id="key.field_name"
                v-model="modelInternalValues[key.field_name]"
                :options="modelOptions[key.field_name]"
                :placeholder="key.nice_name"
                select-label
                :show-labels="false"
                close-on-select
                :disabled="modelValues.hasOwnProperty('type') && modelValues.type == '' && key.field_name == 'object'"
              >
                <template #caret="{ toggle }">
                  <div
                    class="multiselect__select"
                    @mousedown.prevent.stop="toggle"
                  >
                    <i class="icon icon-down-arrow" />
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionsSelect',
  props: {
    loading: Boolean,
    modelKeysArray: Array,
    modelValues: Object,
    modelOptions: Object,
  },
  emits: ['clearFilters', 'orderChanged'],
  data() {
    return {
      modelInternalValues: this.modelValues,
    };
  },
  watch: {
    modelInternalValues: {
      deep: true,
      handler(val) {
        this.$emit('orderChanged', 'modelValues', val);
        const item = this.$refs['options-wrapper'];
        item.style.display = 'none';
      },
    },
  },
  mounted() {
    var item = this.$refs['options-wrapper'];
    item.style.display = 'none';
  },
  methods: {
    selectToggle() {
      const item = this.$refs['options-wrapper'];
      if (item && item.style.display === 'none') {
        item.style.display = 'block';
      } else if (item) {
        item.style.display = 'none';
      }
    },
    clearFilter() {
      let keys = Object.keys(this.modelInternalValues);
      keys.forEach((key) => {
        this.modelInternalValues[key] = '';
      });
      this.$emit('clearFilters');
    },
  },
};
</script>
