<template>
  <div class="chart-info">
    <button
      class="button-no-style link d-flex align-items-center card back-button"
      v-if="!$helpers.isEmptyObject(activeRow) && !showAnalysisModal"
      @click="removeDetails"
    >
      <span class="text-uppercase me-2">Close</span>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{ width: '10px', height: '20px' }"
      >
        <path
          d="M5.47145 8.52858C5.73179 8.78893 5.73179 9.21103 5.47145 9.47138C5.2111 9.73173 4.78899 9.73173 4.52864 9.47138L0.528636 5.47138C0.268287 5.21103 0.268287 4.78892 0.528636 4.52858L4.52864 0.528575C4.78899 0.268226 5.2111 0.268226 5.47145 0.528575C5.73179 0.788925 5.73179 1.21103 5.47145 1.47138L1.94285 4.99998L5.47145 8.52858Z"
          fill="white"
        />
      </svg>
    </button>
  </div>

  <div
    v-if="items.length"
    class="table-card little-table-container"
    style="border-radius: none !importnant"
  >
    <div class="table-responsive m-0">
      <div v-if="activeRow.title" class="dashboard-table-header">
        <h2 class="capitalize">
          <span v-if="showTitle">{{
            $helpers.capitalWithoutDashes(name)
          }}</span>
          <span>{{ activeRow.title || '' }}</span>
        </h2>
      </div>

      <div
        v-if="!$helpers.isEmptyObject(activeRow) && !showAnalysisModal"
        ref="chartBlock"
      >
        <StackedChart
          v-if="useStackedChart && name != 'currencies'"
          :key="chartComponentKey"
          object="sales"
          :currency-id="activeRow.currency_id"
          :object-id="activeRow.id"
          :style-object="{
            width: '100%',
            alignSelf: 'center',
            margin: '0 0 30px 0',
          }"
          :end-date="range[1]"
          :start-date="range[0]"
          data-type="exchangeEffects"
          :quote="'Source: SAP BW. Calculation of LE and BU exchange rate effect based on monthly average actual rates. Calculation of LE rest exchange rate effect based on daily last updated exchange rate.'"
          :page-name="activeRow.name"
        />

        <Chart
          v-if="name === 'currencies'"
          :key="chartComponentKey"
          :search-path="getItemUrl(openedDetails)"
          :end-date="range[1]"
          :start-date="range[0]"
          object="currencies"
          :table-mode="false"
          :show-calendar="true"
          :style-object="{ boxShadow: 'none', border: 'none' }"
          :quote="'Source: SAP BW'"
          :page-name="activeRow.name"
        />

        <Chart
          v-if="!useStackedChart && name != 'currencies'"
          :key="chartComponentKey"
          :search-path="getItemUrl(openedDetails)"
          :end-date="range[1]"
          :start-date="range[0]"
          :show-switch="true"
          object="sales"
          :table-mode="false"
          :show-calendar="true"
          :object-type="objectType"
          :object-id="objectId"
          :style-object="{ boxShadow: 'none', border: 'none' }"
          :quote="'Source: SAP BW'"
          :page-name="activeRow.name"
        />
      </div>

      <div class="dashboard-table-header">
        <div />
        <div>
          <ExcelXlsxButton
            class="toggle-button-xlsx"
            style="border: 1px solid $main-blue;"
            :data="items"
            :columns="xlsxColumns"
            :file-name="name + ' data'"
            sheet-name="Default"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22px"
              height="22px"
              viewBox="0 0 26 26"
              :stroke="'#3b7d15'"
            >
              <path
                style="fill: #030104"
                d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
              />
              <path
                style="fill: #ffffff"
                d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
              />
            </svg>
          </ExcelXlsxButton>
          <button
            v-if="name == 'customers'"
            class="btn btn-white-normal me-30 display-inline"
            @click="joinCustomers"
          >
            Aggregate by group 2
          </button>
          <Multiselect
            v-if="!$helpers.isEmptyObject(filterOptions) && showTabFilter"
            v-model="selectedFilter"
            :options="filterOptions"
            select-label
            placeholder
            :show-labels="false"
            class="display-inline"
          >
            <template #caret="{ toggle }">
              <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                <i class="icon icon-down-arrow" @click="refreshOptions" />
              </div>
            </template>
          </Multiselect>
        </div>
      </div>

      <AnalysisModal
        :open="analysisModalVisible"
        :collapsed="true"
        parent-class="analysis-slide-modal"
      >
        <SalesDetailsModal
          :open="analysisModalVisible"
          :data="salesDetailsData"
          :object-type="objectType"
          @close="analysisModalVisible = false"
        />
      </AnalysisModal>

      <table
        class="table table-striped-small borderless little-table"
        :style="
          $helpers.isCurrenciesListPage($route.path)
            ? 'table-layout: fixed !important;'
            : ''
        "
      >
        <thead>
          <tr>
            <th
              v-for="(key, i) in tableKeys"
              :key="`${key}${$route.path}`"
              scope="col"
              :style="{
                width: getHeaderNameWidth(i),
                textAlign: getTextAlign(i, tableKeys.length),
              }"
              class="clickable link"
              @click="sortByRow(key)"
            >
              <span class="display-inline" style="font-size: 15px !important">
                {{
                  $helpers
                    .capitalWithoutDashes(key)
                    .replace('percent', '%')
                    .replace('eur', 'EUR')
                    .replace('ytd', 'YTD')
                    .replace('ytg', 'YTG')
                    .replace('Ytd', 'YTD')
                }}
              </span>
              <span
                class="display-inline"
                style="vertical-align: middle; margin-left: 10px"
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-down-arrow open-details-cell-icon clickable link"
                  :class="{
                    'rotate-tab-arrow': sortDir === 'desc' && sortKey === key,
                  }"
                >
                  <path
                    d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                    fill="#9CB2CB"
                  />
                </svg>
              </span>
            </th>
            <th />
          </tr>
        </thead>

        <tbody id="tab-container" class="scrollbar" :style="`${setHeight}`">
          <template
            v-for="(row, rowIndex) in filteredResults"
            :key="`${rowIndex}${$route.path}`"
          >
            <tr :style="rowStyle(row, rowIndex)">
              <td
                v-for="(key, index) in tableKeys"
                :key="rowIndex + index + $route.path"
                :class="
                  index === 0 && row[key] !== 'Total' ? 'link-with-hover' : ''
                "
                :style="{
                  width: getHeaderNameWidth(index),
                  textAlign: getTextAlign(index, tableKeys.length),
                }"
                @click="
                  index === 0 && row[key] !== 'Total'
                    ? $router.push(getItemUrl(row))
                    : ''
                "
              >
                <span class="display-inline">
                  {{ $helpers.numberWithCommasAndZeros(row[key]) }}
                  {{ showPercent(index) }}
                </span>
              </td>
              <td>
                <span v-if="showAnalysisModal" @click="openDetailsModal(row)">
                  <div class="info-quote-clickable" style="text-align: center;">Show details</div>
                </span>
                <button
                  v-if="showChangeRequestButton(row)"
                  class="btn btn-new-currency-rate btn-sm ms-10 with-tooltip"
                  @click="openChangeRequestModal(row['current_fixed_rate'], row)"
                >
                  <i class="icon new-currency-rate-icon" />
                </button>
                <button
                  v-if="openChart(row) && !showAnalysisModal"
                  class="btn btn-show-chart btn-sm ms-10 with-tooltip"
                  @click="openDetailsRow(rowIndex, row)"
                >
                  <i class="icon show-chart-icon" />
                </button>
              </td>
            </tr>
          </template>
          <CurrencyDataChangeRequest
            v-if="changeRequestModalVisible"
            :top="50"
            :left="650"
            :current-value="changeRequestCurrentValue"
            object="currency"
            :object-id="changeRequestObjectId"
            :fields="['exch_fixed', 'exch_bu']"
            :object-name="changeRequestObjectName"
            :user-info="userInfo"
            :change-request-with-cfo="changeRequestWithCfo"
            :currency-sign="changeRequestCurrencySign"
            @close="changeRequestModalVisible = false"
          >
          </CurrencyDataChangeRequest>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import Chart from '@/components/Chart/index.vue';
import StackedChart from '@/components/StackedChart.vue';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';
import CurrencyDataChangeRequest from '@/components/CurrencyDataChangeRequest/index.vue';

export default {
  name: 'HierarchyItemTab',
  components: {
    Chart,
    StackedChart,
    ExcelXlsxButton,
    CurrencyDataChangeRequest,
    AnalysisModal: defineAsyncComponent(() =>
      import('@/components/Modals/SlideModal/AnalysisModal.vue')
    ),
    SalesDetailsModal: defineAsyncComponent(() =>
      import('@/components/DiscountsAndDeductions/SalesDetailsModal.vue')
    ),
  },
  props: {
    items: Array,
    name: String,
    tableSortKey: String,
    tableSortable: String,
    tableKeys: Array,
    useStackedChart: Boolean,
    ignoreUrlId: {
      type: Boolean,
      default: false,
    },
    objectType: String,
    objectId: String,
    showCharts: {
      type: Boolean,
      default: true,
    },
    showAnalysisModal: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    preSelectedFilter: {
      type: String,
      default: '',
    },
    userInfo: Object,
    showTabFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterOptions: [],
      selectedFilter: '',
      openDetailsLoading: false,
      openedDetails: {
        row: -1,
        data: {},
        labels: [],
        results: [],
        id: null,
      },
      range: [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31),
      ],
      nameColumnWidth: '300px',
      redirectPath: '',
      activeRow: {},
      chartComponentKey: 0,
      analysisModalVisible: false,
      salesDetailsData: this.items[0],
      sortKey: 'ytd_eur',
      sortDir: 'desc',
      showCustomersJoined: false,
      uniqueGroupTwoKeys: [],
      changeRequestIconTop: 0,
      changeRequestIconLeft: 0,
      changeRequestModalVisible: false,
      changeRequestObjectId: '',
      changeRequestCurrentValue: 0.0,
      changeRequestObjectName: '',
      changeRequestWithCfo: false,
      changeRequestCurrencySign: '',
    };
  },
  computed: {
    filteredResults() {
      let result = [];

      if (this.selectedFilter === 'extended') {
        result = this.items.filter(
          (row) => row[this.tableSortable] == this.selectedFilter || row[this.tableSortable] == 'main'
        );
      } else if (this.selectedFilter !== 'Show all') {
        result = this.items.filter(
          (row) => row[this.tableSortable] == this.selectedFilter
        );
      } else {
        result = this.items;
      }

      if (this.showCustomersJoined) {
        let grouped;
        let month_eur;
        let month_units;
        let ytd_eur;
        let ytd_units;
        let groupedItems;

        groupedItems = this.uniqueGroupTwoKeys.map((key) => {
          grouped = this.items.filter((row) => row.group_2 === key);
          month_eur = grouped.reduce((a, b) => {
            return a + b.month_eur;
          }, 0);
          month_units = grouped.reduce((a, b) => {
            return a + b.month_units;
          }, 0);
          ytd_eur = grouped.reduce((a, b) => {
            return a + b.ytd_eur;
          }, 0);
          ytd_units = grouped.reduce((a, b) => {
            return a + b.ytd_units;
          }, 0);

          return {
            group_2: grouped[0].group_2,
            group: grouped[0].group,
            id: grouped[0].id,
            name: grouped[0].name,
            visible: grouped[0].visible,
            month_eur: month_eur,
            month_units: month_units,
            ytd_eur: ytd_eur,
            ytd_units: ytd_units,
          };
        });

        result = this.items
          .filter((el) => el.group_2 === '')
          .concat(groupedItems);
      }

      result = result.sort((a, b) => {
        let modifier = 1;
        if (this.sortDir === 'desc') modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });

      result = result.sort((a, b) => {
        return b.visible - a.visible;
      });

      if (this.showTotal) {
        result = result.filter((row) => row.name != 'Total');
        let summary = this.addSummary(result);
        result.unshift(summary);
      }

      return result;
    },
    setHeight() {
      const pcvLength = this.filteredResults.length

      if (pcvLength >= 6) {
        return 'height: 400px;'
      } else {
        return 'height: 100%;'
      }
    },
    xlsxColumns() {
      return this.tableKeys.filter(item => !/month/i.test(item)).map(item => {  
        const capitalized = item.charAt(0).toUpperCase() + item.slice(1);
        const formatted = capitalized.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        return { label: formatted, field: item};
      });
    }
  },

  watch: {
    preSelectedFilter() {
      if (this.preSelectedFilter !== '') {
        this.selectedFilter = this.preSelectedFilter;
      }
    },
  },

  async mounted() {
    if (this.preSelectedFilter != '') {
      this.selectedFilter = this.preSelectedFilter;
    } else {
      this.selectedFilter = 'Show all';
    }
    if (this.$route.path == '/currencies' && this.name == 'currencies')
      this.selectedFilter = 'main';

    if (this.items.length > 0) {
      let array = ['Show all'];
      this.items.map((item) => array.push(item[this.tableSortable]));
      this.filterOptions = [...new Set(array)];
    }

    this.uniqueGroupTwoKeys = [
      ...new Set(this.items.map((a) => a.group_2)),
    ].filter((el) => el != '');
  },

  methods: {
    joinCustomers() {
      this.showCustomersJoined =
        this.showCustomersJoined === false ? true : false;
    },

    sortByRow(key) {
      if (key === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = key;
    },

    openDetailsRow(index, row) {
      if (index === this.openedDetails.row && this.openedDetails.id === row.id) {
        this.openedDetails.row = -1;
        this.removeDetails();
      } else {
        this.openedDetails.id = row.id;
        this.openedDetails.row = index;
        this.activeRow = row;
        this.chartComponentKey += 1;
        this.activeRow.title = row.name;
        this.activeRow.index = index;
        this.scrollToChart();
      }
    },

    removeDetails() {
      this.activeRow = {};
      this.openedDetails = {
        row: -1,
        data: {},
        labels: [],
        results: [],
        id: null,
      };
    },

    refreshOptions() {
      if (this.items.length > 0 && this.filterOptions.length == 0) {
        let array = ['Show all'];
        this.items.map((item) => array.push(item[this.tableSortable]));
        this.filterOptions = [...new Set(array)];
      }
    },

    getHeaderNameWidth(index) {
      if (index == 0 && this.$helpers.isCurrenciesListPage(this.$route.path)) {
        return '200px';
      }
    },

    showPercent(index) {
      if (
        (index == 3 || index == 5) &&
        ['country_discounts_and_deductions'].includes(this.name)
      ) {
        return '%';
      }
    },

    getItemUrl(row) {
      let outputUrl = undefined;

      if (this.ignoreUrlId) {
        outputUrl = `/${row.id}`;
      } else if (this.countriesExchangeRatesTable()) {
        outputUrl = `/countries/${row.id}`;
      } else if (this.name === 'sales_exchange_rate_effects') {
        outputUrl = `/currencies/${row.currency_id}`;
      } else {
        outputUrl = `/${this.name}/${row.id}`;
      }

      return outputUrl;
    },

    openChart(row) {
      if (
        this.uniqueGroupTwoKeys.includes(row.group_2) &&
        this.showCustomersJoined
      )
        return false;
      return !['Undefined', 'discounts', 'deductions', 'Total'].includes(
        row.name
      );
    },

    columnVisible(index) {
      if (this.name == 'sales_exchange_rate_effects') {
        return index > 0 && index !== 7 && index !== 4;
      } else {
        return index > 0;
      }
    },

    countriesExchangeRatesTable() {
      return (
        this.name == 'sales_exchange_rate_effects' &&
        this.$route.path == '/currencies'
      );
    },

    rowStyle(row, row_index) {
      if (this.showTotal && row_index == 0) {
        return 'background: #f2efaa';
      } else if (this.activeRow.index == row_index) {
        return 'background: #f2efaa';
      } else if (row.visible == false) {
        return 'background: #EEEEEE';
      } else {
        return 'background: #F4FCEE;';
      }
    },

    scrollToChart() {
      let el = this.$refs['chartBlock'];
      el && el.scrollIntoView();
    },

    getTextAlign(index, length) {
      if (index + 1 == length) {
        return 'center';
      } else if (index > 0) {
        return 'right';
      } else {
        return 'left';
      }
    },

    openDetailsModal(row) {
      this.salesDetailsData = row;
      this.analysisModalVisible = true;
    },

    openChangeRequestModal(currentValue, row) {
      this.changeRequestObjectId = row['id'];
      this.changeRequestObjectName = row['name'];
      this.changeRequestCurrentValue = currentValue;
      this.changeRequestWithCfo = !this.$helpers.isEmptyArray(row['cfo_ids']);
      this.changeRequestCurrencySign = this.$helpers.getCurrencySymbol(
        row['short_name']
      );
      this.changeRequestIconTop = 175;
      this.changeRequestIconLeft = 700;
      this.changeRequestModalVisible = true;
    },

    addSummary(items) {
      let totals = {};

      if (items.length == 0) return totals;

      let keys = Object.keys(items[0]);
      keys.forEach((x) => {
        if (typeof items[0][x] == 'number') {
          totals[x] = 0;
        } else {
          totals[x] = '';
        }
      });

      totals.name = 'Total';
      totals.country = 'Total';

      items.forEach((h) => {
        totals.month_eur += h.month_eur;
        totals.month_units += h.month_units;
        totals.ytd_eur += h.ytd_eur;
        totals['ytd_eur_%'] += h['ytd_eur_%'];
        totals.ytd_units += h.ytd_units;
        totals['ytd_units_%'] += h['ytd_units_%'];
        totals.sales_effect_month += h.sales_effect_month;
        totals.sales_effect_ytg += h.sales_effect_ytg;
      });

      return totals;
    },

    showChangeRequestButton(row) {
      if (this.$helpers.isEmptyObject(this.userInfo)) return false;

      return (
        (row.cfo_ids.includes(this.userInfo.id) ||
          this.$helpers.includedInRoleArray(
            ['cfo', 'mob', 'mob_dep','gpo','admin','dcr_admin'],
            this.userInfo.account_types
          )) && row['current_fixed_rate']
      );
    },
  },
};
</script>
