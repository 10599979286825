<template>
  <div class="products-table">
    <div class="products-table-title">
      <h1>Pricing Principles</h1>
    </div>

    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span><PulseLoader :loading="loading" /></span>
    </div>

    <div class="row small-table-row">
      <span class="color-aaa"> Premium Pricing </span>
    </div>

    <div
      class="row small-table-row"
      @click="$router.push('/pricing_principles/bottom_and_top_down')"
    >
      <a class="link clickable"> Bottom up and Top down approach </a>
    </div>

    <div
      class="row small-table-row"
      @click="
        $router.push('/pricing_principles/corridor_of_net_wholesaler_purchases')
      "
    >
      <a class="link clickable">
        Corridor of net wholesaler purchase prices in customs unions: +/-10%;
        worldwide +/-15%
      </a>
    </div>

    <div
      class="row small-table-row"
      @click="$router.push('/pricing_principles/standartization_of_pricing')"
    >
      <a class="link clickable">
        Standardization of pricing within one brand
      </a>
    </div>

    <div class="row small-table-row">
      <span class="color-aaa">
        Management of prices in cases of significant exchange rate fluctuations
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrincingPrinciples',
  data() {
    return {
      data: {},
      loading: false,
      items: [],
    };
  },
  async beforeMount() {
    this.loading = true;

    this.loading = false;
  },
};
</script>
