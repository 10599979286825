<template>
  <div class="buildup-card">
    <div class="buildup-card-main">
      <div class="buildup-card-line">
        <span v-if="data && !editingField">{{ data.change_name }}</span>
        <input
          v-if="!data"
          v-model="formValues.changeName"
          placeholder="Enter Level  transformation"
        />
        <input v-if="data && editingField" v-model="formValues.changeName" />

        <span v-if="data && !editingField">
          {{ data.change_value.toFixed(3) }} {{ pbWithPercentSign ? '%' : currencySign }}
        </span>
        <input
          v-if="!data"
          v-model="formValues.changeValue"
          placeholder="Enter Value"
        />
        <input v-if="data && editingField" v-model="formValues.changeValue" />

        <div style="width: 83px" />
      </div>
      <button v-if="editingField" class="btn btn-green" @click="updateField">
        Save
      </button>
    </div>
    <div v-if="!editingField" class="buildup-card-right-panel">
      <button class="buildup-card-right-panel-button button-no-style">
        <i class="icon arrows-icon" />
      </button>
      <button
        class="buildup-card-right-panel-button button-no-style"
        @click="editField"
      >
        <i class="icon open-filters-icon" />
      </button>
      <button class="buildup-card-right-panel-button button-no-style">
        <i class="icon comment-icon" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceBuildupCard',
  props: {
    id: Number,
    data: Object,
    creatingNewField: Boolean,
    editingField: {
      type: Boolean,
      default: false,
    },
    currencySign: '',
  },
  emits: ['editField', 'updateField'],
  data() {
    return {
      retailPriceInclVATCurrency: {
        value: 'Euro',
        options: ['Euro', 'Dollar'],
      },
      retailPricewoVATCurrency: {
        value: 'Euro',
        options: ['Euro', 'Dollar'],
      },
      formValues: {
        outputName: '',
        endPrice: '',
        changeName: '',
        changeValue: '',
        inputName: '',
        startPrice: '',
      },
    };
  },
  computed: {
    pbWithPercentSign() {
      return [
          'marg_man','disc_man','marg_man2','sal_deduct','disc_ws','mark_up_ws','disc_ph','mark_up_ph','disc_cons','vat'
        ].includes(this.data.change_name)
    }
  },
  mounted() {
    if (this.data && this.editingField) {
      this.formValues.changeName = this.data.change_name;
      this.formValues.changeValue = this.data.change_value;
    }
  },
  methods: {
    editField() {
      this.$emit('editField', this.data.change_name);
    },
    updateField() {
      this.$emit('updateField', this.formValues);
    },
  },
};
</script>
