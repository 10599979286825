export const countryCapitalCoordinates = [
  {
    name: "Afghanistan",
    iso_code: "AF",
    iso_code3: "AFG",
    capital: "Kabul",
    latitude: 34.5155,
    longitude: 69.1952
  },
  {
    name: "Albania",
    iso_code: "AL",
    iso_code3: "ALB",
    capital: "Tirana",
    latitude: 41.3279,
    longitude: 19.8190
  },
  {
    name: "Algeria",
    iso_code: "DZ",
    iso_code3: "DZA",
    capital: "Algiers",
    latitude: 36.7755,
    longitude: 3.0597
  },
  {
    name: "American Samoa",
    iso_code: "AS",
    iso_code3: "ASM",
    capital: ""
  },
  {
    name: "Andorra",
    iso_code: "AD",
    iso_code3: "AND",
    capital: "Andorra la Vella"
  },
  {
    name: "Angola",
    iso_code: "AO",
    iso_code3: "AGO",
    capital: "Luanda",
    latitude: -8.8159,
    longitude: 13.2306
  },
  {
    name: "Anguilla",
    iso_code: "AI",
    iso_code3: "AIA",
    capital: ""
  },
  {
    name: "Antarctica",
    iso_code: "AQ",
    iso_code3: "ATA",
    capital: ""
  },
  {
    name: "Antigua and Barbuda",
    iso_code: "AG",
    iso_code3: "ATG",
    capital: "St. John's"
  },
  {
    name: "Argentina",
    iso_code: "AR",
    iso_code3: "ARG",
    capital: "Buenos Aires",
    latitude: -34.6118,
    longitude: -58.4173
  },
  {
    name: "Armenia",
    iso_code: "AM",
    iso_code3: "ARM",
    capital: "Yerevan",
    latitude: 40.1596,
    longitude: 44.509
  },
  {
    name: "Aruba",
    iso_code: "AW",
    iso_code3: "ABW",
    capital: ""
  },
  {
    name: "Australia",
    iso_code: "AU",
    iso_code3: "AUS",
    capital: "Canberra"
  },
  {
    name: "Austria",
    iso_code: "AT",
    iso_code3: "AUT",
    capital: "Vienna",
    latitude: 48.2092,
    longitude: 16.3728
  },
  {
    name: "Azerbaijan",
    iso_code: "AZ",
    iso_code3: "AZE",
    capital: "Baku",
    latitude: 40.3834,
    longitude: 49.8932
  },
  {
    name: "Bahamas",
    iso_code: "BS",
    iso_code3: "BHS",
    capital: "Nassau"
  },
  {
    name: "Bahrain",
    iso_code: "BH",
    iso_code3: "BHR",
    capital: "Manama",
    latitude: 26.1921,
    longitude: 50.5354
  },
  {
    name: "Bangladesh",
    iso_code: "BD",
    iso_code3: "BGD",
    capital: "Dhaka",
    latitude: 23.7106,
    longitude: 90.3978
  },
  {
    name: "Barbados",
    iso_code: "BB",
    iso_code3: "BRB",
    capital: "Bridgetown",
    latitude: 13.0935,
    longitude: -59.6105
  },
  {
    name: "Belarus",
    iso_code: "BY",
    iso_code3: "BLR",
    capital: "Minsk",
    latitude: 53.9678,
    longitude: 27.5766
  },
  {
    name: "Belgium",
    iso_code: "BE",
    iso_code3: "BEL",
    capital: "Brussels",
    latitude: 50.8371,
    longitude: 4.3676
  },
  {
    name: "Belize",
    iso_code: "BZ",
    iso_code3: "BLZ",
    capital: "Belmopan",
    latitude: 17.2534,
    longitude: -88.7713
  },
  {
    name: "Benin",
    iso_code: "BJ",
    iso_code3: "BEN",
    capital: "Porto Novo",
    latitude: 6.4779,
    longitude: 2.6323
  },
  {
    name: "Bermuda",
    iso_code: "BM",
    iso_code3: "BMU",
    capital: ""
  },
  {
    name: "Bhutan",
    iso_code: "BT",
    iso_code3: "BTN",
    capital: "Thimphu",
    latitude: 27.4405,
    longitude: 89.673
  },
  {
    name: "Bolivia (Plurinational State of)",
    iso_code: "BO",
    iso_code3: "BOL",
    capital: "Sucre",
    latitude: -19.0421,
    longitude: -65.2559
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso_code: "BQ",
    iso_code3: "BES",
    capital: ""
  },
  {
    name: "Bosnia and Herzegovina",
    iso_code: "BA",
    iso_code3: "BIH",
    capital: "Sarajevo",
    latitude: 43.8608,
    longitude: 18.4214
  },
  {
    name: "Botswana",
    iso_code: "BW",
    iso_code3: "BWA",
    capital: "Gaborone",
    latitude: -24.657,
    longitude: 25.9089
  },
  {
    name: "Bouvet Island",
    iso_code: "BV",
    iso_code3: "BVT",
    capital: ""
  },
  {
    name: "Brazil",
    iso_code: "BR",
    iso_code3: "BRA",
    capital: "Brasilia",
    latitude: -15.7801,
    longitude: -47.9292
  },
  {
    name: "British Indian Ocean Territory",
    iso_code: "IO",
    iso_code3: "IOT",
    capital: ""
  },
  {
    name: "British Virgin Islands",
    iso_code: "VG",
    iso_code3: "VGB",
    capital: ""
  },
  {
    name: "Brunei Darussalam",
    iso_code: "BN",
    iso_code3: "BRN",
    capital: "Bandar Seri Begawan",
    latitude: 4.9431,
    longitude: 114.9425
  },
  {
    name: "Bulgaria",
    iso_code: "BG",
    iso_code3: "BGR",
    capital: "Sofia",
    latitude: 42.7105,
    longitude: 23.3238
  },
  {
    name: "Burkina Faso",
    iso_code: "BF",
    iso_code3: "BFA",
    capital: "Ouagadougou",
    latitude: 12.3569,
    longitude: -1.5352
  },
  {
    name: "Burundi",
    iso_code: "BI",
    iso_code3: "BDI",
    capital: "Bujumbura",
    latitude: -3.3818,
    longitude: 29.3622
  },
  {
    name: "Cabo Verde",
    iso_code: "CV",
    iso_code3: "CPV",
    capital: "Praia"
  },
  {
    name: "Cambodia",
    iso_code: "KH",
    iso_code3: "KHM",
    capital: "Phnom Penh",
    latitude: 11.5434,
    longitude: 104.8984
  },
  {
    name: "Cameroon",
    iso_code: "CM",
    iso_code3: "CMR",
    capital: "Yaoundé"
  },
  {
    name: "Canada",
    iso_code: "CA",
    iso_code3: "CAN",
    capital: "Ottawa",
    latitude: 45.4235,
    longitude: -75.6979
  },
  {
    name: "Cayman Islands",
    iso_code: "KY",
    iso_code3: "CYM",
    capital: ""
  },
  {
    name: "Central African Republic",
    iso_code: "CF",
    iso_code3: "CAF",
    capital: "Bangui",
    latitude: 4.3621,
    longitude: 18.5873
  },
  {
    name: "Chad",
    iso_code: "TD",
    iso_code3: "TCD",
    capital: "N'Djamena"
  },
  {
    name: "Chile",
    iso_code: "CL",
    iso_code3: "CHL",
    capital: "Santiago",
    latitude: -33.4691,
    longitude: -70.642
  },
  {
    name: "China",
    iso_code: "CN",
    iso_code3: "CHN",
    capital: "Beijing"
  },
  {
    name: "China, Macao Special Administrative Region",
    iso_code: "MO",
    iso_code3: "MAC",
    capital: ""
  },
  {
    name: "Christmas Island",
    iso_code: "CX",
    iso_code3: "CXR",
    capital: ""
  },
  {
    name: "Cocos (Keeling) Islands",
    iso_code: "CC",
    iso_code3: "CCK",
    capital: ""
  },
  {
    name: "Colombia",
    iso_code: "CO",
    iso_code3: "COL",
    capital: "Bogota",
    latitude: 4.6473,
    longitude: -74.0962
  },
  {
    name: "Comoros",
    iso_code: "KM",
    iso_code3: "COM",
    capital: "Moroni"
  },
  {
    name: "Congo",
    iso_code: "CG",
    iso_code3: "COG",
    capital: "Brazzaville",
    latitude: -4.2767,
    longitude: 15.2662
  },
  {
    name: "Cook Islands",
    iso_code: "CK",
    iso_code3: "COK",
    capital: ""
  },
  {
    name: "Costa Rica",
    iso_code: "CR",
    iso_code3: "CRI",
    capital: "San José"
  },
  {
    name: "Croatia",
    iso_code: "HR",
    iso_code3: "HRV",
    capital: "Zagreb",
    latitude: 45.815,
    longitude: 15.9785
  },
  {
    name: "Cuba",
    iso_code: "CU",
    iso_code3: "CUB",
    capital: "Havana",
    latitude: 23.1333,
    longitude: -82.3667
  },
  {
    name: "Cura�ao",
    iso_code: "CW",
    iso_code3: "CUW",
    capital: ""
  },
  {
    name: "Cyprus",
    iso_code: "CY",
    iso_code3: "CYP",
    capital: "Nicosia",
    latitude: 35.1676,
    longitude: 33.3736
  },
  {
    name: "Czech Republic",
    iso_code: "CZ",
    iso_code3: "CZE",
    capital: "Prague",
    latitude: 50.0878,
    longitude: 14.4205
  },
  {
    name: "Ivory Coast",
    iso_code: "CI",
    iso_code3: "CIV",
    capital: "Yamoussoukro",
    latitude: 6.8067,
    longitude: -5.2728
  },
  {
    name: "Democratic People's Republic of Korea",
    iso_code: "KP",
    iso_code3: "PRK",
    capital: "Pyongyang"
  },
  {
    name: "Democratic Republic of the Congo",
    iso_code: "CD",
    iso_code3: "COD",
    capital: "Kinshasa",
    latitude: -4.3369,
    longitude: 15.3271
  },
  {
    name: "Denmark",
    iso_code: "DK",
    iso_code3: "DNK",
    capital: "Copenhagen",
    latitude: 55.6763,
    longitude: 12.5681
  },
  {
    name: "Djibouti",
    iso_code: "DJ",
    iso_code3: "DJI",
    capital: "Djibouti",
    latitude: 11.5806,
    longitude: 43.1425
  },
  {
    name: "Dominica",
    iso_code: "DM",
    iso_code3: "DMA",
    capital: "Roseau",
    latitude: 15.2976,
    longitude: -61.39
  },
  {
    name: "Dominican Republic",
    iso_code: "DO",
    iso_code3: "DOM",
    capital: "Santo Domingo",
    latitude: 18.479,
    longitude: -69.8908
  },
  {
    name: "Ecuador",
    iso_code: "EC",
    iso_code3: "ECU",
    capital: "Quito",
    latitude: -0.2295,
    longitude: -78.5243
  },
  {
    name: "Egypt",
    iso_code: "EG",
    iso_code3: "EGY",
    capital: "Cairo",
    latitude: 30.0571,
    longitude: 31.2272
  },
  {
    name: "El Salvador",
    iso_code: "SV",
    iso_code3: "SLV",
    capital: "San Salvador",
    latitude: 13.7034,
    longitude: -89.2073
  },
  {
    name: "Equatorial Guinea",
    iso_code: "GQ",
    iso_code3: "GNQ",
    capital: "Malabo"
  },
  {
    name: "Eritrea",
    iso_code: "ER",
    iso_code3: "ERI",
    capital: "Asmara",
    latitude: 15.3315,
    longitude: 38.9183
  },
  {
    name: "Estonia",
    iso_code: "EE",
    iso_code3: "EST",
    capital: "Tallinn",
    latitude: 59.4389,
    longitude: 24.7545
  },
  {
    name: "Eswatini",
    iso_code: "SZ",
    iso_code3: "SWZ",
    capital: "Mbabane",
    latitude: -26.3186,
    longitude: 31.141
  },
  {
    name: "Ethiopia",
    iso_code: "ET",
    iso_code3: "ETH",
    capital: "Addis Ababa",
    latitude: 9.0084,
    longitude: 38.7575
  },
  {
    name: "Falkland Islands (Malvinas)",
    iso_code: "FK",
    iso_code3: "FLK",
    capital: ""
  },
  {
    name: "Faroe Islands",
    iso_code: "FO",
    iso_code3: "FRO",
    capital: ""
  },
  {
    name: "Fiji",
    iso_code: "FJ",
    iso_code3: "FJI",
    capital: "Suva"
  },
  {
    name: "Finland",
    iso_code: "FI",
    iso_code3: "FIN",
    capital: "Helsinki",
    latitude: 60.1699,
    longitude: 24.9384
  },
  {
    name: "France",
    iso_code: "FR",
    iso_code3: "FRA",
    capital: "Paris",
    latitude: 48.8567,
    longitude: 2.351
  },
  {
    name: "French Guiana",
    iso_code: "GF",
    iso_code3: "GUF",
    capital: ""
  },
  {
    name: "French Polynesia",
    iso_code: "PF",
    iso_code3: "PYF",
    capital: ""
  },
  {
    name: "French Southern Territories",
    iso_code: "TF",
    iso_code3: "ATF",
    capital: ""
  },
  {
    name: "Gabon",
    iso_code: "GA",
    iso_code3: "GAB",
    capital: "Libreville",
    latitude: 0.3858,
    longitude: 9.4496
  },
  {
    name: "Gambia",
    iso_code: "GM",
    iso_code3: "GMB",
    capital: "Banjul",
    latitude: 13.4399,
    longitude: -16.6775
  },
  {
    name: "Georgia",
    iso_code: "GE",
    iso_code3: "GEO",
    capital: "Tbilisi",
    latitude: 41.701,
    longitude: 44.793
  },
  {
    name: "Germany",
    iso_code: "DE",
    iso_code3: "DEU",
    capital: "Berlin",
    latitude: 52.5235,
    longitude: 13.4115
  },
  {
    name: "Ghana",
    iso_code: "GH",
    iso_code3: "GHA",
    capital: "Accra",
    latitude: 5.5401,
    longitude: -0.2074
  },
  {
    name: "Gibraltar",
    iso_code: "GI",
    iso_code3: "GIB",
    capital: "Gibraltar",
    latitude: 36.1377,
    longitude: -5.3453
  },
  {
    name: "Great Britain",
    iso_code: "GB",
    iso_code3: "GBR",
    capital: "London",
    latitude: 51.5002,
    longitude: -0.1262
  },
  {
    name: "Greece",
    iso_code: "GR",
    iso_code3: "GRC",
    capital: "Athens",
    latitude: 37.9792,
    longitude: 23.7166
  },
  {
    name: "Greenland",
    iso_code: "GL",
    iso_code3: "GRL",
    capital: ""
  },
  {
    name: "Grenada",
    iso_code: "GD",
    iso_code3: "GRD",
    capital: "St. George's"
  },
  {
    name: "Guadeloupe",
    iso_code: "GP",
    iso_code3: "GLP",
    capital: ""
  },
  {
    name: "Guam",
    iso_code: "GU",
    iso_code3: "GUM",
    capital: ""
  },
  {
    name: "Guatemala",
    iso_code: "GT",
    iso_code3: "GTM",
    capital: "Guatemala City",
    latitude: 14.6248,
    longitude: -90.5328
  },
  {
    name: "Guernsey",
    iso_code: "GG",
    iso_code3: "GGY",
    capital: ""
  },
  {
    name: "Guinea",
    iso_code: "GN",
    iso_code3: "GIN",
    capital: "Conakry",
    latitude: 9.537,
    longitude: -13.6785
  },
  {
    name: "Guinea-Bissau",
    iso_code: "GW",
    iso_code3: "GNB",
    capital: "Bissau",
    latitude: 11.8598,
    longitude: -15.5875
  },
  {
    name: "Guyana",
    iso_code: "GY",
    iso_code3: "GUY",
    capital: "Georgetown"
  },
  {
    name: "Haiti",
    iso_code: "HT",
    iso_code3: "HTI",
    capital: "Port-au-Prince"
  },
  {
    name: "Heard Island and McDonald Islands",
    iso_code: "HM",
    iso_code3: "HMD",
    capital: ""
  },
  {
    name: "Holy See",
    iso_code: "VA",
    iso_code3: "VAT",
    capital: "Vatican City"
  },
  {
    name: "Honduras",
    iso_code: "HN",
    iso_code3: "HND",
    capital: "Tegucigalpa"
  },
  {
    name: "Hong Kong",
    iso_code: "HK",
    iso_code3: "HKG",
    capital: ""
  },
  {
    name: "Hungary",
    iso_code: "HU",
    iso_code3: "HUN",
    capital: "Budapest",
    latitude: 47.4984,
    longitude: 19.0408
  },
  {
    name: "Iceland",
    iso_code: "IS",
    iso_code3: "ISL",
    capital: "Reykjavik",
    latitude: 64.1353,
    longitude: -21.8952
  },
  {
    name: "India",
    iso_code: "IN",
    iso_code3: "IND",
    capital: "New Delhi",
    latitude: 28.6353,
    longitude: 77.225
  },
  {
    name: "Indonesia",
    iso_code: "ID",
    iso_code3: "IDN",
    capital: "Jakarta",
    latitude: -6.1862,
    longitude: 106.8063
  },
  {
    name: "Iran",
    iso_code: "IR",
    iso_code3: "IRN",
    capital: "Tehran",
    latitude: 35.69439,
    longitude: 51.42151,
  },
  {
    name: "Iraq",
    iso_code: "IQ",
    iso_code3: "IRQ",
    capital: "Baghdad",
    latitude: 33.3157,
    longitude: 44.3922
  },
  {
    name: "Ireland",
    iso_code: "IE",
    iso_code3: "IRL",
    capital: "Dublin",
    latitude: 53.3441,
    longitude: -6.2675
  },
  {
    name: "Isle of Man",
    iso_code: "IM",
    iso_code3: "IMN",
    capital: ""
  },
  {
    name: "Israel",
    iso_code: "IL",
    iso_code3: "ISR",
    capital: "Jerusalem",
    latitude: 31.7719,
    longitude: 35.2170
  },
  {
    name: "Italy",
    iso_code: "IT",
    iso_code3: "ITA",
    capital: "Rome",
    latitude: 41.8955,
    longitude: 12.4823
  },
  {
    name: "Jamaica",
    iso_code: "JM",
    iso_code3: "JAM",
    capital: "Kingston"
  },
  {
    name: "Japan",
    iso_code: "JP",
    iso_code3: "JPN",
    capital: "Tokyo",
    latitude: 35.6785,
    longitude: 139.6823
  },
  {
    name: "Jersey",
    iso_code: "JE",
    iso_code3: "JEY",
    capital: ""
  },
  {
    name: "Jordan",
    iso_code: "JO",
    iso_code3: "JOR",
    capital: "Amman",
    latitude: 31.9394,
    longitude: 35.9349
  },
  {
    name: "Kazakhstan",
    iso_code: "KZ",
    iso_code3: "KAZ",
    capital: "Astana",
    latitude: 51.1796,
    longitude: 71.4475
  },
  {
    name: "Kenya",
    iso_code: "KE",
    iso_code3: "KEN",
    capital: "Nairobi",
    latitude: -1.2762,
    longitude: 36.7965
  },
  {
    name: "Kiribati",
    iso_code: "KI",
    iso_code3: "KIR",
    capital: "Tarawa"
  },
  {
    name: "Korea",
    iso_code: "KR",
    iso_code3: "KOR",
    capital: "Seoul",
    latitude: 37.5139,
    longitude: 126.9828
  },
  {
    name: "Kuwait",
    iso_code: "KW",
    iso_code3: "KWT",
    capital: "Kuwait City",
    latitude: 29.3721,
    longitude: 47.9824
  },
  {
    name: "Kyrgyzstan",
    iso_code: "KG",
    iso_code3: "KGZ",
    capital: "Bishkek",
    latitude: 42.8679,
    longitude: 74.5984
  },
  {
    name: "Lao People's Democratic Republic",
    iso_code: "LA",
    iso_code3: "LAO",
    capital: "Vientiane",
    latitude: 17.9689,
    longitude: 102.6137
  },
  {
    name: "Latvia",
    iso_code: "LV",
    iso_code3: "LVA",
    capital: "Riga",
    latitude: 56.9465,
    longitude: 24.1049
  },
  {
    name: "Lebanon",
    iso_code: "LB",
    iso_code3: "LBN",
    capital: "Beirut",
    latitude: 33.8872,
    longitude: 35.5134
  },
  {
    name: "Lesotho",
    iso_code: "LS",
    iso_code3: "LSO",
    capital: "Maseru",
    latitude: -29.2976,
    longitude: 27.4854
  },
  {
    name: "Liberia",
    iso_code: "LR",
    iso_code3: "LBR",
    capital: "Monrovia",
    latitude: 6.3106,
    longitude: -10.8047
  },
  {
    name: "Libya",
    iso_code: "LY",
    iso_code3: "LBY",
    capital: "Tripoli",
    latitude: 32.883,
    longitude: 13.1897
  },
  {
    name: "Liechtenstein",
    iso_code: "LI",
    iso_code3: "LIE",
    capital: "Vaduz",
    latitude: 47.1411,
    longitude: 9.5215
  },
  {
    name: "Lithuania",
    iso_code: "LT",
    iso_code3: "LTU",
    capital: "Vilnius",
    latitude: 54.6896,
    longitude: 25.2799
  },
  {
    name: "Luxembourg",
    iso_code: "LU",
    iso_code3: "LUX",
    capital: "Luxembourg",
    latitude: 49.61,
    longitude: 6.1296
  },
  {
    name: "Madagascar",
    iso_code: "MG",
    iso_code3: "MDG",
    capital: "Antananarivo",
    latitude: -18.9201,
    longitude: 47.5237
  },
  {
    name: "Malawi",
    iso_code: "MW",
    iso_code3: "MWI",
    capital: "Lilongwe",
    latitude: -13.9899,
    longitude: 33.7703
  },
  {
    name: "Malaysia",
    iso_code: "MY",
    iso_code3: "MYS",
    capital: "Kuala Lumpur",
    latitude: 3.1502,
    longitude: 101.7077
  },
  {
    name: "Maldives",
    iso_code: "MV",
    iso_code3: "MDV",
    capital: "Malé"
  },
  {
    name: "Mali",
    iso_code: "ML",
    iso_code3: "MLI",
    capital: "Bamako",
    latitude: 12.653,
    longitude: -7.9864
  },
  {
    name: "Malta",
    iso_code: "MT",
    iso_code3: "MLT",
    capital: "Valletta",
    latitude: 35.9042,
    longitude: 14.5189
  },
  {
    name: "Marshall Islands",
    iso_code: "MH",
    iso_code3: "MHL",
    capital: "Majuro"
  },
  {
    name: "Martinique",
    iso_code: "MQ",
    iso_code3: "MTQ",
    capital: ""
  },
  {
    name: "Mauritania",
    iso_code: "MR",
    iso_code3: "MRT",
    capital: "Nouakchott",
    latitude: 18.0669,
    longitude: -15.99
  },
  {
    name: "Mauritius",
    iso_code: "MU",
    iso_code3: "MUS",
    capital: "Port Louis",
    latitude: -20.1654,
    longitude: 57.4896
  },
  {
    name: "Mayotte",
    iso_code: "YT",
    iso_code3: "MYT",
    capital: ""
  },
  {
    name: "Mexico",
    iso_code: "MX",
    iso_code3: "MEX",
    capital: "Mexico City",
    latitude: 19.4271,
    longitude: -99.1276
  },
  {
    name: "Micronesia (Federated States of)",
    iso_code: "FM",
    iso_code3: "FSM",
    capital: "Palikir"
  },
  {
    name: "Moldova",
    iso_code: "MD",
    iso_code3: "MDA",
    capital: "Chisinau",
    latitude: 47.0167,
    longitude: 28.8497
  },
  {
    name: "Monaco",
    iso_code: "MC",
    iso_code3: "MCO",
    capital: "Monaco",
    latitude: 43.7325,
    longitude: 7.4189
  },
  {
    name: "Mongolia",
    iso_code: "MN",
    iso_code3: "MNG",
    capital: "Ulaanbaatar"
  },
  {
    name: "Montenegro",
    iso_code: "ME",
    iso_code3: "MNE",
    capital: "Podgorica",
    latitude: 42.4602,
    longitude: 19.2595
  },
  {
    name: "Montserrat",
    iso_code: "MS",
    iso_code3: "MSR",
    capital: ""
  },
  {
    name: "Morocco",
    iso_code: "MA",
    iso_code3: "MAR",
    capital: "Rabat",
    latitude: 33.9905,
    longitude: -6.8704
  },
  {
    name: "Mozambique",
    iso_code: "MZ",
    iso_code3: "MOZ",
    capital: "Maputo",
    latitude: -25.9686,
    longitude: 32.5804
  },
  {
    name: "Myanmar",
    iso_code: "MM",
    iso_code3: "MMR",
    capital: "Naypyidaw"
  },
  {
    name: "Namibia",
    iso_code: "NA",
    iso_code3: "NAM",
    capital: "Windhoek",
    latitude: -22.5749,
    longitude: 17.0805
  },
  {
    name: "Nauru",
    iso_code: "NR",
    iso_code3: "NRU",
    capital: "Yaren"
  },
  {
    name: "Nepal",
    iso_code: "NP",
    iso_code3: "NPL",
    capital: "Kathmandu",
    latitude: 27.7058,
    longitude: 85.3157
  },
  {
    name: "Netherlands",
    iso_code: "NL",
    iso_code3: "NLD",
    capital: "Amsterdam",
    latitude: 52.3738,
    longitude: 4.891
  },
  {
    name: "New Caledonia",
    iso_code: "NC",
    iso_code3: "NCL",
    capital: ""
  },
  {
    name: "New Zealand",
    iso_code: "NZ",
    iso_code3: "NZL",
    capital: "Wellington"
  },
  {
    name: "Nicaragua",
    iso_code: "NI",
    iso_code3: "NIC",
    capital: "Managua",
    latitude: 12.1475,
    longitude: -86.2734
  },
  {
    name: "Niger",
    iso_code: "NE",
    iso_code3: "NER",
    capital: "Niamey",
    latitude: 13.5164,
    longitude: 2.1157
  },
  {
    name: "Nigeria",
    iso_code: "NG",
    iso_code3: "NGA",
    capital: "Abuja",
    latitude: 9.058,
    longitude: 7.4891
  },
  {
    name: "Niue",
    iso_code: "NU",
    iso_code3: "NIU",
    capital: ""
  },
  {
    name: "Norfolk Island",
    iso_code: "NF",
    iso_code3: "NFK",
    capital: ""
  },
  {
    name: "Northern Mariana Islands",
    iso_code: "MP",
    iso_code3: "MNP",
    capital: ""
  },
  {
    name: "North Macedonia",
    iso_code: "MK",
    iso_code3: "MKD",
    capital: "Skopje",
    latitude: 42.0024,
    longitude: 21.4361
  },
  {
    name: "Norway",
    iso_code: "NO",
    iso_code3: "NOR",
    capital: "Oslo",
    latitude: 59.9138,
    longitude: 10.7387
  },
  {
    name: "Oman",
    iso_code: "OM",
    iso_code3: "OMN",
    capital: "Muscat",
    latitude: 23.6086,
    longitude: 58.5922
  },
  {
    name: "Pakistan",
    iso_code: "PK",
    iso_code3: "PAK",
    capital: "Islamabad",
    latitude: 33.6751,
    longitude: 73.0946
  },
  {
    name: "Palau",
    iso_code: "PW",
    iso_code3: "PLW",
    capital: "Melekeok"
  },
  {
    name: "Panama",
    iso_code: "PA",
    iso_code3: "PAN",
    capital: "Panama City",
    latitude: 8.9943,
    longitude: -79.5188
  },
  {
    name: "Papua New Guinea",
    iso_code: "PG",
    iso_code3: "PNG",
    capital: "Port Moresby"
  },
  {
    name: "Paraguay",
    iso_code: "PY",
    iso_code3: "PRY",
    capital: "Asunción"
  },
  {
    name: "Peru",
    iso_code: "PE",
    iso_code3: "PER",
    capital: "Lima",
    latitude: -12.0931,
    longitude: -77.0465
  },
  {
    name: "Philippines",
    iso_code: "PH",
    iso_code3: "PHL",
    capital: "Manila",
    latitude: 14.579,
    longitude: 120.9726
  },
  {
    name: "Pitcairn",
    iso_code: "PN",
    iso_code3: "PCN",
    capital: ""
  },
  {
    name: "Poland",
    iso_code: "PL",
    iso_code3: "POL",
    capital: "Warsaw",
    latitude: 52.2297,
    longitude: 21.0122
  },
  {
    name: "Portugal",
    iso_code: "PT",
    iso_code3: "PRT",
    capital: "Lisbon",
    latitude: 38.7072,
    longitude: -9.1355
  },
  {
    name: "Puerto Rico",
    iso_code: "PR",
    iso_code3: "PRI",
    capital: ""
  },
  {
    name: "Qatar",
    iso_code: "QA",
    iso_code3: "QAT",
    capital: "Doha",
    latitude: 25.2948,
    longitude: 51.5082
  },
  {
    name: "Romania",
    iso_code: "RO",
    iso_code3: "ROU",
    capital: "Bucharest",
    latitude: 44.4479,
    longitude: 26.0979
  },
  {
    name: "Russia",
    iso_code: "RU",
    iso_code3: "RUS",
    capital: "Moscow",
    latitude: 55.7558,
    longitude: 37.6176
  },
  {
    name: "Rwanda",
    iso_code: "RW",
    iso_code3: "RWA",
    capital: "Kigali",
    latitude: -1.9441,
    longitude: 30.0619
  },
  {
    name: "R�union",
    iso_code: "RE",
    iso_code3: "REU",
    capital: ""
  },
  {
    name: "Saint Barth�lemy",
    iso_code: "BL",
    iso_code3: "BLM",
    capital: ""
  },
  {
    name: "Saint Helena",
    iso_code: "SH",
    iso_code3: "SHN",
    capital: ""
  },
  {
    name: "Saint Kitts and Nevis",
    iso_code: "KN",
    iso_code3: "KNA",
    capital: "Basseterre"
  },
  {
    name: "Saint Lucia",
    iso_code: "LC",
    iso_code3: "LCA",
    capital: "Castries",
    latitude: 13.9972,
    longitude: -60.0018
  },
  {
    name: "Saint Martin (French Part)",
    iso_code: "MF",
    iso_code3: "MAF",
    capital: ""
  },
  {
    name: "Saint Pierre and Miquelon",
    iso_code: "PM",
    iso_code3: "SPM",
    capital: ""
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso_code: "VC",
    iso_code3: "VCT",
    capital: "Kingstown"
  },
  {
    name: "Samoa",
    iso_code: "WS",
    iso_code3: "WSM",
    capital: "Apia"
  },
  {
    name: "San Marino",
    iso_code: "SM",
    iso_code3: "SMR",
    capital: "San Marino",
    latitude: 43.9424,
    longitude: 12.4578
  },
  {
    name: "Sao Tome and Principe",
    iso_code: "ST",
    iso_code3: "STP",
    capital: "São Tomé"
  },
  {
    name: "Sark",
    iso_code: "GG",
    iso_code3: "XXX",
    capital: ""
  },
  {
    name: "Saudi Arabia",
    iso_code: "SA",
    iso_code3: "SAU",
    capital: "Riyadh",
    latitude: 24.6748,
    longitude: 46.6977
  },
  {
    name: "Senegal",
    iso_code: "SN",
    iso_code3: "SEN",
    capital: "Dakar",
    latitude: 14.6953,
    longitude: -17.4439
  },
  {
    name: "Serbia",
    iso_code: "RS",
    iso_code3: "SRB",
    capital: "Belgrade",
    latitude: 44.8048,
    longitude: 20.4781
  },
  {
    name: "Seychelles",
    iso_code: "SC",
    iso_code3: "SYC",
    capital: "Victoria"
  },
  {
    name: "Sierra Leone",
    iso_code: "SL",
    iso_code3: "SLE",
    capital: "Freetown",
    latitude: 8.4697,
    longitude: -13.2659
  },
  {
    name: "Singapore",
    iso_code: "SG",
    iso_code3: "SGP",
    capital: "Singapore",
    latitude: 1.2894,
    longitude: 103.85
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso_code: "SX",
    iso_code3: "SXM",
    capital: ""
  },
  {
    name: "Slovakia",
    iso_code: "SK",
    iso_code3: "SVK",
    capital: "Bratislava",
    latitude: 48.2116,
    longitude: 17.1547
  },
  {
    name: "Slovenia",
    iso_code: "SI",
    iso_code3: "SVN",
    capital: "Ljubljana",
    latitude: 46.0514,
    longitude: 14.506
  },
  {
    name: "Solomon Islands",
    iso_code: "SB",
    iso_code3: "SLB",
    capital: "Honiara"
  },
  {
    name: "Somalia",
    iso_code: "SO",
    iso_code3: "SOM",
    capital: "Mogadishu",
    latitude: 2.0411,
    longitude: 45.3426
  },
  {
    name: "South Africa",
    iso_code: "ZA",
    iso_code3: "ZAF",
    capital: "Pretoria",
    latitude: -25.7463,
    longitude: 28.1876
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    iso_code: "GS",
    iso_code3: "SGS",
    capital: ""
  },
  {
    name: "South Sudan",
    iso_code: "SS",
    iso_code3: "SSD",
    capital: "Juba",
    latitude: 4.8594,
    longitude: 31.5713
  },
  {
    name: "Spain",
    iso_code: "ES",
    iso_code3: "ESP",
    capital: "Madrid",
    latitude: 40.4167,
    longitude: -3.7033
  },
  {
    name: "Sri Lanka",
    iso_code: "LK",
    iso_code3: "LKA",
    capital: "Sri Jayawardenapura Kotte"
  },
  {
    name: "State of Palestine",
    iso_code: "PS",
    iso_code3: "PSE",
    capital: "East Jerusalem"
  },
  {
    name: "Sudan",
    iso_code: "SD",
    iso_code3: "SDN",
    capital: "Khartoum",
    latitude: 15.5007,
    longitude: 32.5599
  },
  {
    name: "Suriname",
    iso_code: "SR",
    iso_code3: "SUR",
    capital: "Paramaribo",
    latitude: 5.8232,
    longitude: -55.1679
  },
  {
    name: "Svalbard and Jan Mayen Islands",
    iso_code: "SJ",
    iso_code3: "SJM",
    capital: ""
  },
  {
    name: "Sweden",
    iso_code: "SE",
    iso_code3: "SWE",
    capital: "Stockholm",
    latitude: 59.3328,
    longitude: 18.0645
  },
  {
    name: "Switzerland",
    iso_code: "CH",
    iso_code3: "CHE",
    capital: "Bern",
    latitude: 46.948,
    longitude: 7.4481
  },
  {
    name: "Syrian Arab Republic",
    iso_code: "SY",
    iso_code3: "SYR",
    capital: "Damascus",
    latitude: 33.5158,
    longitude: 36.2939
  },
  {
    name: "Tajikistan",
    iso_code: "TJ",
    iso_code3: "TJK",
    capital: "Dushanbe",
    latitude: 38.5737,
    longitude: 68.7738
  },
  {
    name: "Thailand",
    iso_code: "TH",
    iso_code3: "THA",
    capital: "Bangkok",
    latitude: 13.7573,
    longitude: 100.502
  },
  {
    name: "Timor-Leste",
    iso_code: "TL",
    iso_code3: "TLS",
    capital: "Dili",
    latitude: -8.5662,
    longitude: 125.588
  },
  {
    name: "Togo",
    iso_code: "TG",
    iso_code3: "TGO",
    capital: "Lomé"
  },
  {
    name: "Tokelau",
    iso_code: "TK",
    iso_code3: "TKL",
    capital: "Tokelau",
    latitude: -9.1668,
    longitude: -171.8182
  },
  {
    name: "Tonga",
    iso_code: "TO",
    iso_code3: "TON",
    capital: "Nukualofa"
  },
  {
    name: "Trinidad and Tobago",
    iso_code: "TT",
    iso_code3: "TTO",
    capital: "Port of Spain"
  },
  {
    name: "Tunisia",
    iso_code: "TN",
    iso_code3: "TUN",
    capital: "Tunis",
    latitude: 36.8117,
    longitude: 10.1761
  },
  {
    name: "Turkey",
    iso_code: "TR",
    iso_code3: "TUR",
    capital: "Ankara",
    latitude: 39.9439,
    longitude: 32.856
  },
  {
    name: "Turkmenistan",
    iso_code: "TM",
    iso_code3: "TKM",
    capital: "Ashgabat",
    latitude: 37.9509,
    longitude: 58.3794
  },
  {
    name: "Turks and Caicos Islands",
    iso_code: "TC",
    iso_code3: "TCA",
    capital: ""
  },
  {
    name: "Tuvalu",
    iso_code: "TV",
    iso_code3: "TUV",
    capital: "Funafuti"
  },
  {
    name: "Uganda",
    iso_code: "UG",
    iso_code3: "UGA",
    capital: "Kampala"
  },
  {
    name: "Ukraine",
    iso_code: "UA",
    iso_code3: "UKR",
    capital: "Kiev",
    latitude: 50.4422,
    longitude: 30.5367
  },
  {
    name: "United Arab Emirates",
    iso_code: "AE",
    iso_code3: "ARE",
    capital: "Abu Dhabi",
    latitude: 24.4764,
    longitude: 54.3705
  },
  {
    name: "United Republic of Tanzania",
    iso_code: "TZ",
    iso_code3: "TZA",
    capital: "Dodoma",
    latitude: -6.167,
    longitude: 35.7497
  },
  {
    name: "United States Minor Outlying Islands",
    iso_code: "UM",
    iso_code3: "UMI",
    capital: ""
  },
  {
    name: "United States of America",
    iso_code: "US",
    iso_code3: "USA",
    capital: "Washington D.C.",
    latitude: 38.8921,
    longitude: -77.0241
  },
  {
    name: "United States Virgin Islands",
    iso_code: "VI",
    iso_code3: "VIR",
    capital: ""
  },
  {
    name: "Uruguay",
    iso_code: "UY",
    iso_code3: "URY",
    capital: "Montevideo",
    latitude: -34.8941,
    longitude: -56.0675
  },
  {
    name: "Uzbekistan",
    iso_code: "UZ",
    iso_code3: "UZB",
    capital: "Tashkent",
    latitude: 41.3193,
    longitude: 69.2481
  },
  {
    name: "Vanuatu",
    iso_code: "VU",
    iso_code3: "VUT",
    capital: "Port Vila"
  },
  {
    name: "Venezuela",
    iso_code: "VE",
    iso_code3: "VEN",
    capital: "Caracas",
    latitude: 10.4961,
    longitude: -66.8983
  },
  {
    name: "Vietnam",
    iso_code: "VN",
    iso_code3: "VNM",
    capital: "Hanoi",
    latitude: 21.0341,
    longitude: 105.8372
  },
  {
    name: "Wallis and Futuna Islands",
    iso_code: "WF",
    iso_code3: "WLF",
    capital: ""
  },
  {
    name: "Western Sahara",
    iso_code: "EH",
    iso_code3: "ESH",
    capital: ""
  },
  {
    name: "Yemen",
    iso_code: "YE",
    iso_code3: "YEM",
    capital: "Sana'a"
  },
  {
    name: "Zambia",
    iso_code: "ZM",
    iso_code3: "ZMB",
    capital: "Lusaka"
  },
  {
    name: "Zimbabwe",
    iso_code: "ZW",
    iso_code3: "ZWE",
    capital: "Harare"
  },
  {
    name: "�land Islands",
    iso_code: "AX",
    iso_code3: "ALA",
    capital: ""
  }
];
