<template>
  <div class="card p-0 m-0">
    <div class="card-item">
      <div class="country-bubble-chart-header">
        <div class="country-bubble-chart-header__pv">
          <div class="table-header-left selectors">
            <Multiselect
              v-model="variantName"
              :options="chartSelectOptions"
              select-label
              placeholder="Please select a product"
              :show-labels="false"
              class="display-inline"
              style="min-width: 300px"
              @select="updateData"
            >
              <template #caret="{ toggle }">
                <div
                  class="multiselect__select"
                  @mousedown.prevent.stop="toggle"
                >
                  <i class="icon icon-down-arrow" />
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="country-bubble-chart-header__date">
          <DatePicker
            v-model:value="range"
            :clearable="false"
            type="date"
            range
            :disabled-date="disabledEndDate"
            placeholder="Data Select"
            @change="updateData"
          >
            <template #icon-calendar>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                  :fill="
                    range[0] !== null && range[1] !== null
                      ? '#003B6A'
                      : '#839AB5'
                  "
                />
              </svg>
            </template>
            <template #icon-clear>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                  fill="#003B6A"
                />
              </svg>
            </template>
          </DatePicker>
        </div>
      </div>
      <CountryBubbleChart :country="country" :chart-data="chartData" />
      <div :class="{ loading }">
        <div
          v-if="!loading && registeredPricesData.length > 0"
          class="country-bubble-chart-footer"
        >
          <span class="country-bubble-chart-footer__title">
            Current registered prices:
          </span>
          <div
            v-for="registeredPrice in registeredPricesData"
            :key="$helpers.toString(registeredPrice)"
            class="country-bubble-chart-footer__info"
          >
            <div>
              <span class="country-bubble-chart-footer__price-level">
                {{ `${registeredPrice.registered_price_level}` }}:
              </span>
            </div>

            <span class="country-bubble-chart-footer__price">
              {{ `${registeredPrice.price} ${registeredPrice.currency}` }}
            </span>
            <span
              v-if="registeredPrice.price_eur"
              class="country-bubble-chart-footer__price"
            >
              {{ `(${registeredPrice.price_eur} EUR)` }}
            </span>
          </div>
          <LastUpdatedArea :prices-data="registeredPricesData" />
        </div>
      </div>
      <div class="price-table-registration-prices">
        <CountryIconsBox
          with-prices
          :items="maxRegPriceData"
          :summary="maxRegPriceSummary"
          :item-key="'price_level'"
          :local-currency="country.currency"
          title="Calculation scheme:"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import CountryBubbleChart from '@/pages/PriceReferencing/CountryBubbleChart.vue';
import CountryIconsBox from '@/pages/PriceReferencing/CountryIconsBox.vue';
import LastUpdatedArea from '@/pages/PriceReferencing/LastUpdatedArea.vue';

export default {
  name: 'PriceTable',
  components: {
    CountryBubbleChart,
    CountryIconsBox,
    LastUpdatedArea,
  },
  props: {
    country: Object,
  },
  data() {
    return {
      productVariants: [],
      variantName: [],
      chartData: [],
      maxRegPriceData: [],
      maxRegPriceSummary: {},
      loading: false,
      range: [
        this.startDate
          ? this.startDate
          : new Date(new Date().getFullYear(), 0, 1),
        this.endDate ? this.endDate : new Date(),
      ],
      disabledBefore: new Date(2012, 0, 1),
      disabledAfter: new Date(2026, 0, 1),
      registeredPricesData: [],
    };
  },
  computed: {
    chartSelectOptions() {
      let pvNames = this.productVariants
        ? this.productVariants.map((pv) => pv.name)
        : [];
      return [...new Set(pvNames)];
    },
    selectedProductVariantId() {
      let selectedProductVariant = this.productVariants.find(
        (pv) => pv.name === this.variantName
      );
      return selectedProductVariant?.id;
    },
  },
  watch: {
    country: {
      deep: true,
      async handler() {
        await this.setNewCountry();
      },
    },
  },
  async mounted() {
    await this.setNewCountry();
  },
  methods: {
    disabledEndDate(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    async getData() {
      this.loading = true;
      if (this.selectedProductVariantId) {
        const result = await api.post(
          `/price_referencing/product_variant_prices_info`,
          JSON.stringify({
            country_iso: this.country.iso_code,
            pv_id: this.selectedProductVariantId,
            from_date: this.$helpers.dateToString(this.range[0]),
            to_date: this.$helpers.dateToString(this.range[1]),
          })
        );
        this.chartData = result.data.references;
        this.registeredPricesData = result.data.registered_prices;
        this.maxRegPriceData = result.data.max_reg_price.data;
        this.maxRegPriceSummary = result.data.max_reg_price.summary;
      }
      this.loading = false;
    },
    async updateData() {
      await this.getData();
    },
    async setNewCountry() {
      this.productVariants = this.country.product_variants;
      this.variantName = this.productVariants[0]?.name;
      await this.updateData();
    },
  },
};
</script>

<style scoped>
#bubble-chart {
  width: 100%;
  height: 500px;
}
</style>
