<template>
  <div class="products-table">
    <div class="products-table-title">
      <h1>Data Change Requests</h1>
    </div>

    <div v-if="loading" class="loading-roll is-active">
      <div class="loading-background" />
      <span><PulseLoader :loading="loading" /></span>
    </div>
    <HierarchyItemListHeader
      :loading="loading"
      :dropdowns-widths="dropdownsWidths"
      :model-keys-array="modelKeysArray"
      :model-values="modelValues"
      :model-options="modelOptions"
      :order-value="orderValue"
      :order-options="orderOptions"
      :items-per-page-value="itemsPerPageValue"
      :items-per-page-options="itemsPerPageOptions"
      :user-info="userInfo"
      @order-changed="filterChanged"
      @clear-filters="clearFilters"
      @click.self="closeFilters()"
    />
    <b-card no-body class="tabs-card">
      <b-tabs
        card
        content-class="mt-3"
        fill
        active-nav-item-class="font-weight-bold text-uppercase"
      >
        <b-tab no-body>
          <template #title @click.self="closeFilters()">
            <b-spinner
              v-if="loading"
              type="border"
              small
              class="tab-spinner"
            />

            <b-skeleton v-if="loading" width="90px" height="35px" />
            <div v-else class="chart-toggle-buttons report-toggle-area" @click.self="closeFilters()">
              <ExcelXlsxButton
                class="toggle-button"
                :data="items"
                :columns="xlsx_keys"
                :file-name="'data_change_requests'"
                :sheet-name="'data_change_requests'"
                @click.self="closeFilters()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22px"
                  height="22px"
                  viewBox="0 0 26 26"
                  :stroke="'#3b7d15'"
                >
                  <path
                    style="fill: #030104"
                    d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162   C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8   h-4V6h4V8z"
                  />
                  <path
                    style="fill: #ffffff"
                    d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678   H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037   c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107   L9.488,18.08L9.488,18.08z"
                  />
                </svg>
              </ExcelXlsxButton>
            </div>
          </template>
          <div
            v-if="items"
            class="row small-table-row"
          >
            <div class="col-md-12">
              <ListPage
                v-if="items"
                :items="items"
                :table-keys="[
                  'id',
                  'type',
                  'object',
                  'valid_from',
                  'valid_until',
                  'created_by',
                  'created_by_name',
                  'status',
                  'approved',
                  'last_modified'
                ]"
                :name="'data_change_requests'"
                :show-title="true"
                @close-filters="closeFilters()"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="d-flex align-items-center justify-content-center">
      <button
        v-if="!loading.selectors && !loading.table"
        class="btn btn-green load-more-button mt-3"
        @click="loadMore()"
      >
        <span>Load more</span>
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import HierarchyItemListHeader from '@/components/HierarchyItemList/HierarchyItemListHeader.vue';
import ListPage from '@/components/DataChangeRequests/ListPage.vue';
import ExcelXlsxButton from '@/components/ExcelXlsxButton.vue';

export default {
  name: 'DataChangeRequests',
  components: {HierarchyItemListHeader, ListPage, ExcelXlsxButton},
  props:{
    userInfo: Object,
  },
  data() {
    return {
      data: {},
      loading: false,
      items: [],
      page: 1,
      modelKeysArray: [],
      modelOptions: {},
      modelValues: {},
      itemsPerPageOptions: [20, 40, 60, 80, 100],
      itemsPerPageValue: 20,
      orderOptions: [],
      orderValue: { direction: 'ASC', field_name: 'id', full_name: 'id up' },
      dropdownsWidths: {},
      xlsx_keys: [
        {label: 'Type', field: 'type'},
        {label: 'Target', field: 'object'},
        {label: 'Status', field: 'status'},
        {label: 'Creator roles', field: 'created_by'},
        {label: 'Creator name', field: 'created_by_name'},
        {label: 'Valid from', field: 'valid_from'},
        {label: 'Valid to', field: 'valid_until'},
        {label: 'Approved on', field: 'approved'},
        {label: 'Modified on', field: 'last_modified'},
      ],
    };
  },
  async beforeMount() {
    this.loading = true;

    let result = await this.getData();
    this.items = result.data_change_requests

    let filterData = await this.getFilterData();
    this.processFilters(filterData)

    this.loading = false;
  },
  methods: {
    async getData() {
      const serializedObject = this.$helpers.serialize({
        ...this.modelValues,
        page: this.page,
        items_per_page: this.itemsPerPageValue,

      });
      const url = `/data_change_requests/get_data_change_requests.json${
        serializedObject === '' ? '' : `?${serializedObject}`
      }`;

      let result = await api.get(url);
      // const result = await api.get(
      //   `/data_change_requests/get_data_change_requests?page=${this.page}&items_per_page=${this.itemsPerPageValue}`
      // );
      // this.orderOptions = result.data.field_data.order_options.options.sort(
      //   this.$helpers.dynamicSort('full_name')
      // );

      return result.data
    },
    async getFilterData() {
      const serializedObject = this.$helpers.serialize({
        ...this.modelValues
      });
      const url = `/data_change_requests/get_filter_data.json${
        serializedObject === '' ? '' : `?${serializedObject}`
      }`;

      let result = await api.get(url);

      return result.data
    },
    async loadMore() {
      this.page += 1;

      const res = await this.getData();
      const newItems = res.data_change_requests;
      if (!this.$helpers.isEmptyArray(newItems)) {
        this.items = [...this.items, ...newItems];
      } else {
        this.page -= 1;
      }
    },
    async filterChanged(prop, val) {
      this[prop] = val;
      this.page = 1;
      this.itemsPerPageValue = 20;
      let result = await this.getData();
      this.items = result.data_change_requests;
    },
    processFilters(filterData){
      const modelOptions = filterData.field_data.model_options.field_data;
      // modelOptions['order_by'] = filterData.field_data.order_options.options.map(
      //   (orderOption) => orderOption.full_name
      // );

      for (const option in modelOptions) {
        modelOptions[option] = modelOptions[option].filter(
          (optionItem) => optionItem !== null && optionItem !== ''
        );
        if (typeof modelOptions[option][0] === 'string') {
          modelOptions[option] = modelOptions[option].sort();
        } else if (typeof modelOptions[option][0] === 'number') {
          modelOptions[option] = modelOptions[option].sort((a, b) => a - b);
        }
      }

      for (const prop in modelOptions) {
        this.modelOptions[prop] = modelOptions[prop];
        const modelOptionsCopy = [...modelOptions[prop]];
        modelOptionsCopy.push(prop);
        this.dropdownsWidths[prop] =
          modelOptionsCopy
            .filter((item) => item !== null)
            .reduce(function (a, b) {
              return a.length > b.length ? a : b;
            }).length * 9.5;
      }

      filterData.field_data.model_options.fields.map((field) => {
        this.modelValues[field] = '';
      });

      this.modelKeysArray = [
        // ...this.modelKeysArray,
        ...filterData.field_data.model_options.field_names,
      ];
    },
    async clearFilters() {
      for (const value in this.modelValues) {
        if (value === 'items_per_page') {
          this.modelValues[value] = 20;
        } else {
          this.modelValues[value] = '';
        }
      }
      let result = await this.getData();
      this.items = result.data_change_requests;
      let filterData = await this.getFilterData();
      this.processFilters(filterData)
    },
    closeFilters() {
      const item = document.getElementById('options-wrapper');
      item.style.display = 'none';
    },
  }
};
</script>
