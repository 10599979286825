<template>
  <div class="profit-center-variant-detail price-change-request" style="margin: 60px 10px 60px 0px !important;">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="d-flex align-items-start">
      <div class="card" style="margin-right: 0px !important">
        <div class="table-responsive overflow-x-clip">

          <table class="table table-striped-pricing borderless">
            <thead>
              <tr>
                <th>Name</th>
                <th><span class="ms-30">Status</span>
                </th>
                <th />
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              <template
                v-for="(brand, idx) in brands"
                :key="`${brand.name}${brand.id}${idx}`"
              >
                <tr>
                  <td style="background: #f4fcee" @click="openItemPage(brand)">
                    <div class="link-with-hover">{{ brand.name }}</div>
                    <progress-bar
                      v-if="brand && brand?.data_change_request?.status"
                      :bgcolor="'#A6DE7A'"
                      :completed="completed[brand.status][0]"
                      :notice="completed[brand.status][1]"
                      />
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-if="brand.status"
                      v-floating-tooltip="revisionTooltip(brand.reviewed)"
                      class="d-flex help ms-30"
                      :style="`color: ${$helpers.getStatusColor(
                        brand.status
                      )};`"
                    >
                      {{ $helpers.statusLineWithReviewerRole(brand) }}
                      <i
                        :class="$helpers.getIconStatusClass(brand.reviewed)"
                        class="ms-10 with-tooltip"
                      />
                    </div>
                  </td>
                  <td style="background: #f4fcee">
                    <div
                      v-if="![''].includes(brand.status)"
                      @click="openDetailsRow(idx, brand.id)"
                    >
                      <div class="info-quote-clickable d-flex">
                        {{ openedDetails.row == idx ? 'Hide' : 'Show' }} brand
                        price changes
                        <svg
                          v-floating-tooltip="
                            `Show ${
                              openedDetails.row == idx ? 'less' : 'more'
                            } information`
                          "
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-down-arrow clickable link ms-10"
                          :class="{ rotator: openedDetails.row == idx }"
                        >
                          <path
                            d="M11.601 1.60104C11.933 1.2691 11.933 0.730905 11.601 0.398959C11.2691 0.0670136 10.7309 0.0670136 10.399 0.398959L11.601 1.60104ZM6 6L5.39896 6.60104C5.73091 6.93299 6.26909 6.93299 6.60104 6.60104L6 6ZM1.60104 0.398959C1.2691 0.0670136 0.730905 0.0670136 0.398959 0.398959C0.0670137 0.730905 0.0670137 1.2691 0.398959 1.60104L1.60104 0.398959ZM10.399 0.398959L5.39896 5.39896L6.60104 6.60104L11.601 1.60104L10.399 0.398959ZM6.60104 5.39896L1.60104 0.398959L0.398959 1.60104L5.39896 6.60104L6.60104 5.39896Z"
                            fill="#9CB2CB"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                  <td style="background: #f4fcee">
                      <button
                        v-if="showApproveButton(brand)"
                        class="btn btn-success btn-sm ms-10 with-tooltip"
                        @click="$emit('openApproveModal', brand)"
                      >
                        <i class="icon mark-icon" />
                      </button>

                      <button
                        v-if="showRejectButton(brand)"
                        class="btn btn-warning btn-sm ms-10 with-tooltip"
                        @click="$emit('openRejectModal', brand.data_change_request, 'brand')"
                      >
                        <i class="icon reject-icon" />
                      </button>

                      <label class="btn btn-no-style btn-sm ms-10 with-tooltip"
                             style="background: #eee;"
                             v-if="brand.status != ''">
                        <RequestComments
                          style="padding-right: -10px"
                          :request="brand.data_change_request"
                          :user-info="userInfo"
                          :commentable-type="'BrandDataChangeRequest'"
                        />
                      </label>
                  </td>
                </tr>
                <!-- TODO: v-if user to be "!$helpers.isEmptyArray(brandPeriodicalData)" -->
                <tr
                  v-if="true"
                  :key="`${brand.name}_${brand.id}_${idx}_1`"
                  class="no-hover"
                >
                  <td
                    v-show="openedDetails.row === idx"
                    colspan="5"
                    class="opened-details"
                  >
                    <div class="plan-prices-table" style="max-width:90%">
                      <ProductPriceOverview
                        :periodical-data="brandPeriodicalData"
                        :user-info="userInfo"
                        @open-price-buildup="
                          (request) => $emit('openPriceBuildup', request)
                        "
                        @open-reject-modal="
                          (request) =>
                            $emit('openRejectModal', request, 'product')
                        "
                      />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api';
import ProductPriceOverview from '@/components/PricePlanning/ProductPriceOverview.vue';
import RequestComments from '@/components/PricePlanning/RequestComments.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  name: 'BrandRequestsList',
  components: { ProductPriceOverview, RequestComments, FadeLoader,ProgressBar },
  props: {
    brands: {},
    userInfo: Object,
    country: Object,
  },
  emits: ['approveChanges', 'openRejectModal', 'openPriceBuildup'],
  data() {
    return {
      openedDetails: {
        row: -1,
        id: null,
      },
      brandPeriodicalData: [],
      loading: false,
      completed: {
        "rejected": [15,"Brand manager: approve product requests."],
        "initialized": [50,"Brand manager: submit brand request."],
        "submitted": [75,"Head of marketing(Marketing analyst/Distribution manager): approve brand request."],
        "approved_one": [99,"Head of marketing(Marketing analyst/Distribution manager): approve brand request."],
        "approved_two": [99,"Head of marketing(Marketing analyst/Distribution manager): approve brand request."],
        "approved": [100,"Head of marketing: submit country request."],
      }
    };
  },
  watch: {
    brands() {
      if (this.openedDetails.id) this.getPeriodicalData(this.openedDetails.id);
    }
  },
  methods: {
    openItemPage(item) {
      this.$router.push(this.getItemUrl(item));
    },
    getItemUrl(item) {
      return `/price_plannings/${this.$route.params.id}/product_data_change_requests?country_id=${item.country_id}&brand_id=${item.id}`;
    },
    revisionTooltip(reviewed) {
      if (reviewed) {
        return 'Brand changes passed a review. Ready for approval.';
      } else {
        return 'Product changes must be reviewed.';
      }
    },
    approveButtonName() {
      return `Approve${this.$helpers.byRoleLine(this.userInfo.account_types)}`;
    },
    showApproveButton(brand) {
      let nextRoleScope;
      if (this.country?.user_roles?.includes('marketing_analyst') || this.country?.user_roles?.includes('distribution_manager')) {
        nextRoleScope = ['dcr_admin', 'distribution_manager', 'marketing_analyst'];
      } else {
        nextRoleScope = ['dcr_admin', 'head_of_marketing', 'distribution_manager', 'marketing_analyst'];
      }
      let incr = 0;
      if (this.country?.user_roles?.includes('marketing_analyst')) incr = incr + 1;
      if (this.country?.user_roles?.includes('distribution_manager')) incr = incr + 1;

      let condition0 =
        brand.data_change_request &&
        !this.$helpers.includedInRoleArray(
          brand.data_change_request.corrections[0].user.account_types,
          this.userInfo.account_types
        ) &&
        brand.status == 'approved_one' &&
        this.country?.user_roles?.includes('marketing_analyst') &&
        this.country?.user_roles?.includes('distribution_manager')&&
        this.$helpers.includedInRoleArray(
          this.userInfo.account_types,
          ['dcr_admin', 'distribution_manager', 'marketing_analyst']
        );
      let condition1 =
        brand.data_change_request &&
        this.$helpers.includedInRoleArray(
          brand.data_change_request.corrections[0].user.account_types,
          this.userInfo.account_types
        ) &&
        brand.status == 'submitted' &&
        this.$helpers.includedInRoleArray(
          nextRoleScope,
          this.userInfo.account_types
        );
      let condition2 =
        brand.data_change_request &&
        !this.$helpers.includedInRoleArray(
          brand.data_change_request.corrections[0].user.account_types,
          this.userInfo.account_types
        ) &&
        this.$helpers.includedInRoleArray(
          nextRoleScope,
          this.userInfo.account_types
        ) &&
        brand.status != 'initialized' &&
        brand.status != 'approved' && brand.status != 'approved_two';
      let condition3 =
        brand.data_change_request &&
        ['approved_two'].includes(brand.status) &&
        !this.$helpers.includedInRoleArray(
          brand.data_change_request.corrections[0].user.account_types,
          this.userInfo.account_types
        ) &&
        this.$helpers.includedInRoleArray(
          ['dcr_admin', 'head_of_marketing'],
          this.userInfo.account_types
        );
      let condition4 =
        brand.data_change_request &&
        ['approved_one'].includes(brand.status) &&
        !this.$helpers.includedInRoleArray(
          brand.data_change_request.corrections[0].user.account_types,
          this.userInfo.account_types
        ) &&
        this.$helpers.includedInRoleArray(
          ['dcr_admin', 'head_of_marketing'],
          this.userInfo.account_types
        ) && (incr == 1);
      let condition5 =
        brand.data_change_request &&
        ['approved_one', 'approved_two'].includes(brand.status) &&
        this.$helpers.includedInRoleArray(['dcr_admin'], this.userInfo.account_types);

      if (condition0 || condition1 || condition2 || condition3 || condition4 || condition5) {
        return true;
      } else {
        return false;
      }
    },
    showRejectButton(brand) {
      return (
        brand.status != 'rejected' &&
        brand.status != '' &&
        this.$helpers.includedInRoleArray(
          ['dcr_admin', 'head_of_marketing', 'distribution_manager', 'marketing_analyst'],
          this.userInfo.account_types
        )
      );
    },
    async openDetailsRow(index, id) {
      if (index === this.openedDetails.row) {
        this.openedDetails.row = -1;
      } else {
        this.getPeriodicalData(id);
        this.openedDetails.id = id;
        this.openedDetails.row = index;
      }
    },
    async getPeriodicalData(id) {
      this.loading = true;

      this.brandPeriodicalData = [];
      const url = `${this.$route.path}/get_brand_data?brand_id=${id}&country_id=${this.$route.query.country_id}`;
      let { data: response } = await api.get(url);
      this.brandPeriodicalData = response;

      this.loading = false;
    },
  },
};
</script>
