<template>
  <div>
    <div
      v-if="
        changeRequest &&
        !$helpers.isEmptyArray(changeRequest.corrections) &&
        changeRequest.changeable_type == 'Currency'
      "
    >
      <span class="comment-number">
        Last proposed by {{ lastCorrectionWithChanges.user.first_name }}
        {{ lastCorrectionWithChanges.user.last_name }} value:
      </span>
      <div class="m-3">
        <h4>{{ lastCorrectionWithChanges.values[field] }}{{ currencySign }}</h4>
      </div>
      <b-spinner
        v-if="requestInProgress"
        type="border"
        small
        class="tab-spinner"
      />
      <button
        v-if="!requestInProgress && readyForAppoval()"
        class="btn btn-success normal-button"
        @click="approveChanges()"
      >
        Approve
      </button>
      <button
        v-if="!requestInProgress && readyForFinishing()"
        class="btn btn-success normal-button"
        @click="finishRequest()"
      >
        Finish
      </button>
    </div>

    <hr />
    <template
      v-if="
        ['approved', 'rejected', 'initialized'].includes(
          changeRequest.status
        ) && changeRequest.changeable_type == 'Currency'
      "
    >
      <div class="form-group row ms-0">
        <h3>
          {{
            changeRequest && $helpers.isEmptyArray(changeRequest.corrections)
              ? 'Submit ' + $helpers.fieldToSpecificName(field) + ' changes'
              : 'Reject ' +
                $helpers.fieldToSpecificName(field) +
                ' with correction'
          }}
        </h3>
      </div>

      <RequestForm
        v-if="changeRequest"
        v-model:change-request="changeRequestModel"
        :field="field"
        :request-in-progress="requestInProgress"
        @reject-changes="rejectChanges"
      >
      </RequestForm>
      <hr />
    </template>

    <template
      v-if="
        ['Brand', 'Country', 'ProfitCenterVariant'].includes(
          changeRequest.changeable_type
        )
      "
    >
      <div class="d-flex align-items-start">
        <div class="table-responsive overflow-x-clip" style="width: 100% !important;">
          <table class="table table-notstriped-details borderless">
            <tbody>
              <tr>
                <td>
                  <div class="card plan-prices-table">
                    <ProductPriceOverview
                      :periodical-data="changeRequest.periodical_data"
                      :user-info="userInfo"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <div
      v-if="changeRequest && !$helpers.isEmptyArray(changeRequest.corrections)"
      class="form-group row ms-0"
    >
      <h3 class="display-inline">Correction history</h3>
      <span
        class="info-quote-clickable display-inline"
        @click="showCorrectionHistory(rowIndex)"
      >
        {{ openedDetails.historyRow === rowIndex ? 'Hide ' : 'Show ' }} history
      </span>
      <div v-show="openedDetails.historyRow === rowIndex" class="col-lg-10">
        <div
          v-for="correction in changeRequest.corrections"
          :key="correction.created_at"
        >
          <Correction
            :user-info="userInfo"
            :correction="correction"
            :currency-sign="currencySign"
          />
        </div>
      </div>
    </div>
    <hr />
    <div
      v-if="changeRequest.changeable_type == 'Currency'"
      class="form-group row ms-0"
    >
      <b-spinner
        v-if="requestInProgress"
        type="border"
        small
        class="tab-spinner"
      />
      <button
        v-else
        class="btn btn-danger normal-button"
        @click="discardRequest()"
      >
        Discard
      </button>
    </div>
    <button
      v-if="changeRequest.changeable_type != 'Currency' && $helpers.includedInRoleArray(userInfo.account_types, ['admin', 'dcr_admin'])"
      class="btn btn-danger normal-button"
      @click="discardRequest()"
    >
      Discard
    </button>
  </div>
</template>

<script>
import Correction from '@/components/CurrencyDataChangeRequest/Correction.vue';
import RequestForm from '@/components/CurrencyDataChangeRequest/RequestForm.vue';
import ProductPriceOverview from '@/components/PricePlanning/ProductPriceOverview.vue';

export default {
  name: 'RequestDetails',
  components: { Correction, RequestForm, ProductPriceOverview },
  props: {
    changeRequest: Object,
    field: String,
    userInfo: Object,
    rowIndex: Number,
    changeRequestWithCfo: {
      type: Boolean,
      default: false,
    },
    currencySign: {
      type: String,
      default: '',
    },
    requestInProgress: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'submitChanges',
    'rejectChanges',
    'approveChanges',
    'openFinishModal',
    'discardRequest',
    'update:changeRequest',
  ],
  data() {
    return {
      openedDetails: {
        row: -1,
        id: null,
        historyRow: -1,
      },
      defaultCorrection: {
        user: {
          first_name: 'Responsible',
          last_name: 'User',
        },
        values: { [this.field]: 0 },
      },
    };
  },
  computed: {
    lastCorrectionWithChanges() {
      if (
        this.changeRequest.corrections &&
        !this.$helpers.isEmptyArray(this.changeRequest.corrections)
      ) {
        return this.changeRequest.corrections.filter(
          (c) => c.status !== 'approved'
        )[0];
      } else {
        return this.defaultCorrection;
      }
    },
    changeRequestModel: {
      get() {
        return this.changeRequest;
      },
      set(value) {
        this.$emit('update:changeRequest', value);
      },
    },
  },
  methods: {
    readyForAppoval() {
      if (!['rejected', 'initialized'].includes(this.changeRequest.status))
        return false;
      if (
        this.$helpers.hasCurrencyApproveAccess(this.userInfo.account_types) &&
        !this.changeRequestWithCfo
      )
        return true;

      return this.changeRequest.corrections[0].user.id !== this.userInfo.id;
    },
    readyForFinishing() {
      return (
        this.changeRequest.status == 'approved' &&
        this.$helpers.hasCurrencyRequestFinishAccess(
          this.userInfo.account_types
        )
      );
    },
    showCorrectionHistory(index) {
      if (index === this.openedDetails.historyRow) {
        this.openedDetails.historyRow = -1;
      } else {
        this.openedDetails.historyRow = index;
      }
    },
    submitChanges() {
      this.$emit('submitChanges', this.changeRequest);
    },
    rejectChanges() {
      this.$emit('rejectChanges', this.changeRequest);
    },
    approveChanges() {
      this.$emit('approveChanges', this.changeRequest);
    },
    finishRequest() {
      this.$emit('openFinishModal', this.changeRequest);
    },
    discardRequest() {
      this.$emit('discardRequest', this.changeRequest);
    },
  },
};
</script>
