<template>
  <div class="view">
    <div
      v-for="product in periodicalData"
      :key="`${product.info.id}${product.info.gpms_name}`"
      class="price-planning-details"
      style="margin-left: 10px; text-align: left;"
    >
      <h4
        class="mt-30 link_underlined"
        @click="
          visibilityList[product.info.id]
            ? showItem(product.info.id, false)
            : showItem(product.info.id, true)
        "
      >
        {{ product.info.gpms_name }}
        <span v-if="periodicalData.length > 1" class="quote-line-grayed ms-10">
          {{ visibilityList[product.info.id] ? 'Hide ' : 'Show ' }}product
          details
        </span>
      </h4>

      <table
        v-show="visibilityList[product.info.id]"
        class="price-planning-details__table_new"
      >
        <thead>
          <tr>
            <th class="sticky-col first-col" />
            <th />
            <th
              v-for="request in product.pdcrs"
              :key="`${request.status}${request.id}${request.reviewed}`"
            >
              <i
                :class="$helpers.getIconStatusClass(request.reviewed)"
                class="ms-10 with-tooltip display-inline"
                @click="openedCorrectionsModal(request)"
              />
              <div
                class="with-tooltip status-title mg-10-30"
                :style="`color: ${$helpers.getStatusColor(request.status)};`"
                @click="openedCorrectionsModal(request)"
              >
                {{ request.status }}
              </div>
              <div
                class="btn-group btn-group-toggle btn-group-sm hidden-inputs"
                data-toggle="buttons"
              >
                <template
                  v-if="
                    $route.path.includes('product_data_change_requests') &&
                    (request.status == 'initialized' ||
                      request.status == 'rejected')
                  "
                >
                  <label
                    v-floating-tooltip="'Edit request'"
                    class="btn btn-outline-secondary-opt btn-sm"
                    @click="$emit('openPriceBuildup', request)"
                  >
                    <input
                      id="option1"
                      type="radio"
                      name="options"
                      autocomplete="off"
                      checked
                    />
                    <i class="icon edit-pen-icon" />
                  </label>
                  <label
                    v-floating-tooltip="'Approve request'"
                    class="btn btn-success-opt btn-sm"
                    @click="$emit('approveChanges', request)"
                  >
                    <input
                      id="option2"
                      type="radio"
                      name="options"
                      autocomplete="off"
                    />
                    <i class="icon mark-icon" />
                  </label>
                  <label
                    v-floating-tooltip="'Discard request'"
                    class="btn btn-danger btn-sm"
                    @click="$emit('openDiscardModal', request)"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      autocomplete="off"
                    /><i class="icon trash-icon" />
                  </label>
                </template>

                <template
                  v-if="
                    request.status == 'approved' &&
                    $helpers.includedInRoleArray(
                      [
                        'dcr_admin',
                        'brand_manager',
                        'area_sales_manager',
                        'head_of_marketing',
                        'distribution_manager',
                        'marketing_analyst',
                      ],
                      userInfo.account_types
                    )
                  "
                >
                  <label
                    v-floating-tooltip="'Reject request'"
                    class="btn btn-warning btn-sm"
                    @click="$emit('openRejectModal', request)"
                  >
                    <input
                      id="option3"
                      type="radio"
                      name="options"
                      autocomplete="off"
                    />
                    <i class="icon reject-icon" />
                  </label>
                </template>

                <label
                  v-floating-tooltip="'Comment request'"
                  class="btn btn-outline-secondary-opt btn-sm"
                >
                  <input
                    id="option3"
                    type="radio"
                    name="options"
                    autocomplete="off"
                  />
                  <RequestComments
                    style="padding-right: -10px"
                    :request="request"
                    :user-info="userInfo"
                    :commentable-type="'ProductDataChangeRequest'"
                  />
                </label>
              </div>
            </th>
          </tr>
          <tr>
            <th class="sticky-col first-col" />
            <th v-for="(period, i) in product.periods" :key="i">
              <div
                v-if="$helpers.isEmptyArray(period)"
                class="details-header-title"
              >
                Current values
              </div>
              <div v-else class="details-header-title">
                {{ $helpers.dayMonthYear(period[0]) }} -
                {{ $helpers.dayMonthYear(period[1]) }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(priceChange, i) in product.pricing"
            :key="$helpers.toString(priceChange)"
          >
            <td class="sticky-col first-col">
              {{ keysNames[keys[i]] }}
            </td>
            <td v-for="change in priceChange" :key="$helpers.toString(change)">
              <div
                v-if="
                  change['deviation_eur'] !== null ||
                  change['deviation_loc'] !== null
                "
                class="price-planning-details__diff_right"
              >
                <span :class="diffClass(change['deviation_eur'])">
                  {{ toSignedPercent(change['deviation_eur']) }}
                </span><br v-if="change['deviation_loc']">
                <span
                  v-if="change['deviation_loc']"
                  :class="diffClass(change['deviation_loc'])"
                >
                  {{ toSignedPercent(change['deviation_loc']) }}
                </span>
              </div>
              <div
                v-if="change['values']"
                class="price-planning-details__price"
              >
                <div class="price-planning-details__eur-mean">
                  {{ formatPrice(change['values']['eur_val']) }}
                  {{ $helpers.getCurrencySymbol('EUR') }}
                </div>
                <div
                  v-if="change['values']['loc_val']"
                  class="price-planning-details__local-mean"
                >
                  {{ formatPrice(change['values']['loc_val']) }}
                  {{ $helpers.getCurrencySymbol(change['values']['currency']) }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CorrectionsModal
        v-if="showModal"
        :change-request="correctionsModalRequest"
        :user-info="userInfo"
        @close="showModal = false"
      />
    </div>
  </div>
</template>

<script>
import RequestComments from '@/components/PricePlanning/RequestComments.vue';
import CorrectionsModal from '@/components/PricePlanning/Modals/CorrectionsModal.vue';

export default {
  name: 'ProductPriceOverview',
  components: { RequestComments, CorrectionsModal },
  props: {
    userInfo: Object,
    periodicalData: {},
  },
  emits: [
    'openRejectModal',
    'openDiscardModal',
    'openPriceBuildup',
    'approveChanges',
  ],
  data() {
    return {
      keys: [
        'lp1',
        'lp2',
        'plan_price',
        'ws_pprice_gross',
        'ph_pprice_gross',
        'ret_price_incl_vat',
      ],
      keysNames: {
        lp1: 'List price gross 1',
        lp2: 'List price gross 2',
        plan_price: 'Plan Price',
        ws_pprice_gross: 'FAP',
        ph_pprice_gross: 'AEP',
        ret_price_incl_vat: 'AVP',
      },
      visibilityList: {},
      showModal: false,
      correctionsModalRequest: {},
    };
  },
  mounted() {
    if (this.periodicalData && this.periodicalData.length == 1) {
      this.showItem(this.periodicalData[0].info.id, true);
    }
  },
  methods: {
    openedCorrectionsModal(request) {
      this.correctionsModalRequest = request;
      this.showModal = true;
    },
    formatPrice(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        useGrouping: false,
      });
    },
    toSignedPercent(value) {
      return `${value > 0 ? '+' : ''}${this.formatPrice(value.toFixed(2))}%`;
    },
    diffClass(price) {
      return {
        'plan-prices__diff-positive': price > 0,
        'plan-prices__diff-negative': price < 0,
      };
    },
    showItem(id, status) {
      if (status) {
        this.visibilityList[id] = true;
      } else {
        this.visibilityList[id] = false;
      }
    },
  },
};
</script>
