<template>
  <div
    v-if="!loading && items.length"
    class="card table-card"
    :class="{ loading }"
    :style="tableStyle"
  >
    <div class="table-responsive">
      <table class="table table-striped borderless table-card-table">
        <thead>
          <tr>
            <th v-if="showIdCol" scope="col">
              <IconText title="ID" icon="id-icon" />
            </th>
            <th v-if="isNotDataChangeRequests" scope="col">
              <IconText
                :title="`${title} name`"
                :icon="`${$helpers.fromCamelCaseToKebabCase(title)}-icon`"
              />
            </th>
            <th v-if="showShortNameCol" scope="col">
              <IconText
                title="Short name"
                :icon="`${$helpers.fromCamelCaseToKebabCase(title)}-icon`"
              />
            </th>
            <th v-if="showBrandCol" scope="col">
              <IconText title="Brand" icon="brand-icon" />
            </th>
            <th v-if="showCustomerGroupCol" scope="col">
              <IconText title="Customer Group" icon="manufacturer-icon" />
            </th>
            <th v-if="showCountryCol" scope="col">
              <IconText title="Country" icon="manufacturer-icon" />
            </th>
            <th v-if="showManufacturerCol" scope="col">
              <IconText title="Manufacturer" icon="manufacturer-icon" />
            </th>
            <th v-if="showProductCategoryCol" scope="col">
              <IconText title="Product category" icon="product-category-icon" />
            </th>
            <th v-if="showProductCatShortCol" scope="col">
              <IconText
                title="Product cat short"
                icon="product-category-icon"
              />
            </th>

            <th v-if="showTypeCol" scope="col">
              <IconText title="Type" icon="manufacturer-icon" />
            </th>
            <th v-if="showObjectCol" scope="col">
              <IconText title="Object" icon="id-icon" />
            </th>
            <th v-if="showCurrencyCol" scope="col">
              <IconText title="Currency" icon="manufacturer-icon" />
            </th>
            <th v-if="showExchangeRateCol" scope="col">
              <IconText title="Exchange Rate" icon="id-icon" />
            </th>
            <th v-if="showValidFromCol" scope="col">
              <IconText title="Valid From" icon="id-icon" />
            </th>
            <th v-if="showValidToCol" scope="col">
              <IconText title="Valid To" icon="id-icon" />
            </th>
            <th v-if="showCreatedByCol" scope="col">
              <IconText title="Created by" icon="id-icon" />
            </th>
            <th v-if="showStatusCol" scope="col">
              <IconText title="Status" icon="manufacturer-icon" />
            </th>
            <th v-if="showIsoCodeCol" scope="col">
              <IconText title="Iso Code3" icon="id-icon" />
            </th>
            <th v-if="showBusinessModelCol" scope="col">
              <IconText title="Business Model" icon="id-icon" />
            </th>

            <th v-if="showSalesCol" scope="col">
              <IconText title="Sales" />
            </th>
            <th v-if="showItemsCol" scope="col">
              <IconText title="Items" />
            </th>
            <th v-if="showPrinciplesWrongCol" scope="col">
              <IconText title="Principles Wrong" />
            </th>
            <th scope="col" />
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in items" :key="item.id + $route.path">
            <td v-if="showIdCol">
              {{ item.id }}
            </td>
            <td
              v-if="isNotDataChangeRequests"
              :class="'ps-47 link'"
              @click="$router.push(`${$route.path}/${item.id}`)"
            >
              {{ item.full_name ? item.full_name : item.name }}
            </td>
            <td
              v-if="showShortNameCol"
              :class="
                $route.path.includes('product_variants') ? 'ps-25' : 'ps-47'
              "
            >
              {{ item?.short_name || '—' }}
            </td>
            <td
              v-if="item.brand_id"
              class="link ps-25"
              @click="$router.push(`/brands/${item.brand_id}`)"
            >
              {{ item.brand_name }}
            </td>
            <td v-if="item.customer_group" class="ps-25">
              {{ item.customer_group }}
            </td>
            <td
              v-if="item.countries"
              @click="
                item.countries[0]
                  ? $router.push(`/countries/${item.countries[0].id}`)
                  : null
              "
              :class="item.countries[0] ? 'ps-25 link' : 'ps-25 text-muted'"
            >
              {{ item.countries[0] ? item.countries[0].name : 'No country' }}
            </td>
            <td
              v-if="item.manufacturer_id"
              class="link ps-25"
              @click="$router.push(`/manufacturers/${item.manufacturer_id}`)"
            >
              {{ item.manufacturer_name }}
            </td>
            <td v-if="showProductCategoryCol" class="ps-25">
              {{ item?.product_category || '—' }}
            </td>
            <td v-if="item.product_cat_short && !isBrandsPage" class="ps-57">
              {{ item.product_cat_short }}
            </td>
            <td
              v-if="item.changeable_type"
              class="ps-25 link"
              @click="$router.push(`${$route.path}/${item.id}`)"
            >
              {{ item.changeable_type }}
            </td>
            <td v-if="item.changeable" class="ps-25">{{ item.changeable }}</td>
            <td v-if="item.object && isNotDataChangeRequests" class="ps-25">
              {{ item.object }}
            </td>
            <td v-if="item.last_correction" class="ps-25">
              {{ item.last_correction }}
            </td>
            <td v-if="item.valid_from" class="ps-25">{{ item.valid_from }}</td>
            <td v-if="item.valid_until" class="ps-25">
              {{ item.valid_until }}
            </td>
            <td v-if="item.initiator" class="ps-25">
              {{ item.initiator.join(', ') }}
            </td>
            <td v-if="item.status" class="ps-25">{{ item.status }}</td>
            <td
              v-if="item.iso_code3"
              @click="$router.push(`${$route.path}/${item.id}`)"
              class="ps-25 link"
            >
              {{ item.iso_code3 }}
            </td>
            <td
              v-if="item.business_model"
              @click="$router.push(`${$route.path}/${item.id}`)"
              class="ps-25 link"
            >
              {{ item.business_model }}
            </td>

            <td>
              <b-dropdown>
                <template
                  v-if="$helpers.hasAdminAccess(userInfo)"
                  #button-content
                >
                  <i class="icon dots link" />
                </template>
                <b-dropdown-item @click="$emit('openEditModal', item.id)">
                  Edit
                </b-dropdown-item>
                <b-dropdown-item
                  @click="$emit('deleteConfirmationModal', item.id)"
                >
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </td>
            <td v-if="item.sales">{{ item.sales }}</td>
            <td v-if="item.items">{{ item.items }}</td>
            <td v-if="item.principlesWrong">
              <span
                :class="{ link: item.principlesWrong.length }"
                @click="openPrinciplesModal(item.principlesWrong)"
              >
                {{ item.principlesWrong.length }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CommentsModal
      v-if="showPrinciplesModal"
      :container-width="315"
      @close="showPrinciplesModal = false"
    >
      {{ principlesWrongModal }}
    </CommentsModal>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import IconText from '@/components/IconText.vue';
  import CommentsModal from '@/components/Modals/CommentsModal/index.vue';

  const { items, title, tableStyle, loading, userInfo } = defineProps<{
    items?: any[];
    title?: string;
    tableStyle?: any;
    loading?: boolean;
    userInfo?: any;
  }>();

  defineEmits(['deleteConfirmationModal', 'openEditModal']);

  const route = useRoute();

  const showPrinciplesModal = ref(false);
  const principlesWrongModal = ref([]);

  const isNotDataChangeRequests = computed(
    () => !route.path.includes('data_change_requests')
  );
  const isBrandsPage = computed(() => route.path.endsWith('brands'));

  const showIdCol = computed(() => isNotDataChangeRequests && !isBrandsPage);
  const showShortNameCol = computed(() => items[0].short_name && !isBrandsPage);
  const showBrandCol = computed(() => items[0].brand_id);
  const showCustomerGroupCol = computed(() => items[0].customer_group);
  const showCountryCol = computed(() => items[0].countries);
  const showManufacturerCol = computed(() => items[0].manufacturer_id);
  const showProductCategoryCol = computed(() => items[0].product_category);
  const showProductCatShortCol = computed(
    () => items[0].product_cat_short && !isBrandsPage
  );
  const showTypeCol = computed(() => items[0].changeable_type);
  const showObjectCol = computed(() => items[0].changeable);
  const showCurrencyCol = computed(
    () => items[0].object && isNotDataChangeRequests
  );
  const showExchangeRateCol = computed(() => items[0].last_correction);
  const showValidFromCol = computed(() => items[0].valid_from);
  const showValidToCol = computed(() => items[0].valid_until);
  const showCreatedByCol = computed(() => items[0].initiator);
  const showStatusCol = computed(() => items[0].status);
  const showIsoCodeCol = computed(() => items[0].iso_code3);
  const showBusinessModelCol = computed(() => items[0].business_model);
  const showSalesCol = computed(() => items[0].sales);
  const showItemsCol = computed(() => items[0].items);
  const showPrinciplesWrongCol = computed(() => items[0].principlesWrong);

  const openPrinciplesModal = (principlesWrong: any[]) => {
    showPrinciplesModal.value = true;
    principlesWrongModal.value = principlesWrong;
  };

  const sortByRow = (key) => {
    if (key === this.sortKey) {
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
    }
    this.sortKey = key;
  };
</script>
