<template>
  <div class="products-table">
    <div v-if="loading" class="disable" />
    <fade-loader :loading="loading" :color="'#fff'" />
    <div class="products-table-title">
      <h1>{{ data.pcv_name }} Price Buildup</h1>
      <br />
      <h4>{{ data.status }}</h4>
    </div>

    <div class="d-flex justify-content-left mt-30 mb-30 ms-30">
      <button
        class="buildup-card-right-panel-button btn btn-outline-dark vertical-middle"
        @click="returnToList"
      >
        <i
          class="icon back-button display-inline me-30 vertical-middle"
        />
        <span class="property-title-label display-inline vertical-middle">
          Back to Products
        </span>
      </button>
    </div>

    <div class="ms-0 mb-30">
      <div class="display-inline">
        <label>Price Validity Period:</label><br>
        <DatePicker
          ref="datepicker"
          v-model:value="validDates"
          :clearable="false"
          type="day"
          range
          :disabled-date="disabledDates"
          placeholder="Date Select"
          @change="saveDatePeriod()"
          :open="isVisibleDatePicker"
          :close="!isVisibleDatePicker"
          @close="isVisibleDatePicker = false"
          @open="isVisibleDatePicker = true"
        >
          <template #icon-calendar>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 0C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75ZM16.5 5.5H1.5V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V5.5ZM4.75 11.5C5.44036 11.5 6 12.0596 6 12.75C6 13.4404 5.44036 14 4.75 14C4.05964 14 3.5 13.4404 3.5 12.75C3.5 12.0596 4.05964 11.5 4.75 11.5ZM9 11.5C9.69036 11.5 10.25 12.0596 10.25 12.75C10.25 13.4404 9.69036 14 9 14C8.30964 14 7.75 13.4404 7.75 12.75C7.75 12.0596 8.30964 11.5 9 11.5ZM4.75 7.5C5.44036 7.5 6 8.05964 6 8.75C6 9.44036 5.44036 10 4.75 10C4.05964 10 3.5 9.44036 3.5 8.75C3.5 8.05964 4.05964 7.5 4.75 7.5ZM9 7.5C9.69036 7.5 10.25 8.05964 10.25 8.75C10.25 9.44036 9.69036 10 9 10C8.30964 10 7.75 9.44036 7.75 8.75C7.75 8.05964 8.30964 7.5 9 7.5ZM13.25 7.5C13.9404 7.5 14.5 8.05964 14.5 8.75C14.5 9.44036 13.9404 10 13.25 10C12.5596 10 12 9.44036 12 8.75C12 8.05964 12.5596 7.5 13.25 7.5ZM14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V4H16.5V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5Z"
                :fill="
                  range[0] !== null && range[1] !== null ? '#003B6A' : '#839AB5'
                "
              />
            </svg>
          </template>
          <template #icon-clear>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
              <path
                d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"
                fill="#003B6A"
              />
            </svg>
          </template>
        </DatePicker>
      </div>

      <div class="justify-content-left display-inline ms-30">
        <button
          v-if="request.status == 'initialized' || request.status == 'rejected'"
          class="btn btn-success btn-sm"
          @click="openApproveModal(request)"
        >
          Approve
        </button>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div class="card">
        <div class="table-responsive overflow-x-clip">
          <table class="table table-striped-details borderless">
            <thead class="price-change-request-header">
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Current</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <PriceLevelCard
                v-for="element in data.pbi_info_data"
                :key="element.key"
                :data="element"
                :current-price-buildup="previousData"
                :editing-field="editedField == element.key"
                :currency="data.currency_short"
                :edited-field="editedField"
                :is-error="errorField == element.key"
                :error-message="errorMessage"
                @edit-field="editField"
                @cancel-edit="cancelEdit"
                @save="saveChange"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <PopupModal
      v-if="popupModalVisible"
      v-model:changeRequest="changeRequest"
      :key="componentKey"
      :top="150"
      :left="650"
      :object-name="modalObjectName"
      :user-info="userInfo"
      :modal-action="modalAction"
      :reject-type="rejectType"
      @close="popupModalVisible = false"
      @approve-changes="approveChanges"
      @open-warning-modal="openWarningModal"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
import PopupModal from '@/components/PricePlanning/Modals/PopupModal.vue';
import PriceLevelCard from '@/components/PricePlanning/ProductLevelCard.vue';

export default {
  name: 'ProductPricePlanningPriceBuildup',
  components: { PriceLevelCard, FadeLoader, PopupModal },
  props: {
    userInfo: Object,
  },
  data() {
    return {
      isVisibleDatePicker: false,
      loading: false,
      data: {},
      editedField: '',
      previousData: {},
      errorMessage: '',
      errorField: '',
      validDates: [],
      disabledBefore: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ),
      disabledAfter: new Date(new Date().getFullYear() + 5, 11, 31),
      range: [new Date(), new Date(new Date().getFullYear(), 12, 31)],
      request: {
        type: Object,
        default: {},
      },
      popupModalVisible: false,
      componentKey: Math.random(),
      changeRequest: {},
      modalAction: '',
      modalObjectName: '',
    };
  },
  watch: {
    validDates() {
      this.isVisibleDatePicker = false;
    },
  },
  async beforeMount() {
    this.loading = true;
    await this.getPriceChangeRequest();
    this.loading = false;
  },
  methods: {
    disabledDates(date) {
      return date < this.disabledBefore || date > this.disabledAfter;
    },
    async getPriceChangeRequest() {
      const result = await api.get(`${this.$route.path}/price_buildup_data`);
      this.data = result.data.price_buildup_data;
      this.previousData = result.data.previous_price_buildup_data;
      this.request = result.data.product_data_change_request;
      if (this.request.main_data_change_request_id !== null) {
        this.openWarningModal()
      }
      this.validDates = [
        new Date(this.request.valid_from),
        new Date(this.request.valid_until),
      ];
    },
    editField(key) {
      this.editedField = key;
      this.errorField = '';
    },
    cancelEdit() {
      this.editedField = '';
    },
    async saveChange(key, value) {
      this.editedField = '';
      this.errorField = '';
      await api
        .patch(`/price_change_requests/${this.data.id}/update_field`, {
          key,
          value,
        })
        .then((response) => {
          this.data = response.data.price_buildup_data;
          this.request = response.data.product_data_change_request;
          this.validDates = [
            new Date(this.request.valid_from),
            new Date(this.request.valid_until),
          ];
          this.$helpers.notifySuccess('Field succesfully updated');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          let message = err.response.data.errors[errorField][0];

          this.errorMessage = message;
          this.$helpers.notifyError(message);
        });
    },
    async saveDatePeriod() {
      let valid_from = this.$helpers.dateToString(this.validDates[0]);
      let valid_until = this.$helpers.dateToString(this.validDates[1]);
      const result = await api
        .patch(`${this.$route.path}/update_date`, {
          valid_from,
          valid_until,
        })
        .then((response) => {
          this.data = response.data.price_buildup_data;
          this.request = response.data.product_data_change_request;
          this.validDates = [
            new Date(this.request.valid_from),
            new Date(this.request.valid_until),
          ];
          this.$helpers.notifySuccess('Date period updated');
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });

      if (result && result.status === 200) {
        this.data = result.data;
      }
      await this.getPriceChangeRequest();
    },
    openApproveModal(item) {
      this.componentKey = Math.random();
      this.changeRequest = item;
      this.popupModalVisible = true;
      this.modalAction = 'approveFiveYears';
      this.modalObjectName = item.object_name;
    },
    async approveChanges() {
      await api
        .post(`${this.$route.path}/approve`)
        .then(() => {
          this.$helpers.notifySuccess('Product Data Change Request Approved');
          this.returnToList();
        })
        .catch((err) => {
          const errorField = Object.keys(err.response.data.errors)[0];
          this.$helpers.notifyError(err.response.data.errors[errorField][0]);
        });
      this.popupModalVisible = false;
    },
    openWarningModal() {
      this.popupModalVisible = true
      this.modalAction = 'openWarningModal';
    },
    returnToList() {
      this.$router.push(
        `/price_plannings/${this.$route.params.id}/product_data_change_requests?country_id=${this.$route.query.country_id}&brand_id=${this.$route.query.brand_id}`
      );
    },
  },
};
</script>
