<template>
  <div class="container">
    <div class="row mb-30">
      <h1>Dashboard - Pharma Global</h1>
    </div>

    <div class="row" style="position: relative; margin-bottom: 5px;">
      <div class="col-md-4 col-lg-6">
        <div class="little-processes-info-card">
          <span class="icon" :class="setIconStatus(this.sales_status)"></span> 
          <span style='margin-left: 30px;'>Sales are {{ this.sales_status }}</span>
          <span class="icon" :class="setIconStatus(this.pcvs_status)" style="margin-left: 10px; display: inline-grid"></span> 
          <span style='margin-left: 40px;'>Products are {{ this.pcvs_status }}</span>
          <span class="icon" :class="setIconStatus(this.daily_status)" style="margin-left: 10px; display: inline-grid"></span> 
          <span style='margin-left: 40px;'>Daily process: {{ this.daily_status }}</span>
        </div>
      </div>
      <div class="col-md-4 col-lg-6">
        <div class="mb-30" style="margin-left: auto; width: fit-content;">
          <ToggleSwitch
            v-model="switchSaleType"
            :show-switch="true"
            :switch-options="switchSaleTypes"
            @change:model-value="getLittleCardsData"
          />
        </div>
      </div>
    </div>

    <div style="margin-bottom: 60px" class="row" v-if="isNetSales">
      <div class="col-md-6 col-lg-3">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          :title="textWithMonthName('Net Sales in €')"
          :value="littleCardsData[0]?.deviation_value"
          img="littleInfoCardPic1"
          :info="littleCardsData[0]?.total_info"
        />
      </div>
      <div class="col-md-6 col-lg-3">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          :title="textWithMonthName('Net Sales in Units')"
          :value="littleCardsData[1]?.deviation_value"
          img="littleInfoCardPic2"
          :info="littleCardsData[1]?.total_info"
        />
      </div>
      <div class="col-md-6">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          title="YTD Net Sales in € YTD"
          :value="littleCardsData[2]?.deviation_value"
          img="littleInfoCardPic3"
          :info="littleCardsData[2]?.total_info"
        />
      </div>
    </div>

    <div style="margin-bottom: 60px" class="row" v-if="!isNetSales">
      <div class="col-md-6 col-lg-3">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          :title="textWithMonthName('Gross Sales II in €')"
          :value="littleCardsData[0]?.deviation_value"
          img="littleInfoCardPic1"
          :info="littleCardsData[0]?.total_info"
        />
      </div>
      <div class="col-md-6 col-lg-3">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          :title="textWithMonthName('Gross Sales II in Units')"
          :value="littleCardsData[1]?.deviation_value"
          img="littleInfoCardPic2"
          :info="littleCardsData[1]?.total_info"
        />
      </div>
      <div class="col-md-6">
        <DashboardInfoCard
          :loading="littleCardsDataLoading"
          title="YTD Gross Sales II in € YTD"
          :value="littleCardsData[2]?.deviation_value"
          img="littleInfoCardPic3"
          :info="littleCardsData[2]?.total_info"
        />
      </div>
    </div>

    <div class="row mb-30 map-header">
      <h2>Net sales</h2>
    </div>

    <div style="margin-bottom: 60px">
      <MapGraph
        :loading="saleDeviationLoading"
        :sale-deviation="saleDeviation"
        :options="options"
        @get-sale-deviation="getSaleDeviation"
      />
    </div>

    <Chart
      object="sales"
      :ids="results.ids"
      :start-date="range[0]"
      :end-date="range[1]"
      :quote="defineQuote()"
    />
  </div>
</template>

<script>
import api from '@/helpers/api';
import Chart from '@/components/Chart/index.vue';
import MapGraph from '@/components/MapGraph.vue';
import { filtersFormEmpty } from '@/helpers/constants';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import DashboardInfoCard from '@/components/Dashboard/DashboardInfoCard.vue';

export default {
  name: 'DashboardPage',
  components: {
    Chart,
    MapGraph,
    ToggleSwitch,
    DashboardInfoCard
  },
  props: {
    results: Object,
  },
  emits: ['searchProfitCenterVariant'],
  data() {
    return {
      littleCardsData: [],
      saleDeviation: [],
      isNetSales: true,
      pcvs_status: '',
      sales_status: '',
      daily_status: '',
      switchSaleType: 'Net Sales',
      switchSaleTypes: ['Net Sales', 'Gross Sales II'],
      options: ['Month', 'YTD'],
      range: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      saleDeviationLoading: true,
      littleCardsDataLoading: true,
      orangeStatus: ['loading'],
      updatedDataStatus: ['updated', 'visible'],
      notUpdatedDataStatus: ['not started', 'not updated', 'not visible']
    };
  },
  mounted() {
    this.getSaleDeviation([
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ]);

    this.getDailyStatus();
    this.getLittleCardsData();
    this.getInfoProcessContent();
    this.$emit('searchProfitCenterVariant', {
      ...filtersFormEmpty,
      status: {
        value: 'active',
      },
    });
  },
  methods: {
    async getDailyStatus() {
      const result = await api.get('/sidekiq_info/get_daily_status');
      this.daily_status = result.data.status;
    },
    async getInfoProcessContent() {
      const result = await api.get('/dashboard/get_info_process_content');
      this.pcvs_status = result.data.pcvs_status;
      this.sales_status = result.data.sales_status;
    },
    async getSaleDeviation(range) {
      this.saleDeviationLoading = true;
      const result = await api.get(`/dashboard/get_map_info?&from_date=${range[0]}&to_date=${range[1]}`);
      let data = result.data;

      if (this.$helpers.isEmptyArray(data)) return;

      this.saleDeviation = data;
      this.saleDeviationLoading = false;
    },

    async getLittleCardsData(selectedOption) {
      let sales;
      let items;
      let sales_ytd;
      this.littleCardsDataLoading = true;

      if (selectedOption === 'Gross Sales II') {
        this.isNetSales = false
        sales = await api.get('/dashboard/get_info_card_content?type=sales')
        items = await api.get('/dashboard/get_info_card_content?type=items')
        sales_ytd = await api.get('/dashboard/get_info_card_content?type=sales_ytd')
      } else {
        this.isNetSales = true
        sales = await api.get('/dashboard/get_info_card_content?type=net_sales')
        items = await api.get('/dashboard/get_info_card_content?type=net_items')
        sales_ytd = await api.get('/dashboard/get_info_card_content?type=net_sales_ytd')
      }

      const result = await Promise.all([sales, items, sales_ytd])
        .then((values) => Promise.all(values.map((v) => v.data)));

      this.littleCardsData = result;
      this.littleCardsDataLoading = false;
    },

    defineQuote() {
      return this.addDateToText(
        'Source: SAP BW. Bundles are not shown as individual packages. Sales in Units: Bundles are shown as individual packages. Sales w/o Advertising Materials. Updated:'
      );
    },

    getMonthName() {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const date = new Date();

      return monthNames[date.getMonth()];
    },

    textWithMonthName(text) {
      const monthName = this.getMonthName();
      return text + ' ' + monthName;
    },

    addDateToText(text) {
      const date = new Date();
      const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      return text + ' ' + date.toLocaleDateString('de-DE', dateOptions);
    },

    setIconStatus(status) {
      if (this.notUpdatedDataStatus.includes(status)) {
        return 'status-not-updated-data';
      } else if (this.updatedDataStatus.includes(status)) {
        return 'status-updated-data';
      } else {
        return 'status-updating-data';
      }
    }
  }
};
</script>
