<template>
  <div v-if="loading" class="d-flex flex-column" style="min-height: 230px">
    <b-skeleton class="flex-grow-1 flex-shrink-1" />
  </div>
  <div v-else class="little-info-card">
    <div class="little-info-card-main">
      <div class="little-info-card-main-data">
        <span class="little-info-card-title">{{ title }}</span>
        <span class="little-info-card-value">{{
          $helpers.numberWithCommas(value)
        }}</span>
      </div>
      <div class="little-info-card-main-pic">
        <!-- <i :class="`icon ${img}`"/>-->
      </div>
    </div>
    <div class="little-info-card-footer">
      <div v-for="(couple_of_items, index) in grouped(info)" :key="index">
        <div class="me-30">
          <div
            v-for="(item, idx) in couple_of_items"
            :key="`${idx}${deviation(item)}${deviation(item)}`"
          >
            <div class="item-footer">
              <i :class="`icon ${direction(item)}-icon`" />
              <span
                :class="`little-info-card-percentage little-info-card-percentage-${direction(
                  item
                )}`"
                >{{ deviation(item) }}%</span
              >
              <span class="little-info-card-footer-text">
                {{ footerText(item) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardInfoCard',
  props: ['title', 'value', 'img', 'info', 'loading'],
  methods: {
    chooseDateName(title) {
      return title == 'Sales in € YTD' ? 'YTD' : 'month';
    },
    chooseSumType(title) {
      return title == 'Sales in Units month' ? 'units ' : '';
    },
    getKeyName(item) {
      return Object.keys(item)[0];
    },
    direction(item) {
      return item[this.getKeyName(item)].direction;
    },
    deviation(item) {
      return item[this.getKeyName(item)].deviation;
    },
    footerText(item) {
      switch (this.getKeyName(item)) {
        case 'share_of_budget':
          return 'Share of budget/year';
        case 'share_of_forecast':
          return 'Share of forecast/year';
        case 'budget':
        case 'forecast':
          return `Δ to ${this.getKeyName(item)}`;
      }
    },
    grouped(info) {
      let grouped = [];
      let index = -1;
      let infoLength = info ? info.length : 0;
      for (let i = 0; i < infoLength; i++) {
        if (i % 2 == 0) {
          index++;
          grouped[index] = [];
        }
        grouped[index].push(info[i]);
      }
      return grouped;
    },
  },
};
</script>
