export const countriesArray =
  [
    {code: "ER", name: "Eritrea"},
    {code: "ET", name: "Ethiopia"},
    {code: "TF", name: "French Southern Territories"},
    {code: "AZ", name: "Azerbaijan"},
    {code: "KZ", name: "Kazakhstan"},
    {code: "UA", name: "Ukraine"},
    {code: "DE", name: "Germany"},
    {code: "BI", name: "Burundi"},
    {code: "SC", name: "Seychelles"},
    {code: "SH", name: "Saint Helena"},
    {code: "AE", name: "United Arab Emirates"},
    {code: "DZ", name: "Algeria"},
    {code: "LY", name: "Libya"},
    {code: "MA", name: "Morocco"},
    {code: "SD", name: "Sudan"},
    {code: "TN", name: "Tunisia"},
    {code: "EH", name: "Western Sahara"},
    {code: "IO", name: "British Indian Ocean Territory"},
    {code: "MZ", name: "Mozambique"},
    {code: "MU", name: "Mauritius"},
    {code: "YT", name: "Mayotte"},
    {code: "RE", name: "R�union"},
    {code: "DJ", name: "Djibouti"},
    {code: "RW", name: "Rwanda"},
    {code: "MG", name: "Madagascar"},
    {code: "KE", name: "Kenya"},
    {code: "SS", name: "South Sudan"},
    {code: "UG", name: "Uganda"},
    {code: "TZ", name: "United Republic of Tanzania"},
    {code: "ZM", name: "Zambia"},
    {code: "ZW", name: "Zimbabwe"},
    {code: "AO", name: "Angola"},
    {code: "CM", name: "Cameroon"},
    {code: "CF", name: "Central African Republic"},
    {code: "TD", name: "Chad"},
    {code: "CG", name: "Congo"},
    {code: "CD", name: "Democratic Republic of the Congo"},
    {code: "GQ", name: "Equatorial Guinea"},
    {code: "GA", name: "Gabon"},
    {code: "ST", name: "Sao Tome and Principe"},
    {code: "BW", name: "Botswana"},
    {code: "SZ", name: "Eswatini"},
    {code: "LS", name: "Lesotho"},
    {code: "NA", name: "Namibia"},
    {code: "ZA", name: "South Africa"},
    {code: "BJ", name: "Benin"},
    {code: "BF", name: "Burkina Faso"},
    {code: "CV", name: "Cabo Verde"},
    {code: "CI", name: "C�te d�Ivoire"},
    {code: "GM", name: "Gambia"},
    {code: "EG", name: "Egypt"},
    {code: "CN", name: "China"},
    {code: "GN", name: "Guinea"},
    {code: "MW", name: "Malawi"},
    {code: "LR", name: "Liberia"},
    {code: "ML", name: "Mali"},
    {code: "MR", name: "Mauritania"},
    {code: "NE", name: "Niger"},
    {code: "NG", name: "Nigeria"},
    {code: "SN", name: "Senegal"},
    {code: "SL", name: "Sierra Leone"},
    {code: "TG", name: "Togo"},
    {code: "AI", name: "Anguilla"},
    {code: "AG", name: "Antigua and Barbuda"},
    {code: "AW", name: "Aruba"},
    {code: "BS", name: "Bahamas"},
    {code: "BB", name: "Barbados"},
    {code: "BQ", name: "Bonaire, Sint Eustatius and Saba"},
    {code: "VG", name: "British Virgin Islands"},
    {code: "KY", name: "Cayman Islands"},
    {code: "CU", name: "Cuba"},
    {code: "CW", name: "Cura�ao"},
    {code: "DM", name: "Dominica"},
    {code: "KM", name: "Comoros"},
    {code: "SO", name: "Somalia"},
    {code: "GH", name: "Ghana"},
    {code: "GW", name: "Guinea-Bissau"},
    {code: "DO", name: "Dominican Republic"},
    {code: "MS", name: "Montserrat"},
    {code: "GP", name: "Guadeloupe"},
    {code: "HT", name: "Haiti"},
    {code: "JM", name: "Jamaica"},
    {code: "PR", name: "Puerto Rico"},
    {code: "BL", name: "Saint Barth�lemy"},
    {code: "KN", name: "Saint Kitts and Nevis"},
    {code: "MF", name: "Saint Martin (French Part)"},
    {code: "VC", name: "Saint Vincent and the Grenadines"},
    {code: "SX", name: "Sint Maarten (Dutch part)"},
    {code: "GD", name: "Grenada"},
    {code: "TT", name: "Trinidad and Tobago"},
    {code: "MQ", name: "Martinique"},
    {code: "TC", name: "Turks and Caicos Islands"},
    {code: "VI", name: "United States Virgin Islands"},
    {code: "BZ", name: "Belize"},
    {code: "CR", name: "Costa Rica"},
    {code: "SV", name: "El Salvador"},
    {code: "GT", name: "Guatemala"},
    {code: "HN", name: "Honduras"},
    {code: "NI", name: "Nicaragua"},
    {code: "AR", name: "Argentina"},
    {code: "BO", name: "Bolivia (Plurinational State of)"},
    {code: "BV", name: "Bouvet Island"},
    {code: "BR", name: "Brazil"},
    {code: "CL", name: "Chile"},
    {code: "CO", name: "Colombia"},
    {code: "EC", name: "Ecuador"},
    {code: "FK", name: "Falkland Islands (Malvinas)"},
    {code: "GF", name: "French Guiana"},
    {code: "GY", name: "Guyana"},
    {code: "PY", name: "Paraguay"},
    {code: "GS", name: "South Georgia and the South Sandwich Islands"},
    {code: "SR", name: "Suriname"},
    {code: "UY", name: "Uruguay"},
    {code: "VE", name: "Venezuela"},
    {code: "BM", name: "Bermuda"},
    {code: "CA", name: "Canada"},
    {code: "GL", name: "Greenland"},
    {code: "US", name: "United States of America"},
    {code: "AQ", name: "Antarctica"},
    {code: "KG", name: "Kyrgyzstan"},
    {code: "TJ", name: "Tajikistan"},
    {code: "TM", name: "Turkmenistan"},
    {code: "UZ", name: "Uzbekistan"},
    {code: "HK", name: "Hong Kong"},
    {code: "MO", name: "China, Macao Special Administrative Region"},
    {code: "LC", name: "Saint Lucia"},
    {code: "PA", name: "Panama"},
    {code: "PE", name: "Peru"},
    {code: "PM", name: "Saint Pierre and Miquelon"},
    {code: "KP", name: "Democratic People's Republic of Korea"},
    {code: "MY", name: "Malaysia"},
    {code: "KR", name: "Korea"},
    {code: "BN", name: "Brunei Darussalam"},
    {code: "KH", name: "Cambodia"},
    {code: "MM", name: "Myanmar"},
    {code: "PH", name: "Philippines"},
    {code: "SG", name: "Singapore"},
    {code: "TH", name: "Thailand"},
    {code: "TL", name: "Timor-Leste"},
    {code: "VN", name: "Vietnam"},
    {code: "AF", name: "Afghanistan"},
    {code: "JP", name: "Japan"},
    {code: "BD", name: "Bangladesh"},
    {code: "BT", name: "Bhutan"},
    {code: "IR", name: "Iran"},
    {code: "ID", name: "Indonesia"},
    {code: "LA", name: "Lao People's Democratic Republic"},
    {code: "IN", name: "India"},
    {code: "MN", name: "Mongolia"},
    {code: "MV", name: "Maldives"},
    {code: "PK", name: "Pakistan"},
    {code: "NP", name: "Nepal"},
    {code: "BH", name: "Bahrain"},
    {code: "CY", name: "Cyprus"},
    {code: "GE", name: "Georgia"},
    {code: "LK", name: "Sri Lanka"},
    {code: "IL", name: "Israel"},
    {code: "JO", name: "Jordan"},
    {code: "KW", name: "Kuwait"},
    {code: "LB", name: "Lebanon"},
    {code: "OM", name: "Oman"},
    {code: "QA", name: "Qatar"},
    {code: "AM", name: "Armenia"},
    {code: "PS", name: "State of Palestine"},
    {code: "SY", name: "Syrian Arab Republic"},
    {code: "YE", name: "Yemen"},
    {code: "BY", name: "Belarus"},
    {code: "CZ", name: "Czech Republic"},
    {code: "HU", name: "Hungary"},
    {code: "FM", name: "Micronesia (Federated States of)"},
    {code: "RU", name: "Russia"},
    {code: "IQ", name: "Iraq"},
    {code: "SA", name: "Saudi Arabia"},
    {code: "SK", name: "Slovakia"},
    {code: "KI", name: "Kiribati"},
    {code: "MH", name: "Marshall Islands"},
    {code: "MD", name: "Moldova"},
    {code: "LV", name: "Latvia"},
    {code: "AX", name: "�land Islands"},
    {code: "NO", name: "Norway"},
    {code: "GR", name: "Greece"},
    {code: "GB", name: "Great Britain"},
    {code: "AL", name: "Albania"},
    {code: "FO", name: "Faroe Islands"},
    {code: "AD", name: "Andorra"},
    {code: "GG", name: "Guernsey"},
    {code: "SJ", name: "Svalbard and Jan Mayen Islands"},
    {code: "FI", name: "Finland"},
    {code: "VA", name: "Holy See"},
    {code: "IS", name: "Iceland"},
    {code: "IE", name: "Ireland"},
    {code: "LT", name: "Lithuania"},
    {code: "BA", name: "Bosnia and Herzegovina"},
    {code: "JE", name: "Jersey"},
    {code: "GI", name: "Gibraltar"},
    {code: "PT", name: "Portugal"},
    {code: "SM", name: "San Marino"},
    {code: "GG", name: "Sark"},
    {code: "MT", name: "Malta"},
    {code: "DK", name: "Denmark"},
    {code: "FR", name: "France"},
    {code: "LI", name: "Liechtenstein"},
    {code: "EE", name: "Estonia"},
    {code: "LU", name: "Luxembourg"},
    {code: "MC", name: "Monaco"},
    {code: "NL", name: "Netherlands"},
    {code: "CH", name: "Switzerland"},
    {code: "ME", name: "Montenegro"},
    {code: "AU", name: "Australia"},
    {code: "CX", name: "Christmas Island"},
    {code: "CC", name: "Cocos (Keeling) Islands"},
    {code: "HM", name: "Heard Island and McDonald Islands"},
    {code: "NZ", name: "New Zealand"},
    {code: "SI", name: "Slovenia"},
    {code: "NF", name: "Norfolk Island"},
    {code: "FJ", name: "Fiji"},
    {code: "NC", name: "New Caledonia"},
    {code: "SB", name: "Solomon Islands"},
    {code: "VU", name: "Vanuatu"},
    {code: "BE", name: "Belgium"},
    {code: "IM", name: "Isle of Man"},
    {code: "IT", name: "Italy"},
    {code: "MK", name: "North Macedonia"},
    {code: "PG", name: "Papua New Guinea"},
    {code: "GU", name: "Guam"},
    {code: "BG", name: "Bulgaria"},
    {code: "NR", name: "Nauru"},
    {code: "MP", name: "Northern Mariana Islands"},
    {code: "PW", name: "Palau"},
    {code: "UM", name: "United States Minor Outlying Islands"},
    {code: "AS", name: "American Samoa"},
    {code: "CK", name: "Cook Islands"},
    {code: "PF", name: "French Polynesia"},
    {code: "NU", name: "Niue"},
    {code: "PN", name: "Pitcairn"},
    {code: "WS", name: "Samoa"},
    {code: "TK", name: "Tokelau"},
    {code: "TO", name: "Tonga"},
    {code: "TV", name: "Tuvalu"},
    {code: "WF", name: "Wallis and Futuna Islands"},
    {code: "HR", name: "Croatia"},
    {code: "RS", name: "Serbia"},
    {code: "RO", name: "Romania"},
    {code: "AT", name: "Austria"},
    {code: "PL", name: "Poland"},
    {code: "ES", name: "Spain"},
    {code: "MX", name: "Mexico"},
    {code: "TR", name: "Turkey"},
    {code: "SE", name: "Sweden"}
  ]
